<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-square-spin" [fullScreen] = "true"><p style="color: white" ></p></ngx-spinner>

<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'admin.listadmin' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'Jurisdiction Type' | translate }} 
      </div>
    </div>
    <div class="panel-body">
      

      <div class="input-group mb-3">
        <label>{{ 'Jurisdiction' | translate }}</label>
        <select class="form-control" style="max-width: 200px;" (change)="jurisdictionLevelChange($event)">
          <option [value]="item.id" *ngFor="let item of jurisdictions">{{item.name}}</option>
        </select>
        <select class="form-control" style="max-width: 230px; margin-left: 30px;" (change)="jurisdictionChange($event)" #test>
          <option value="-1" #test>{{ 'SelectJuridiction' | translate }}</option>
          <option [value]="item.id" *ngFor="let item of allowUsersList" #test>{{item.login}}</option>
        </select>
      </div>
      <div style="text-align: center;">
        <button class="btn btn-success btn-sm" (click)="filterBtn()" [disabled]="test.value == -1">{{ 'Filter' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">list</span>
        {{ 'admin.listadmin' | translate }}
      </div>
    </div>
    <div class="panel-body"> 
      <div style="width:100%">
        <div style="text-align: left;float: left;">
          <button class="btn btn-success btn-sm" (click)="createNew()">{{ 'createAdministrator' | translate }}</button>
        </div>
        <div style="text-align: right;">
        <mat-form-field  >
          <mat-label>{{"Search" | translate}}...</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field> </div>
      </div>
      <div class="mat-elevation-z8" style="overflow: auto;">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> {{ ('username.title' | translate).toUpperCase() }} </th>
            <td mat-cell *matCellDef="let element"> {{element.username}}
            </td>
          </ng-container>

          <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef> {{ ('admin.fullname' | translate).toUpperCase() }}</th>
            <td mat-cell *matCellDef="let element"> {{element.fullname}}</td>
          </ng-container>

          <ng-container matColumnDef="level">
            <th mat-header-cell *matHeaderCellDef> {{ ('Level' | translate).toUpperCase() }} </th>
            <td mat-cell *matCellDef="let element"> {{element.level.split('|')[0]}} <br/>{{element.level.split('|')[1]}} </td>
          </ng-container>

          <ng-container matColumnDef="access">
            <th mat-header-cell *matHeaderCellDef> {{ ('Access' | translate).toUpperCase() }} </th>
            <td mat-cell *matCellDef="let element"> {{element.access}} </td>
          </ng-container>
         
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef> {{ ('Action' | translate).toUpperCase() }} </th>
            <td mat-cell *matCellDef="let element">
              <button class="btn btn-success btn-gradient btn-sm text-white button-action" role="button" (click)="onClickEdit(element.id)"><i class="fas fa-pen"></i></button>
              <button *ngIf="EditAdministratorPermit" class="btn btn-danger btn-gradient btn-sm text-white button-action" role="button" (click)="onClickRemove(element.id)"><i class="fas fa-trash-alt"></i></button>

            </td>
          </ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
<!-- [config]="{backdrop: false, ignoreBackdropClick: true}" -->
 <div style="overflow-y: auto" id="newAdminView" mdbModal #newAdminView="mdbModal" class="modal fade"
tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
aria-hidden="true">
<div class="modal-dialog modal-lg" role="document">
  <form [formGroup]="newAdminForm" (ngSubmit)="onNewAdminSubmit(newAdminForm.value)" >
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="newAdminView.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'admin.newadmin' | translate }}</h4>
      </div>
      <div class="modal-body">
        
        <div class="input-group mb-3">
          <label for="new_login">{{ 'Juridiction' | translate }}</label>
          <input id="new_login" formControlName="new_jurisdiction" type="text" class="form-control"
            spellcheck="false" style="max-width: 400px;" readonly/>
        </div>
        <div class="input-group mb-3">
          <label for="new_username">{{ 'admin.username' | translate }}*</label>
          <input id="new_username" formControlName="new_username" type="text" class="form-control"
            aria-label="new_username" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;"/>
           
        </div>
        <div class="input-group mb-3" style="padding-left:209px"> <div style="color:red" *ngIf="newAdminForm.get('new_username').errors?.required">
          <span><b>Username</b> is required</span>
        </div>  </div>
        <div class="input-group mb-3">
          <label for="new_password">{{ 'admin.password' | translate }}*</label>
          <input id="new_password" formControlName="new_password" type="password" class="form-control"
            spellcheck="false" style="max-width: 400px;"/>
        </div>
        <div class="input-group mb-3" style="padding-left:209px">  <div style="color:red" *ngIf="newAdminForm.get('new_password').errors?.required">
          <span><b>Password</b> is required</span>
        </div> </div>
        <div class="input-group mb-3">
          <label for="new_name">{{ 'Name' | translate }}</label>
          <input id="new_name" formControlName="new_name" type="text" class="form-control"
            aria-label="new_name" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;"/>
        </div>
        <div class="input-group mb-3">
          <label for="new_email">{{ 'admin.email' | translate }}*</label>
          <input id="new_email" formControlName="new_email" type="text" class="form-control"
            aria-label="new_email" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;"/>
           
       </div>
       <div class="input-group mb-3" style="padding-left:209px">
        <div style="color:red" *ngIf="newAdminForm.get('new_email').errors?.required">
          <span><b>Email</b> is required</span>
        </div>
       </div>
       
        <div class="input-group mb-3 justify-align-center">
          <label>{{ 'admin.access' | translate }}</label>
          <mdb-checkbox [inline]="true" [default]="true" formControlName="new_Access" class="d-flex"></mdb-checkbox>
        </div>
      </div>
       <div *ngIf="EditAdministratorPermit" class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'permits.title' | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column flex-md-row flex-wrap">
          <div *ngFor="let permit of permitsList" style="min-width: 250px;">
            <mdb-checkbox *ngIf="EditAdministratorPermit" #newPermitChk1 [inline]="true" [default]="true" [id]="'new.' + permit.name">{{  permit.name  | translate }}</mdb-checkbox>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect>{{ 'savebtn' | translate }}
        
        </button>
      </div>
    </div>
  </form>
</div>
</div>  

<!-- [config]="{backdrop: false, ignoreBackdropClick: true}" -->
<div style="overflow-y: auto" id="editAdminView"
mdbModal #editAdminView="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
aria-hidden="true">
<div class="modal-dialog modal-lg" role="document">
  <form [formGroup]="editAdminForm" (ngSubmit)="onEditAdminSubmit(editAdminForm.value)">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="editAdminView.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'Edit User Assistant ' | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="input-group mb-3">
        
          <input id="edit_parentId" formControlName="edit_parentId" type="text" class="form-control"
            spellcheck="false" style="max-width: 400px;" readonly hidden/>
        </div>
        <div class="input-group mb-3">
          <label for="edit_login">{{ 'Juridiction' | translate }}</label>
          <input id="edit_login" formControlName="edit_jurisdiction" type="text" class="form-control"
            spellcheck="false" style="max-width: 400px;" readonly/>
        </div>
        <div class="input-group mb-3">
          <label for="edit_username">{{ 'admin.username' | translate }}*</label>
          <input id="edit_username" formControlName="edit_username" type="text" class="form-control"
            aria-label="edit_username" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;"/>
        
          </div>
          <div class="input-group mb-3" style="padding-left:209px">
            <div style="color:red" *ngIf="editAdminForm.get('edit_username').errors?.required">
              <span><b>Username</b> is required</span>
            </div>
          </div>
        <div class="input-group mb-3">
          <label for="edit_password">{{ 'admin.password' | translate }}*</label>
          <input id="edit_password" formControlName="edit_password" type="password" class="form-control"
            spellcheck="false" style="max-width: 400px;"/>
        
        </div>
        <div class="input-group mb-3" style="padding-left:209px">   <div style="color:red" *ngIf="editAdminForm.get('edit_password').errors?.required">
          <span><b>Password</b> is required</span>
        </div></div>
        <div class="input-group mb-3">
          <label for="edit_name">{{ 'Name' | translate }}</label>
          <input id="edit_name" formControlName="edit_name" type="text" class="form-control"
            aria-label="edit_name" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;"/>
        </div>
        <div class="input-group mb-3">
          <label for="edit_email">{{ 'admin.email' | translate }}*</label>
          <input id="edit_email" formControlName="edit_email" type="text" class="form-control"
            aria-label="edit_email" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;"/>
           
          </div>
          <div class="input-group mb-3" style="padding-left:209px">
            <div style="color:red" *ngIf="editAdminForm.get('edit_email').errors?.required">
              <span><b>Email</b> is required</span>
            </div>
         </div>
        <div *ngIf="EditAdministratorPermit" class="input-group mb-3 justify-align-center">
          <label>{{ 'admin.access' | translate }}</label>
          <mdb-checkbox  [inline]="true" [default]="true" formControlName="edit_Access" class="d-flex"></mdb-checkbox>
        </div>
      </div>
      <div *ngIf="EditAdministratorPermit" class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'permits.title' | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column flex-md-row flex-wrap">
          <div *ngFor="let permit of permitsList" style="min-width: 250px;">
            <mdb-checkbox *ngIf="EditAdministratorPermit" #editPermitChk1 [inline]="true" [default]="true" [id]="'edit.' + permit.name">{{  permit.name  | translate }}</mdb-checkbox>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect>{{ 'savebtn' | translate }}
          <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="savingNewAdmin"></span>
        </button>
      </div>
    </div>
  </form>
</div>
</div>

<div style="overflow-y: auto" id="deleteConfirm" mdbModal #deleteConfirm="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="deleteConfirm.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'delete.title' | translate }}</h4>
      </div>
      <div class="modal-body">
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'delete.content' | translate }}</h4>
      </div>
      <div class="modal-footer">
        <button type="submit" mdbBtn color="danger" class="relative waves-light" mdbWavesEffect (click)="deleteAdmin()">{{ 'delete.confirm' | translate }}
          <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="savingNewAdmin"></span>
        </button>
        <button type="submit" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect (click)="deleteConfirm.hide()">{{ 'delete.cancel' | translate }}
          <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="savingNewAdmin"></span>
        </button>
      </div>
    </div>
  </div>
</div>
<div id="notificationModal" #notificationModal mdbModal #notificationChangeModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="notificationModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'Attention' | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            {{'Its necessary to select jurisdiction to create its administrator.' | translate}}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="notificationModal.hide()" mdbWavesEffect>{{ 'OK' | translate }}</button>
      </div>
    </div>
  </div>
</div>