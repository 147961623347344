<div id="topbar">
  <!-- <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'createjurisdiction' | translate }}</a>
    </li>
  </ol> -->
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title row">
        <div class="col-md-9">
          <span class="material-icons default-icon">list</span>
          {{ 'createjurisdiction' | translate }}
        </div>
      </div>
    </div>

    <div id="content" style="padding-top:0px">

      <div class="panel ">
        <div class="panel-body pn">
          <div class="row">
            <div class="col-md-5 panel-sidemenu va-t " style="padding-top:0px; padding-left:20px">
              <!-- <div style="text-align:right"> <a routerLink="/jurisdiction/list"> Manage Administrators</a></div> -->
              <div id="tree" class="tree-alt mt20">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="bg-theme">
                  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <li class="mat-tree-node">
                      <button mat-icon-button disabled></button>
                      <img src="../../../../assets/images/{{node.jurisdictionId}}.png" width="20px" height="20px"
                        style="margin-right: 3px">
                      <span style="font-weight: 700; color: green; cursor: pointer" (click)="openEditModal(node.id)">
                        {{node.login}} <i class="fas fa-edit" aria-hidden="true"></i>

                      </span>

                    </li>
                  </mat-tree-node>
                  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <li style="list-style: none">
                      <div class="mat-tree-node">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                          <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                        <img src="../../../../assets/images/{{node.jurisdictionId}}.png" width="20px" height="20px"
                          style="margin-right: 3px">
                        <span style="font-weight: 700; color: green; cursor: pointer" (click)="openEditModal(node.id)">
                          {{node.login}} <i class="fas fa-edit" aria-hidden="true"></i>

                        </span>

                      </div>
                      <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)" style="list-style: none">
                        <ng-container matTreeNodeOutlet></ng-container>
                      </ul>
                    </li>
                  </mat-nested-tree-node>
                </mat-tree>
              </div>
            </div>
            <div class="col-md-7 va-t" style="padding-left:20px">
              <form [formGroup]="createPlayerForm" (ngSubmit)="createPlayer(createPlayerForm.value)">
                <div class="pt-3">

                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <!-- <label for="login"> {{'Jurisdiction' | translate}} <span class="text-danger text-bold">*</span></label> -->
                      <label class="m-0 d-flex text-nowrap">Selecione uma Jurisdição <span class="pl-2 text-danger text-bold">*</span></label>
                    </div>
                    <div id="SelectJurisdiction-btn" class="col-md-7">
                      <button type="button" *ngIf="jurisdictionLoginId2" (click)="selectJurisdictionLevel(2, 'Nacional')"
                      class="btna w600 fs15 btnjur" data-type="2" style="background-color:#B22222">N</button>
                    <button type="button" *ngIf="jurisdictionLoginId3" (click)="selectJurisdictionLevel(3, 'Regional')"
                      class="btna w600 fs15 btnjur" data-type="3" style="background-color:#870099">R</button>
                    <button type="button" *ngIf="jurisdictionLoginId4" (click)="selectJurisdictionLevel(4, 'Distrito')"
                      class="btna w600 fs15 btnjur" data-type="4" style="background-color:#FF6600">D</button>
                    <button type="button" *ngIf="jurisdictionLoginId5" (click)="selectJurisdictionLevel(5, 'Local')"
                      class="btna w600 fs15 btnjur" data-type="5" style="background-color:#008702">L</button>
                    <button type="button" *ngIf="jurisdictionLoginId6" (click)="selectJurisdictionLevel(6, 'Clube')"
                      class="btna w600 fs15 btnjur" data-type="6" style="background-color:#116062">C</button>
                    <button type="button" *ngIf="jurisdictionLoginId7" (click)="selectJurisdictionLevel(7, 'Jogador')"
                      class="btna w600 fs15 btnjur" data-type="7" style="background-color:#116000">P</button>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-7 mt-3">
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="login"> {{'Jurisdiction Father' | translate}} <span class="text-danger text-bold">*</span></label>
                    </div>
                    <div class="col-md-7">
                      <select (change)="jurisdictionLevelChange($event.target.value)" [attr.disabled]="this.allowUsersList.length == 0 ? 'disabled' : null" class="form-control" formControlName="parentAdminId" [(ngModel)]="selectedParentAdminId">
                        <option value="">{{'Select-Jurisdiction-Father' | translate}}</option>
                        <option [value]="item.id" *ngFor="let item of allowUsersList">{{item.login}}</option>
                      </select>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-7 mt-3">
                      <div *ngIf="createPlayerForm.get('parentAdminId').invalid && (createPlayerForm.get('parentAdminId').touched || createPlayerForm.get('parentAdminId').dirty) || createPlayerForm.get('parentAdminId').value === null"
                        class="text-danger error-msg">
                        <ng-container *ngIf="createPlayerForm.get('parentAdminId').value == ''">
                          <span><b>{{'Select-Jurisdiction-Father' | translate}}!</b></span>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="login"> {{'Jurisdiction-to-be-created' | translate}}<span class="text-danger text-bold">*</span></label>
                    </div>
                    <div class="col-md-7">
                      {{jurisdictionName}}
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-7 mt-3">
                    </div>
                  </div>

                  <div *ngIf="this.isPlayer != true" class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="commission">{{ 'Commission' | translate }} ({{ this.parentComission }}%) <span class="text-danger text-bold">*</span></label>
                    </div>
                    <div class="col-md-7">
                      <select (click)="getNumberArray(this.parentComission)" class="form-control" formControlName="commission" [attr.disabled]="!createPlayerForm.get('parentAdminId').value ? 'disabled' : null">
                          <option [value]="index" *ngFor="let index of commissionArray">{{ index }}%</option>
                      </select>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-7 mt-3">
                      <div *ngIf="createPlayerForm.get('commission').invalid && (createPlayerForm.get('commission').touched || createPlayerForm.get('commission').dirty) || createPlayerForm.get('commission').value === null"
                        class="text-danger error-msg">
                        <ng-container *ngIf="!createPlayerForm.get('parentAdminId')">
                          <span><b>{{ 'Select-Jurisdiction-Comission-error' | translate }}</b></span>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="userName"> {{'Username' | translate}} <span class="text-danger text-bold">*</span></label>
                    </div>
                    <div class="col-md-7">
                      <input (keyup)="verifyIfPrefixNameWasErased(userName.value)" #userName type="text" id="userName" formControlName="userName" placeholder="Username"
                        class="form-control">
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-7 mt-3">
                      <div
                        *ngIf="createPlayerForm.get('userName').invalid && (createPlayerForm.get('userName').touched || createPlayerForm.get('userName').dirty)"
                        class="text-danger error-msg">
                        <span><b>{{'Username-required' | translate}}</b></span>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3" *ngIf="this.isClub">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="jurisdictionCoupon">{{'friend-coupon' | translate}}</label>
                    </div>
                    <div class="col-md-2">
                      <input #jurisdictionCoupon type="text" disabled id="jurisdictionCoupon" formControlName="jurisdictionCoupon" class="form-control">
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-7 mt-3">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="name"> {{'Name' | translate}} <span class="text-danger text-bold">*</span></label>
                    </div>
                    <div class="col-md-7">
                      <input type="text" id="name" formControlName="name" placeholder="{{'Name' | translate}}" class="form-control">
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-7 mt-3">
                      <div
                        *ngIf="createPlayerForm.get('name').invalid && (createPlayerForm.get('name').touched || createPlayerForm.get('name').dirty)"
                        class="text-danger error-msg">
                        <span><b>{{'Name-required' | translate}}</b></span>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="lastname"> {{'Last Name' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="text" id="lastname" formControlName="lastname" placeholder="{{'Last Name' | translate}}"
                        class="form-control">
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="password"> {{'Password' | translate}} <span class="text-danger text-bold">*</span></label>
                    </div>
                    <div class="col-md-7">
                      <input type="password" id="password" formControlName="password" placeholder="{{'Password' | translate}}"
                        class="form-control">
                    </div>
                    <div class="col-md-4"></div>
                    <div
                      *ngIf="createPlayerForm.get('password').invalid && (createPlayerForm.get('password').dirty || createPlayerForm.get('password').touched)"
                      class="text-danger error-msg col-md-7 mt-3">
                      <div *ngIf="createPlayerForm.get('password').errors?.required">
                        <span><b>{{'Password-required' | translate}}</b></span>
                      </div>
                      <div *ngIf="createPlayerForm.get('password').errors?.minlength">
                        <span><b>{{'Password-minLength' | translate}}</b></span>
                      </div>

                    </div>

                  </div>
                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="confirmPassword"> {{'Confirm Password' | translate}} <span class="text-danger text-bold">*</span></label>
                    </div>
                    <div class="col-md-7">
                      <input type="password" id="confirmPassword" formControlName="confirmPassword"
                        placeholder="{{'Confirm Password' | translate}}" class="form-control">
                    </div>

                    <div class="col-md-4"></div>
                    <div
                      *ngIf="createPlayerForm.get('confirmPassword').invalid && (createPlayerForm.get('confirmPassword').dirty || createPlayerForm.get('confirmPassword').touched)"
                      class="text-danger error-msg col-md-7 mt-2">
                      <div *ngIf="createPlayerForm.get('confirmPassword').errors?.required">
                        <span><b>{{'Confirm-password-required' | translate}}</b></span>
                      </div>
                      <div *ngIf="createPlayerForm.get('confirmPassword').errors?.minlength">
                        <span><b>{{'Confirm-password-minLength' | translate}}</b></span>
                      </div>
                      <div *ngIf="createPlayerForm.get('confirmPassword').errors?.confirmedValidator">
                        <span><b>{{'Confirm-password-mismatch' | translate}}</b></span>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="email"> {{'Email' | translate}} <span class="text-danger text-bold">*</span></label>
                    </div>
                    <div class="col-md-7">
                      <input type="email" id="email" formControlName="email" placeholder="Email" class="form-control">
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-7 mt-3">
                      <div
                        *ngIf="createPlayerForm.get('email').invalid && (createPlayerForm.get('email').touched || createPlayerForm.get('email').dirty)"
                        class="text-danger error-msg">
                        <span><b>{{'Email-required' | translate}}</b></span>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="email2"> {{'Email 2' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="email" id="email2" formControlName="email2" placeholder="Email 2"
                        class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="email3"> {{'Email 3' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="email" id="email3" formControlName="email3" placeholder="Email 3"
                        class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="GenderCP"> {{'Gender' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="radio" formControlName="gender" id="GenderMaleCP" value="Male" name="gender"> {{ 'Male' | translate }}
                      <input type="radio" formControlName="gender" id="GenderFemaleCP" value="FeMale" name="gender"> {{ 'Female' | translate }}
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="birthDate"> {{'Birth date' | translate}}</label>
                    </div>
                    <div class="col-md-7">

                      <mat-form-field appearance="fill">
                        <input matInput [matDatepicker]="picker1" formControlName="birthDate">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>

                    </div>
                  </div>


                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="address"> {{'Address' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="text" id="address" formControlName="address" placeholder="{{'Address' | translate}}"
                        class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="country"> {{'Country' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <select class="form-control " id="country" formControlName="country">
                        <option [ngValue]="''" disabled>{{ 'Select' | translate }}</option>
                        <option *ngFor="let country of countries" [ngValue]="country.name">{{country.name | translate }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="city"> {{'City' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="text" id="city" formControlName="city" placeholder="{{'City' | translate}}" class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="zipCode"> {{'Zip Code' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="text" id="zipCode" formControlName="zipCode" placeholder="{{'Zip Code' | translate}}"
                        class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="phone"> {{'Phone' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="text" id="phone" formControlName="phone" placeholder="{{'Phone' | translate}}" class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="phone2"> {{'Phone2' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="text" id="phone2" formControlName="phone2" placeholder="{{'Phone2' | translate}}"
                        class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="cellPhone"> {{'Cell Phone' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="text" id="cellPhone" formControlName="cellPhone" placeholder="{{'Cell Phone' | translate}}"
                        class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="whatsApp"> {{'Whatsapp' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="text" id="whatsApp" formControlName="whatsApp" placeholder="Whatsapp"
                        class="form-control">
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-7 mt-3">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="notes"> {{'Notes' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <textarea id="notes" formControlName="notes" placeholder="Notes" class="form-control"
                        rows="3"></textarea>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-3 d-flex align-items-center justify-content-end">
                      <label class="m-0" for="notes"> {{'Access' | translate}}</label>
                    </div>
                    <div class="col-md-7">
                      <input type="checkbox" id="deleted" formControlName="deleted" placeholder="deleted" class="checkbox">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <button id="createJurisdictionBtn" *ngIf="CreateNewJurisdiction && !loading" type="submit" mdbBtn color="primary" mdbWavesEffect class="btn btn-primary"
                    data-bs-dismiss="Save">{{'pg-manage-group.save' | translate}} </button>
                    <div *ngIf="loading" class="spinner-border ml-4" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>

                    <button *ngIf="!loading" routerLink="/jurisdiction/add" mdbBtn color="danger" class="relative waves-light btn-sm"
                      mdbWavesEffect>{{'closebtn' | translate}}
                    </button>

                  </div>
                </div>
              </form>
              <br /><br />
              <div class=panel *ngIf="jurisdictionLoginIsPlayer">
                <div class="panel-heading">
                  <div class="panel-title">
                    <span class="material-icons default-icon">person</span>
                    {{'Identity Documents' | translate}}
                  </div>
                </div>
                <div class="panel-body">
                  <div class="row" *ngIf="IDCardFront != ''">
                    <div class="col-md-8">

                      <div class="row">
                        <div class="col-md-12">
                          <label for="LoginCP"> {{'ID card(front)' | translate}}</label>
                          <img [src]="IDCardFront" alt="" height="190" width="300">
                        </div>
                      </div>


                    </div>
                    <div class="col-md-4">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="LoginCP"> {{'Notes' | translate}}</label>
                          <textarea [(ngModel)]="IDCardFrontNotes" class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <label for="LoginCP"> {{'Status' | translate}}</label>
                          <select [(ngModel)]="IDCardFrontStatus" class="form-control">
                            <option [ngValue]="-1">Please select</option>
                            <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                            <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                            <option [ngValue]="2" style="background-color: red;">Rejected</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <button type="button" class="btn btn-sm btn-primary" (click)="fileCNICF.click()">
                        <i class="fa fa-file"> {{'Choose ID Card (Front)' | translate}}</i>
                      </button>

                      <input type="file" #fileCNICF placeholder="Choose file"
                        (change)="uploadFile(fileCNICF.files,'cnicF')" style="display:none;" class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3" *ngIf="IDCardBack != ''">
                    <div class="col-md-8">

                      <div class="row">
                        <div class="col-md-12">
                          <label for="LoginCP"> {{'ID card(Back)' | translate}}</label>
                          <img [src]="IDCardBack" alt="" height="190" width="300">
                        </div>
                      </div>


                    </div>
                    <div class="col-md-4">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="LoginCP"> {{'Notes' | translate}}</label>
                          <textarea [(ngModel)]="IDCardBackNotes" class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <label for="LoginCP"> {{'Status' | translate}}</label>
                          <select [(ngModel)]="IDCardBackStatus" class="form-control">
                            <option [ngValue]="-1">Please select</option>
                            <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                            <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                            <option [ngValue]="2" style="background-color: red;">Rejected</option>
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <button type="button" class="btn btn-sm btn-primary" (click)="fileCNICB.click()">
                        <i class="fa fa-file"> {{'Choose ID Card (Back)' | translate}}</i>
                      </button>
                      <input type="file" #fileCNICB placeholder="Choose file"
                        (change)="uploadFile(fileCNICB.files,'cnicB')" style="display:none;" class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3" *ngIf="ProofOfAddress != ''">
                    <div class="col-md-8">

                      <div class="row">
                        <div class="col-md-12">
                          <label for="LoginCP"> {{'Proof Of Address' | translate}}</label>
                          <img [src]="ProofOfAddress" alt="" height="190" width="300">
                        </div>
                      </div>


                    </div>
                    <div class="col-md-4">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="LoginCP"> {{'Notes' | translate}}</label>
                          <textarea [(ngModel)]="ProofOfAddressNotes" class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <label for="LoginCP"> {{'Status' | translate}}</label>
                          <select [(ngModel)]="ProofOfAddressStatus" class="form-control">
                            <option [ngValue]="-1">Please select</option>
                            <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                            <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                            <option [ngValue]="2" style="background-color: red;">Rejected</option>
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <button type="button" class="btn btn-sm btn-primary" (click)="fileTaxCode.click()">
                        <i class="fa fa-file"> {{'Choose Proof Of Address' | translate}}</i>
                      </button>
                      <input type="file" #fileTaxCode placeholder="Choose file"
                        (change)="uploadFile(fileTaxCode.files,'Taxcode')" style="display:none;" class="form-control">
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-10">
                      <span class="upload" *ngIf="progress > 0">
                        {{'Progress' | translate}}
                      </span>
                      <span class="upload" *ngIf="progress > 0">
                        {{progress}}%
                      </span>
                      <span class="upload" *ngIf="message">
                        {{message}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

</div>

<!-- MODAL EDIT JURISDICTION -->
<div mdbModal #frame1="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify" id="StyleConfig" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead" style="color: #333">{{ 'pg-jurisdiction.edit-jurisdiction' | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame1.hide()">
          <span aria-hidden="true" style="color: #333">&times;</span>
        </button>
      </div>

      <!--Body-->
      <form [formGroup]="editJurisdictionForm" (submit)="editJursdiction(editJurisdictionForm.value, editableJurisdiction.jurisdictionId)">
        <div class="modal-body" id="JurisdicStyle">
          <div class="text-center">
            <div class="row">


              <div class="col-md-4 text-left">
                <label for="login">Username</label>
              </div>
              <div class="col-md-8 text-left">
                {{ editableJurisdiction.login }}
              </div>
            </div>

            <div class="row" style="margin-top: 2%;">
            <div class="col-md-4 text-left" style="padding-left: 14px;">
              <label for="coupon">{{ 'admin.coupon' | translate }}</label>
            </div>
            <div class="col-md-8 text-left" style="padding-left: 16px;">
              {{jurisdictionCoupon}}
            </div>
          </div>


            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Name' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.name" formControlName="name" placeholder="name"
                  class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Last Name' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.lastName" formControlName="lastName"
                  placeholder="lastName" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Password' | translate}} <span class="text-danger text-bold">*</span></label>
              </div>
              <div class="col-md-8 text-left">
                <input type="password" [value]="editableJurisdiction.password" formControlName="password"
                  placeholder="password" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Confirm Password' | translate}} <span class="text-danger text-bold">*</span></label>
              </div>
              <div class="col-md-8 text-left">
                <input type="password" [value]="editableJurisdiction.password" formControlName="confirmPassword"
                  placeholder="confirmPassword" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'E-mail' | translate}} <span class="text-danger text-bold">*</span></label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.email" formControlName="email" placeholder="email"
                  class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'E-mail 2' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.email2" formControlName="email2" placeholder="email2"
                  class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'E-mail 3' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.email3" formControlName="email3" placeholder="email3"
                  class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Gender' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="radio" formControlName="gender" value="Male" name="gender"> Male
                <input type="radio" formControlName="gender" value="FeMale" name="gender"> Female
              </div>
            </div>

            <div class="row mt-3 text-left">
              <div class="col-md-4 text-left">
                <label for="birthDate"> {{'Birth date' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <mat-form-field appearance="fill">
                  <input matInput [matDatepicker]="picker2" formControlName="birthDate">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Address' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.address" formControlName="address"
                  placeholder="address" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Country' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <select class="form-control" formControlName="country">
                  <option [value]="''" disabled>{{ 'Select' | translate}}</option>
                  <option *ngFor="let country of countries" [value]="country.name">
                    {{ country.name | translate }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'City' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.city" formControlName="city" placeholder="city"
                  class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Zip code' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.zipCode" formControlName="zipCode"
                  placeholder="zipCode" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Phone' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.phone" formControlName="phone" placeholder="phone"
                  class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Phone 2' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.phone2" formControlName="phone2" placeholder="phone2"
                  class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Cell Phone' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.cellPhone" formControlName="cellPhone"
                  placeholder="cellPhone" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Whatsapp' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.whatsapp" formControlName="whatsapp"
                  placeholder="whatsapp" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Commission' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <input type="text" [value]="editableJurisdiction.commission" formControlName="commission"
                  placeholder="commission" class="form-control">
              </div>
            </div>

            <ng-template #AreaDissable>
              <div class="row mt-3">
                <div class="col-md-4 text-left">
                  <label for="login">Codigo Amigo</label>
                </div>
                <div class="col-md-8 text-left">
                  <input type="text" style="max-width: 100px;" [value]="jurisdictionCoupon" name="" disabled="true">
                  {{Currency.currencySymbol}}
                  <input class="col-md-9" style="max-width: 85px;text-align: end;" type="text" min="0" max="1000" name=""
                    disabled="true">,00
                  <br>
                </div>
              </div>
            </ng-template>

            <div class="row mt-3">
              <div class="col-md-4 text-left">
                <label for="login">{{'Notes' | translate}}</label>
              </div>
              <div class="col-md-8 text-left">
                <textarea formControlName="notes" placeholder="Notes" class="form-control" rows="3"></textarea>
              </div>
            </div>

            <ng-container *ngIf="PermitLockAndUnlock(); else UnLockJuriPermit">
              <div class="row mt-3">
                <div class="col-md-4 text-left">
                  <label for="login">{{'Access' | translate}}</label>
                </div>
                <div class="col-md-8 text-left">
                  <input type="checkbox" formControlName="deleted" placeholder="deleted" class="checkbox">
                </div>
              </div>
            </ng-container>
            <ng-template #UnLockJuriPermit>
              <div class="row mt-3">
                <div class="col-md-4 text-left">
                  <label for="login">{{'Access' | translate}}</label>
                </div>
                <div class="col-md-8 text-left">
                  <input type="checkbox" formControlName="deleted" placeholder="deleted" class="checkbox" disabled> <i *ngIf="!deleted" id="stylePermitJurisdict">{{'AssistContat' | translate}}</i>
                </div>
              </div>
            </ng-template>
            <br>
            <div *ngIf="EditJurisdictionPermit" class="text-left">
              <div class="modal-header">
                <h4 class="modal-title w-100" id="TitlePermits">{{ 'permits.title' | translate }}</h4>
              </div>
              <div class="modal-body">
                <div class="d-flex flex-column flex-md-row flex-wrap box">
                  <div *ngFor="let permit of permitsList" id="PermitsListAdjust">
                    <mdb-checkbox (click)="onPermitClick(permit)" #newPermitChk1 [inline]="true" [default]="true" [id]="'new.' + permit.name">{{
                      permit.name | translate }}</mdb-checkbox>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <p *ngIf="hasChangedRoundStylePermitts" class="text-warning">AVISO: Você alterou as permissões do estilo de rodada pra essa jurisdição, lembre-se de salvar as mudanças abaixo</p>

        </div>

        <!--Footer-->
        <div class="modal-footer justify-content-center">
          <div class="row">
            <button type="submit" mdbBtn color="primary" mdbWavesEffect class="btn btn-primary"
              data-bs-dismiss="Save">{{'savebtn' | translate}}</button>

            <button type="button" mdbBtn color="danger" class="btn" data-dismiss="modal"
              (click)="frame1.hide()">{{'closebtn' |
              translate}}</button>

          </div>
        </div>
      </form>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- MODAL EDIT JURISDICTION -->

<!-- MODAL EDIT JURISDICTION -->
<div mdbModal #manageStyleModal="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify manageStyleModal" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="bg-light-gray modal-header">
        <p class="heading lead" style="color: #333">Gerenciar estilo de rodada</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeManageStylePermitModal()">
          <span aria-hidden="true" style="color: #333">&times;</span>
        </button>
      </div>

      <!--Body-->
      
      <body class="p-4 bg-white">
        <div class="text-center">
          <mdb-checkbox [inline]="true" [default]="true">
            Permitir todas
          </mdb-checkbox>
        </div>
      
        <hr>
        
        <div class="w-100 checkbox-container">
          <mdb-checkbox [(ngModel)]="roundStyle.checked" (change)="onChangeCheckboxValue(roundStyle)" class="mb-3" *ngFor="let roundStyle of tempRoundsStylesToPermit; let i = index" [inline]="true" [default]="true">
            {{ roundStyle.name }}
          </mdb-checkbox>
        </div>
      </body>

        <!--Footer-->
        <div class="modal-footer justify-content-center bg-white">
          <div class="row">
            <button (click)="onSaveRoundStylePermit()" type="submit" mdbBtn color="primary" mdbWavesEffect class="btn btn-primary"
              data-bs-dismiss="Save">{{'savebtn' | translate}}</button>

            <button type="button" mdbBtn color="danger" class="btn" data-dismiss="modal"
              (click)="closeManageStylePermitModal()">{{'closebtn' |
              translate}}</button>

          </div>
        </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- MODAL EDIT JURISDICTION -->
