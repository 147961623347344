<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-square-spin" [fullScreen]="true">
    <p style="color: white"></p>
</ngx-spinner>
<div id="topbar">
    <ol class="breadcrumb">
        <li class="crumb-active">
            <a href="#">{{ 'Coupon List' | translate }}</a>
        </li>
    </ol>
</div>
<div id="content">
    <div class="panel">
        <div class="panel-heading">
            <div class="panel-title">
                <span class="material-icons default-icon">search</span>
                {{ 'Filter Coupons' | translate }}
            </div>
        </div>
        <div class="panel-body">
            <div class="input-group mb-3" style="position: unset; display: inline;">
                <label style="margin-top: auto; display: inline-block;">{{ 'admin.daterange' | translate }}</label>
                <!--<input type="text" [locale]="{format: 'DD/MM/YYYY'}" ngxDaterangepickerMd [(ngModel)]="selected" class="form-control" style="max-width: 200px; margin: 25px 0px 15px; display: inline-block" (change)="changeDate($event)"/>-->

                <mat-form-field appearance="fill">
                    <mat-label>{{ 'startDate' | translate }}</mat-label>
                    <input matInput (dateChange)="changeDateFrom($event)" [matDatepicker]="picker1"
                        [formControl]="startDate">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'endDate' | translate }}</mat-label>
                    <input matInput (dateChange)="changeDateEnd($event)" [matDatepicker]="picker2"
                        [formControl]="endDate">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

            </div>

            <!-- <div class="input-group mb-3">
                <label>{{ 'Jurisdiction' | translate }}</label>
                <select class="form-control" style="max-width: 200px;" (change)="jurisdictionLevelChange($event)">
                    <option [value]="item.id" *ngFor="let item of jurisdictions">{{item.name}}</option>
                </select>
                <select class="form-control" style="max-width: 200px; margin-left: 30px;"
                    (change)="jurisdictionChange($event)">
                    <option value="-1">{{ 'All' | translate }}</option>
                    <option [value]="item.id" *ngFor="let item of allowUsersList">{{item.login}}</option>
                </select>
            </div>
            <div style="text-align: center;">
                <button class="btn btn-success btn-sm" (click)="applyFilter2()">{{ 'Filter' | translate }}</button>
            </div> -->
            <br>
            <div class="input-group mb-3" style="position: unset; display: inline;">
                <label style="margin-top: auto; display: inline-block;">{{ 'Jurisdiction' | translate }}</label>
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'LevelJurisdiction' | translate }}</mat-label>
                  <mat-select #jurisdictionLevelId style="max-width: 200px;" [(ngModel)]="selectJurisId"
                    (selectionChange)="jurisdictionLevelChange($event)">
                    <mat-option [value]="item.id" *ngFor="let item of jurisdictions">{{item.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field style="margin-left: 20px;" appearance="fill">
                  <mat-label>{{ 'NameJurisdiction' | translate }}</mat-label>
                  <mat-select #jurisdictionId style="max-width: 200px;" [(ngModel)]="selectJurisIdName"
                    (selectionChange)="jurisdictionChange()">
                    <mat-option [value]="item.id" *ngFor="let item of allowUsersList">{{item.login}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <button class="btn btn-success btn-sm" style="margin-left: 2%; margin-top: auto;" (click)="applyFilter2()"
                  [disabled]="missingJurisName">{{ 'Filter' | translate }}</button>
              </div>

        </div>
    </div>
    <div class="panel">
        <div class="panel-heading">
            <div class="panel-title">
                <span class="material-icons default-icon">search</span>
                {{ 'Coupons List' | translate }}
            </div>
        </div>
        <div class="panel-body">
            <div style="text-align: right;">
                <mat-form-field>
                    <mat-label>{{ 'Search' | translate }}...</mat-label>
                    <input matInput (keyup)="applyFilter($event)" #input>
                </mat-form-field>
            </div>
            <div class="mat-elevation-z8" style="overflow: auto;white-space: normal;word-wrap: break-word !important;">
                <table class="table table-bordered" mat-table [dataSource]="dataSource" style="width:100%;" matSort>
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Coupon' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index"
                            [style.background-color]="backgroundColor(element)" (click)="detailCoupon(element.uuid8)"
                            style="cursor: pointer;color:#003771;"> {{element.uuid8}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Status' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index"
                            [style.background-color]="backgroundColor(element)" style="color: white !important;">
                            <span *ngIf='element.status'>{{ 'Active' | translate }}</span>
                            <span *ngIf='!element.status' style='text-decoration:line-through;'>{{ 'Canceled' |
                                translate }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="betDateStr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'date.title' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index"
                            [style.background-color]="backgroundColor(element)" style="color: white !important;">
                            {{element.betDateStr}} </td>
                    </ng-container>
                    <ng-container matColumnDef="operator">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'pg-tickets.operator' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index"
                            [style.background-color]="backgroundColor(element)" style="color: white !important;">
                            <!-- {{userNameToShow[i]}}  -->{{element.operator}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="playerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Player' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index"
                            [style.background-color]="backgroundColor(element)" style="color: white !important;">
                            {{element.playerName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="country">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Country' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index"
                            [style.background-color]="backgroundColor(element)" style="color: white !important;">
                            {{element.country}} </td>
                    </ng-container>
                    <ng-container matColumnDef="city">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'City' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index"
                            [style.background-color]="backgroundColor(element)" style="color: white !important;">
                            {{element.city}} </td>
                    </ng-container>

                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Qty' | translate }} </th>
                        <td mat-cell *matCellDef="let element; let i = index"
                            [style.background-color]="backgroundColor(element)" style="color: white !important;">
                            {{element.quantity}} </td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'pg-credit-transfer.value' | translate}} </th>
                        <td mat-cell *matCellDef="let element; let i = index"
                            [style.background-color]="backgroundColor(element)" style="color: white !important;">
                            {{Currency.currencySymbol}} {{element.value * Currency.quote | currency:'':'' }} </td>
                    </ng-container>


                    <!-- 
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> {{ 'pg-manage-ticket.actions' | translate }} </th>
                  <td mat-cell *matCellDef="let element; let i = index" [style.background-color]="backgroundColor(element)">
                    <button *ngIf="element.status" mdbBtn class="small-spaces" type="button" (click)="makeTicket(i, 2)"
                      color="red" mdbWavesEffect>
                      <mdb-icon fas icon="ban"></mdb-icon>
                    </button>
                  </td>
                </ng-container>
                -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSize]="50" [pageSizeOptions]="[10,50,100,200]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>

<div mdbModal #frame2="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-primary" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-manage-ticket.view-ticket' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame2.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <p id="final-msg" [innerHtml]="viewTicket"></p>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="btn" mdbWavesEffect
                    (click)="frame2.hide()" data-dismiss="modal">{{ 'pg-manage-ticket.back' | translate }}</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal #frame3="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-danger" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-manage-ticket.confirm-cancel' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame3.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <p id="final-msg" [innerHtml]="viewTicket"></p>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="danger" class="waves-light" (click)="cancelTicket()" mdbWavesEffect>{{
                    'pg-manage-ticket.confirm' | translate }}
                </a>
                <a type="button" mdbBtn color="primary" outline="true" class="btn" mdbWavesEffect
                    (click)="frame3.hide()" data-dismiss="modal">{{ 'pg-manage-ticket.back' | translate }}</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal #frameMessage="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
        <div class="modal-content modal-lg  bg-theme" style="height: auto; max-height: 700px; background-color: #1f1f1f !important;">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="frameMessage.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3 adjustModel scrollDeg">


                <p style="color: black" id="msg" [innerHtml]="msgToAdd"></p>


            </div>
            <div class="modal-footer d-flex justify-content-center" style="background-color: #1f1f1f !important;">

                <a type="button" color="waves-light button-font"
                    style="padding:.54rem 2.14rem; background-color:#33a0df;" class="btn" mdbWavesEffect
                    (click)="frameMessage.hide()" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>

<!--Modal Standard Danger Message-->
<div mdbModal #frameMessageDanger="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardDanger" style="height: 350px;
max-height: 350px;">
        <div class="modal-content  bg-theme" style="height: 378px;
max-height: 400px;">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="frameMessageDanger.hide();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">


                <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>


            </div>
            <div class="modal-footer d-flex justify-content-center">

                <a type="button" color="waves-light button-font" style="background-color:#ff4b4b;" class="btn"
                    mdbWavesEffect (click)="frameMessageDanger.hide();" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>

<!--Modal Standard Message-->

<!--Modal Credit Message-->
<div mdbModal #frameMessageCredit="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardCredit">
        <div class="modal-content  bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="CreditRedirect();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">


                <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>


            </div>
            <div class="modal-footer d-flex justify-content-center">
                <a type="button" color="waves-light button-font" style="background-color:#01d36b;" class="btn"
                    mdbWavesEffect (click)="CreditRedirect();" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>

<!--Modal Credit Message-->

<!--Modal Betting Message-->
<div mdbModal #frameMessageBetting="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardBetting">
        <div class="modal-content  bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="BettingRedirect();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">
                <div class="">

                    <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>

                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">

                <a type="button" color="waves-light button-font" style="background-color:#01d36b;" class="btn"
                    mdbWavesEffect (click)="BettingRedirect();" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>
