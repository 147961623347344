<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-square-spin" [fullScreen]="true">
  <p style="color: white"></p>
</ngx-spinner>
<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'admin.entitycredit' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'admin.creditslist' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="bg-theme">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <li class="mat-tree-node">
                <button mat-icon-button disabled></button>
                <img src="../../../../assets/images/{{node.jurisdictionId}}.png" width="20px" height="20px"
                                    style="margin-right: 3px">
                <span style="font-weight: 700; color: green;">
                  {{node.login}} 
                </span>&nbsp;
                {{Currency.currencySymbol}} &nbsp;
                <span style="font-weight: 600;">
                  {{node.credit.toFixed(2) * Currency.quote | currency:'':''}}
                </span>
                <img (click)="detailBtn(node)" src="../../../../assets/images/detail.png" width="20px" height="20px"
                                    style="margin-right: 3px; cursor: pointer;">
                <!-- <mat-icon (click)="detailBtn(node)">subject</mat-icon> -->
            </li>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <li style="list-style: none">
                <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <img src="../../../../assets/images/{{node.jurisdictionId}}.png" width="20px" height="20px"
                                    style="margin-right: 3px">
                    <span style="font-weight: 700; color: green;">
                      {{node.login}} 
                    </span>&nbsp;
                    {{Currency.currencySymbol}} &nbsp;
                    <span style="font-weight: 600;">
                      {{node.credit.toFixed(2) * Currency.quote | currency:'':''}}
                    </span>&nbsp;
                    <span style="color:green;" [style.background]="getBackground(node.jurisdictionId)">
                      <span style="font-style: italic;">
                        ({{node.children?.length}} {{getJurisdictionName(node.jurisdictionId == 7 ? node.jurisdictionId : node.jurisdictionId + 1).name}} {{Currency.currencySymbol}}
                        <span style="font-weight: 700;">
                          {{node.credit_right_below.toFixed(2) * Currency.quote | currency:'':''}})
                        </span>
                      </span>&nbsp;
                      <span>
                        - ({{Currency.currencySymbol}} 
                        <span style="font-weight: 700;">
                          {{node.credit_below.toFixed(2) * Currency.quote | currency:'':''}})
                        </span>
                      </span>
                    </span>
                    <img (click)="detailBtn(node)" src="../../../../assets/images/detail.png" width="20px" height="20px"
                                    style="margin-right: 3px; cursor: pointer;">
                    <!-- <mat-icon (click)="detailBtn(node)">subject</mat-icon> -->
                </div>
                <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                    style="list-style: none">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
  </div>
  <div class="panel" *ngIf="selectedId">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'admin.search' | translate }}
      </div>
    </div>
    <div class="panel-body" style="position: unset; display: inline;">
      <label style="margin-top: auto; margin-right: 20px; display: inline-block;">{{ 'admin.daterange' | translate }}</label>
      <!--<input type="text" [locale]="{format: 'DD/MM/YYYY'}" ngxDaterangepickerMd [(ngModel)]="selected" class="form-control" style="max-width: 200px; margin: 25px 0px 15px; display: inline-block" (change)="changeDate($event)"/>-->

      <mat-form-field appearance="fill">
        <mat-label>{{ 'startDate' | translate }}</mat-label>
        <input matInput
          [matDatepicker]="picker1"
          [formControl]="startDate">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'endDate' | translate }}</mat-label>
        <input matInput
          [matDatepicker]="picker2" 
          [formControl]="endDate">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>

      <button class="btn btn-success btn-sm" (click)="changeDate()">{{ 'Filter' | translate }}</button>

    </div>
  </div>
  <div class="panel" *ngIf="start_date">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'admin.transactionslist' | translate }} 
        <span style="color: green; font-weight: 700;">
          {{getUserNameById(selectedId).login}}
        </span>
      </div>
    </div>
    <div class="panel-body">
      <div style="text-align: right;">
        <mat-form-field>
          <mat-label>{{"Search" | translate}}...</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
      </div>
      <div class="mat-elevation-z8" style="overflow: auto;">
        <table mat-table [dataSource]="dataSource2">
      
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
      
          <ng-container matColumnDef="coupon">
            <th mat-header-cell *matHeaderCellDef> {{ ('Coupon' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> 
              <span *ngIf="element.betUuid8 && element.betUuid8 != 'null'">
                {{element.betUuid8}}
              </span>
             </td>
          </ng-container>
      
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> {{ ('date.title' | translate).toUpperCase()}}</th>
            <td mat-cell *matCellDef="let element"> {{element.date_date}} {{element.date_time}} </td>
          </ng-container>
      
          <ng-container matColumnDef="movement">
            <th mat-header-cell *matHeaderCellDef> {{ ('Movement' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{element.typeDesc}} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{ ('Description' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>

          <ng-container matColumnDef="input">
            <th mat-header-cell *matHeaderCellDef> {{ ('Input' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.creditIn.toFixed(2) * Currency.quote | currency:'':''}} </td>
          </ng-container>

          <ng-container matColumnDef="output">
            <th mat-header-cell *matHeaderCellDef> {{ ('Output' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.creditOut.toFixed(2) * Currency.quote | currency:'':''}} </td>
          </ng-container>

          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef> {{ ('Balance' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.balance.toFixed(2) * Currency.quote | currency:'':''}} </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [style.background]="getTableBackground(row.type)"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
