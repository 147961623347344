import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
//import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app.routing';
import { AppTranslateModule } from './app.translate';
import { AuthLayoutModule } from './layouts/auth-layout/auth-layout.module';

import { ComponentsModule } from './components/components.module';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import { WhatsappListComponent } from './pages/whatsapp-list/whatsapp-list.component';
//import { ManageBetsComponent } from './pages/manage-bets/manage-bets.component';
//import { TimepickerModule } from 'ngx-bootstrap/timepicker';
//import { PostresultComponent } from './pages/postresult/postresult.component';
//import { ManageroundsComponent } from './pages/managerounds/managerounds.component';
//import { CouponDetailComponent } from './pages/coupon-detail/coupon-detail.component';
//import { CouponListComponent } from './pages/coupon-list/coupon-list.component';


//import { EditPlayer2Component } from './pages/players/edit-player2/edit-player2.component';
//import { DocumentListComponent } from './pages/document-list/document-list.component';

//import { EditPlayerComponent } from './pages/players/edit-player/edit-player.component';
registerLocaleData(localePtBr);
@NgModule({
  declarations: [
    AuthLayoutComponent,
    AdminLayoutComponent,
    AppComponent,
    // RoundsLoggerComponent,
    //WhatsappListComponent,
    //ManageBetsComponent,
    //PostresultComponent,
    //ManageroundsComponent,
    //CouponDetailComponent,
    //CouponListComponent
    //EditPlayer2Component,
    //DocumentListComponent,    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppTranslateModule,
    AuthLayoutModule,
    AdminLayoutModule,
    AppRoutingModule,
    ComponentsModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule
  ],
  providers: [
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
