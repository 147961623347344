import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Captcha from 'captcha-image';
import { CookieService } from 'ngx-cookie-service';
import { Login } from '../../interface/login.interface';
import { LoginHistory } from '../../interface/loginhistory.interface';
import { InteractionService } from '../../services/interaction.service';
import { LoginService } from '../../services/login.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	////////////////////////s
	public isLogged: boolean = false;
	userAdmin: any;
	public cookie: string;
	loginData: any = {};

	loginForm: FormGroup;
	loading = false;
	captchaImage: Captcha;
	captcha = '';

	show_password: boolean = false;

	constructor(private router: Router,
		private formBuilder: FormBuilder,
		private toastService: ToastService,
		private loginService: LoginService,
		private interactionservice: InteractionService,
		private cookieService: CookieService,
		private translateService: TranslateService) {
		if (loginService.isLogin()) {
			this.router.navigate(['/home']);
		}
		this.loginForm = this.formBuilder.group({
			name: ['', Validators.required],
			password: ['', Validators.required],
			captcha: [''],
		});

		this.loginService.getIPAddress().then((response) => {
			localStorage.setItem('currentUserIp', response.data['ip']);
			this.loginService.getCountry(response.data['ip'])
				.then((response) => {
					localStorage.setItem('currentUserCountry', response.data['country']);
				}, error => console.log(error))
		}, error => console.log(error))

	}

	generateCaptcha(): void {
		this.captchaImage = new Captcha('30px Arial',
			'center',
			'middle',
			200,
			80,
			'#eee',
			'#111',
			6
		)
			.createImage();
		var re = /<image/gi;
		this.captchaImage = this.captchaImage.replace(re, '<image class="img-fluid"');
	}

	ngOnInit(): void {
		this.generateCaptcha();
	}

	onSubmit(formData): void {
		if (this.loginForm.valid) {
			const originCaptcha = this.getOriginText(this.captchaImage).toLowerCase();
			const inputCaptcha = formData.captcha.toLowerCase();
			if (inputCaptcha !== originCaptcha) {
				this.toastService.showNotification({ message: this.translateService.instant('login.wrongcaptcha'), type: 'danger' });
				this.loginForm.controls['name'].setValue("");
				this.loginForm.controls['password'].setValue("");
				this.loginForm.controls['captcha'].setValue("");

				if (localStorage.getItem('currentUserIp') == null || localStorage.getItem('currentUserCountry') == null) {
					this.loginService.getIPAddress().then((response) => {
						localStorage.setItem('currentUserIp', response.data['ip']);
						this.loginService.getCountry(response.data['ip'])
							.then((response) => {
								localStorage.setItem('currentUserCountry', response.data['country']);
								var loginHistory = {
									status: 'failed',
									userId: -1,
									ipAddress: localStorage.getItem('currentUserIp')
								}
								this.loginService.postLoginHistory(loginHistory, formData.name).subscribe();
							}, error => console.log(error))
					}, error => console.log(error))
				} else {
					var loginHistory = {
						status: 'captcha failed',
						userId: -1,
						ipAddress: localStorage.getItem('currentUserIp')
					}
					this.loginService.postLoginHistory(loginHistory, formData.name).subscribe();
				}

				this.generateCaptcha();
				return;
			}
			this.loading = true;
			this.getLogindesktop(formData.name, formData.password);
		} else {
			this.validateForm(this.loginForm);
		}
	}

	getOriginText(captcha: string): string {
		const key = captcha.match(/(data-key=")\w+/g)!;
		return key[0].slice(-6);
	}

	validateForm(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field)!;
			control.markAsTouched({ onlySelf: true });
			control.markAsDirty({ onlySelf: true });
		});
	}

	getLogindesktop(log: string, pass: string) {
		this.loginService.getLogin(log, pass)
			.subscribe((data: Login) => {
				this.loginData = data
				if (this.loginData.user.jurisdictionId == 7) {
					this.toastService.showNotification({ message: 'Not allowed!', type: 'danger' });
					this.loading = false;

					return;
				}
				if (this.loginData.user.firstLogin) {
					this.loading = false;

				} else {
					this.interactionservice.setLoginData(data);
					this.userAdmin = data.user;
					// localStorage.setItem('currentUser', this.userAdmin.id);
					localStorage.setItem('currentUserJurisdictionId', this.userAdmin.jurisdictionId);
					localStorage.setItem('currentUserToken', data.token);

					if (this.loginData.creatorId != 0)
						localStorage.setItem('creatorId', this.loginData.creatorId);
					else
						localStorage.setItem('creatorId', this.loginData.user.id);

					this.isLogged = true;
					// localStorage.setItem('user', JSON.stringify(data.user));
					localStorage.setItem('isAssistant', Number(data.isAssistant).toString())
					//document.cookie = "user=" + JSON.stringify(data.user);
					this.cookieService.set('user', JSON.stringify(data.user)); //set not working from chrome 80 version
					this.loginService.setGlobalCurrency();
					if (localStorage.getItem('currentUserIp') == null || localStorage.getItem('currentUserCountry') == null) {
						this.loginService.getIPAddress().then((response) => {
							localStorage.setItem('currentUserIp', response.data['ip']);
							this.loginService.getCountry(response.data['ip'])
								.then((response) => {
									localStorage.setItem('currentUserCountry', response.data['country']);
									var loginHistory = {
										status: 'success',
										userId: data.user['id'],
										ipAddress: localStorage.getItem('currentUserIp')
									}
									this.loginService.postLoginHistory(loginHistory, log)
										.subscribe((data: LoginHistory) => {
											this.loading = false;
											location.replace("/home");
										}, error => {
											console.log(error);
											this.loading = false;
										});
								}, error => console.log(error))
						}, error => console.log(error))
					} else {
						var loginHistory = {
							status: 'success',
							userId: data.user['id'],
							ipAddress: localStorage.getItem('currentUserIp')
						}
						this.loginService.postLoginHistory(loginHistory, log)
							.subscribe((data: LoginHistory) => {
								this.loading = false;
								location.replace("/home");
							}, error => {
								console.log(error);
								this.loading = false;
							});
					}
				}
			}, error => {
				this.toastService.showNotification({ message: this.translateService.instant('login.wronginfo'), type: 'danger' });
				this.loginForm.controls['name'].setValue("");
				this.loginForm.controls['password'].setValue("");
				this.loginForm.controls['captcha'].setValue("");

				if (localStorage.getItem('currentUserIp') == null || localStorage.getItem('currentUserCountry') == null) {
					this.loginService.getIPAddress().then((response) => {
						localStorage.setItem('currentUserIp', response.data['ip']);
						this.loginService.getCountry(response.data['ip'])
							.then((response) => {
								localStorage.setItem('currentUserCountry', response.data['country']);
								var loginHistory = {
									status: 'password incorrect',
									userId: -1,
									ipAddress: localStorage.getItem('currentUserIp')
								}
								this.loginService.postLoginHistory(loginHistory, log).subscribe();
							}, error => console.log(error))
					}, error =>
						console.log(error))
				} else {
					var loginHistory = {
						status: 'password incorrect',
						userId: -1,
						ipAddress: localStorage.getItem('currentUserIp')
					}
					this.loginService.postLoginHistory(loginHistory, log).subscribe();
					this.generateCaptcha();
				}
				console.log(error);
				this.loading = false;
			});
	}

	showPassword() {
		this.show_password = !this.show_password;
	}

}
