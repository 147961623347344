import { RoundStyle } from './../../interface/RoundStyle/RoundStyle';
import { Component, OnInit, Type, ViewChild } from '@angular/core';
import { PostRoundStyle } from 'src/app/interface/RoundStyle/PostRoundStyle';
import { PostRoundEventStyle } from 'src/app/interface/RoundEventStyle/PostRoundEventStyle';
import { RoundStyleService } from '../../services/round-style.service';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from  '@ngx-translate/core';
import { RoundEventStyle } from 'src/app/interface/RoundEventStyle/RoundEventStyle';
import { Router } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import {CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-round-style',
  templateUrl: './round-style.component.html',
  styleUrls: ['./round-style.component.css']
})

export class RoundStyleComponent implements OnInit {
  @ViewChild('frameDeleteStyle', { static: true }) modalDelete: ModalDirective;

  SendRoundStyle: PostRoundStyle = {
    Name: '',
    RoundEventsStyles: [],
    PermittedUsers: ''
  }

  SendEventsStyles: PostRoundEventStyle[] = [
    {
      Name: '',
      Type: null,
      Order: 0
    }
  ];

  roundStyles: RoundStyle[] = [];
  roundsStylesToDelete : RoundStyle[] = [];
  roundStyle : RoundStyle;
  roundStyleToRemove = null;
  roundEventsStyle: RoundEventStyle[] =[];

  loading: boolean = false;

  TypeRoundEvent = [];

  userId = +localStorage.getItem("creatorId");

  constructor(private roundStyleService:RoundStyleService,
              private toastService: ToastService,
              private translate: TranslateService,
              private router: Router) { }

  ngOnInit(): void {
    this.GetRoundStyles();
    // this.GetUpgradeableRoundStyles();
  }

  GenerateEventStyleFields(){
    this.addEventStyleToSend();
  }

  addEventStyleToSend(){
    let SendEventsStyles: PostRoundEventStyle = {
      Name: '',
      Type: null,
      Order: 0
    }

    this.SendEventsStyles.push(SendEventsStyles);
  }

  removeEventStyleToSend(index: number){
    let eventsStylesHelper = [];

    this.SendEventsStyles.forEach((style, i) => {
      if(i!= index)
        eventsStylesHelper.push(style);
    });

    this.SendEventsStyles = eventsStylesHelper;
  }

  setEventsStyleList(eventsList){
    this.roundEventsStyle = eventsList;
  }

  addEventStyleToUpdate(){
    let roundStyleToUpdate: RoundEventStyle = {
      id: '',
      name: '',
      type: null,
      roundStyleId: this.roundStyle.id,
      order: 0
    };

    this.roundEventsStyle.push(roundStyleToUpdate);
  }

  removeEventStyleToUpdate( index: number){
    let eventsStylesHelper = [];

    this.roundEventsStyle.forEach((style, i) => {
      if(i!= index)
        eventsStylesHelper.push(style);
    });

    this.roundEventsStyle = eventsStylesHelper;
  }

  CreateRoundStyle(){
    this.SendEventsStyles.forEach((event, i) =>{
       event.Order = i;
    });
    this.SendRoundStyle.RoundEventsStyles = this.SendEventsStyles;
    this.SendRoundStyle.PermittedUsers = "1|";

    this.roundStyleService.CreateRoundStyle(this.SendRoundStyle)
      .subscribe( data =>{
        this.SendRoundStyle = {
          Name: '',
          RoundEventsStyles: [],
          PermittedUsers: ""
        };
        this.SendEventsStyles = [];

        this.toastService.showNotification({ message: this.translate.instant('CreateRoundStyleSuccess'), type: 'success' });
      }, error => {
        this.toastService.showNotification({ message: this.translate.instant('CreateRoundStyleFail'), type: 'danger' });
        console.log(error);
      });
  }

  GetRoundStyles(){
    this.roundStyleService.GetRoundStyles()
      .subscribe(styles =>{
        this.roundStyles = styles;

        styles.forEach(style => {
          this.roundsStylesToDelete.push(style);
        });
      }, error =>{
        console.log(error);
      });
  }

  UpdateRoundStyle(){
    if(this.checkUpdateRoundStyle())
      return;

    this.roundEventsStyle.forEach((element, i)=>{
      element.order = i;
    });

    this.roundStyle.roundEventsStyles = this.roundEventsStyle;

    this.roundStyleService.UpdateRoundStyle(this.roundStyle)
      .subscribe(response => {
        this.toastService.showNotification({ message: this.translate.instant('UpdateRoundStyleSuccess'), type: 'success' });
        this.roundStyle = null;
      }, error =>{
        this.toastService.showNotification({ message: this.translate.instant('UpdateRoundStyleFail'), type: 'danger' });
        console.log(error);
      });
  }

  checkUpdateRoundStyle(){
    if(this.roundStyle.id == '' && this.roundStyle.name == ''){
      this.toastService.showNotification({ message: this.translate.instant('UpdateRoundStyleFaultInfoRoundStyle'), type: 'danger' });
      return true;
    }
    
    return this.checkUpdateRoundEventStyle();
  }

  checkUpdateRoundEventStyle(){
    if(this.roundEventsStyle.length == 0){
      this.toastService.showNotification({ message: this.translate.instant('UpdateRoundStyleNeedEventsStyles'), type: 'danger' });
      return true;
    }

    this.roundEventsStyle.forEach(eventStyle =>{
      if(eventStyle.name == '' && eventStyle.type == null){
        this.toastService.showNotification({ message: this.translate.instant('UpdateRoundStyleFaultInfoRoundEventStyle'), type: 'danger' });
        return true;
      }
    });

    return false;
  }

  GetUpgradeableRoundStyles(){
    this.roundStyleService.GetUpgradeableRoundStyles()
      .subscribe(data=>{
        this.roundStyles = data;
      }, error => {
        if(error.status == 404){
          console.log("Round Styles not found.");
        }
        else{
          console.log(error);
        }
      });
  }

  drop(event: CdkDragDrop<any[]>){
    moveItemInArray(this.SendEventsStyles, event.previousIndex, event.currentIndex);
    
    this.SendEventsStyles.forEach((style, i)=> {
      style.Order = i;
    });
  }

  dropUpdate(event: CdkDragDrop<any[]>){
    moveItemInArray(this.roundEventsStyle, event.previousIndex, event.currentIndex);
    
    this.roundEventsStyle.forEach((style, i)=> {
      style.order = i;
    }); 
  }

  RemoveRoundStyle(){
    this.loading = true;

    this.roundStyleService.RemoveRoundStyle(this.roundStyleToRemove.id)
      .subscribe(data =>{
        this.toastService.showNotification({ message: this.translate.instant('RemoveStyleSuccess'), type: 'success' });
        this.roundsStylesToDelete = null;
        this.loading = false;
        this.modalDelete.hide();
      }, error => {
        this.toastService.showNotification({ message: this.translate.instant('RemoveStyleFail'), type: 'danger' });
        console.log(error);
        this.loading = false;
      });
  }
}

