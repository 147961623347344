<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-square-spin" [fullScreen]="true">
  <p style="color: white"></p>
</ngx-spinner>
<style>
  .mat-sort-header-content {
    text-align: left !important;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
</style>
<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'Players' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <!-- <div>
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="thumb-up"></mat-icon>
      </div> -->
      <div class="panel-title">
        <div>
          <span class="material-icons default-icon">search</span>
          {{ 'Search' | translate }}
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-2">
          <label for="jurisdictionId">{{ 'Jurisdiction' | translate }}</label>
        </div>
        <div class="col-md-2 mb-2">
          <select class="form-control" id="jurisdictionId" (change)="jurisdictionLevelChange($event)">
            <option [value]="-1">{{ 'Select' | translate }}</option>
            <option [value]="item.id" *ngFor="let item of jurisdictions">{{item.name}}</option>
          </select>
          <!-- <select class="form-control " id="jurisdictionId" [(ngModel)]="jurisdictionId" (change)="jurisdictionLevelChange($event)">
            <option [ngValue]="-1">{{ 'Select' | translate }}</option>
            <option *ngFor="let jurisdiction of jurisdictions" [ngValue]="jurisdiction.id">{{jurisdiction.name |
              translate }}</option>
          </select> -->
        </div>
        <div class="col-md-2 mb-2">
          <select class="form-control" (change)="jurisdictionChange($event)">
            <option [value]="-1">{{ 'All' | translate }}</option>
            <option [value]="item.id" *ngFor="let item of allowUsersList">{{item.login}}</option>
          </select>
          <!-- <select class="form-control " id="countrId" [(ngModel)]="countryId">
            <option [ngValue]="null" disabled>{{ 'Select' | translate }}</option>
            <option *ngFor="let country of countries" [ngValue]="country.id">{{country.name | translate }}</option>
          </select> -->
        </div>
        <!-- <div class="col-md-1">


        </div> -->
        <div class="col-md-6">
          <label for="jurisdictionId">{{ 'Situation' | translate }}</label>
          <input type="radio" [(ngModel)]="typeId" class="ml-2" id="All" name="type" value="All"> {{ 'All'
          | translate }}
          <input type="radio" [(ngModel)]="typeId" class="ml-2" id="Active" name="type" value="Active"> {{
          'Active' | translate }}
          <input type="radio" [(ngModel)]="typeId" class="ml-2" id="Blocked" name="type" value="Blocked">
          {{ 'Blocked' | translate }}
          <!-- <div class="col-md-4"></div>
          <div class="col-md-4"></div>
          <div class="col-md-4"></div> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-2">
          <button (click)="filterGrid()" class="btn btn-primary btn-sm">{{ 'ApplyFilters' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title row">
        <div class="col-md-9">
          <span class="material-icons default-icon">search</span>
          {{ 'PlayersList' | translate }}
        </div>
        <div class="col-md-3 text-right" *ngIf="CreteNewPlayerPermit">
          <a routerLink="/players/create"><button class="btn btn-success btn-sm"> {{'Create New' | translate}}
            </button></a>
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div style="text-align: right;">
        <mat-form-field>
          <mat-label>{{ 'Search' | translate }}...</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
      </div>
      <div class="table-responsive" style="overflow: auto;white-space: normal;word-wrap: break-word !important;">
        <table class="table table-bordered" mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="mat-sort-header-content">
                {{ ('Username').toUpperCase()}} <br />
                {{ ('Name' | translate).toUpperCase()}} <br>
                {{ ('Email' | translate).toUpperCase()}}
              </span>
            </th>
            <td mat-cell *matCellDef="let element"
              [ngClass]="{'text-danger': element.deleted == true, '' : element.deleted == false }">{{'Username' |
              translate}} : {{element.login}} <br>
              {{'Name' | translate}} : {{element.name}} <br>
              {{'Email' | translate}} : {{element.email}}
            </td>
          </ng-container>

          <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="mat-sort-header-content">
                {{ ('Country' | translate).toUpperCase()}} <br>
                {{ ('Region' | translate).toUpperCase()}}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.country}} <br>
              {{element.city}}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastLogin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="mat-sort-header-content">
                {{ ('LastLogin' | translate).toUpperCase()}} <br>
                {{ ('Registration Date' | translate).toUpperCase()}} <br>
                {{ ('Number of Logins' | translate).toUpperCase()}}
              </span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="description">
                {{ ('LastLogin' | translate)}}:</span>
              <span *ngIf="element.numberOfLogins != 0">
                {{convertedDate(element.lastLogin) | date: 'dd/MM/yyyy - hh:mm:ss'}}
              </span>
              <span *ngIf="element.numberOfLogins == 0">
                {{ ('notLoggedIn' | translate) }}
              </span> <br>
              <span class="description">{{ ('Registration Date' | translate)}}:</span> {{element.registrationDate |
              date: 'dd/MM/yyyy - hh:mm:ss'}} <br>
              <span class="description">{{ ('Number of Logins' | translate)}}:</span> {{element.numberOfLogins}}
            </td>
          </ng-container>

          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> <span class="mat-sort-header-content">{{ ('Account
                Balance' |
                translate).toUpperCase()}}</span>
            </th>
            <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.credit * Currency.quote |
              currency:'':''}} </td>
          </ng-container>
          <ng-container matColumnDef="deposits">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="mat-sort-header-content">
                {{ ('Total Deposits' | translate).toUpperCase()}} <br>
                <!-- {{ ('Commission' | translate).toUpperCase()}} <br> -->
                {{ ('Total Withdrawals' | translate).toUpperCase()}}
              </span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{Currency.currencySymbol}} {{element.deposits * Currency.quote | currency:'':''}} <br>
              <!-- {{Currency.currencySymbol}} {{element.commission * Currency.quote | currency:'':''}} <br> -->
              {{Currency.currencySymbol}} {{element.withdrawals * Currency.quote | currency:'':''}}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalBettingAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span class="mat-sort-header-content">
                {{ ('Total Betting Amount' | translate).toUpperCase()}} <br>
                {{ ('Betting Win Amount' | translate).toUpperCase()}} <br>
                {{ ('Betting Balance' | translate).toUpperCase()}}
              </span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>{{Currency.currencySymbol}} {{element.totalBettingAmount * Currency.quote | currency:'':''}}</span><br>
              <span>{{Currency.currencySymbol}} {{element.bettingWinAmount * Currency.quote | currency:'':''}}</span><br>
              <span *ngIf="(element.bettingWinAmount - element.totalBettingAmount) == 0.0">{{Currency.currencySymbol}} {{element.bettingBalance * Currency.quote | currency:'':''}}</span>
              <span *ngIf="(element.bettingWinAmount - element.totalBettingAmount) < 0"
                class="text-danger">{{Currency.currencySymbol}} {{(element.bettingWinAmount - element.totalBettingAmount) *
                  Currency.quote | currency:'':''}}</span>
              <span *ngIf="(element.bettingWinAmount - element.totalBettingAmount) > 0" 
                class="text-success">{{Currency.currencySymbol}}
                {{(element.bettingWinAmount - element.totalBettingAmount) *
                Currency.quote | currency:'':''}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="jurisdictionStr">
            <th mat-header-cell *matHeaderCellDef>
              <span class="mat-sort-header-content">
                {{ ('Jurisdiction' | translate).toUpperCase()}}
              </span>
            </th>
            <td mat-cell *matCellDef="let element">
              <!-- <span class="mb-2" *ngFor="let item of element.jurisdictionStr.reverse()">{{item}} <br></span> -->
              <span class="mb-2"
                *ngFor="let item of element.jurisdictionStr.split('|').slice(1).reverse(); let last = last">{{item}}
                <ng-container *ngIf="!last"><br></ng-container>
              </span>
              <!-- {{ element.jurisdictionStr }} -->
            <td>
          </ng-container>



          <!-- <ng-container matColumnDef="totalBetAmount">
            <th mat-header-cell *matHeaderCellDef> {{ ('Total Betting Amount' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> R$ {{element.totalBetAmount.toFixed(2)}} </td>
          </ng-container>
          <ng-container matColumnDef="betWinAmount">
            <th mat-header-cell *matHeaderCellDef> {{ ('Betting win Amount' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> R$ {{element.betWinAmount.toFixed(2)}} </td>
          </ng-container>
          <ng-container matColumnDef="betBalance">
            <th mat-header-cell *matHeaderCellDef> {{ ('Betting Balance' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> R$ {{element.betBalance.toFixed(2)}} </td>
          </ng-container> -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              <span class="mat-sort-header-content">
                {{('Action' | translate).toUpperCase()}}
              </span>
            </th>
            <td mat-cell *matCellDef="let item">
              <button *ngIf="PlayerPassResetPermit" style="width: 27px;" type="button" (click)="getUserName(item.id)"
                class="btn btn-primary btn-sm px-2" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-key"
                  aria-hidden="true"></i></button>
              <button *ngIf="displayRechargeButton" style="width: 27px;" type="button"
                (click)="getDepositDetail(item.id)" class="btn btn-success btn-sm px-2" data-toggle="modal"
                data-target="#exampleModal2"><i class="fas fa-euro-sign" aria-hidden="true"></i></button>
              <button *ngIf="PlayerEditPermit" style="width: 27px;" type="button" (click)="getPlayerInfo(item.id)"
                class="btn btn-success btn-sm px-2"><i class="fas fa-edit" aria-hidden="true"></i></button>
              <button style="width: 27px;" type="button" (click)="loadTransactions(item.id)"
                class="btn btn-primary btn-sm px-2" data-toggle="modal" data-target="#exampleModal8"><i
                  class="fa fa-info-circle" aria-hidden="true"></i></button>
              <ng-container *ngIf="validadePermit(item.deleted); else UnBlockPlayer">
                <button *ngIf="displayBlockPlayer" style="width: 27px;" type="button" (click)="selectedPlayer(item.id)"
                  [ngClass]="item.deleted == true ? 'btn btn-success btn-sm px-2' : 'btn btn-danger btn-sm px-2'"
                  data-toggle="modal" data-target="#exampleModal9"><i class="fa fa-ban" aria-hidden="true"></i></button>
              </ng-container>
              <ng-template #UnBlockPlayer>
                <div class="btn btn-warning btn-sm px-2 BtnBlockDefault" data-toggle="modal"
                  matTooltip="{{'AssistContat' | translate}}"><i class="fa fa-exclamation" aria-hidden="true"></i></div>
              </ng-template>
            </td>
          </ng-container>


          <tr style="background:#eee" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons #paginator1></mat-paginator>
      </div>


      <!-- <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>
                {{ 'Name' | translate }}
                <br />
                {{ 'Email' | translate }}
              </th>
              <th>
                {{ 'Country' | translate }}
                <br />
                {{ 'City' | translate }}
              </th>
              <th>
                {{ 'Registration Date' | translate }}
                <br />
                {{ 'Last login' | translate }}
                <br />
                {{ 'No. of logins' | translate }}
              </th>
              <th>{{ 'Balance' | translate }}</th>
              <th>
                {{ 'Deposits' | translate }}
                <br />
                {{ 'Withdrawals' | translate }}
              </th>
              <th>
                {{ 'Total bet amount' | translate }}
                <br />
                {{ 'Bet win amount' | translate }}
                <br />
                {{ 'Bet balance' | translate }}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of players">
              <td>
                {{ item.name }}
                <br />
                {{ item.email }}
                <br />
                {{ item.userName }}
              </td>
              <td>
                {{ item.country }}
                <br />
                {{ item.city }}
              </td>
              <td>
                {{ item.registrationDate }}
                <br />
                {{ item.lastLogin }}
                <br />
                {{ item.numberOfLogins }}
              </td>
              <td>{{ item.balance }}</td>
              <td>
                {{ item.deposits }}
                <br />
                {{ item.withdrawals }}
              </td>
              <td>
                {{ item.totalBetAmount }}
                <br />
                {{ item.betWinAmount }}
                <br />
                {{ item.betBalance }}
              </td>
              <td>
                <button type="button" (click)="getUserName(item.id)" class="btn btn-primary px-2" data-toggle="modal"
                  data-target="#exampleModal"><i class="fa fa-key" aria-hidden="true"></i></button>
                <button type="button" (click)="getDepositDetail(item.id)" class="btn btn-default px-2"
                  data-toggle="modal" data-target="#exampleModal2">$</button>
                <button type="button" (click)="infoPlayer(item.id)" class="btn btn-secondary px-2" data-toggle="modal"
                  data-target="#exampleModal5">E</button>
                <button type="button" (click)="loadTransactions(item.id)" class="btn btn-success px-2"
                  data-toggle="modal" data-target="#exampleModal8"><i class="fa fa-info-circle"
                    aria-hidden="true"></i></button>
                <button type="button" (click)="selectedPlayer(item.id)" class="btn btn-info px-2" data-toggle="modal"
                  data-target="#exampleModal9"><i class="fa fa-ban" aria-hidden="true"></i></button>

              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="exampleModalLabel">{{'Password Reset' | translate}}</h4>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="usernamePasswordReset"> {{'UserName' | translate}}</label>
          </div>
          <div class="col-md-4">
            <p id="UserNamePR">{{ UserNamePR | translate}}</p>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm" data-dismiss="modal">{{'closebtn' | translate}}</button>
        <button type="button" class="btn btn-danger btn-sm" data-dismiss="modal"
          (click)="ResetPassword($event)">{{'Password Reset' |
          translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="exampleModalLabel2">{{'Inserting Transactions Details' | translate}}</h4>
        <button type="button" #closeModal class="close pull-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <label for="custNameIDD"> {{'Customer Name' | translate}}</label>
            </div>
            <div class="col-md-4">
              <p id="custNameIDD">{{ custNameIDD | translate}}</p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <label for="balanceIDD"> {{'Balance' | translate}}</label>
            </div>
            <div class="col-md-4">
              <p id="balanceIDD">
                {{Currency.currencySymbol}} {{balanceIDD * Currency.quote | currency:'':'' }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <label for="ttIDD"> {{'Transfer Type' | translate}}</label>
            </div>
            <div class="col-md-7">
              <select id="ttIDD" [(ngModel)]="ttIDD" class="form-control">
                <option [value]="creditCard">{{'Extend Credit' | translate}}</option>
                , <option [value]="debitCard">{{'Withdraw' | translate}}</option>
              </select>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <label for="ATDIDD"> {{'Amount' | translate}}</label>
            </div>
            <div class="col-md-7">
              <input type="number" [(ngModel)]="ATDIDD" #amount class="form-control" name="ATDIDD" id="ATDIDD"
                placeholder="{{'Amount' | translate}}" value="">
              <span class="text-danger" *ngIf="amount.value < 0">Digite um valor válido!</span>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" data-dismiss="modal">{{'closebtn' |
          translate}}</button>
        <button [disabled]="amount.value == '' || amount.value <= 0 ? true : false" type="button"
          class="btn btn-primary" (click)="attentionDeposit($event)" data-dismiss="Save">{{'savebtn' |
          translate}}</button>
      </div>
    </div>
  </div>
</div>

<div id="myModal" mdbModal #resetPassModel="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="resetPassModel.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'Attention' | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" [innerHtml]="newPassword">
            <!-- {{newPassword}} -->
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="resetPassModel.hide()" mdbWavesEffect>{{ 'OK' | translate }}</button>
      </div>
    </div>

  </div>
</div>

<div class="modal fade" mdbModal #depostiFundsModal="mdbModal" id="exampleModal3" tabindex="-1"
  aria-labelledby="exampleModalLabel3" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="exampleModalLabel3">{{'Attention' | translate}}</h4>
        <button type="button" #confirmationModel class="close pull-right" (click)="depostiFundsModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-9">
            <p>{{'Do you really want to make transaction' | translate}}?</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-9">
            <p>{{Currency.currencySymbol}} {{depositedAmountA * Currency.quote | currency:'':''}}</p>
          </div>
        </div>
        <p></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" (click)="depostiFundsModal.hide()">{{'closebtn' | translate}}</button>
        <button type="button" class="btn btn-primary" data-dismiss="Save" data-toggle="modal"
          data-target="#exampleModal4" (click)="depositFunds($event)">{{'savebtn' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel4" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="exampleModalLabel2">{{'permits.transactionsummary' | translate}}</h4>
        <button type="button" #closeModal class="close pull-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="depositedIDD"> {{'Transaction Amount' | translate}}</label>
          </div>
          <div class="col-md-4">
            <p id="depositedIDD">{{Currency.currencySymbol}} {{depositedIDD * Currency.quote | currency:'':''}}</p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="balanceIDD"> {{'Balance' | translate}}</label>
          </div>
          <div class="col-md-4">
            <p id="balanceIDD">{{Currency.currencySymbol}} {{balanceIDD * Currency.quote | currency:'':''}}</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="depositFunds($event)" data-dismiss="modal">{{'savebtn' |
          translate}}</button>
      </div>
    </div>
  </div>
</div> -->


<div class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel5" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title text-danger" id="exampleModalLabel5">{{'Edit player' | translate}}</h1>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="UsernameEP"> {{'Username' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="text" id="UsernameEP" placeholder="Username" class="form-control">
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="LastnameEP"> {{'Last name' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="text" id="LastnameEP" [(ngModel)]="LastnameEP" placeholder="Last name" class="form-control">
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="PasswordEP"> {{'Password' | translate}}</label>
          </div>
          <div class="col-md-4">
            <input type="password" id="PasswordEP" [(ngModel)]="PasswordEP" placeholder="Password" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="ConfirmPasswordEP"> {{'Confirm Password' | translate}}</label>
          </div>
          <div class="col-md-4">
            <input type="password" id="ConfirmPasswordEP" [(ngModel)]="ConfirmPasswordEP" placeholder="Confirm password" class="form-control">
          </div>
        </div> -->

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="EmailEP"> {{'Email' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="email" id="EmailEP" [(ngModel)]="EmailEP" placeholder="Email" class="form-control">
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="GenderEP"> {{'Gender' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="radio" [(ngModel)]="GenderMaleEP" id="GenderMaleEP" name="genderEP"> Male
            <input type="radio" [(ngModel)]="GenderMaleEP" id="GenderFemaleEP" name="genderEP"> Female
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="BirthdayEP"> {{'Birthday' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="date" id="BirthdayEP" [(ngModel)]="BirthdayEP" placeholder="Birthday" class="form-control">
          </div>
        </div>


        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="AddressEP"> {{'Address' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="text" id="AddressEP" [(ngModel)]="AddressEP" placeholder="Address" class="form-control">
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="CityEP"> {{'City' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="text" id="CityEP" [(ngModel)]="CityEP" placeholder="City" class="form-control">
          </div>
        </div>


        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="countryIdEP"> {{'Country' | translate}}</label>
          </div>
          <div class="col-md-7">
            <select class="form-control " id="countryIdEP" [(ngModel)]="countryIdEP">
              <option [ngValue]="null" disabled>{{ 'Select' | translate }}</option>
              <option *ngFor="let country of countries" [ngValue]="country.id">{{country.name | translate }}</option>
            </select>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="ZipCodeEP"> {{'Zip-code' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="text" id="ZipCodeEP" [(ngModel)]="ZipCodeEP" placeholder="Zip code" class="form-control">
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="PhoneEP"> {{'Phone' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="text" id="PhoneEP" [(ngModel)]="PhoneEP" placeholder="Phone" class="form-control">
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="CellPhoneEP"> {{'Cell Phone' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="text" id="CellPhoneEP" [(ngModel)]="CellPhoneEP" placeholder="Phone" class="form-control">
          </div>
        </div>


        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="WhatsappEP"> {{'Whatsapp' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="text" id="WhatsappEP" [(ngModel)]="WhatsappEP" placeholder="Whatsapp" class="form-control">
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <label for="NotesEP"> {{'Notes' | translate}}</label>
          </div>
          <div class="col-md-7">
            <input type="text" id="NotesEP" [(ngModel)]="NotesEP" placeholder="Notes" class="form-control">
          </div>
        </div>



        <div class="modal-footer">
          <button type="button" class="btn" data-dismiss="modal">{{'closebtn' | translate}}</button>
          <button type="button" class="btn btn-danger" (click)="editPlayer($event)" data-dismiss="modal">{{'savebtn' |
            translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModal6" tabindex="-1" aria-labelledby="exampleModalLabel6" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="exampleModalLabel5">{{'Withdrawal Requests' | translate}}</h4>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  {{ 'Id' | translate }}
                </th>
                <th>
                  {{ 'Name' | translate }}
                </th>
                <th>
                  {{ 'Creation' | translate }}
                </th>
                <th>{{ 'Last update' | translate }}</th>
                <th>
                  {{ 'Amount' | translate }}
                </th>
                <th>
                  {{ 'Status' | translate }}
                </th>
                <th>
                  {{ 'Description' | translate }}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of players">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.country }}
                </td>
                <td>
                  {{ item.registrationDate }}
                </td>
                <td>{{Currency.currencySymbol}} {{ item.balance * Currency.quote | currency:'':''}}</td>
                <td>
                  {{Currency.currencySymbol}} {{ item.deposits * Currency.quote | currency:'':'' }}
                </td>
                <td>
                  {{Currency.currencySymbol}} {{ item.totalBetAmount * Currency.quote | currency:'':'' }}
                </td>
                <td>
                  {{Currency.currencySymbol}} {{ item.totalBetAmount * Currency.quote | currency:'':'' }}
                </td>
                <td>
                  <button type="button" class="btn btn-secondary px-2" data-toggle="modal"
                    data-target="#exampleModal7">E</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" data-dismiss="modal">{{'closebtn' | translate}}</button>
        <button type="button" class="btn btn-danger" data-dismiss="Save">{{'savebtn' | translate}}</button>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="exampleModal7" tabindex="-1" aria-labelledby="exampleModalLabel7" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="exampleModalLabel7">{{'Attention' | translate}}</h4>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <label for="UserIDAWR">{{'ID' | translate}}</label>
          </div>
          <div class="col-md-3">
            <p id="UserIDAWR">{{UserIDAWR}}</p>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-2">
            <label for="UsernameAWR">{{'Username' | translate}}</label>
          </div>
          <div class="col-md-3">
            <p id="UsernameAWR">{{UsernameAWR}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <label for="AmountAWR">{{'Amount' | translate}}</label>
          </div>
          <div class="col-md-3">
            <p id="AmountAWR">{{Currency.currencySymbol}} {{AmountAWR}}</p>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-2">
            <label for="LastupdateAWR">{{'Lastupdate' | translate}}</label>
          </div>
          <div class="col-md-3">
            <p id="LastupdateAWR">{{LastupdateAWR}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <label for="StatusAWR">{{'Status' | translate}}</label>
          </div>
          <div class="col-md-3">
            <select class="form-control " id="StatusAWR" [(ngModel)]="StatusAWR">
              <option [ngValue]="Waiting">{{ 'Waiting' | translate }}</option>
              <option [ngValue]="Accepted">{{ 'Accepted' | translate }}</option>
              <option [ngValue]="Rejected">{{ 'Rejected' | translate }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <label for="DescriptionAWR">{{'Description' | translate}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <textarea id="DescriptionAWR" name="DescriptionAWR" rows="4" cols="60"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" data-dismiss="modal">{{'closebtn' | translate}}</button>
        <button type="button" class="btn btn-danger" data-dismiss="Save" data-toggle="modal"
          data-target="#exampleModal4">{{'savebtn' | translate}}</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModal8" tabindex="-1" aria-labelledby="exampleModalLabel8" aria-hidden="true">
  <div class="modal-dialog modal-lg " style="max-width: fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title h4" id="StyleModalLabel">{{'Transaction List' | translate}}</h4>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <div style="text-align: left;">
            <mat-form-field>
              <mat-label>{{ 'Search' | translate }}...</mat-label>
              <input matInput (keyup)="applyFilterTransaction($event)" #input>
            </mat-form-field>
          </div>
          <table mat-table [dataSource]="dataSourceTransaction" style="width: 100% ;background-color:#e3e1e1;" matSort>
            <ng-container matColumnDef="id">
              <th mat-header-cell class="HeaderStyle" *matHeaderCellDef> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="coupon">
              <th mat-header-cell class="HeaderStyle" *matHeaderCellDef> {{ ('Coupon' | translate).toUpperCase() }}
              </th>
              <td mat-cell *matCellDef="let element">
                <span style="cursor: pointer;color:#003771;" (click)="detailCoupon(element.betUuid8)"
                  *ngIf="element.betUuid8 && element.betUuid8 != 'null' && element.type == 1 || element.type == 3|| element.type == 5">
                  {{element.betUuid8}}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell class="HeaderStyle" *matHeaderCellDef> {{ ('date.title' | translate).toUpperCase()}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.dateFormatted}} </td>
            </ng-container>

            <ng-container matColumnDef="movement">
              <th mat-header-cell class="HeaderStyle" *matHeaderCellDef> {{ ('Movement' | translate).toUpperCase()}}
              </th>
              <td mat-cell *matCellDef="let element" [style.color]="element.color"> {{element.typeDesc | translate}}
              </td>
              <!-- [style.color]="element.color" -->
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell class="HeaderStyle" *matHeaderCellDef> {{ ('Description' |
                translate).toUpperCase()}} </th>
              <td mat-cell *matCellDef="let element"> {{element.description | translate}} </td>
            </ng-container>

            <ng-container matColumnDef="input">
              <th mat-header-cell class="HeaderStyle" *matHeaderCellDef> {{ ('Input' | translate).toUpperCase()}}
              </th>
              <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.creditIn * Currency.quote
                |
                currency:'':''}} </td>
            </ng-container>

            <ng-container matColumnDef="output">
              <th mat-header-cell class="HeaderStyle" *matHeaderCellDef> {{ ('Output' | translate).toUpperCase()}}
              </th>
              <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.creditOut * Currency.quote
                |
                currency:'':''}} </td>
            </ng-container>

            <ng-container matColumnDef="balance">
              <th mat-header-cell class="HeaderStyle" *matHeaderCellDef> {{ ('Balance' | translate).toUpperCase()}}
              </th>
              <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.balance * Currency.quote |
                currency:'':''}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayTransColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayTransColumns;">
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="[05, 10]" showFirstLastButtons #paginator2></mat-paginator>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" data-dismiss="modal">{{'closebtn' | translate}}</button>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="exampleModal9" tabindex="-1" aria-labelledby="exampleModalLabel9" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="exampleModalLabel9">{{'Attention' | translate}}</h4>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-9">
            <p>{{'You Sure Want to Block Player?' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <ng-container *ngIf="displayUnblockPlayer; else ModalBlackAndUnBlockUser">
          <button type="button" class="btn btn-success" (click)="unBlockUser($event)" data-dismiss="modal">{{'UnBlock' |
            translate}}</button>
        </ng-container>
        <ng-template #ModalBlackAndUnBlockUser>
          <button class="defaultUnblockBTN disabledBTN">{{'UnBlock' |
            translate}}</button>
        </ng-template>
        <!-- <button type="button" class="btn btn disabled" (click)="unBlockUser($event)" data-dismiss="modal">{{'UnBlock' |
          translate}}</button> -->
        <button type="button" class="btn btn-danger" (click)="blockPlayer($event)" data-dismiss="modal">{{'Block' |
          translate}}</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">{{'delete.cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #frame2="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-primary" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'pg-manage-ticket.view-ticket' | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame2.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <p id="final-msg" [innerHtml]="viewTicket"></p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="primary" outline="true" class="btn" mdbWavesEffect (click)="frame2.hide()"
          data-dismiss="modal">{{ 'pg-manage-ticket.back' | translate }}</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #frame3="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'pg-manage-ticket.confirm-cancel' | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame3.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <p id="final-msg" [innerHtml]="viewTicket"></p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="danger" class="waves-light" (click)="cancelTicket()" mdbWavesEffect>{{
          'pg-manage-ticket.confirm' | translate }}
        </a>
        <a type="button" mdbBtn color="primary" outline="true" class="btn" mdbWavesEffect (click)="frame3.hide()"
          data-dismiss="modal">{{ 'pg-manage-ticket.back' | translate }}</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #frameMessage="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
    <div class="modal-content modal-lg  bg-theme"
      style="height: auto; max-height: 700px; background-color: #1f1f1f !important;">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frameMessage.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3 adjustModel scrollDeg">


        <p style="color: black" id="msg" [innerHtml]="msgToAdd"></p>


      </div>
      <div class="modal-footer d-flex justify-content-center" style="background-color: #1f1f1f !important;">

        <a type="button" color="waves-light button-font" style="padding:.54rem 2.14rem; background-color:#33a0df;"
          class="btn" mdbWavesEffect (click)="frameMessage.hide()" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>

<!--Modal Standard Danger Message-->
<div mdbModal #frameMessageDanger="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardDanger" style="height: 350px;
max-height: 350px;">
    <div class="modal-content  bg-theme" style="height: 378px;
max-height: 400px;">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="frameMessageDanger.hide();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">


        <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>


      </div>
      <div class="modal-footer d-flex justify-content-center">

        <a type="button" color="waves-light button-font" style="background-color:#ff4b4b;" class="btn" mdbWavesEffect
          (click)="frameMessageDanger.hide();" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>

<!--Modal Standard Message-->

<!--Modal Credit Message-->
<div mdbModal #frameMessageCredit="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardCredit">
    <div class="modal-content  bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="CreditRedirect();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">


        <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>


      </div>
      <div class="modal-footer d-flex justify-content-center">
        <a type="button" color="waves-light button-font" style="background-color:#01d36b;" class="btn" mdbWavesEffect
          (click)="CreditRedirect();" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>

<!--Modal Credit Message-->

<!--Modal Betting Message-->
<div mdbModal #frameMessageBetting="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardBetting">
    <div class="modal-content  bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="BettingRedirect();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="">

          <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>

        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">

        <a type="button" color="waves-light button-font" style="background-color:#01d36b;" class="btn" mdbWavesEffect
          (click)="BettingRedirect();" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>