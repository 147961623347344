import { Login } from '../../interface/login.interface';
import { Transaction, PlayerTransaction } from '../../interface/transaction.interface';
import { DepositFunds } from '../../interface/deposit-funds.interface';
import { Player, PlayerDocs } from '../../interface/player.interface';
import { Jurisdiction } from '../../interface/jurisdiction.interface';
import { CountryService } from '../../services/country.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Country } from 'src/app/interface/country.interface';
import { PlayerService } from 'src/app/services/player.service';
import { JurisdictionsService } from 'src/app/services/jurisdictions.service';
import { LoginService } from '../../services/login.service';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { TransactionService } from 'src/app/services/transaction.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['userName', 'fileName', 'typeDesc', 'statusDesc', 'description', 'createdDate'];
  userAdmin: any;
  PlayerEditPermit: boolean = false;

  constructor(private playerService: PlayerService, private countryService: CountryService,
    private jurisdictionService: JurisdictionsService, private transactionService: TransactionService,
    private loginService: LoginService, private toastService: ToastService, private route: Router,
    public spinner: NgxSpinnerService, private cookieService: CookieService,
    private translateService: TranslateService) {
    this.userAdmin = loginService.getUserInfoFromCookie();
    this.loginService.GetPendingDocuments(this.userAdmin.id, this.userAdmin.login).subscribe((data: PlayerDocs[]) => {
      //this.dataSource.data = data
      this.dataSource.data = data.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1)
      this.dataSource.paginator = this.paginator
    });;

  }

  userDetail(userId: number) {
    if(this.PlayerEditPermit)this.route.navigate(['/players/edit/' + userId]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    this.PlayerEditPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'editplayerdocuments') == null ? false : true : true;
  }

}
