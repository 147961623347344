import { Login } from './../../../interface/login.interface';
import { Transaction, PlayerTransaction } from './../../../interface/transaction.interface';
import { DepositFunds } from './../../../interface/deposit-funds.interface';
import { Player } from './../../../interface/player.interface';
import { Jurisdiction } from './../../../interface/jurisdiction.interface';
import { CountryService } from './../../../services/country.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Country } from 'src/app/interface/country.interface';
import { PlayerService } from 'src/app/services/player.service';
import { JurisdictionsService } from 'src/app/services/jurisdictions.service';
import { LoginService } from '../../../services/login.service';
import { FormGroup, NgModel } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DepositSummary } from 'src/app/interface/deposit-funds.interface';
import { TransactionService } from 'src/app/services/transaction.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective } from 'angular-bootstrap-md';
import { ToastService } from 'src/app/services/toast.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';
import { Currency } from 'src/app/interface/currency.interface';
import { CouponListComponent } from '../../coupon-list/coupon-list.component';
import { CouponServiceService } from 'src/app/services/coupon-service.service';
import { Round } from 'src/app/interface/round.interface';
import { Bet } from 'src/app/interface/bet.interface';
import { User, userAssistant } from 'src/app/interface/user.interface';

const formula1Shields = [
  { team: 'Alpha Romeo', shield: 'f1-alpha-romeo.png', drivers: 'guanyu valtteri bottas' },
  { team: 'AlphaTauri', shield: 'f1-alphatauri.png', drivers: 'pierre gasly yuki tsunda' },
  { team: 'Alpine', shield: 'f1-alpine.png', drivers: 'esteban ocon fernando alonso' },
  { team: 'Aston Martin', shield: 'f1-aston-martin.png', drivers: 'sebastian vettel lance stroll' },
  { team: 'Ferrari', shield: 'f1-ferrari.png', drivers: 'charles leclerc carlos sainz' },
  { team: 'Haas F1 Team', shield: 'f1-haas-f1-team.png', drivers: 'kevin magnussen mick schumacher' },
  { team: 'McLaren', shield: 'f1-mclaren.png', drivers: 'lando norris daniel ricciardo' },
  { team: 'Mercedes', shield: 'f1-mercedes.png', drivers: 'george russell lewis hamilton' },
  { team: 'Red Bull', shield: 'f1-red-bull.png', drivers: 'max verstappen sergio perez' },
  { team: 'Williams', shield: 'f1-williams.png', drivers: 'alexander albon nicholas latifi' }
];
// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//     name: 'myfilter',
//     pure: false
// })

@Component({
  selector: 'app-players-list',
  templateUrl: './players-list.component.html',
  styleUrls: ['./players-list.component.scss']
})
export class PlayersListComponent implements OnInit {
  @ViewChild('closeModal') private closeModal: any;
  @ViewChild('confirmationModel') confirmationModel: any;
  @ViewChild('resetPassModel') resetPassModel: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('frame3', { static: true }) modalCancel: ModalDirective;
  @ViewChild('frame3', { static: true }) modalRegister: ModalDirective;
  @ViewChild('frame2', { static: true }) modalTicket: ModalDirective;
  @ViewChild('frame4', { static: true }) modalWarning: ModalDirective;
  @ViewChild('frameMessage', { static: true }) modalMessages: ModalDirective;
  @ViewChild('frameMessageDanger', { static: true }) modalMessagesDanger: ModalDirective;
  @ViewChild('frameMessageCredit', { static: true }) modalMessagesCredit: ModalDirective;
  @ViewChild('frameMessageBetting', { static: true }) modalMessagesBetting: ModalDirective;
  @ViewChild('depostiFundsModal', { static: true }) depostiFundsModal: ModalDirective;

  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];
  showCloseBtn: boolean = false;
  showCloseRoundBtn: boolean = false;
  msgToAdd: string = '';
  titleToAdd: string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild('paginator1') set matPaginator1(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild('paginator2') set matPaginator2(mp: MatPaginator) {
    this.paginator2 = mp;
    this.dataSourceTransaction.paginator = this.paginator2;
  }
  displayedColumns: string[] = ['lastName', 'country', 'lastLogin', 'balance', 'deposits', 'totalBettingAmount', 'jurisdictionStr', 'actions'];
  //displayedColumns: string[] = ['lastName', 'name', 'userName', 'email', 'country', 'city', 'registerDateTime', 'lastLogin', 'numberOfLogins', 'deposits', 'withdrawals', 'balance', 'actions'];
  displayTransColumns: string[] = ['id', 'coupon', 'date', 'movement', 'description', 'input', 'output', 'balance'];
  //displayedColumns: string[] = ['lastName', 'email', 'lastLogin', 'numberOfLogins', 'deposits', 'withdrawals', 'balance', 'totalBetAmount','betWinAmount','betBalance','actions'];
  dataSource = new MatTableDataSource<any>([]);
  dataSourceTransaction = new MatTableDataSource<any>([]);
  creditCard: string;
  debitCard: string;
  players: Array<Player> = [];
  transactions: Array<Transaction> = [];
  playerSorted: Array<Player> = [];
  searchForm: FormGroup;
  countries: Country[];
  jurisdictions: Jurisdiction[];
  userAdmin: any;
  jurisdictionId: string;
  countryId: string;
  typeId: string = "All";
  registerForm: FormGroup;
  countryEPId: string;
  StatusAWR: string;
  Waiting: string;
  Accepted: string;
  Rejected: string;
  UserNamePR: string;
  custNameIDD: string;
  balanceIDD: number;
  ATDIDD: number;
  ttIDD: string;
  depositedAmountA: number;
  depositedIDD: number;
  LastnameEP: string;
  PasswordEP: string;
  ConfirmPasswordEP: string;
  EmailEP: string;
  GenderMaleEP: string;
  BirthdayEP: string;
  AddressEP: string;
  CityEP: string;
  countryIdEP: string;
  ZipCodeEP: string;
  PhoneEP: string;
  CellPhoneEP: string;
  WhatsappEP: string;
  NotesEP: string;
  newPassword: string;
  AmountAWR: string;
  LastupdateAWR: string;
  UserIDAWR: string;
  UsernameAWR: string;
  resetPasswordResult: boolean;
  depositSummaryResult: DepositSummary;
  playerDetails: Player;
  blockPlayerResult: boolean;
  selectedPlayerId: number;
  depositfunds: DepositFunds;
  editPlayerRequest: Player;
  seletedJurisdiction: any;
  usersList: any = [];
  allowUsersList: any = [];
  selectedJurisdictionLevel: any;
  Currency: Currency = <Currency>{};
  transactionList: Array<Transaction>[] = [];

  round: Round;
  bets: Bet[] = [];
  users: User[] = [];
  userNameToShow = new Array<string>();
  isLoaded: boolean = false;
  searchReturn: boolean = false;
  resultSearch: string;
  info: any = [{ id: 0, name: 'All' }];
  roundName: string;
  indexActivated: number;
  viewTicket: string;
  userAllBets: Bet[] = [];
  selecte = 0;

  displayRechargeButton: boolean = false;
  displayBlockPlayer: boolean = false;
  displayUnblockPlayer: boolean = false;
  PlayerEditPermit: boolean = false;
  PlayerPassResetPermit: boolean = false;
  CreteNewPlayerPermit: boolean = false;
  formattedDate: Date[];
  radarSport: number;
  singleMatch: number;


  constructor(private playerService: PlayerService, private countryService: CountryService,
    private jurisdictionService: JurisdictionsService, private transactionService: TransactionService,
    private loginService: LoginService, private toastService: ToastService, private route: Router,
    public spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private cookieService: CookieService,
    private interactionService: InteractionService,
    private couponService: CouponServiceService) {
    this.creditCard = 'creditCard';
    this.debitCard = 'debitCard';

    this.userAdmin = loginService.getUserInfoFromCookie();
    this.displayRechargeButton = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'playerdeposit') == null ? false : true : true;
    this.displayBlockPlayer = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'blockplayer') == null ? false : true : true;
    this.displayUnblockPlayer = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'unlockplayer') == null ? false : true : true;
    this.PlayerEditPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'editplayerdata') == null ? false : true : true;
    this.PlayerPassResetPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'resetpassword') == null ? false : true : true;
    this.CreteNewPlayerPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'newplayer') == null ? false : true : true;

    this.ttIDD = this.creditCard;
    this.selectedJurisdictionLevel = -1;
    this.loadPlayers();
    this.getUsersList();
    // loading countries
    this.countryService.getCountries()
      .subscribe((data: Country[]) => {
        this.countries = data;
      }, error => {
        console.log(error);
      });

    // loading countries
    this.loginService.getJurisdictions(parseInt(localStorage.getItem("currentUserJurisdictionId"))).subscribe(res => {
      this.jurisdictions = res;
    }, error => {
      console.log(error);
    });
    this.selectedJurisdictionLevel = parseInt(localStorage.getItem("currentUserJurisdictionId"))

  }
  // tslint:disable-next-line:typedef
  // applyFilter($event) {
  //   this.players = [];
  //   this.playerSorted = [];
  //   this.playerService.getPlayers()
  //     .subscribe((data: Player[]) => {
  //       this.players = data;
  //       this.players.forEach((item) => {
  //         if (this.countryId === item.country) {
  //           this.playerSorted.push(item);
  //         }
  //       });
  //       this.players = this.playerSorted;
  //     }, error => {
  //       console.log(error);
  //     });
  // }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'lastLogin':
          return item.lastLogin
        // return this.convertedDate(item.lastLogin)
        default: return item[property];
      }
    };
  }

  convertedDate(element): Date {
    // const [date1, date2, date3] = element.split(' ')
    // const [day, month, year] = date1.split('/');
    // const [hours, minutes, seconds] = date3.split(':');
    // const formattedDate = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
    // return formattedDate;
    return element;
  }

  validadePermit(element) {
    if (!element) {
      if (this.displayBlockPlayer) return true
    } else if (element) {
      if (this.displayUnblockPlayer) return true
    }
  }

  async loadPlayers() {
    // Loading Players
    this.spinner.show();
    this.userAdmin = this.loginService.getUserInfoFromCookie();
    this.playerService.getPlayers(this.userAdmin.id, this.userAdmin.login)
      .subscribe((data: Player[]) => {
        this.spinner.hide();
        this.players = data;
        this.dataSource.data = data
        this.dataSource.paginator = this.paginator;
      }, error => {
        console.log(error);
      });
  }

  async loadPlayersCredit() {
    // Loading Players
    this.userAdmin = this.loginService.getUserInfoFromCookie();
    this.playerService.getPlayers(this.userAdmin.id, this.userAdmin.login)
      .subscribe((data: Player[]) => {
        this.players = data;
        this.dataSource.data = data
        this.dataSource.paginator = this.paginator;
      }, error => {
        console.log(error);
      });
  }

  detailCoupon(coupon) {
    this.couponDetail(coupon);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterTransaction(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTransaction.filter = filterValue.trim().toLowerCase();
  }

  filterGrid() {
    //GetPlayersByFilter
    this.spinner.show();
    this.userAdmin = this.loginService.getUserInfoFromCookie();
    if (this.selectedJurisdictionLevel == -1) {
      this.playerService.getPlayerByFilter(this.userAdmin.id, this.selectedJurisdictionLevel, this.seletedJurisdiction, this.typeId)
        .subscribe((data: Player[]) => {
          this.spinner.hide();
          this.players = data;
          this.dataSource.data = data
          this.dataSource.paginator = this.paginator;
        }, error => {
          console.log(error);
          this.spinner.hide();
        });
    }
    else {
      this.playerService.getPlayers(this.seletedJurisdiction, this.userAdmin.login)
        .subscribe((data: Player[]) => {
          this.spinner.hide();
          //deleted : true
          let listControler: Player[] = [];
          for (let player of data) {
            if (this.typeId == "All") listControler.push(player);
            else if (this.typeId == "Active" && player.deleted == false) listControler.push(player)
            else if (this.typeId == "Blocked" && player.deleted == true) listControler.push(player)
          }
          this.players = listControler;
          this.dataSource.data = listControler;
          this.dataSource.paginator = this.paginator;
        }, error => {
          console.log(error);
          this.spinner.hide();
        });
    }
  }

  // tslint:disable-next-line:typedef
  ResetPassword($event) {
    this.spinner.show();
    this.playerService.resetPassword(this.selectedPlayerId)
      .subscribe((data) => {
        this.spinner.hide();
        this.resetPassModel.show();
        this.newPassword = 'New Password: <b>' + data.password + '</b> ' + this.translateService.instant('Reset was sent by e-mail to the user.');
        //this.toastService.showNotification({ message: '(' + data.password + ') '+ this.translateService.instant('Reset was sent by e-mail to the user.') , type: 'success' });
        this.resetPasswordResult = data;
      }, error => {
        console.log(error);
      });
  }

  getTableBackground(type) {
    switch (type) {
      case 1:
        return "#00ffba"
        break;
      case 2:
        return "pink"
        break;
      case 3:
        return "#ffff69"
        break;
      case 4:
        return "#D3D3D3"
        break;
      case 5:
        return "#C0C0C0"
        break;
      case 6:
        return "pink"
        break;
      case 7:
        return "#00ffba"
        break;
      case 8:
        return "pink"
        break;
      case 9:
        return "#00ffba"
        break;
      case 10:
        return "#C0C0C0"
        break;
      default:
        break;
    }
  }

  // tslint:disable-next-line:typedef
  depositFunds($event) {
    this.userAdmin = this.loginService.getUserInfoFromCookie();
    this.depositfunds = {} as DepositFunds;
    this.depositfunds.playerId = this.selectedPlayerId;
    this.depositfunds.amount = this.depositedAmountA;
    this.depositfunds.balance = this.balanceIDD;
    this.depositfunds.userAdminId = this.userAdmin.id;
    this.depositfunds.transactionType = this.ttIDD;
    let user: User = this.loginService.getUserInfoFromCookie();
    this.depositfunds.assUsername = user.login;
    this.playerService.depositFunds(this.depositfunds)
      .subscribe((data: DepositSummary) => {
        this.loadPlayers();
        this.depositSummaryResult = data;
        this.cleanTempBalance();
        this.ATDIDD = null;
      }, error => {
        console.log(error)
      });
    this.confirmationModel.nativeElement.click();
    this.closeModal.nativeElement.click();
  }

  editPlayer($event) {
    this.editPlayerRequest = {} as Player;
    this.editPlayerRequest.id = this.selectedPlayerId;
    this.editPlayerRequest.city = this.CityEP;
    this.editPlayerRequest.contact = this.PhoneEP;
    this.editPlayerRequest.country = this.countryIdEP;
    this.editPlayerRequest.email = this.EmailEP;
    this.editPlayerRequest.notes = this.NotesEP;
    this.editPlayerRequest.address = this.AddressEP;
    this.editPlayerRequest.zipCode = this.ZipCodeEP;
    this.editPlayerRequest.whatsapp = this.WhatsappEP;
    this.playerService.updatePlayer(this.selectedPlayerId, this.editPlayerRequest)
      .subscribe((editData: any) => {
      }, error => {
        console.log(error);
      });


  }

  getPlayerInfo(playerId: number) {
    this.route.navigate(['/players/edit/' + playerId]);
    //this.route.navigate(['/players/edit']);
    //this.route.navigate(['/players', playerId,'edit']);
  }
  // tslint:disable-next-line:typedef
  infoPlayer(playerId: number) {
    this.selectedPlayerId = playerId;
    this.playerService.getPlayer(this.selectedPlayerId)
      .subscribe((data: Player) => {
        this.CityEP = data.city;
        this.EmailEP = data.email;
        this.NotesEP = data.notes;
        this.PhoneEP = data.contact;
        this.AddressEP = data.address;
        this.ZipCodeEP = data.zipCode;
        this.WhatsappEP = data.whatsapp;
        this.playerDetails = data;
      }, error => {
        console.log(error);
      });
  }

  // tslint:disable-next-line:typedef
  blockPlayer($event) {
    this.playerService.blockPlayer(this.selectedPlayerId)
      .subscribe((data: boolean) => {
        this.blockPlayerResult = data;
        if (data)
          this.toastService.showNotification({ message: this.translateService.instant('Player has been blocked.'), type: 'success' });
        this.loadPlayers();
      }, error => {
        console.log(error);
      });
  }
  // tslint:disable-next-line:typedef
  unBlockUser($event) {
    this.playerService.unBlockPlayer(this.selectedPlayerId)
      .subscribe((data: boolean) => {
        this.blockPlayerResult = data;
        if (data)
          this.toastService.showNotification({ message: this.translateService.instant('Player has been unblocked.'), type: 'success' });
        this.loadPlayers();
      }, error => {
        console.log(error);
      });
  }


  // tslint:disable-next-line:typedef
  loadTransactions(playerId: number) {
    this.transactionList = [];
    this.dataSourceTransaction.paginator = null;
    this.playerService.getPlayerTransaction(playerId)
      .subscribe((data: any[]) => {
        data.map(element => {
          element.transaction.typeDesc = element.transaction.typeDesc;
          let minutes = parseInt(localStorage.getItem('minute'), 10);
          const gameDateTime = (new Date(element.transaction.date)).getTime() + (minutes * 60000);
          element.transaction.date = new Date(gameDateTime);
          if (element.transaction.typeDesc == "Retirada") element.transaction.color = '#f5a0a0';
          if (element.withdraw != null) {
            switch (element.withdraw.statusStr) {
              case 'Waiting':
                element.transaction.color = '#f59247';
                element.transaction.description = 'credit.player_waiting';
                element.transaction.typeDesc = 'credit.player_waiting_descript';
                break;
              case 'Rejected':
                element.transaction.color = '#897d12';
                element.transaction.creditIn = element.transaction.creditOut;
                element.transaction.creditOut = 0;
                element.transaction.balance = element.transaction.balance + element.transaction.creditIn
                element.transaction.description = 'credit.player_refused';
                element.transaction.typeDesc = 'credit.player_waiting_descript';
                break;
              case 'Accepted':
                element.transaction.color = '#ff5757';
                element.transaction.description = 'credit.player_accepted';
                element.transaction.typeDesc = 'credit.player_waiting_descript';
                break;
            }
          }
          if (element.round != null && element.withdraw == null && element.transaction.type != 4 && element.transaction.type != 5 && element.transaction.type != 18) {
            if (element.round.posted == 0) {
              element.transaction.color = '#fcff70';
              element.transaction.posted = element.round.posted;
              element.transaction.name = element.round.name;
              element.transaction.description = element.round.name;
              element.transaction.typeDesc = 'credit.betWaiting';
            } else {
              if (element.winnerBet) {
                element.transaction.color = '#56f78c';
                element.transaction.posted = element.round.posted;
                element.transaction.name = element.round.name;
                element.transaction.description = element.round.name;
                element.transaction.typeDesc = 'credit.betWin';
              } else {
                element.transaction.color = '#f75656';
                element.transaction.posted = element.round.posted;
                element.transaction.name = element.round.name;
                element.transaction.description = element.round.name;
                element.transaction.typeDesc = 'credit.betLose';
              }
            }
          }
          if (element.transaction.type == 1 && element.withdraw == null && element.round == null) {
            element.transaction.color = '#56f78c';
            element.transaction.typeDesc = 'credit.player_credtin';
            element.transaction.name = 'credit.transaction_credtin_jurisdction';
            element.transaction.rankMatch = 0;
            element.transaction.singleMatch = 0;
            element.transaction.type = 14
            if (element.transaction.betUuid8 == "null") element.transaction.betUuid8 = null;
          }
          if (element.transaction.type == 2 && element.withdraw == null && element.round == null) {
            element.transaction.color = '#f75656';
            element.transaction.typeDesc = 'credit.player_credtout';
            element.transaction.name = 'credit.transaction_credtout_jurisdction';
            element.transaction.rankMatch = 0;
            element.transaction.singleMatch = 0;
            element.transaction.type = 15
            if (element.transaction.betUuid8 == "null") element.transaction.betUuid8 = null;
          }

          if(element.transaction.type == 18 || element.transaction.type == 5){
            element.transaction.typeDesc = 'credit.refundbet';
            element.transaction.color = '#6d7175';
            element.transaction.typeGame = 1;
            element.transaction.description = element.round.name;//`${element.transaction.activeUser} -> ${element.transaction.passiveUser}`;
            element.transaction.name = element.round.name;
          }

          this.transactionList.push(element.transaction);
        });
        setTimeout(() => {
          this.dataSourceTransaction.data = this.transactionList;
          this.dataSourceTransaction.paginator = this.paginator2;
        });
      }, error => {
        console.log(error);
      });
  }

  getUserName(playerId: number) {
    this.playerService.getPlayer(playerId)
      .subscribe((data: Player) => {
        this.UserNamePR = data.login;
        this.selectedPlayerId = playerId;
      }, error => {
        console.log(error);
      });
  }

  // tslint:disable-next-line:typedef
  getDepositDetail(playerId: number) {
    this.selectedPlayerId = playerId;
    this.playerService.getPlayer(playerId)
      .subscribe((data: Player) => {
        this.custNameIDD = data.login;
        this.balanceIDD = data.balance;
      }, error => {
        console.log(error);
      });
  }

  selectedPlayer(playerId: number): void {
    this.selectedPlayerId = playerId;
  }
  // tslint:disable-next-line:typedef

  attentionDeposit($event) {
    this.loginService.getUser(this.selectedPlayerId).subscribe(selectedUser => {
      this.loginService.getUser(this.userAdmin.id).subscribe(currentUser => {
        let user: User = this.loginService.getUserInfoFromCookie();
        if (this.ttIDD == "creditCard" && this.ATDIDD > currentUser.credit && user.jurisdictionId != 1) {
          return this.toastService.showNotification({ message: "Você não tem saldo para realizar o depósito!", type: "danger" });
        }

        if (this.ttIDD == "debitCard" && this.ATDIDD > selectedUser.credit) {
          return this.toastService.showNotification({ message: "O usuário selecionado não tem saldo para realizar o saque!", type: "danger" });
        }

        this.depositedAmountA = this.ATDIDD;
        this.depositedIDD = this.ATDIDD;
        this.balanceIDD = this.ttIDD == "creditCard" ? selectedUser.credit : this.balanceIDD = selectedUser.credit;
        this.depostiFundsModal.show();
        this.cleanTempBalance();
      });
    });
  }

  closeDepostiFundsModal() {
    this.depostiFundsModal.hide();
  }

  cleanTempBalance() {
    this.getDepositDetail(this.selectedPlayerId);
  }

  getUsersList() {
    var UserId: number = null;
    this.loginService.GetUsersAssisList().subscribe(data => {
      for (let us of data) {
        if (us.userName == this.userAdmin.login && us.id == this.userAdmin.id) {
          UserId = us.adminParentID
        }
        else if (UserId == null) {
          UserId = this.userAdmin.id;
        }
      }
      this.loginService.GetUsersList(UserId).subscribe(res => {
        for (let element of res) {
          if (element.jurisdictionId == this.selectedJurisdictionLevel && element.id == UserId) {
            this.allowUsersList.push(element)
            this.usersList.push(element)
          }
        };
        this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
        this.loginService.getUsersTreeList(UserId)
          .subscribe(res => {
            res.forEach(element => {
              this.usersList.push(element)
            });
            this.seletedJurisdiction = -1
          })
      })
    })
  }

  jurisdictionLevelChange(event) {
    this.selectedJurisdictionLevel = event.target.value
    this.allowUsersList = []
    var UserId: number = null;
    this.loginService.GetUsersAssisList().subscribe(data => {
      data.forEach(us => {
        if (us.userName == this.userAdmin.login && us.id == this.userAdmin.id) {
          UserId = us.adminParentID
        }
        else if (UserId == null) {
          UserId = this.userAdmin.id;
        }
      })
      this.usersList.forEach(element => {
        if (element.jurisdictionId == this.selectedJurisdictionLevel) {
          if (parseInt(localStorage.getItem("currentUserJurisdictionId")) == this.selectedJurisdictionLevel) {
            if (element.id == UserId) {
              this.allowUsersList = [element]
            }
          } else {
            if (this.allowUsersList.find(x => x.id == element.id) == null) {
              this.allowUsersList.push(element)
            }
          }
        }
      });
      this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
      this.seletedJurisdiction = -1
    })
  }

  jurisdictionChange(event) {
    this.seletedJurisdiction = event.target.value
  }

  getJurisdictionByName(name) {
    return this.usersList.find(e => e.login == name)
  }

  async getPlayers(children, details, jurisdictionName) {
    for (let i = 0; i < children.length; i++) {
      let element = children[i]
      if (element.jurisdictionId == 7) {
        let activities = [{
          jurisdictionId: element.jurisdictionId,
          player: element.login,
          commission: element.commission,
          ori_commission: element.commission
        }]
        let result = await this.getUser(element.parentId, this.getJurisdictionByName(jurisdictionName).id, activities)
        details.push({
          club: element.login,
          financial: { deposit: 0, withdraw: 0 },
          activity: { bet: 0, rake: 0, children: result },
        })
      } else {
        if (element.children.length > 0) {
          details = await this.getPlayers(element.children, details, jurisdictionName)
        }
      }
    }
    return details
  }

  async getUser(id, finalId, activities) {
    return await this.loginService.getUser(id).toPromise().then(async data => {
      activities.push({
        jurisdictionId: data.jurisdictionId,
        player: data.login,
        ori_commission: data.commission,
        commission: data.commission - activities[activities.length - 1].ori_commission
      })
      if (data.id == finalId) {
        return activities
      } else {
        activities = await this.getUser(data.parentId, finalId, activities)
        return activities
      }
    })
  }

  // tslint:disable-next-line:typedef
  onSubmit() { }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.loginService.setGlobalCurrency()
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    // setInterval(() => {
    //   this.loadPlayersCredit();
    // }, 3000);
  }

  getChampionshipLogos(name: string) {
    //debugger;
    let searchText = name.split(" ");
    let ret = '../../../assets/shields/default-logo.png';
    if (name.toLocaleUpperCase().includes(("POKER").toLocaleUpperCase())) {
      if (name.toLocaleUpperCase().includes(("Israelita").toLocaleUpperCase())) {
        return ret = '../../../assets/image/ChampionShipFlags/estrDavy.png'
      }
      return ret = '../../../assets/image/ChampionShipFlags/poker.png'
    }
    if (name.toLocaleUpperCase().includes(("LA LIGA").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/laliga.png'
    if (name.toLocaleUpperCase().includes(("campeonato BRASILEIRO").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/brasileirao.png'
    if (name.toLocaleUpperCase().includes("MOTO GP")) return ret = '../../../assets/image/ChampionShipFlags/motogp.png'
    if (name.toLocaleUpperCase().includes("FÓRMULA 1")) return ret = '../../../assets/image/ChampionShipFlags/f1.png'
    if (name.toLocaleUpperCase().includes(("Bundesliga").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/bl.png'
    if (name.toLocaleUpperCase().includes(("campeonato italiano").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/it.png'
    if (name.toLocaleUpperCase().includes(("copa do mundo").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/cp.png'
    if (name.toLocaleUpperCase().includes(("campeonato frances").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/fr.png'
    if (name.toLocaleUpperCase().includes(("premier league").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/pl.png'
    if (name.toLocaleUpperCase().includes(("uefa").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/uefa.png'
    if (name.toLocaleUpperCase().includes(("Internacionais").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/international.png'
    //  searchText.forEach(element => {
    //    let found = ChampionshipLogos.find(x => x.name.includes(element.toLowerCase()));
    //    if (found != null) {
    //      ret = '../../../assets/shields/' + found.shield;
    //    }
    //  });
    return ret;
  }

  getSingleMatchShield(elementName: String, team: number) {
    let nameTeam: string[]
    elementName = elementName.replace(" (", " X ")
    elementName = elementName.replace(" - ", " X ")
    //console.log(elementName)
    nameTeam = elementName.toLocaleLowerCase().split((" X ").toLocaleLowerCase());
    //console.log(nameTeam)
    let getTemLogo;
    getTemLogo = nameTeam[team];
    getTemLogo = this.remove_accents(getTemLogo)
    getTemLogo = getTemLogo.replaceAll(" ", "-")

    //console.log(getTemLogo)
    return `https://e.imguol.com/futebol/brasoes/40x40/${getTemLogo}.png`
  }

  remove_accents(str) {
    let accented = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    let withoutAccent = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let namewithoutAccent = "";
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < accented.length; a++) {
        if (str.substr(i, 1) == accented.substr(a, 1)) {
          namewithoutAccent += withoutAccent.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        namewithoutAccent += str.substr(i, 1);
      }
    }
    return namewithoutAccent;
  }

  getSelectedShield(name: string, betsport: number) {
    //console.log(name + " / " + betsport)
    let searchText = name.split(" ");
    let ret = '../../../assets/shields/default-logo.png';
    if (betsport == 40) {
      searchText.forEach(element => {
        let found = formula1Shields.find(x => x.drivers.includes(element.toLowerCase()));
        if (found != null) {
          ret = 'https://poolbet365.com/assets/shields/' + found.shield;
        }
      });
    }
    if (betsport == 1000) {
      ret = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Naipe_ouros.png/216px-Naipe_ouros.png'
    }
    return ret;
  }

  couponDetail(ticketCode) {
    this.couponService.getBet(ticketCode)
      .subscribe(bet => {
        if (bet == null) {
          this.msgStandard(this.translateService.instant("pg-tickets.ticket-not-found"), this.translateService.instant("comp-search-ticket.fail-info1") + ticketCode + this.translateService.instant("comp-search-ticket.fail-info2"), 4);
        }
        else {
          let rankResult = [];
          let isSingleMatch = false;
          this.couponService.getRoundById(bet.roundId)
            .subscribe(round => {
              this.roundName = round.name;
              this.radarSport = round.betradarSportId;
              this.singleMatch = round.singleMatchRound;
              if (round && round.rankRound && round.rankResult) {
                rankResult = round.rankResult.split('|');
              }
              isSingleMatch = round.singleMatchRound == 1 ? true : false;
            });
          this.couponService.getBasicUser(bet.userAdminId)
            .subscribe(user => {
              this.couponService.getGames(bet.roundId)
                .subscribe((matchs) => {
                  let results = bet.results.split("|");
                  let total_hit = 0;
                  let total_cancel = 0;
                  let mWinner;
                  if (bet.rankRound && results.length) {
                    rankResult.forEach((element, i) => {
                      if (results[i] == element) {
                        total_hit++;
                      }
                    });
                  }
                  if (!isSingleMatch) {
                    matchs.forEach((element, i) => {
                      if (element.status == 0) {
                        total_cancel++;
                      } else {
                        if (results[i].includes(element.matchResult) && !bet.rankRound) {
                          total_hit++;
                        }
                      }
                    });
                  }
                  else {
                    if (matchs[0].matchWinner != null) {
                      mWinner = matchs[0].matchWinner.split('|');
                      results.forEach((element, index) => {
                        if (element.includes(mWinner[index])) {
                          total_hit++;
                        }
                      });
                    }
                  }
                  let legend: string = "<div class='col-12'>";
                  legend += "<div class='row legend'><div class='col-3'><div class='running'></div>&nbsp;" + this.translateService.instant("pg-tickets.running") + "</div>";
                  legend += "<div class='col-3'><div class='hit'></div>&nbsp;" + this.translateService.instant("pg-tickets.hit") + "</div>";
                  legend += "<div class='col-3'><div class='wrong'></div>&nbsp;" + this.translateService.instant("pg-tickets.wrong") + "</div>";
                  legend += "<div class='col-3'><div class='cancel'></div>&nbsp;" + this.translateService.instant("pg-tickets.cancelled") + "</div>";
                  legend += "</div></div>";

                  let resultMats: string = "<div class='col-12 total-show mb-1'>" + /*legend + */
                    "<div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-round.matches") + "</strong>: " + matchs.length +
                    "</div><div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-result.number-hits") + "</strong>: " + total_hit +
                    "</div><div class='col-12 total-cancel'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-manage-ticket.canceled") + "</strong>: " + total_cancel + "</div>" +
                    "<div class='col-12  total-hit text-center p-1 mb-2 mt-2'>" + `<img class='mr-1 mb-1' width='30px' src='${this.getChampionshipLogos(this.roundName)}'>` + this.roundName + "</div>" +
                    "</div>";
                  if (this.singleMatch == 1) {
                    resultMats = "<div class='col-12 total-show mb-1'>" + /*legend + */
                      "<div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-round.matches") + "</strong>: " + matchs.length +
                      "</div><div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-result.number-hits") + "</strong>: " + total_hit +
                      "</div><div class='col-12 total-cancel'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-manage-ticket.canceled") + "</strong>: " + total_cancel + "</div>" +
                      "<div class='col-12  total-hit text-center p-1 mb-2 mt-2'>" + `<img class='mr-1 mb-1' width='30px' src='${this.getSingleMatchShield(this.roundName, 0)}'>` + this.roundName + ` <img class='mr-1 mb-1' width='30px' src='${this.getSingleMatchShield(this.roundName, 1)}'>` + "</div>" +
                      "</div>";
                  }
                  let count: number = 0;
                  if (bet.rankRound) {
                    results.forEach((element, i) => {
                      if (i < rankResult.length && rankResult[i] == element) {
                        resultMats += "<div class='d-flex justify-content-around align-items-center col-9 search-game-list'>"
                          + `<div class='col-9 driver-name'>`
                          + "<img class='mr-3' width='22px' src='"
                          + this.getSelectedShield(element, this.radarSport)
                          + "'>"
                          + element
                          + `</div>`
                          + `<div class='col-3 d-flex justify-content-inherit align-items-center'><img width='5px' class='mr-2' src='../../assets/svgs/green-icon.svg'>`
                          + `<span class='goals'>${[i + 1]}</span>`
                          + `</div>`
                          + "</div><div class='col-2 result-game-list'>"
                          + rankResult[i]
                          + "</div>"
                          + "<div class='correct'></div>";
                      } else if (i < rankResult.length && rankResult[i] != element) {
                        resultMats += "<div class='d-flex justify-content-around align-items-center col-9 search-game-list'>"
                          + `<div class='col-9 driver-name'>`
                          + "<img class='mr-3' width='22px' src='"
                          + this.getSelectedShield(element, this.radarSport)
                          + "'>"
                          + element
                          + `</div>`
                          + `<div class='col-3 d-flex justify-content-inherit align-items-center'><img width='5px' class='mr-2' src='../../assets/svgs/green-icon.svg'>`
                          + `<span class='goals'>${[i + 1]}</span>`
                          + `</div>`
                          + "</div><div class='col-2 result-game-list'>"
                          + rankResult[i]
                          + "</div>"
                          + "<div class='no-hit'></div>";
                      }
                      else {
                        resultMats += "<div class='col-9 search-game-list'>" + element + "</div><div class='col-3 result-game-list'> " + (i + 1) + "°</div>" + "<div></div>";
                      }
                    });
                  } else {
                    if (isSingleMatch) {
                      matchs.forEach(element => {
                        if (element.status == 0) {
                          resultMats += "<div class='col-9 search-game-list cancelled'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list cancelled'> " + results[count] + "</div>";
                        } else {
                          if (element.matchResult == "0") {
                            // resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list'> " + results[count] + "</div>";
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Final Result") + "</div><div class='col-3 search-game-list'> " + results[0] + "</div>";
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("More Ball Possession") + "</div><div class='col-3 search-game-list'> " + results[1] + "</div>";
                            var data = results[count] == "12" ? this.translateService.instant("Over-Under") : results[2] == "1" ? this.translateService.instant("Over") : this.translateService.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Total Goals") + "(" + element.totalGoals + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Over-Under") : results[3] == "1" ? this.translateService.instant("Over") : this.translateService.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Over-Under") : results[4] == "1" ? this.translateService.instant("Over") : this.translateService.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Over-Under") : results[5] == "1" ? this.translateService.instant("Over") : this.translateService.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Over-Under") : results[6] == "1" ? this.translateService.instant("Over") : this.translateService.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Over-Under") : results[7] == "1" ? this.translateService.instant("Over") : this.translateService.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Over-Under") : results[8] == "1" ? this.translateService.instant("Over") : this.translateService.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Yes-No") : results[9] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Will be Penalty (90 min)") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Yes-No") : results[10] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Goal in the 1st Half") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Yes-No") : results[11] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Goal in the 2nd Half") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Yes-No") : results[12] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("GG (Both Score)") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translateService.instant("Yes-No") : results[13] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translateService.instant("Red Card") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                          } else {
                            //if (results[0].includes(element.matchResult) && !bet.rankRound) {
                            if (results[0].includes(mWinner[0]) && !bet.rankRound) {
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Final Result") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + results[0] + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Final Result") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + results[0] + "</div>" + "<div class='no-hit'></div>";
                            }
                            //if (results[1].includes(element.moreBallPossession != "" ? element.moreBallPossession.split(',')[0] > element.moreBallPossession.split(',')[1] ? "1" : "2" : "X") && !bet.rankRound) {
                            if (results[1].includes(mWinner[1]) && !bet.rankRound) {
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("More Ball Possession") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + results[1] + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("More Ball Possession") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + results[1] + "</div>" + "<div class='no-hit'></div>";
                            }
                            //if (results[2].includes(element.totalGoals.split(',')[0] > element.totalGoals.split(',')[1] ? "1" : "2") && !bet.rankRound) {
                            //var data = results[count] == "12" ? "Over-Under" : results[count] == "1" ? "Over" : "Under";
                            if (results[2].includes(mWinner[2]) && !bet.rankRound) {
                              var data = results[2] == "12" ? this.translateService.instant("Over-Under") : results[2] == "1" ? this.translateService.instant("Over") : this.translateService.instant("Under");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Total Goals") + "(" + element.totalGoals + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[count] == "12" ? "Over-Under" : results[count] == "1" ? "Over" : "Under";
                              if (results[2] == '12') {
                                var data = results[2] == "12" ? this.translateService.instant("Over-Under") : this.translateService.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Total Goals") + "(" + element.totalGoals + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[2] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Total Goals") + "(" + element.totalGoals + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }
                            if (results[3].includes(mWinner[3]) && !bet.rankRound) {
                              var data = results[3] == '12' ? this.translateService.instant("Over-Under") : results[3] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              // var data = results[3] == '12' ? this.translateService.instant("Over-Under") : results[3] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              // resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[3] == '12') {
                                var data = results[3] == '12' ? this.translateService.instant("Over-Under") : this.translateService.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[3] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }

                            if (results[4].includes(mWinner[4]) && !bet.rankRound) {
                              var data = results[4] == '12' ? this.translateService.instant("Over-Under") : results[4] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[4] == '12' ? this.translateService.instant("Over-Under") : results[4] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              //resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[4] == '12') {
                                var data = results[4] == '12' ? this.translateService.instant("Over-Under") : this.translateService.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[4] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }

                            if (results[5].includes(mWinner[5]) && !bet.rankRound) {
                              var data = results[5] == '12' ? this.translateService.instant("Over-Under") : results[5] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[5] == '12' ? this.translateService.instant("Over-Under") : results[5] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              //resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[5] == '12') {
                                var data = results[5] == '12' ? this.translateService.instant("Over-Under") : this.translateService.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[5] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }

                            if (results[6].includes(mWinner[6]) && !bet.rankRound) {
                              var data = results[6] == '12' ? this.translateService.instant("Over-Under") : results[6] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              // var data = results[6] == '12' ? this.translateService.instant("Over-Under") : results[6] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              // resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[6] == '12') {
                                var data = results[6] == '12' ? this.translateService.instant("Over-Under") : this.translateService.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[6] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }
                            if (results[7].includes(mWinner[7]) && !bet.rankRound) {
                              var data = results[7] == '12' ? this.translateService.instant("Over-Under") : results[7] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[7] == '12' ? this.translateService.instant("Over-Under") : results[7] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              //resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[7] == '12') {
                                var data = results[7] == '12' ? this.translateService.instant("Over-Under") : this.translateService.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[7] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }
                            if (results[8].includes(mWinner[8]) && !bet.rankRound) {
                              var data = results[8] == '12' ? this.translateService.instant("Over-Under") : results[8] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[8] == '12' ? this.translateService.instant("Over-Under") : results[8] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                              //resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[8] == '12') {
                                var data = results[8] == '12' ? this.translateService.instant("Over-Under") : this.translateService.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[8] == "1" ? this.translateService.instant("Over") : this.translateService.instant(this.translateService.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }
                            if (results[9].includes(mWinner[9]) && !bet.rankRound) {
                              var data = results[9] == '12' ? this.translateService.instant("Yes-No") : results[9] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Will be Penalty (90 min)") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[9] == '12' ? this.translateService.instant("Yes-No") : results[9] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Will be Penalty (90 min)") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                            if (results[10].includes(mWinner[10]) && !bet.rankRound) {
                              var data = results[10] == '12' ? this.translateService.instant("Yes-No") : results[10] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Goal in the 1st Half") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[10] == '12' ? this.translateService.instant("Yes-No") : results[10] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("Goal in the 1st Half") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                            if (results[11].includes(mWinner[11]) && !bet.rankRound) {
                              var data = results[11] == '12' ? this.translateService.instant("Yes-No") : results[11] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Goal in the 2nd Half") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[11] == '12' ? this.translateService.instant("Yes-No") : results[11] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Goal in the 2nd Half") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                            if (results[12].includes(mWinner[12]) && !bet.rankRound) {
                              var data = results[12] == '12' ? this.translateService.instant("Yes-No") : results[12] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("GG (Both Score)") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[12] == '12' ? this.translateService.instant("Yes-No") : results[12] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translateService.instant("GG (Both Score)") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                            if (results[13].includes(mWinner[13]) && !bet.rankRound) {
                              var data = results[13] == '12' ? this.translateService.instant("Yes-No") : results[13] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Red Card") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[13] == '12' ? this.translateService.instant("Yes-No") : results[13] == "1" ? this.translateService.instant("Yes") : this.translateService.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translateService.instant("Red Card") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                          }
                        }
                        count++;
                      });
                    }
                    else {
                      matchs.forEach(element => {
                        if (element.status == 0) {
                          resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " <br/> " + element.awayName + "</div><div class='col-2 result-game-list'> " + results[count] + "</div>" + "<div class='cancelled'></div>";
                        } else {
                          if (element.matchResult == "0") {
                            resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " <br/> " + element.awayName + "</div><div class='col-2 result-game-list'> " + results[count] + "</div>" + "<div class='cancelled'></div>";
                          } else {
                            if (results[count].includes(element.matchResult)) {
                              resultMats += "<div class='col-9 search-game-list'><span>" + `<img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="https://poolbet365.com/assets/shields/${element.homeName}.png">` + `<span>${element.homeName}</span></span>` + `<span class="couponScores">${element.homeTeamScore}</span>` + " </br> " + `<span><img class="mr-1" width="21px" src="https://poolbet365.com/assets/shields/${element.awayName}.png"` + `<span>${element.awayName}</span></span>` + `<span class="couponScores">${element.awayTeamScore}</span>` + "</div><div class='col-2 result-game-list couponResults'> " + results[count] + "</div>" + "<div class='correct'></div>";
                            } else {
                              resultMats += "<div class='col-9 search-game-list'><span>" + `<img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="https://poolbet365.com/assets/shields/${element.homeName}.png">` + `<span>${element.homeName}</span></span>` + `<span class="couponScores">${element.homeTeamScore}</span>` + " </br> " + `<span><img class="mr-1" width="21px" src="https://poolbet365.com/assets/shields/${element.awayName}.png"` + `<span>${element.awayName}</span></span>` + `<span class="couponScores">${element.awayTeamScore}</span>` + "</div><div class='col-2 result-game-list couponResults'> " + results[count] + "</div>" + "<div class='no-hit'></div>";
                            }
                          }
                        }
                        count++;
                      });
                    }
                  }

                  let betDate = (new Date(bet.betDate)).getTime() - (new Date()).getTimezoneOffset() * 60000;
                  let stringTable = "<div class='row justify-content-center'>" +
                    "<div class='col-6 font-bold search-head font-adjust text-right text-white pr-2'>" + this.translateService.instant("pg-tickets.operator") + ":" + "</div>" +
                    "<div class='col-6 font-adjust p-0'>" + user.login + "</div>" +
                    "<div class='col-6 font-bold search-head font-adjust text-right text-white pr-2'>" + this.translateService.instant("pg-tickets.player") + ":" + "</div>" +
                    "<div class='col-6 font-adjust p-0'>" + bet.playerName + "</div>" +
                    "<div class='col-6 font-bold search-head font-adjust text-right text-white pr-2'>" + this.translateService.instant("pg-credit-transfer.value") + ":" + "</div>" +
                    "<div class='col-6 font-adjust p-0'>" + this.couponService.formatCurrencyValue(bet.value) + "</div>" +
                    "<div class='col-6 font-bold search-head font-adjust text-right text-white pr-2'>" + this.translateService.instant("pg-post-result.date") + ":" + "</div>" +
                    "<div class='col-6 font-adjust p-0'>" + this.datePipe.transform(betDate, 'dd/MM/y HH:mm') + "</div>" +
                    resultMats
                  "</div>";
                  this.msgStandard(this.translateService.instant("pg-tickets.resume-ticket") + ticketCode, stringTable, 1);
                  (<HTMLInputElement>document.getElementById("code-search")).value = "";
                }, error => {
                  this.msgStandard(this.translateService.instant("pg-tickets.ticket-not-found"), this.translateService.instant("comp-search-ticket.fail-info1") + ticketCode + this.translateService.instant("comp-search-ticket.fail-info2"), 4);
                });
            }, error => {
              this.msgStandard(this.translateService.instant("pg-tickets.ticket-not-found"), this.translateService.instant("comp-search-ticket.fail-info1") + ticketCode + this.translateService.instant("comp-search-ticket.fail-info2"), 4);
            });
        }
      }, error => {
        this.msgStandard(this.translateService.instant("pg-tickets.ticket-not-found"), this.translateService.instant("comp-search-ticket.fail-info1") + ticketCode + this.translateService.instant("comp-search-ticket.fail-info2"), 4);
      });
  }


  msgStandard(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandard').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandard').classList.add(this.msgType[type]);
    }

    this.modalMessages.show();
  }

  msgStandardDanger(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardDanger').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardDanger').classList.add(this.msgType[type]);
    }

    this.modalMessagesDanger.show();
  }

  msgStandardCredit(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardCredit').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardCredit').classList.add(this.msgType[type]);
    }

    this.modalMessagesCredit.show();
  }

  msgStandardBetting(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardBetting').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardBetting').classList.add(this.msgType[type]);
    }

    this.modalMessagesCredit.show();
  }

  CreditRedirect() {
    this.modalMessagesCredit.hide();
    this.route.navigate(['/payment']);
    //location.replace("/payment");
  }

  BettingRedirect() {
    this.modalMessagesCredit.hide();
    this.route.navigate(['/home']);
    //location.replace("/home");
  }

  makeTicket(index: number, whichModal: number) {
    this.indexActivated = index;

    let results: string = this.bets[index].results;

    let day = this.bets[index].betDate.toString().substring(8, 10);
    let month = this.bets[index].betDate.toString().substring(5, 7);
    let year = this.bets[index].betDate.toString().substring(0, 4);
    let hour = this.bets[index].betDate.toString().substring(11, 16);
    let completeDate: string = day + '/' + month + '/' + year;

    this.viewTicket =
      this.translateService.instant("pg-manage-ticket.code") + ': ' + this.bets[index].id + ' - ' + this.translateService.instant("pg-manage-ticket.round") + ': ' + this.bets[index].roundId + '<br/>' +
      ' ' + this.translateService.instant("pg-manage-ticket.date") + ': ' + completeDate + ' - ' + this.translateService.instant("pg-manage-ticket.hour") + ': ' + hour + '<br/>' +
      this.translateService.instant("pg-manage-ticket.operator") + ': ' + this.userNameToShow[index] + ' - ' + this.translateService.instant("pg-manage-ticket.player") + ': ' + this.bets[index].playerName + '<br/><br/>' +
      this.translateService.instant("pg-manage-ticket.results") + ':<br/><br/>' + results;




    if (whichModal == 1) {
      this.modalTicket.show();
    }
    else {
      this.modalCancel.show();
    }
  }

  descriptionAdjustment(element) {
    if (element.type >= 6) return element.assUsername
    else if (element.type == 1) {
      return this.translateService.instant(element.typeDesc);
    }
    else if (element.type == 2) {
      return this.translateService.instant(element.description);
    }
    else return element.description;
  }


  cancelTicket() {
    // ATTENTION: using this field for a while just to make it work for now
    this.bets[this.indexActivated].resultBetId = this.userAdmin.id;

    this.couponService.updateBet(this.bets[this.indexActivated])
      .subscribe(data => {
        this.msgStandard(this.translateService.instant("pg-manage-ticket.ticket-canceled"), this.translateService.instant("pg-manage-ticket.ticket-canceled-info"), 3);
        this.bets[this.indexActivated].status = 0;
      }, error => {
        let msgComplement: string;
        if (error.error == "Time exceeded!") {
          msgComplement = this.translateService.instant("pg-manage-ticket.time-exceeded");
        }

        if (error.error == "You're not the owner.") {
          msgComplement = this.translateService.instant("pg-manage-ticket.only-owner");
        }

        this.msgStandard(this.translateService.instant("pg-manage-ticket.not-canceled"), this.translateService.instant("pg-manage-ticket.not-canceled-info") + " " + msgComplement, 4);
      });

    this.modalCancel.hide();
  }

}
