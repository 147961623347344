import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bet } from '../interface/bet.interface';
import { Game } from '../interface/game.interface';
import { Round } from '../interface/round.interface';
import { GetRound } from '../interface/Round/GetRound';
import { InteractionService } from './interaction.service';
import { IpConfigService } from './Ip-Config.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class PostResultService {

  apiUrl = ''; // local test
  constructor(private http: HttpClient, private interactionService: InteractionService, private ipConfigService: IpConfigService, private loginService: LoginService) {
    this.apiUrl = ipConfigService.getApiUrl();
  }

  getRoundsFinished() {
    return this.http.get<Round[]>(`${this.apiUrl}/common/GetRoundFinished`);
  }
  getPostedRounds(){
    var currentId = localStorage.getItem("creatorId");

    return this.http.get<Round[]>(`${this.apiUrl}/common/GetPostedRounds/${currentId}`);
  }
  getRoundMatches(roundId: number) {
    return this.http.get<Game[]>(`${this.apiUrl}/common/GetRoundMatches/?roundId=` + roundId);
  }
  getOrderedResultMatches(roundId: number) {
    return this.http.get<Game[]>(`${this.apiUrl}/common/GetOrderedResultMatches?roundId=` + roundId);
  }
  cancelPostedRound(roundId: number, adminLogin: string){
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });
    let userLoged = this.loginService.getUserInfoFromCookie();
    let request = {
      roundId: roundId,
      SkinId : parseInt(localStorage.getItem("creatorId")),
      agentLogin: userLoged.login,
      ipAddress : localStorage.getItem("currentUserIp"),
      userId : userLoged.id,
      IsAssistant: parseInt(localStorage.getItem("isAssistant"))
    }

    return this.http.post<any>(`${this.apiUrl}/common/CancelPostedResult`, request,{ headers: header });
  }

  getGames(roundId: number) {
    return this.http.get<Game[]>(`${this.apiUrl}/common/GetMatchesByRound/?roundId=` + roundId);
  }

  getJiuJitsuGames(roundId: number) {
    return this.http.get<any>(`${this.apiUrl}/common/GetJiuJitsuMatchesByRound/?roundId=` + roundId);
  }

  getBetRadarResults(games: Game[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<Game[]>(`${this.apiUrl}/common/GetBetradarResults/`, games, { headers: header });
  }

  postResult(games: Game[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/PostResult/`, games, { headers: header });
  }

  postJiuJitsuRound(games: any[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/PostJiuJitsuRound/`, games, { headers: header });
  }

  checkPin(password: string) {
    // const header = new HttpHeaders({
    //   'Content-type': 'application/json',
    //   'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    // });

    return this.http.post<any>(`${this.apiUrl}/common/PinPassword/?password=` + password, {});
  }

  sendPinViaEmail() {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<any>(`${this.apiUrl}/common/RecoveryPinViaMail`, { headers: header });
  }

  changePIN(currrentPassword: string, newPassword: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/ChangePIN/?currentPassword=` + currrentPassword + "&newPassword=" + newPassword, {}, { headers: header });
  }

  getVisitorCredit(type: number, userId: number, credit: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/APIUserInfo/?type=` + type + "&userId=" + userId + "&cred=" + credit, { headers: header });
  }

  preCheckingResult(games: Game[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/PreCheckingResult/`, games, { headers: header });
  }

  getBetsByRound(roundId: number) {
    return this.http.get<Game[]>(`${this.apiUrl}/common/GetBetsByRound/?roundId=` + roundId);
  }

  updateBet(bet: Bet) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/UpdateBet/`, bet, { headers: header });
  }

  getRoundToResult(){
    var currentId = localStorage.getItem("creatorId");

    return this.http.get<GetRound[]>(`${this.apiUrl}/rounds/RoundToPostResult/${currentId}`);
  }

  PostRoundResult(result){
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/PostResult`, result, { headers: header });
  }
}
