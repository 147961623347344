import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../../services/login.service';
import { PlayerService } from 'src/app/services/player.service';
import { DropDownList, Player, PlayerDocs } from './../../interface/player.interface';
import { DepositFunds } from './../../interface/deposit-funds.interface';
import { InteractionService } from 'src/app/services/interaction.service';
import { Currency } from 'src/app/interface/currency.interface';
import { CouponServiceService } from 'src/app/services/coupon-service.service';
import { formatCurrency } from '@angular/common';
import { User } from 'src/app/interface/user.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toast.service';


@Component({
  selector: 'app-credit-transfer',
  templateUrl: './credit-transfer.component.html',
  styleUrls: ['./credit-transfer.component.css']
})
export class CreditTransferComponent implements OnInit {
  @ViewChild('confirmationModel') confirmationModel: any;  
  showModal: boolean;
  transferType: string;
  transferAmount: number;
  JurisdictionType: number;
  Jurisdiction: number;
  creditCard: string;
  debitCard: string;
  seletedJurisdiction: any;
  usersList: any = [];
  allowUsersList: any = [];
  allowUsersHelper: any = [];
  inputAlt: string ="";
  jurisdictions: any;
  selectedJurisdictionLevel: any;
  message: string;
  currentCredit: number;
  currentJurisdiction: string;
  creditedAmount: number;
  creditType: string;
  newCredit: number;
  creditTransfer: DepositFunds;
  currentUserCredit: number;
  Currency: Currency = <Currency>{};
  closeResult = '';
  userAdmin: any;
  UserLoged : any = [];
  targetJurisdiction: any;
  loading: boolean = false;

  constructor(
    public loginService: LoginService,
    private playerService: PlayerService,
    private interactionService: InteractionService,
    private toastService: ToastService
    
  ) {
    this.userAdmin = loginService.getUserInfoFromCookie();
    this.creditCard = "Extend Credit";
    this.debitCard = "Withdraw Credit";
    this.transferType = this.creditCard;
    this.Jurisdiction = -1;

  }

  ngOnInit(): void {
    this.loginService.setGlobalCurrency()
    this.getJurisdictions();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  getJurisdictions() {
    this.loginService.getJurisdictionsAbovePlayer(parseInt(localStorage.getItem("currentUserJurisdictionId"))).subscribe(res => {
      this.jurisdictions = res;
      this.JurisdictionType = -1;
      // this.getUsersList()
    })
  }

  getUsersList() {
    /*this.allowUsersList = [];
    this.loginService.GetUserByJurisdiction(parseInt(localStorage.getItem("currentUserJurisdictionId")) + 1).subscribe(res => {
      res.forEach(element => {
        if (element.jurisdictionId == this.selectedJurisdictionLevel) {
          element.login = element.login + ' [' + this.couponService.formatCurrencyValue(element.credit) + ' available credit]';
          this.allowUsersList.push(element)
          this.usersList.push(element)
        }
      });
      this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
    })*/
    this.loginService.GetUsersList(this.userAdmin.id).subscribe(res => {
      let temUser = res.find(element => element.id == this.userAdmin.id && element.login == this.userAdmin.login)
      if (temUser == null) {
        this.loginService.GetUserAssist(this.userAdmin.id).subscribe(x => {
          temUser = res.find(element => element.id == x.adminParentID && x.userName == this.userAdmin.login)
          this.UserLoged.push(temUser)
          res.forEach(element => {
            if (element.jurisdictionId == this.selectedJurisdictionLevel && element.id == this.UserLoged[0].id) {
              this.allowUsersList.push(element)
              this.usersList.push(element)
            }
          });
          this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
          this.loginService.getUsersTreeList(this.UserLoged[0].id)
            .subscribe(res => {
              res.forEach(element => {
                this.usersList.push(element)
              });
              this.seletedJurisdiction = -1
            })
        })
      } else {
        this.UserLoged.push(temUser)
        res.forEach(element => {
          if (element.jurisdictionId == this.selectedJurisdictionLevel && element.id == this.UserLoged[0].id) {
            this.allowUsersList.push(element)
            this.usersList.push(element)
          }
        });
        this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
        this.loginService.getUsersTreeList(this.UserLoged[0].id)
          .subscribe(res => {
            res.forEach(element => {
              this.usersList.push(element)
            });
            this.seletedJurisdiction = -1
          })
      }
    })
    this.allowUsersHelper = this.allowUsersList;
  }

  jurisdictionLevelChange(event) {
    this.selectedJurisdictionLevel = event.target.value;
    this.resetSelectedJurisdiction();
    this.allowUsersHelper = [];
    this.allowUsersList = [];
    this.loginService.GetJurisdictionChildren(this.userAdmin.id, this.selectedJurisdictionLevel)
    .subscribe(res => {
      this.allowUsersList = res;
      this.allowUsersHelper = res;
    });
  }

  jurisdictionChange(event) {
    this.seletedJurisdiction = event;
    this.Jurisdiction = event;
    this.resetSelectedJurisdiction();
  }

  transferCredit() {
    let user: User = this.loginService.getUserInfoFromCookie();
    this.creditType = this.transferType;
    this.creditedAmount = this.transferAmount;
    this.currentUserCredit = user.credit;
    this.currentJurisdiction = this.targetJurisdiction.login;
    this.currentCredit = this.targetJurisdiction.credit;

    if (this.transferType == "Extend Credit") {
      this.newCredit = this.currentCredit + this.transferAmount;
    } else {
      this.newCredit = this.currentCredit - this.transferAmount;
    }
  }

  onOptionSelected(event: any) {
    this.targetJurisdiction = event.option.value;
  }

  transferCreditConfirm() {
    this.loading = true;
    let user: User = this.loginService.getUserInfoFromCookie();

    if (this.transferType == "Extend Credit" && this.transferAmount > this.currentUserCredit) {
      this.loading = false;
      this.toastService.showNotification({ message: "Operação não permitida. Você não tem saldo suficiente para realizar o depósito.", type: 'danger' });
      return
    }

    if (this.transferType == "Withdraw Credit" && this.newCredit < 0) {
      this.loading = false;
      this.toastService.showNotification({ message: "Operação não permitida. Não é possível retirar mais saldo do que a jurisdição tem.", type: 'danger' });
      return
    }

    this.creditTransfer = {} as DepositFunds;
    this.creditTransfer.playerId = this.Jurisdiction;
    this.creditTransfer.amount = this.transferAmount;
    this.creditTransfer.balance = this.newCredit;
    this.creditTransfer.userAdminId = user.id;
    this.creditTransfer.transactionType = this.transferType;

    this.creditTransfer.assUsername = user.login
    this.playerService.transferCredit(this.creditTransfer)
      .subscribe((data: any) => {
        this.creditCard = "Extend Credit";
        this.debitCard = "Withdraw Credit";
        this.creditType = this.transferType = this.creditCard;
        this.Jurisdiction = -1;
        this.creditedAmount = this.transferAmount = 0;
        this.newCredit = 0;
        this.allowUsersList = [];
        this.usersList = [];
        this.inputAlt = "";
        this.getJurisdictions();
        this.toastService.showNotification({ message: "Operação realizada com sucesso.", type: 'success' });
        this.loading = false;
      }, error => {
        console.log(error);
        this.toastService.showNotification({ message: "Falha ao realizar operação. Contate a assistência.", type: 'danger' });
        this.loading = false;
      });
  }

  FilterUsers(event: Event){
    let baseFilter = this.allowUsersList;
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    baseFilter = baseFilter.filter(x => x.login.toLowerCase().includes(filterValue));
    this.allowUsersHelper = baseFilter;
  }

  resetSelectedJurisdiction() {
    this.creditedAmount = 0;
    this.currentUserCredit = 0;
    this.currentCredit = 0;
    this.newCredit = 0;
  }
}
