import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DatePipe } from '@angular/common';
import { AdminLayoutRoutingModule } from './admin-layout.routing';
import { ComponentsModule } from '../../components/components.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppTranslateModule } from '../../app.translate';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { PlayersListComponent } from '../../pages/players/players-list/players-list.component';
import { EditPlayer2Component } from '../../pages/players/edit-player2/edit-player2.component';
import { DocumentListComponent } from '../../pages/document-list/document-list.component';
import { CreatePlayerComponent } from '../../pages/players/create-player/create-player.component';
import { SettingsComponent } from '../../pages/settings/settings/settings.component';
import { SlidesComponent } from '../../pages/settings/slides/slides.component';
import { BannersComponent } from '../../pages/settings/banners/banners.component';
import { AdministratorsComponent } from '../../pages/settings/administrators/administrators.component';
import { TransactionsComponent } from '../../pages/reports/transactions/transactions.component';
import { DomainsComponent } from '../../pages/settings/domains/domains.component';
import { DemoMaterialModule } from '../../material-module';
import { EntityCreditComponent } from '../../pages/reports/entity-credit/entity-credit.component';
import { FinancialActivityComponent } from '../../pages/reports/financial-activity/financial-activity.component';
import { ListJurisdictionComponent } from '../../pages/jurisdiction/list-jurisdiction/list-jurisdiction.component';
import { AddJurisdictionComponent } from '../../pages/jurisdiction/add-jurisdiction/add-jurisdiction.component';
import { WithdrawComponent } from '../../pages/reports/withdraw/withdraw.component';
import { CreditTransferComponent } from '../../pages/credit-transfer/credit-transfer.component';
import { WithdrawalsComponent } from '../../pages/withdrawals/withdrawals.component';
import { CouponListComponent } from '../../pages/coupon-list/coupon-list.component';
import { CouponDetailComponent } from '../../pages/coupon-detail/coupon-detail.component';
import { ManageroundsComponent } from '../../pages/managerounds/managerounds.component';
import { PostresultComponent } from '../../pages/postresult/postresult.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { EditJackpotComponent } from '../../pages/edit-jackpot/edit-jackpot.component';
import { ManageBetsComponent } from '../../pages/manage-bets/manage-bets.component';
import { WhatsappListComponent } from '../../pages/whatsapp-list/whatsapp-list.component';
import { RoundStyleComponent } from '../../pages/round-style/round-style.component';
import { LogsListComponent } from '../../pages/logs-list/logs-list.component';
import { APIPartnersComponent } from '../../pages/apipartners/apipartners.component';
@NgModule({
  declarations: [
    DashboardComponent,
    SettingsComponent,
    SlidesComponent,
    BannersComponent,
    AdministratorsComponent,
    TransactionsComponent,
    EntityCreditComponent,
    FinancialActivityComponent,
    ListJurisdictionComponent,
    AddJurisdictionComponent,
    WithdrawComponent, 
    DomainsComponent,
    PlayersListComponent,
    CreatePlayerComponent,
    DocumentListComponent,
    EditPlayer2Component,
    CreditTransferComponent,
    WithdrawalsComponent,
    CouponListComponent,
    CouponDetailComponent,
    ManageroundsComponent,
    PostresultComponent,
    EditJackpotComponent,
    ManageBetsComponent,
    WhatsappListComponent,
    RoundStyleComponent,
    LogsListComponent,
    APIPartnersComponent
  ],
  imports: [
    CommonModule,
    AppTranslateModule,
    ComponentsModule,
    AdminLayoutRoutingModule,
    ImageCropperModule,
    DemoMaterialModule,
    NgxSpinnerModule,
    NgxDaterangepickerMd.forRoot(),
    //NgxMaterialTimepickerModule.setLocale('pt-BR')
  ]

})
export class AdminLayoutModule { }
