<div *ngIf="isLoaded" class="position-absolut">
  <div class="loader"></div>
</div>
<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'admin.financialtransactionreport' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'PageTransactionReportTitle' | translate }} :
      </div>
    </div>
    <div class="panel-body">
      <div class="input-group mb-3" style="position: unset; display: inline;">
        <label style="margin-top: auto; display: inline-block;">{{ 'admin.daterange' | translate }}</label>
        <!--<input type="text" [locale]="{format: 'DD/MM/YYYY'}" ngxDaterangepickerMd [(ngModel)]="selected" class="form-control" style="max-width: 200px; margin: 25px 0px 15px; display: inline-block" (change)="changeDate($event)"/>-->

        <mat-form-field appearance="fill">
          <mat-label>{{ 'startDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker1" [formControl]="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'endDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker2" [formControl]="endDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

      </div>

      <!-- <div class="input-group mb-3">
        <label>{{ 'Jurisdiction' | translate }}</label>
        <select class="form-control" style="max-width: 200px;" (change)="jurisdictionLevelChange($event)">
          <option [value]="item.id" *ngFor="let item of jurisdictions">{{item.name}}</option>
        </select>
        <select class="form-control" style="max-width: 200px; margin-left: 30px;" (change)="jurisdictionChange($event)">
          <option value="-1">{{ 'All' | translate }}</option>
          <option [value]="item.id" *ngFor="let item of allowUsersList">{{item.login}}</option>
        </select>
      </div>
      <div style="text-align: center;">
        <button class="btn btn-success btn-sm" (click)="filterBtn()">{{ 'Filter' | translate }}</button>
      </div> -->
      <br>
      <div class="input-group mb-3" style="position: unset; display: inline;">
        <label style="margin-top: auto; display: inline-block;">{{ 'Jurisdiction' | translate }}</label>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'LevelJurisdiction' | translate }}</mat-label>
          <mat-select #jurisdictionLevelId style="max-width: 200px;" [(ngModel)]="selectJurisId"
            (selectionChange)="jurisdictionLevelChange($event)">
            <mat-option [value]="item.id" *ngFor="let item of jurisdictions">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="margin-left: 20px;" appearance="fill">
          <mat-label>{{ 'NameJurisdiction' | translate }}</mat-label>
          <mat-select #jurisdictionId style="max-width: 200px;" [(ngModel)]="selectJurisIdName"
            (selectionChange)="jurisdictionChange()">
            <mat-option [value]="item.id" *ngFor="let item of allowUsersList">{{item.login}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="btn btn-success btn-sm" style="margin-left: 2%; margin-top: auto;" (click)="filterBtn()"
          [disabled]="missingJurisName">{{ 'Filter' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'admin.transactionslist' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <div style="text-align: right;">
        <mat-form-field>
          <mat-label>{{ 'Search' | translate }}...</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
      </div>
      <!-- <div class="mat-elevation-z8" style="overflow: auto;"> -->
      <div class="mat-elevation-z8 transactions-table" style="overflow: auto;">
        <table id="myTable" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="coupon">
            <th mat-header-cell *matHeaderCellDef>
              {{ ('Coupon' | translate).toUpperCase() }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span style="cursor: pointer;color:#2a4e75;text-decoration: underline;"
                (click)="detailCoupon(element.betUuid8)" *ngIf="element.betUuid8 && element.betUuid8 != 'null'">
                {{element.betUuid8}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ ('date.title' | translate).toUpperCase()}}
            </th>
            <td mat-cell *matCellDef="let element"> {{element.date_date}} <span
                id="TimerTransaction">{{element.date_time}}</span></td>
          </ng-container>

          <ng-container matColumnDef="movement">
            <th mat-header-cell *matHeaderCellDef> {{ ('Movement' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{element.typeDesc | translate}} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('Description' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{element.description | translate}} </td>
          </ng-container>

          <ng-container matColumnDef="administrator">
            <th mat-header-cell *matHeaderCellDef> {{ ('Administrator' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{element.assUsername}} </td>
          </ng-container>

          <!-- <ng-container matColumnDef="activeUserName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > {{ ('username.title' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element">
              <span style="cursor: default;color:#000000;"
                *ngIf="element.description == 'Compra de Crédito'" aria-disabled="true">
                {{element.activeUserName}}
              </span>
              <span style="cursor: default;color:#000000;"
                *ngIf="element.description != 'Compra de Crédito'" aria-disabled="true">
                 --
              </span>
            </td>
          </ng-container> -->

          <ng-container matColumnDef="input">
            <th mat-header-cell *matHeaderCellDef>
              {{ ('Input' | translate).toUpperCase()}} </th>
            <!-- <td id="element.creditIn" mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.creditIn.toFixed(2) *
              Currency.quote | currency:'':''}} </td> -->
            <td id="element.creditIn" mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.creditIn == 0; else Positive">
                {{Currency.currencySymbol}} {{element.creditIn.toFixed(2) * Currency.quote | currency:'':''}}
              </ng-container>
              <ng-template #Positive>
                <span id="PositiveCredit">{{Currency.currencySymbol}} {{element.creditIn.toFixed(2) * Currency.quote |
                  currency:'':''}}</span>
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="output">
            <th mat-header-cell *matHeaderCellDef> {{ ('Output' | translate).toUpperCase()}} </th>
            <!-- <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.creditOut.toFixed(2) *
              Currency.quote | currency:'':''}} </td> -->
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.creditOut == 0; else Negative">
                {{Currency.currencySymbol}} {{element.creditOut.toFixed(2) * Currency.quote | currency:'':''}}
              </ng-container>
              <ng-template #Negative>
                <span id="NegativeCredit">{{Currency.currencySymbol}} {{element.creditOut.toFixed(2) * Currency.quote |
                  currency:'':''}}</span>
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef> {{ ('Balance' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.balance.toFixed(2) *
              Currency.quote | currency:'':''}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" style="background-color: black;"></tr>
          <!-- Background Color Remove -->
          <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [style.background]="getTableBackground(row.type)"></tr> -->
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>

<div mdbModal #frame2="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-primary" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'pg-manage-ticket.view-ticket' | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame2.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <p id="final-msg" [innerHtml]="viewTicket"></p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="primary" outline="true" class="btn" mdbWavesEffect (click)="frame2.hide()"
          data-dismiss="modal">{{ 'pg-manage-ticket.back' | translate }}</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #frame3="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'pg-manage-ticket.confirm-cancel' | translate }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame3.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <p id="final-msg" [innerHtml]="viewTicket"></p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="danger" class="waves-light" (click)="cancelTicket()" mdbWavesEffect>{{
          'pg-manage-ticket.confirm' | translate }}
        </a>
        <a type="button" mdbBtn color="primary" outline="true" class="btn" mdbWavesEffect (click)="frame3.hide()"
          data-dismiss="modal">{{ 'pg-manage-ticket.back' | translate }}</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #frameMessage="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
    <div class="modal-content modal-lg  bg-theme"
      style="height: auto; max-height: 700px; background-color: #1f1f1f !important;">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frameMessage.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3 adjustModel scrollDeg">


        <p style="color: black" id="msg" [innerHtml]="msgToAdd"></p>


      </div>
      <div class="modal-footer d-flex justify-content-center" style="background-color: #1f1f1f !important;">

        <a type="button" color="waves-light button-font" style="padding:.54rem 2.14rem; background-color:#33a0df;"
          class="btn" mdbWavesEffect (click)="frameMessage.hide()" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>

<!--Modal Standard Danger Message-->
<div mdbModal #frameMessageDanger="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardDanger" style="height: 350px;
max-height: 350px;">
    <div class="modal-content  bg-theme" style="height: 378px;
max-height: 400px;">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="frameMessageDanger.hide();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">


        <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>


      </div>
      <div class="modal-footer d-flex justify-content-center">

        <a type="button" color="waves-light button-font" style="background-color:#ff4b4b;" class="btn" mdbWavesEffect
          (click)="frameMessageDanger.hide();" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>

<!--Modal Standard Message-->

<!--Modal Credit Message-->
<div mdbModal #frameMessageCredit="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardCredit">
    <div class="modal-content  bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="CreditRedirect();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">


        <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>


      </div>
      <div class="modal-footer d-flex justify-content-center">
        <a type="button" color="waves-light button-font" style="background-color:#01d36b;" class="btn" mdbWavesEffect
          (click)="CreditRedirect();" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>

<!--Modal Credit Message-->

<!--Modal Betting Message-->
<div mdbModal #frameMessageBetting="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardBetting">
    <div class="modal-content  bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="BettingRedirect();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="">

          <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>

        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">

        <a type="button" color="waves-light button-font" style="background-color:#01d36b;" class="btn" mdbWavesEffect
          (click)="BettingRedirect();" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>