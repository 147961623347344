<div mdbModal #frame2="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-primary" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-manage-ticket.view-ticket' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame2.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <p id="final-msg" [innerHtml]="viewTicket"></p>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="btn" mdbWavesEffect
                    (click)="frame2.hide()" data-dismiss="modal">{{ 'pg-manage-ticket.back' | translate }}</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal #frame3="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-danger" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-manage-ticket.confirm-cancel' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame3.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <p id="final-msg" [innerHtml]="viewTicket"></p>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="danger" class="waves-light" (click)="cancelTicket()" mdbWavesEffect>{{
                    'pg-manage-ticket.confirm' | translate }}
                </a>
                <a type="button" mdbBtn color="primary" outline="true" class="btn" mdbWavesEffect
                    (click)="frame3.hide()" data-dismiss="modal">{{ 'pg-manage-ticket.back' | translate }}</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal #frameMessage="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
        <div class="modal-content  bg-theme" style="height: auto; max-height: 700px;">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="frameMessage.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">


                <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>


            </div>
            <div class="modal-footer d-flex justify-content-center">

                <a type="button" color="waves-light button-font"
                    style="padding:.54rem 2.14rem; background-color:#33a0df;" class="btn" mdbWavesEffect
                    (click)="frameMessage.hide()" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>

<!--Modal Standard Danger Message-->
<div mdbModal #frameMessageDanger="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardDanger" style="height: 350px;
max-height: 350px;">
        <div class="modal-content  bg-theme" style="height: 378px;
max-height: 400px;">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="frameMessageDanger.hide();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">


                <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>


            </div>
            <div class="modal-footer d-flex justify-content-center">

                <a type="button" color="waves-light button-font" style="background-color:#ff4b4b;" class="btn"
                    mdbWavesEffect (click)="frameMessageDanger.hide();" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>

<!--Modal Standard Message-->

<!--Modal Credit Message-->
<div mdbModal #frameMessageCredit="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardCredit">
        <div class="modal-content  bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="CreditRedirect();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">


                <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>


            </div>
            <div class="modal-footer d-flex justify-content-center">
                <a type="button" color="waves-light button-font" style="background-color:#01d36b;" class="btn"
                    mdbWavesEffect (click)="CreditRedirect();" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>

<!--Modal Credit Message-->

<!--Modal Betting Message-->
<div mdbModal #frameMessageBetting="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardBetting">
        <div class="modal-content  bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="BettingRedirect();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">
                <div class="">

                    <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>

                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">

                <a type="button" color="waves-light button-font" style="background-color:#01d36b;" class="btn"
                    mdbWavesEffect (click)="BettingRedirect();" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>