<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#" *ngIf="(slideType == 'top')">{{ 'slider.title' | translate }}</a>
      <a href="#" *ngIf="(slideType == 'left')">{{ 'banner.title' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons"
          style="font-size: 18px; vertical-align: middle; margin-right: 5px; color: #f9892e; ">format_align_left</span>
        {{ 'slider.language' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <select class="form-control" [formControl]="languageSelect" name="language" id="language"
        (change)="changeLan($event)">
        <option *ngFor="let lan of languageList" value="{{ lan.id }}">{{ lan.languageName }}</option>
      </select>
    </div>
  </div>
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">upload
        </span>
        {{ 'slider.loadingimage' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <div class="input-group mb-3">
        <label for="uploadtitle">{{ 'slider.upload.title' | translate }}</label>
        <input id="uploadtitle" [formControl]="uploadtitle" value="" type="text" class="form-control" placeholder=""
          aria-label="uploadtitle" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;" />
      </div>
      <div class="input-group mb-3">
        <label for="uploadsubtitle">{{ 'slider.upload.subtitle' | translate }}</label>
        <input id="uploadsubtitle" [formControl]="uploadsubtitle" value="" type="text" class="form-control"
          placeholder="" aria-label="uploadsubtitle" aria-describedby="basic-addon1" spellcheck="false"
          style="max-width: 400px;" />
      </div>
      <div class="input-group mb-3" *ngIf="slideType == 'left'">
        <label for="uploadurl">{{ 'slider.upload.url' | translate }}</label>
        <input id="uploadurl" [formControl]="uploadurl" value="" type="text" class="form-control" placeholder=""
          aria-label="uploadurl" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;" />
      </div>
      <div class="input-group mb-3 justify-align-center justify-content-md-start justify-content-center">
        <label for="visible">{{ 'slider.upload.visible' | translate }}</label>
        <mdb-checkbox #visibleChk [inline]="true" [default]="true" [checked]="true" class="d-flex"></mdb-checkbox>
      </div>
      <span class="text-break pl-2 text-info pb-4" *ngFor="let filename of fileNames">{{ filename.name + " (" +
        filename.type + ", " + filename.size + ")" }}<br></span>
      <label class="btn btn-primary btn-sm choosebtn">
        <input #file type="file" accept=".jpg,.png" (change)="onFileSelected($event)"> <!-- multiple="true" -->
        <i class="fas fa-folder-open"></i>
        {{ 'common.choosefile' | translate }}
      </label>
      <button class="btn btn-success btn-sm" (click)="uploadFile(file.files)" *ngIf="!processingClick"><i class="fas fa-upload mr-1"></i>{{
        'common.upload' | translate }}</button>
        <button class="btn btn-success btn-sm " *ngIf="processingClick" disabled><i class="fa fa-refresh fa-spin"></i>Loading...</button>
      <br class="d-block d-md-none">
      <button class="btn btn-danger btn-sm" (click)="clearFiles()"><i class="fas fa-trash-alt mr-1"></i>{{
        'common.remove' | translate }}</button>
      <br class="d-block d-md-none">
    </div>
  </div>
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">photo
        </span>
        {{ 'slider.photodescription' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <div class="example-boundary d-none d-md-block" cdkDropListGroup>
        <div class="d-flex justify-content-center justify-content-md-start example-inner"
          (cdkDropListDropped)="drop($event)" cdkDropList cdkDropListOrientation="horizontal">
          <div class="imgcontainer example-box" *ngFor="let slide of slides" cdkDrag
            [cdkDragDisabled]="slide.locked == 1" cdkDragBoundary=".example-boundary">
            <div *ngIf="!processingClick" class="imginner">
              <img src="{{ prefixImgPath + slide.fileName }}" alt="upload" class="w-100"
                title="title: {{ slide.title}}, subtitle: {{ slide.subtitle }}" />
              <div class="slider-ctrbtn" *ngIf="true || userAdmin.id == 1 || (slide.userId != 1 && userAdmin.id != 1)">
                <i class="default-icon fas fa-unlock fa-lg" *ngIf="slide.locked == 0"
                  (click)="setSlideLocked(slide.id)"></i>
                <i class="default-icon fas fa-lock fa-lg" *ngIf="slide.locked == 1"
                  (click)="setSlideUnlocked(slide.id)"></i>
                <i class="default-icon fas fa-file-image fa-lg" (click)="setCropperImage(slide)"></i>
                <i class="default-icon fas fa-trash-alt fa-lg" (click)="onClickDelete(slide.id)"></i>
              </div>
            </div>
            <div *ngIf="processingClick"  class="loader"></div>
          </div>
        </div>
      </div>
      <div class="example-boundary d-block d-md-none" cdkDropListGroup>
        <div class="d-flex align-items-center flex-column example-inner" (cdkDropListDropped)="drop($event)" cdkDropList
          cdkDropListOrientation="vertical">
          <div class="imgcontainer example-box" *ngFor="let slide of slides" cdkDrag
            [cdkDragDisabled]="slide.locked == 1" cdkDragBoundary=".example-boundary">
            <div class="imginner">
              <img src="{{ prefixImgPath + slide.fileName }}" alt="upload" class="w-100"
                title="title: {{ slide.title}}, subtitle: {{ slide.subtitle }}" />
              <div class="slider-ctrbtn" *ngIf="true || userAdmin.id == 1 || (slide.userId != 1 && userAdmin.id != 1)">
                <i class="default-icon fas fa-unlock fa-lg" *ngIf="slide.locked == 0"
                  (click)="setSlideLocked(slide.id)"></i>
                <i class="default-icon fas fa-lock fa-lg" *ngIf="slide.locked == 1"
                  (click)="setSlideUnlocked(slide.id)"></i>
                <i class="default-icon fas fa-file-image fa-lg" (click)="setCropperImage(slide)"></i>
                <i class="default-icon fas fa-trash-alt fa-lg" (click)="onClickDelete(slide.id)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <button class="btn btn-success btn-sm" (click)="saveBannersState()"><i class="fas fa-upload mr-1"></i>{{
          'savebtn' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="panel" *ngIf="isEditing">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">crop
        </span>
        {{ 'slider.cropdescription' | translate }}
      </div>
    </div>
    <div class="panel-body">

      <div class="input-group mb-3">
        <label for="cropuploadtitle">{{ 'slider.upload.title' | translate }}</label>
        <input id="cropuploadtitle" [formControl]="cropuploadtitle" value="" type="text" class="form-control"
          placeholder="" aria-label="cropuploadtitle" aria-describedby="basic-addon1" spellcheck="false"
          style="max-width: 400px;" />
      </div>
      <div class="input-group mb-3">
        <label for="cropuploadsubtitle">{{ 'slider.upload.subtitle' | translate }}</label>
        <input id="cropuploadsubtitle" [formControl]="cropuploadsubtitle" value="" type="text" class="form-control"
          placeholder="" aria-label="cropuploadsubtitle" aria-describedby="basic-addon1" spellcheck="false"
          style="max-width: 400px;" />
      </div>
      <div class="input-group mb-3" *ngIf="slideType == 'left'">
        <label for="cropuploadurl">{{ 'slider.upload.url' | translate }}</label>
        <input id="cropuploadurl" [formControl]="cropuploadurl" value="" type="text" class="form-control" placeholder=""
          aria-label="cropuploadurl" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;" />
      </div>
      <div class="input-group mb-3 justify-align-center justify-content-md-start justify-content-center">
        <label for="cropvisible">{{ 'slider.upload.visible' | translate }}</label>
        <mdb-checkbox #cropVisibleChk [inline]="true" [default]="true" [checked]="true" class="d-flex"></mdb-checkbox>
      </div>
      <br class="d-block d-md-none">
      <div class="input-group mb-3">
        <button class="btn btn-success btn-sm" (click)="uploadCrop()"><i class="fas fa-upload mr-1"></i>{{
          'common.upload' | translate }}</button>
      </div>

      <image-cropper [imageBase64]="imageBase64" [aspectRatio]="16 / 9" [maintainAspectRatio]="false"
        (imageCropped)="imageCropped($event)">
      </image-cropper>
    </div>
  </div>
</div>


<div style="overflow-y: auto" id="deleteConfirm" mdbModal #deleteConfirm="mdbModal" class="modal fade" tabindex="-1"
  role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="deleteConfirm.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'delete.slide.title' | translate }}</h4>
      </div>
      <div class="modal-body">
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'delete.slide.content' | translate }}</h4>
      </div>
      <div class="modal-footer">
        <button type="submit" mdbBtn color="danger" class="relative waves-light" mdbWavesEffect
          (click)="deleteSlide()">{{ 'delete.confirm' | translate }}
        </button>
        <button type="submit" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect
          (click)="deleteConfirm.hide()">{{ 'delete.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>