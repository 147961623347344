import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IpConfigService } from './Ip-Config.service';

@Injectable({
  providedIn: 'root'
})
export class SlideService {
  apiUrl = '';
  constructor(private http: HttpClient, private ipConfigService: IpConfigService) {
    this.apiUrl = this.ipConfigService.getApiUrl();
  }

  getSlideByFileName(fileName: string) {
    return this.http.get<string>(`${this.apiUrl}/slides/${fileName}`);
  }
}
