import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Form, FormBuilder } from '@angular/forms';
import { BetradarData } from '../interface/BetradarData';
import { BetradarSport } from '../interface/BetradarSport';
import { Game } from '../interface/game.interface';
import { BetradarTeamDetail } from '../interface/Match.Model';
import { Prize } from '../interface/prize.interface';
import { RoundGroup } from '../interface/round-group.interface';
import { Round } from '../interface/round.interface';
import { InteractionService } from './interaction.service';
import { IpConfigService } from './Ip-Config.service';
import { CreateRound } from '../interface/Round/CreateRound';
import { GetRound } from '../interface/Round/GetRound';
// import { StartRoundRequest } from '../interface/Round/StartRoundRequest';
import { LoginService } from './login.service';
import { StartRoundRequest } from '../interface/Round/StartRoundRequest';

@Injectable({
  providedIn: 'root'
})
export class RoundService {
  apiUrl = ''; // local test
  constructor(private http: HttpClient, private interactionService: InteractionService, private ipConfigService: IpConfigService, private loginService: LoginService) {
    this.apiUrl = ipConfigService.getApiUrl();
  }

  getBetradarSports() {
    return this.http.get<BetradarSport[]>(`${this.apiUrl}/BetradarSports/`);
  }

  getBetradarCategoriesBySportId(sportId: number) {
    return this.http.get(`${this.apiUrl}/BetradarSports/${sportId}/Categories`);
  }

  getBetradarTournamentsByCategoryId(categoryId: number) {
    return this.http.get(`${this.apiUrl}/BetradarCategories/${categoryId}/Tournaments`);
  }

  getBetradarTournaments() {
    return this.http.get(`${this.apiUrl}/BetradarTournaments/`);
    // return this.http.get('../../assets/data/betradar-list.json');
  }

  getBetradarDataByTournamentId(tournamentId: number) {
    return this.http.get(`${this.apiUrl}/Betradar/Tournament/${tournamentId}`);
  }

  getBetradarTeams(listBetradarData: BetradarData[]) {
    const ids = listBetradarData.map(data => {
      return `id=${data.teamHomeId}&id=${data.teamAwayId}`;
    }).join("&");
    return this.http.get(`${this.apiUrl}/BetradarTeams/GetBetradarTeams?${ids}`);
  }

  getBetRadarResults(games: Game[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<Game[]>(`${this.apiUrl}/common/GetBetradarResults/`, games, { headers: header });
  }

  searchShield(teamName: string){
    return this.http.get<any>(`${this.apiUrl}/common/SearchShield/?teamName=` + teamName);
  }

  uploadShield(teamName: string, linkShield: string){
    return this.http.post<any>(`${this.apiUrl}/common/UploadShield/?nameShield=` + teamName + '&linkShield=' + linkShield, {});
  }

  getLastRound() {
    return this.http.get<Round>(`${this.apiUrl}/common/getlastround`);
  }

  getListedRoundGroups() {
    return this.http.get<RoundGroup[]>(`${this.apiUrl}/common/GetListedAllRoundGroups/`);
  }

  getChampionshipRounds() {
    return this.http.get<Round[]>(`${this.apiUrl}/common/GetChampionshipRounds`);
  }

  getFeedMatches() {
    return this.http.get(`${this.apiUrl}/common/GetFeedMatches/`);
  }

  getRoundGroupRound() {
    return this.http.get<any[]>(`${this.apiUrl}/common/GetRoundGroupRound/`);
  }

  getRoundToCancel(){
    var currentId = localStorage.getItem("creatorId");
    const header = new HttpHeaders({
      'Authorizarion' : `Bearer ${localStorage.getItem('currentUserToken')}`
    });
    return this.http.get<any[]>(`${this.apiUrl}/rounds/RoundToCancel/${currentId}`);
  }

  getRoundJackpot(roundId: number) {
    return this.http.get<Round>(`${this.apiUrl}/common/GetRoundJackpotDetails/?roundId=` + roundId);
  }

  getRoundMatches(roundId: number) {
    return this.http.get<Game[]>(`${this.apiUrl}/common/GetRoundMatches/?roundId=` + roundId);
  }

  getJiuJitsuMatchesByRound(roundId: number) {
    return this.http.get<any[]>(`${this.apiUrl}/common/GetJiuJitsuMatchesByRound/?roundId=` + roundId);
  }

  getRoundById(roundId: number){
    return this.http.get<Round>(`${this.apiUrl}/common/GetRoundById/?id=` + roundId);
  }

  getMatcheById(matchId: number) {
    return this.http.get<BetradarData>(`${this.apiUrl}/Matches/` + matchId);
  }

  uploadTournamentIcon(tournamentName: string,file:FormData) {
    const header = new HttpHeaders({
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
    return this.http.post(`${this.apiUrl}/common/TournamentShield/?TournamentName=` + tournamentName,file, { headers: header });
  }

  getLoggerList(actionType: string) {
    const header = new HttpHeaders({
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
    return this.http.get(`${this.apiUrl}/roundLogs?action=` + actionType, { headers: header });
  }

  createRound(groupParent: number, roundName: string, rnEng: string, rnItaly: string, rnSpan: string,
              newJackpot: number, roundValue: number, parentId: number/*,two_options:number*/,
              sportId: number, rankRound: number, singleMatchRound: number, isJiuJitsuMatchRound: number,
              countryId: number, newMatches?: Game[], jiuJitsuMatches?: any[])
  {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    let roundData: any = {};
    roundData.GroupParent = groupParent;
    roundData.RoundName = roundName;
    roundData.RoundNameEng = rnEng;
    roundData.RoundNameItaly = rnItaly;
    roundData.RoundNameSpan = rnSpan;
    roundData.NewJackpot = newJackpot;
    roundData.RoundValue = roundValue;
    roundData.ParentId = parentId;
    roundData.SportId = sportId;
    roundData.RankRound = rankRound;
    roundData.SingleMatchRound = singleMatchRound;
    roundData.JiuJitsuRound = isJiuJitsuMatchRound;
    roundData.newMatches = newMatches;
    roundData.jiuJitsuMatches = jiuJitsuMatches;
    roundData.CountryId = countryId;
    roundData.roundStyleId = 0;

    const postedResul = this.http.post<string>(
      `${this.apiUrl}/Rounds/v2/create-round`,
      roundData,
      { headers: header }
    );
    return postedResul;
  }

  UpdateRound(roundId: number, groupParent: number, roundName: string, rnEng: string, rnItaly: string, rnSpan: string, newJackpot: number, roundValue: number, parentId: number/*,two_options:number*/, sportId: number, rankRound: number, singleMatchRound: number, isJiuJitsuMatchRound: number,countryId: number, newMatches?: Game[], jiuJitsuMatches?: any[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    let roundData: any = {};
    roundData.GroupParent = groupParent;
    roundData.RoundName = roundName;
    roundData.RoundNameEng = rnEng;
    roundData.RoundNameItaly = rnItaly;
    roundData.RoundNameSpan = rnSpan;
    roundData.NewJackpot = newJackpot;
    roundData.RoundValue = roundValue;
    roundData.ParentId = parentId;
    roundData.SportId = sportId;
    roundData.RankRound = rankRound;
    roundData.SingleMatchRound = singleMatchRound;
    roundData.JiuJitsuRound = isJiuJitsuMatchRound;
    roundData.newMatches = newMatches;
    roundData.jiuJitsuMatches = jiuJitsuMatches;
    roundData.countryId =  countryId;

    const postedResul = this.http.post<string>(
      `${this.apiUrl}/common/UpdatePostedRound/?roundId=${roundId}`,
      roundData,
      { headers: header }
    );

    return postedResul;
  }

  getMatchTeams(matchId: number) {
    return this.http.get<BetradarTeamDetail>(`${this.apiUrl}/Betradar/GetBetradarTeamDetailById/?id=` + matchId);
  }

  newCreateRound(round: CreateRound){

    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/rounds/v2/create-round`,round, { headers: header });
  }

  GetRounds(){
    return this.http.get<GetRound[]>(`${this.apiUrl}/rounds`);
  }

  GetRoundsToContinuation(){
    var currentId = this.loginService.getUserInfoFromCookie().id;
    return this.http.get<any[]>(`${this.apiUrl}/rounds/Continuation/${currentId}`);
  }

  GetRoundsToUpdate(){
    var currentId = localStorage.getItem("creatorId");
    return this.http.get<GetRound[]>(`${this.apiUrl}/rounds/RoundToUpdade/${currentId}`);
  }

  NewUpdateRound(round){
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.put(`${this.apiUrl}/rounds`,round, { headers: header });
  }

  StartROund(request: StartRoundRequest){
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/rounds/StartRound`, request, {headers: header});
  }
}
