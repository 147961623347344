import { Component, OnInit } from '@angular/core';
import { LanguageService } from './services/language.service';
import { AutologoutService } from './services/autologout.service';
import { LoginService } from './services/login.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  
  constructor(private languageService: LanguageService,
    private autoLogout: AutologoutService,
    private loginService: LoginService,
    private http: HttpClient,
  ) {
    
  }

  ngOnInit(): void {
    this.languageService.initialize();

    this.http.get<any>("https://api.ipify.org/?format=json")
    .subscribe(resp =>{
      this.http.get<any>(`https://ipapi.co/${resp.ip}/json`)
      .subscribe(resp =>{
        let hours = parseInt(resp.utc_offset.substring(1, 3), 10);
        let minutes = parseInt(resp.utc_offset.substring(3, 5), 10);

        minutes = hours * 60 + minutes;

        if(resp.utc_offset[0] === '-')
          minutes = minutes * -1;

        minutes = minutes + 180;

        localStorage.setItem('minute', `${minutes}`);
      })
    })
  }


}
