import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/login.service';
import { User, UserLoginForm } from '../../interface/user.interface';
import { ToastService } from '../../services/toast.service';
import { HttpClient } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { Permit } from '../../interface/permit.interface';
import { CheckboxComponent } from 'angular-bootstrap-md';
import { Jurisdiction } from '../../interface/jurisdiction.interface';
import { Currency } from '../../interface/currency.interface';
import { InteractionService } from '../../services/interaction.service';
import axios from "axios";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PageEvent } from '@angular/material/paginator';
import { Round } from 'src/app/interface/round.interface';
import { coin } from 'src/app/services/coin.interface';

@Component({
  selector: 'app-edit-jackpot',
  templateUrl: './edit-jackpot.component.html',
  styleUrls: ['./edit-jackpot.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class EditJackpotComponent implements OnInit {
  userAdmin: any;
  userList: User[];
  jackpotList: Round[];
  userEdit: User;
  userCredit: User;
  roundId:number;
  seletctedChampionShipName: string = "";
  deletePopUpTitle:string = "";
  editId: any;
  deleteId: number;
  idForCredit: any;
  pageSize = 5;
  pageNo = 1;
  pageCount = 0;
  jackpointCount = 0;
  countries: any;
  listOfFatherUser: User[];
  Currency: Currency = <Currency>{};
  adminNewCredit: number;
  childNewCredit: number;
  valueToTransfer: number;
  formAmountOfTransfer: number;
  transferType: string = "";
  displayedColumns: string[] = ['id','name','startDateTime' ,'firstJackpot', 'recoveredJackpot' ,  'actions', ];
  dataSource =  new MatTableDataSource<any>([]);;
  pageEvent: PageEvent;
  jackpot: number;
  acumulates: any[];
  total:number;
  filter = "";
  activatedtotal:number;
  pasttotal:number;
  generaltotal:number;
  msgToAdd: string = '';
  titleToAdd: string = '';
  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('frameMessage', { static: true }) modalMessages: ModalDirective;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private formBuilder: FormBuilder, private http: HttpClient, private translateService: TranslateService,private interactionService: InteractionService,
    private loginService: LoginService, private toastService: ToastService,    public spinner: NgxSpinnerService) {
 
      var t=0;
      var pt=0;
      var count=0;
    this.userAdmin = loginService.getUserInfoFromCookie();
    var dataSource1 = [];
    this.spinner.show();
    this.loginService.GetManageChampionshipRounds()
      .subscribe(data => {
        
        this.jackpotList = data;
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        
        this.jackpotList.forEach(element => {
         
          count = count + 1;
         if(element.active == 1 )
         {
          pt = pt + element.recoveredJackpot;
         dataSource1.push({
            'id': element.id,
            'name': element.name,
            'startDateTime': element.startDateTime,
            'firstJackpot': element.firstJackpot,
            'recoveredJackpot': element.recoveredJackpot,
            'actions': '',
          });
        }
        else
        {
          t = t + element.recoveredJackpot; 
        }
        });
      
        this.dataSource.data = dataSource1;
        this.dataSource.paginator = this.paginator;
        this.activatedtotal = pt;
        this.pasttotal = t;
        this.generaltotal = t + pt;
        this.spinner.hide();
      }, error => {
        console.log(error);
      });
  }

  
  headElements = ['Nome do campeonato', 'Valor restante', 'Data de término','Açao'];
 
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  deleteAcumulate(roundId: number){
    this.deleteId = roundId;
    //this.modalDelete.show();
  }

  selectedRoundId(roundId: number, championshipname: any): void {
    this.roundId = roundId;
    this.seletctedChampionShipName = championshipname;
    this.deletePopUpTitle = "";
    this.deletePopUpTitle = "Do you really want to delete this Championship "+ this.seletctedChampionShipName + " ?";
  }

  confirmDelete() {
    this.modalMessages.hide();
    this.spinner.show();
     this.loginService.deleteAllRelatedRound(this.roundId)
     .subscribe(data => {
     this.msgStandard(this.translateService.instant("Deleted"), this.translateService.instant(this.seletctedChampionShipName + " Deleted Successfully"), 3);
     this.ngOnInit();
     
     }, error => {
     this.msgStandard(this.translateService.instant("Error"), this.translateService.instant("Problem In Deleting " + this.seletctedChampionShipName ), 4);
      console.log(error);
     })
  }

  msgStandard(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandard').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandard').classList.add(this.msgType[type]);
    }

    this.modalMessages.show();
  }

  setGlobalCurrency() {
    this.loginService.GetCountryCurrency(localStorage.getItem('currentUserCountry'))
      .subscribe(data => {

        var DataURL: string =  undefined;
        if(data.currencyId == "BRL"){
          DataURL = DataURL = 'https://economia.awesomeapi.com.br/json/USD-BRL';
        }else DataURL = 'https://economia.awesomeapi.com.br/json/BRL-' + data.currencyId;

        //this.http.get('https://free.currconv.com/api/v7/convert?q=BRL_' + data.currencyId + '&apiKey=085a5c4bbbd6e6750bfc')
        this.http.get(DataURL)
          .subscribe((obj: any) => {
            var Eco = JSON.stringify(obj)
            Eco = Eco.replace("[", "").replace("]", "")
            let response: coin = JSON.parse(Eco)
            var price = parseFloat (response.ask);
            if (response /*&& response.data*/) {
              //var price = (response.data.results['BRL_' + data.currencyId].val);
              this.Currency.quote = price;
              this.Currency.coin = 'BRL_' + data.currencyId;
              this.Currency.currencySymbol = data.currencySymbol;
              this.Currency.currency = data.currencyId;
              this.interactionService.setGlobalCountry(this.Currency);
              return;
            }
          }, error => {
            this.Currency.coin = 'BRL_BRL';
            this.Currency.quote = 1;
            this.Currency.currencySymbol = 'R$';
            this.Currency.country = 'Brazil';
            this.Currency.currency = 'BRL';
          });
      });
    this.Currency.coin = 'BRL_BRL';
    this.Currency.quote = 1;
    this.Currency.currencySymbol = 'R$';
    this.Currency.country = 'Brazil';
    this.Currency.currency = 'BRL';
    this.interactionService.setGlobalCountry(this.Currency);
  }

  ngOnInit(): void {
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
        else
        {
          this.setGlobalCurrency();
        }
      });
      var t=0;
      var pt=0;
      var count=0;
     
    var dataSource1 = [];
    this.loginService.GetManageChampionshipRounds()
      .subscribe(data => {
        
        this.jackpotList = data;
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
       
        this.jackpotList.forEach(element => {
         
          count = count + 1;
         if(element.active == 1 )
         {
          pt = pt + element.recoveredJackpot;
         dataSource1.push({
            'id': element.id,
            'name': element.name,
            'startDateTime': element.startDateTime,
            'firstJackpot': element.firstJackpot,
            'recoveredJackpot': element.recoveredJackpot,
            'actions': '',
          });
        }
        else
        {
          t = t + element.recoveredJackpot; 
        }
        });
      
        this.dataSource.data = dataSource1;
        this.dataSource.paginator = this.paginator;
        this.activatedtotal = pt;
        this.pasttotal = t;
        this.generaltotal = t + pt;
        this.spinner.hide();
      }, error => {
        console.log(error);
      });
      
  }

  counter(i: number) {
    return new Array(i);
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  setRedColor(firstJackpot, recoveredJackpot):boolean{
    if (0 > recoveredJackpot){
      return true;
    }
    else {
      return false;
    }
  }
}
