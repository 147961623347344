<div id="content">
  <div class="row">
    <div class="col-md-6">
      <div class="panel">
        <div class="panel-heading">
          <div class="panel-title">
            <span class="material-icons default-icon">person</span>
            {{'Create player' | translate}}
          </div>
        </div>

        <form [formGroup]="createPlayerForm" (ngSubmit)="createPlayer(createPlayerForm.value)">
          <div class="panel-body">

            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="login"> {{'Club Father' | translate}} <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-7">
                <select class="form-control " id="clubParents" formControlName="clubParents">
                  <option [value]="''">{{ 'Select' | translate }}</option>
                  <option *ngFor="let item of clubParents" [ngValue]="item.id">{{item.name | translate }}
                  </option>
                </select>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-7 mt-3">
                <div
                  *ngIf="createPlayerForm.get('clubParents').invalid && (createPlayerForm.get('clubParents').touched || createPlayerForm.get('clubParents').dirty)"
                  class="text-danger error-msg">
                  <span><b>{{'Club Father' | translate}}</b> {{'isRequired' | translate}}</span>
                </div>
              </div>
            </div>
            <!-- <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="login"> {{'Login' | translate}} *</label>
              </div>
              <div class="col-md-7">
                <input type="text" id="login" formControlName="login" placeholder="Login" class="form-control">
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-7 mt-3">
                <div
                  *ngIf="createPlayerForm.get('login').invalid && (createPlayerForm.get('login').touched || createPlayerForm.get('login').dirty)"
                  class="text-danger error-msg">
                  <span><b>Login</b> is required</span>
                </div>
              </div>
            </div> -->

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="userName"> {{'Username' | translate}} <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-7">
                <input type="text" id="userName" formControlName="userName" placeholder="Username" class="form-control">
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-7 mt-3">
                <div
                  *ngIf="createPlayerForm.get('userName').invalid && (createPlayerForm.get('userName').touched || createPlayerForm.get('userName').dirty)"
                  class="text-danger error-msg">
                  <span><b>User Name</b> is required</span>
                </div>
              </div>

            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="name"> {{'Name' | translate}} <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-7">
                <input type="text" id="name" formControlName="name" placeholder="Name" class="form-control">
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-7 mt-3">
                <div
                  *ngIf="createPlayerForm.get('name').invalid && (createPlayerForm.get('name').touched || createPlayerForm.get('name').dirty)"
                  class="text-danger error-msg">
                  <span><b>Name</b> is required</span>
                </div>
              </div>

            </div>
            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="lastname"> {{'Last Name' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="text" id="lastname" formControlName="lastname" placeholder="Last name"
                  class="form-control">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="password"> {{'Password' | translate}} <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-7">
                <input type="password" id="password" formControlName="password" placeholder="Password"
                  class="form-control">
              </div>
              <div class="col-md-4"></div>
              <div
                *ngIf="createPlayerForm.get('password').invalid && (createPlayerForm.get('password').dirty || createPlayerForm.get('password').touched)"
                class="text-danger error-msg col-md-7 mt-3">
                <div *ngIf="createPlayerForm.get('password').errors?.required">
                  <span><b>Password</b> is required</span>
                </div>
                <div *ngIf="createPlayerForm.get('password').errors?.minlength">
                  <b>Password</b> must be at least 8 characters long.
                </div>
                <!-- <div class="col-md-7 mt-3">
                    <div *ngIf="createPlayerForm.get('password').invalid && (createPlayerForm.get('password').touched || createPlayerForm.get('password').dirty)"
                      class="text-danger error-msg">
                      <span><b>Password</b> is required</span>
                    </div>
                  </div> -->
              </div>

            </div>
            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="confirmPassword"> {{'Confirm Password' | translate}} <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-7">
                <input type="password" id="confirmPassword" formControlName="confirmPassword"
                  placeholder="Confirm Password" class="form-control">
              </div>

              <div class="col-md-4"></div>
              <div
                *ngIf="createPlayerForm.get('confirmPassword').invalid && (createPlayerForm.get('confirmPassword').dirty || createPlayerForm.get('confirmPassword').touched)"
                class="text-danger error-msg col-md-7 mt-2">
                <div *ngIf="createPlayerForm.get('confirmPassword').errors?.required">
                  <span><b>Confirm Password</b> is required</span>
                </div>
                <div *ngIf="createPlayerForm.get('confirmPassword').errors?.minlength">
                  <b>Confirm Password</b> must be at least 8 characters long.
                </div>
                <div *ngIf="createPlayerForm.get('confirmPassword').errors?.confirmedValidator">
                  <b>Password</b> and <b>Confirm Password</b> mismatch.
                </div>
                <!-- <div class="col-md-7 mt-3">
                    <div *ngIf="createPlayerForm.get('password').invalid && (createPlayerForm.get('password').touched || createPlayerForm.get('password').dirty)"
                      class="text-danger error-msg">
                      <span><b>Password</b> is required</span>
                    </div>
                  </div> -->
              </div>

            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="email"> {{'Email' | translate}} <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-7">
                <input type="email" id="email" formControlName="email" placeholder="Email" class="form-control">
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-7 mt-3">
                <div
                  *ngIf="createPlayerForm.get('email').invalid && (createPlayerForm.get('email').touched || createPlayerForm.get('email').dirty)"
                  class="text-danger error-msg">
                  <span><b>Email</b> is required</span>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="email2"> {{'Email 2' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="email" id="email2" formControlName="email2" placeholder="Email 2" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="email3"> {{'Email 3' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="email" id="email3" formControlName="email3" placeholder="Email 3" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="GenderCP"> {{'Gender' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="radio" formControlName="gender" id="GenderMaleCP" value="Male" name="gender"> Male
                <input type="radio" formControlName="gender" id="GenderFemaleCP" value="FeMale" name="gender"> Fe-male
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="birthDate"> {{'Birth date' | translate}}</label>
              </div>
              <div class="col-md-7">

                <mat-form-field appearance="fill">
                  <input matInput [matDatepicker]="picker1" formControlName="birthDate">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <!-- <input type="date" id="birthDate" formControlName="birthDate" placeholder="Birthday"
                  class="form-control"> -->
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="address"> {{'Address' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="text" id="address" formControlName="address" placeholder="Address" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="country"> {{'Country' | translate}}</label>
              </div>
              <div class="col-md-7">
                <select class="form-control " id="country" formControlName="country">
                  <option [ngValue]="''" disabled>{{ 'Select' | translate }}</option>
                  <option *ngFor="let country of countries" [ngValue]="country.name">{{country.name | translate }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="city"> {{'City' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="text" id="city" formControlName="city" placeholder="City" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="zipCode"> {{'Zip-code' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="text" id="zipCode" formControlName="zipCode" placeholder="Zip code" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="phone"> {{'Phone' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="text" id="phone" formControlName="phone" placeholder="Phone" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="phone2"> {{'Phone2' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="text" id="phone2" formControlName="phone2" placeholder="phone 2" class="form-control">
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="cellPhone"> {{'Cell Phone' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="text" id="cellPhone" formControlName="cellPhone" placeholder="Phone" class="form-control">
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="whatsApp"> {{'Whatsapp' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="text" id="whatsApp" formControlName="whatsApp" placeholder="Whatsapp" class="form-control">
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-7 mt-3">
                <div
                  *ngIf="createPlayerForm.get('whatsApp').invalid && (createPlayerForm.get('whatsApp').touched || createPlayerForm.get('whatsApp').dirty)"
                  class="text-danger error-msg">
                  <span><b>WhatsApp</b> is required</span>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="notes"> {{'Notes' | translate}}</label>
              </div>
              <div class="col-md-7">
                <textarea id="notes" formControlName="notes" placeholder="Notes" class="form-control"
                  rows="3"></textarea>
                <!-- <input type="text" id="notes" formControlName="notes" placeholder="Notes" class="form-control"> -->
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label for="notes"> {{'Access' | translate}}</label>
              </div>
              <div class="col-md-7">
                <input type="checkbox" id="access" formControlName="access" placeholder="access" class="checkbox">
              </div>
            </div>

            <div class="row mt-3">
              <button *ngIf="!loading" type="submit" mdbBtn color="primary" mdbWavesEffect class="btn btn-primary" data-bs-dismiss="Save">{{'Save' |
                translate}}
              </button>
              <div *ngIf="loading" class="spinner-border ml-4" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <ng-container *ngIf="UserListPermit; else templateA">
                <button *ngIf="!loading" routerLink="/players/list" mdbBtn color="danger" class="relative waves-light btn-sm"
                  mdbWavesEffect>{{'closebtn' | translate}}
                  <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="savingPlayer"></span>
                </button>
              </ng-container>
              <ng-template #templateA>
                <button *ngIf="!loading" routerLink="/home" mdbBtn color="danger" class="relative waves-light btn-sm"
                  mdbWavesEffect>{{'closebtn'|translate}}
                </button>
              </ng-template>
              <!-- <button type="submit" mdbBtn color="primary" class="relative waves-light btn-sm" mdbWavesEffect>{{ 'savebtn' | translate }}
                                                  <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="savingSetting"></span>
                                                  </button>
                                                  <a mdbBtn color="danger" class="relative waves-light btn-sm" mdbWavesEffect (click)="onClickChangePass()">{{ 'changepassword.title' | translate }}
                                                  <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="savingSetting"></span>
                                                  </a> -->
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-6">
      <div class=panel>
        <div class="panel-heading">
          <div class="panel-title">
            <span class="material-icons default-icon">person</span>
            {{'Identity Documents' | translate}}
          </div>
        </div>
        <div class="panel-body">
          <div class="row" *ngIf="IDCardFront != ''">
            <div class="col-md-8">

              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'ID card(front)' | translate}}</label>
                  <img [src]="IDCardFront" alt="" height="190" width="300">
                </div>
              </div>


            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Notes' | translate}}</label>
                  <textarea [(ngModel)]="IDCardFrontNotes" class="form-control" rows="5"></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Status' | translate}}</label>
                  <select [(ngModel)]="IDCardFrontStatus" class="form-control">
                    <option [ngValue]="-1">Please select</option>
                    <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                    <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                    <option [ngValue]="2" style="background-color: red;">Rejected</option>
                  </select>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-4">
              <label for="LoginCP"> {{'ID card(front)' | translate}}</label>
            </div>
            <div class="col-md-1">
              <input type="file" #fileCNICF placeholder="Choose file" (change)="uploadFile(fileCNICF.files,'cnicF')"
                style="display:none;" class="form-control">
            </div>
            <div class="col-md-2" *ngIf="IDCardFront != ''" >
              <img [src]="IDCardFront" height="175" width="175" alt="">
            </div>
            <div class="col-md-5">
              <button type="button" class="btn btn-sm btn-primary" (click)="fileCNICF.click()">{{'Upload' |
                translate}}</button>
            </div>
            <div class="col-md-12 mt-2 text-success">{{IDCardFrontMsg}}</div> -->
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <button type="button" class="btn btn-sm btn-primary" (click)="fileCNICF.click()">
                <i class="fa fa-file"> {{'Choose ID Card (Front)' | translate}}</i>
              </button>
              <!-- <button class="btn btn-sm btn-danger">
                    <i class="fa fa-trash"> Delete</i>
                </button>
                <button class="btn btn-sm btn-success">
                  Save
              </button> -->
              <input type="file" #fileCNICF placeholder="Choose file" (change)="uploadFile(fileCNICF.files,'cnicF')"
                style="display:none;" class="form-control">
            </div>
          </div>

          <div class="row mt-3" *ngIf="IDCardBack != ''">
            <div class="col-md-8">

              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'ID card(Back)' | translate}}</label>
                  <img [src]="IDCardBack" alt="" height="190" width="300">
                </div>
              </div>


            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Notes' | translate}}</label>
                  <textarea [(ngModel)]="IDCardBackNotes" class="form-control" rows="5"></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Status' | translate}}</label>
                  <select [(ngModel)]="IDCardBackStatus" class="form-control">
                    <option [ngValue]="-1">Please select</option>
                    <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                    <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                    <option [ngValue]="2" style="background-color: red;">Rejected</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <button type="button" class="btn btn-sm btn-primary" (click)="fileCNICB.click()">
                <i class="fa fa-file"> {{'Choose ID Card (Back)' | translate}}</i>
              </button>
              <input type="file" #fileCNICB placeholder="Choose file" (change)="uploadFile(fileCNICB.files,'cnicB')"
                style="display:none;" class="form-control">
            </div>
          </div>

          <div class="row mt-3" *ngIf="ProofOfAddress != ''">
            <div class="col-md-8">

              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Proof Of Address' | translate}}</label>
                  <img [src]="ProofOfAddress" alt="" height="190" width="300">
                </div>
              </div>


            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Notes' | translate}}</label>
                  <textarea [(ngModel)]="ProofOfAddressNotes" class="form-control" rows="5"></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Status' | translate}}</label>
                  <select [(ngModel)]="ProofOfAddressStatus" class="form-control">
                    <option [ngValue]="-1">Please select</option>
                    <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                    <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                    <option [ngValue]="2" style="background-color: red;">Rejected</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <button type="button" class="btn btn-sm btn-primary" (click)="fileTaxCode.click()">
                <i class="fa fa-file"> {{'Choose Proof Of Address' | translate}}</i>
              </button>
              <input type="file" #fileTaxCode placeholder="Choose file"
                (change)="uploadFile(fileTaxCode.files,'Taxcode')" style="display:none;" class="form-control">
            </div>
          </div>

          <!--<div class="row mt-3" *ngIf="SelfieWithIdentity != ''">
            <div class="col-md-8">

              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Selfie With Identity' | translate}}</label>
                  <img [src]="SelfieWithIdentity" alt="" height="190" width="300">
                </div>
              </div>


            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Notes' | translate}}</label>
                  <textarea [(ngModel)]="SelfieWithIdentityNotes" class="form-control" rows="5"></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Status' | translate}}</label>
                  <select [(ngModel)]="SelfieWithIdentityStatus" class="form-control">
                    <option [ngValue]="-1">Please select</option>
                    <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                    <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                    <option [ngValue]="2" style="background-color: red;">Rejected</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <button type="button" class="btn btn-sm btn-primary" (click)="filePhoto.click()">
                <i class="fa fa-file"> {{'Choose Selfie With Identity' | translate}}</i>
              </button>
              <input type="file" #filePhoto placeholder="Choose file" (change)="uploadFile(filePhoto.files,'Photo')"
                style="display:none;" class="form-control">
            </div>
          </div>

          <div class="row mt-3" *ngIf="SelfieWithPaper != ''">
            <div class="col-md-8">

              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Selfie With Paper' | translate}}</label>
                  <img [src]="SelfieWithPaper" alt="" height="190" width="300">
                </div>
              </div>


            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Notes' | translate}}</label>
                  <textarea [(ngModel)]="SelfieWithPaperNotes" class="form-control" rows="5"></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <label for="LoginCP"> {{'Status' | translate}}</label>
                  <select [(ngModel)]="SelfieWithPaperStatus" class="form-control">
                    <option [ngValue]="-1">Please select</option>
                    <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                    <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                    <option [ngValue]="2" style="background-color: red;">Rejected</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <button type="button" class="btn btn-sm btn-primary" (click)="fileSelfieWithPaper.click()">
                <i class="fa fa-file"> {{'Choose Selfie With Paper' | translate}}</i>
              </button>
              <input type="file" #fileSelfieWithPaper placeholder="Choose file"
                (change)="uploadFile(fileSelfieWithPaper.files,'SelfieWithPaper')" style="display:none;"
                class="form-control">
            </div>
          </div>-->

          <div class="row mt-3">
            <div class="col-md-10">
              <span class="upload" *ngIf="progress > 0">
                {{'Progress' | translate}}
              </span>
              <span class="upload" *ngIf="progress > 0">
                {{progress}}%
              </span>
              <span class="upload" *ngIf="message">
                {{message}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div id="myModal" mdbModal #validationModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="validationModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'Attention' | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <p>{{'Please fix the following errors:' | translate}}</p>
            <ul>
              <li *ngFor="let error of errors">{{ error | translate }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="validationModal.hide()" mdbWavesEffect>{{ 'OK' | translate }}</button>
      </div>
    </div>

  </div>
</div>
<!-- <div class="row">

  <div class="col-md-6">

  </div>
</div> -->
