import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  STORAGE_KEY = 'Bolao';

  get(key: string): string {
    const item = localStorage.getItem(this.buildKey(key));
    return item ? JSON.parse(item) : '';
  }

  set(key: string, value: any): void {
    localStorage.setItem(this.buildKey(key), JSON.stringify(value));
  }

  remove(key: string): void {
    localStorage.removeItem(this.buildKey(key));
  }

  buildKey(key: string): string {
    return `${this.STORAGE_KEY}.${key}`;
  }
}
