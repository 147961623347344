import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IpConfigService {

  constructor() { }

  public getApiUrl() {
    // return 'https://backend.poolbet365.com'; // production
    return 'http://dev-backend.poolbet365.com' // dev
    // return 'https://localhost:44341'; // local
    //return 'http://54.36.121.165:10060'; // GuiGui
    //return 'http://217.79.251.10:89' //api-v2

  }

  public getImageUrl() {
    return "https://poolbet365.com/assets/documents";
    //return 'http://localhost:4200/assets/documents';//'For local testing';
  }
}
