import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastService } from 'src/app/services/toast.service';
import { Domain } from '../../interface/domain.interface';
import { IPermitRoute } from '../../interface/PermitRoute/IPermitRoute';
import { DomainService } from '../../services/domain.service';
import { LoginService } from '../../services/login.service';

export const ROUTES: IPermitRoute[] = [
  {
    title: 'Settings',
    icon: 'settings',
    children: [
      // { path: '/settings/index', title: 'Settings', icon: '', class: '', id: 41},
      { path: '/settings/slides', title: 'Slides', icon: '', class: '', id: 12 },
      { path: '/settings/banners', title: 'Banners', icon: '', class: '', id: 13 },
      { path: '/settings/admins', title: 'My Administrators', icon: '', class: '', id: 47 },
      { path: '/settings/domains', title: 'Domains Association', icon: '', class: '', id: 46 },
      { path: '/settings/StyleRound', title: 'Round Style', icon: '', class: '', id: 53 },
      { path: '/settings/partners', title: 'Partners', icon: '', class: '', id: 54 }
    ]
  },
  {
    title: 'Logs',
    icon: 'settings',
    children: [
      { path: '/logs-list', title: 'Logs List', icon: '', class: '', id: 55 }
    ]
  },
  {
    title: 'Reports',
    icon: 'description',
    children: [
      { path: '/reports/credits', title: 'Credit Entities', icon: '', class: '', id: 43 },
      { path: '/reports/financial', title: 'Financial Movement', icon: '', class: '', id: 42 },
      { path: '/reports/transactions', title: 'Transactions', icon: '', class: '', id: 18 },
      { path: '/reports/refund', title: 'Refund', icon: '', class: '', id: 7 },

    ]
  },
  {
    title: 'Jurisdiction',
    icon: 'group',
    children: [
      { path: '/jurisdiction/add', title: 'Create Jurisdiction', icon: '', class: '', id: 49 },
      { path: '/jurisdiction/list', title: 'List Administrators', icon: '', class: '', id: 20 },
    ]
  },
  {
    title: 'Transactions',
    icon: 'cached',
    children: [
      { path: '/credit-transfer', title: 'Credit Transfer', icon: '', class: '', id: 44 },
      //{ path: '/transactions/deposit', title: 'Player Deposit', icon: '', class: '', id: 48 },
    ]
  },
  {
    title: 'Documents',
    icon: 'contacts',
    children: [
      { path: '/documents', title: 'Document List', icon: '', class: '', id: 10 },
      { path: '/withdrawals', title: 'Withdrawals List', icon: '', class: '', id: 15 },
      { path: '/whatsapp-list', title: 'Whatsapp List', icon: '', class: '', id: 50 }
    ]
  },
  {
    title: 'Players',
    icon: 'contacts',
    children: [
      { path: '/players/list', title: 'Player List', icon: '', class: '', id: 45 },
      { path: '/players/create', title: 'New Player', icon: '', class: '', id: 27 },
    ]
  },
  {
    title: 'Bets',
    icon: 'paid',
    children: [
      { path: '/coupon-list', title: 'Coupons List', icon: '', class: '', id: 38 },
      { path: '/managerounds', title: 'Create/Manage Rounds', icon: '', class: '', id: 35 },
      { path: '/postresult', title: 'Post Result', icon: '', class: '', id: 34 },
      { path: '/edit-jackpot', title: 'Edit Jackpot', icon: '', class: '', id: 36 }
    ]
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: IPermitRoute[];
  userAdmin: any;
  loginService: LoginService;
  domains: Domain[] = [];
  domainsIndex: number = 0;
  qrCoceLoading: boolean = false;

  linkRefCode: string = "";
  apiurl: string = "https://www.poolbet365.com";
  //apiurl : string = "http://dev-bolao.poolbet365.com"
  // QrCodeLink: string = "https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl="
  QrCodeLink: string = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=';
  jurisdictionCoupon: string = "";

  constructor(loginService: LoginService, private translate: TranslateService, private domainService: DomainService,
    private toastService: ToastService, private clipboardService: ClipboardService,
  ) {
    this.loginService = loginService;
    this.userAdmin = loginService.getUserInfoFromCookie();
  }

  ngOnInit(): void {
    this.menuItems = ROUTES;
    this.getJurisdictionCoupon();
    //if (this.userAdmin.permits && this.userAdmin.permits.length > 0) {
    // tslint:disable-next-line:prefer-for-of
    const permits = this.userAdmin.permits.map(obj => obj.id);
    this.menuItems.forEach(iten => {
      let index = 0
      const amountReps = iten.children.length;
      for (let i = 1; i <= amountReps; i++) {
        if (permits.includes(iten.children[index].id))
          index++;
        else
          iten.children.splice(index, 1);
      }
    });

    // for (let i = 0; i < this.menuItems.length; i++) {
    //   if (this.menuItems[i].title === 'Settings') {
    //     for (let j = 0; j < this.menuItems[i].children.length; j++) {
    //       // if (this.menuItems[i].children[j].title === 'Settings' && this.userAdmin.permits.find(x => x.name === 'settings') == null) {
    //       //   this.menuItems[i].children.splice(j, 1);
    //       // }
    //       if (this.menuItems[i].children[j].title === 'Slides' && this.userAdmin.permits.find(x => x.name === 'sliders') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Banners' && this.userAdmin.permits.find(x => x.name === 'banners') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'My Administrators' && this.userAdmin.permits.find(x => x.name === 'myadministrators') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Domains Association' && this.userAdmin.permits.find(x => x.name === 'domainassociation') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Round Style' && this.userAdmin.permits.find(x => x.name === 'manageStyles') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Partners' && this.userAdmin.permits.find(x => x.name === 'managePartners') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //     }
    //   }
    //   if (this.menuItems[i].title === 'Logs') {
    //     for (let j = 0; j < this.menuItems[i].children.length; j++) {
    //       if (this.menuItems[i].children[j].title === 'Logs List' && this.userAdmin.permits.find(x => x.name === 'logsList') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //     }
    //   }
    //   if (this.menuItems[i].title === 'Reports') {
    //     for (let j = 0; j < this.menuItems[i].children.length; j++) {
    //       if (this.menuItems[i].children[j].title === 'Credit Entities' && this.userAdmin.permits.find(x => x.name === 'creditentities') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Financial Movement' && this.userAdmin.permits.find(x => x.name === 'financialmovement') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Transactions' && this.userAdmin.permits.find(x => x.name === 'transactionsummary') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Refund' && this.userAdmin.permits.find(x => x.name === 'refunds') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //     }
    //   }

    //   if (this.menuItems[i].title === 'Jurisdiction') {
    //     for (let j = 0; j < this.menuItems[i].children.length; j++) {
    //       if (this.menuItems[i].children[j].title === 'Create Jurisdiction' && this.userAdmin.permits.find(x => x.name === 'createjurisdiction') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'List Administrators' && this.userAdmin.permits.find(x => x.name === 'administratorslist') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }

    //     }
    //   }
    //   if (this.menuItems[i].title === 'Transactions') {
    //     for (let j = 0; j < this.menuItems[i].children.length; j++) {
    //       if (this.menuItems[i].children[j].title === 'Credit Transfer' && this.userAdmin.permits.find(x => x.name === 'credittransfer') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       // if (this.menuItems[i].children[j].title === 'Player Deposit' && this.userAdmin.permits.find(x => x.name === 'playerdeposit') == null) {
    //       //   this.menuItems[i].children.splice(j, 1);
    //       // }
    //     }
    //   }
    //   if (this.menuItems[i].title === 'Documents') {
    //     for (let j = 0; j < this.menuItems[i].children.length; j++) {
    //       if (this.menuItems[i].children[j].title === 'Document List' && this.userAdmin.permits.find(x => x.name === 'editplayerdocuments') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Withdrawals List' && this.userAdmin.permits.find(x => x.name === 'withdrawal') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Whatsapp List' && this.userAdmin.permits.find(x => x.name === 'whatsapplist') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //     }
    //   }
    //   if (this.menuItems[i].title === 'Players') {
    //     for (let j = 0; j < this.menuItems[i].children.length; j++) {
    //       var teste = this.userAdmin.permits.find(x => x.name === 'playerlist')
    //       if (this.menuItems[i].children[j].title === 'Player List' && this.userAdmin.permits.find(x => x.name === 'playerlist') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'New Player' && this.userAdmin.permits.find(x => x.name === 'newplayer') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //     }
    //   }
    //   if (this.menuItems[i].title === 'Bets') {
    //     for (let j = 0; j < this.menuItems[i].children.length; j++) {
    //       if (this.menuItems[i].children[j].title === 'Coupons List' && this.userAdmin.permits.find(x => x.name === 'couponslist') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Create/Manage Rounds' && this.userAdmin.permits.find(x => x.name === 'managerounds') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Post Result' && this.userAdmin.permits.find(x => x.name === 'postresult') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //       if (this.menuItems[i].children[j].title === 'Edit Jackpot' && this.userAdmin.permits.find(x => x.name === 'editjackpot') == null) {
    //         this.menuItems[i].children.splice(j, 1);
    //       }
    //     }
    //   }
    // }
    //}
  }

  userLogout(): void {
    this.loginService.logout();
    location.reload();
  }

  fadeoutSidebar(): void {
    const sidevar = document.getElementById('app-sidebar');
    sidevar.setAttribute('style', 'display: none;');
  }

  onNavClicked(menuId): void {
    this.menuItems.forEach(menuItem => {
      if (menuItem.title !== menuId) {
        const domOfMenu = document.getElementById(menuItem.title);
        domOfMenu.classList.remove('show');
      }
    });
  }

  CheckElement(element) {
    if (element.children.length == 0) {
      return false
    }
    else return true;
  }


  getJurisdictionCoupon() {
    this.qrCoceLoading = true;
    this.loginService.getCoupon(this.userAdmin.id)
      .subscribe(data => {
        this.jurisdictionCoupon = data != null ? data.code : "";
        this.linkRefCode = this.apiurl + "/home/refcode/" + this.jurisdictionCoupon
        // this.QrCodeLink = this.QrCodeLink + this.linkRefCode;
        this.generateQrCodes(this.jurisdictionCoupon);
      }, error => {
        console.log(error)
      })
  }

  generateQrCodes(cupon: string) {
    this.domainService.RecoverDomainsByUser(this.userAdmin.id, this.userAdmin.skinPartnerId)
      .subscribe(
        domainsResp => {
          domainsResp.forEach(
            domain => {
              domain.name = domain.url.split('.')[1];
              domain.url = `${domain.url}/home/refcode/${cupon}`;
              domain.qrCodeLink = `${this.QrCodeLink}${domain.url}`;
              this.domains.push(domain);

              this.qrCoceLoading = false;
            }
          );
        }
      )
  }

  addInDomainsIndex(value: number) {
    const actualIdenx = this.domainsIndex + value;

    if (actualIdenx < 0 || actualIdenx > this.domains.length)
      return;

    this.domainsIndex = actualIdenx;
  }

  DisableButton(range: number) {
    if (this.domainsIndex == range || this.domains.length == 0)
      return true;

    return false;
  }

  copyLinkRefCode(): void {
    this.clipboardService.copyFromContent(this.linkRefCode);
  }

  async downloadImage(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(blob);
      anchor.download = `Qr Code - ${this.domains[this.domainsIndex].name.toUpperCase()}`;
      anchor.click();
      URL.revokeObjectURL(anchor.href);
      this.toastService.showNotification({ message: this.translate.instant("friendCode.DownloadSuccess"), type: 'success' });
    } catch (error) {
      this.toastService.showNotification({ message: this.translate.instant("friendCode.DownloadError"), type: 'danger' });
    }
  }
}
