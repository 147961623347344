
import { NestedTreeControl } from '@angular/cdk/tree';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxComponent } from 'angular-bootstrap-md';
import { Country } from 'src/app/interface/country.interface';
import { Currency } from 'src/app/interface/currency.interface';
import { Permit } from 'src/app/interface/permit.interface';
import { User } from 'src/app/interface/user.interface';
import { InteractionService } from 'src/app/services/interaction.service';
import { IpConfigService } from 'src/app/services/Ip-Config.service';
import { JurisdictionsService } from 'src/app/services/jurisdictions.service';
import { PlayerService } from 'src/app/services/player.service';
import { ConfirmedValidator } from '../../../helper/confirmed.validator';
import { DepositFunds } from '../../../interface/deposit-funds.interface';
import { JurisdictionEntity, JurisdictionNode } from '../../../interface/jurisdiction.interface';
import { DropDownList, Player, PlayerDocs } from '../../../interface/player.interface';
import { LoginService } from '../../../services/login.service';
import { ToastService } from '../../../services/toast.service';
import { CountryService } from './../../../services/country.service';
import { RoundStyleService } from '../../../services/round-style.service'

@Component({
  selector: 'app-add-jurisdiction',
  templateUrl: './add-jurisdiction.component.html',
  styleUrls: ['./add-jurisdiction.component.css'],
})
export class AddJurisdictionComponent implements OnInit {
  apiUrl = ''; // local test
  imageUrl = '';
  public progress: number;
  public message: string;

  savingPlayer: boolean = false;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onUploadFinished = new EventEmitter();
  @ViewChildren('newPermitChk1') newPermitChk1: QueryList<CheckboxComponent>;
  @ViewChild('confirmationModel') confirmationModel: any;
  @ViewChild('validationModal') validationModal: any;
  @ViewChild('frame1') modalEditJurisdiction: any;
  @ViewChild('manageStyleModal') manageStyleModal: any;
  showModal: boolean;
  countries: Country[];
  newAdminForm: FormGroup;
  transferType: string;
  transferAmount: number;
  JurisdictionType: number;
  clubParents: DropDownList[];
  jurisdictionName: string;
  jurisdictionLoginId: number;
  jurisdictionLoginId2: boolean;
  jurisdictionLoginId3: boolean;
  jurisdictionLoginId4: boolean;
  jurisdictionLoginId5: boolean;
  jurisdictionLoginId6: boolean;
  jurisdictionLoginId7: boolean;
  jurisdictionLoginIsPlayer: boolean;
  Jurisdiction: number;
  todayDate: string;
  userAdmin: User;
  creditCard: string;
  debitCard: string;
  passMinLength = 8;
  createPlayerRequest: Player;
  seletedJurisdiction: any;
  usersList: any = [];
  allowUsersList: any = [];
  jurisdictions: any;
  jurisdictionCoupon: string = '';
  CouponValue: number = 0;
  selectedJurisdictions: any;
  selectedJurisdictionLevel: any;
  currentCredit: number;
  currentJurisdiction: string;
  creditedAmount: number;
  creditType: string;
  newCredit: number;
  creditTransfer: DepositFunds;
  currentUserCredit: number;
  Currency: Currency = <Currency>{};
  closeResult = '';
  createPlayerForm: FormGroup;
  IDCardFrontMsg: string;
  IDCardBackMsg: string;
  ProofOfAddressMsg: string;
  SelfieWithIdentityMsg: string;
  SelfieWithPaperMsg: string;
  IDCardFront: string;
  IDCardFrontNotes: string;
  IDCardFrontStatus: number;
  IDCardBack: string;
  IDCardBackNotes: string;
  IDCardBackStatus: number;
  ProofOfAddress: string;
  ProofOfAddressNotes: string;
  ProofOfAddressStatus: number;
  SelfieWithIdentity: string;
  SelfieWithIdentityNotes: string;
  SelfieWithIdentityStatus: number;
  SelfieWithPaper: string;
  SelfieWithPaperNotes: string;
  SelfieWithPaperStatus: number;
  parentComission: number;
  loading: boolean = false;
  permitsList: Permit[];
  userEdit: User;

  CreateNewJurisdiction: boolean = false;
  EditJurisdictionPermit: boolean = false;
  LockAccessJuri: boolean = false;
  UnlockAccessJuri: boolean = false;

  editJurisdictionForm: FormGroup;
  editableJurisdiction = {} as User;

  playerDocs: PlayerDocs[];
  errors: string[] = [];
  TREE_USERS: any[] = [];
  treeControl = new NestedTreeControl<JurisdictionNode>(
    (node) => node.children
  );
  dataSource = new MatTreeNestedDataSource<JurisdictionNode>();
  hasChild = (_: number, node: JurisdictionNode) =>
    !!node.children && node.children.length > 0;
  commissionArray: number[];
  selectedParentAdminId: any;
  comissionField: any;
  isPlayer: boolean;
  isClub: boolean;
  roundsStylesToPermit: any;
  tempRoundsStylesToPermit: any;
  hasChangedRoundStylePermitts: boolean = false;

  constructor(
    public loginService: LoginService,
    private playerService: PlayerService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private translateService: TranslateService,
    private countryService: CountryService,
    private interactionService: InteractionService,
    private http: HttpClient,
    private ipConfigService: IpConfigService,
    private jurisdictionsService: JurisdictionsService,
    private router: Router,
    private roundStyleService: RoundStyleService,
    private cdr: ChangeDetectorRef
  ) {
    this.newAdminForm = this.formBuilder.group(
      {
        new_username: ['', Validators.required],
        new_address: ['', Validators.required],
        new_phone: ['', Validators.required],
        new_note: ['', Validators.required],
      },
      {}
    );
    this.userAdmin = loginService.getUserInfoFromCookie();
    this.IDCardBack = '';
    this.IDCardBackNotes = '';
    this.IDCardBackStatus = -1;
    this.IDCardFront = '';
    this.IDCardFrontNotes = '';
    this.IDCardFrontStatus = -1;
    this.SelfieWithIdentity = '';
    this.SelfieWithIdentityNotes = '';
    this.SelfieWithIdentityStatus = -1;
    this.SelfieWithPaper = '';
    this.SelfieWithPaperNotes = '';
    this.SelfieWithPaperStatus = -1;
    this.ProofOfAddress = '';
    this.ProofOfAddressNotes = '';
    this.ProofOfAddressStatus = -1;
    this.playerDocs = new Array();
    this.todayDate = new Date().toLocaleDateString();
    this.Jurisdiction = -1;
    this.createPlayerRequest = {} as Player;
    this.apiUrl = ipConfigService.getApiUrl();
    this.imageUrl = ipConfigService.getImageUrl();
    this.createPlayerForm = this.formBuilder.group(
      {
        login: [''],
        userName: [this.createPlayerRequest.userName, Validators.required],
        jurisdictionCoupon: [''],
        name: ['', Validators.required],
        email: ['', Validators.required],
        whatsApp: [''],
        parentAdminId: ['', Validators.required],
        password: ['', Validators.compose([Validators.required, Validators.minLength(this.passMinLength)])],
        confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(this.passMinLength)])],
        lastname: [''],
        email2: [''],
        email3: [''],
        gender: [''],
        birthDate: [''],
        address: [''],
        city: [''],
        country: [''],
        zipCode: [''],
        phone: [''],
        commission: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), this.validateCommission.bind(this)],
        ],
        phone2: [''],
        cellPhone: [''],
        notes: [''],
        deleted: [],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );

    this.editJurisdictionForm = this.formBuilder.group(
      {
        login: [''],
        userName: [''],
        name: [''],
        email: ['', Validators.required],
        whatsapp: [''],
        lastName: [''],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        email2: [''],
        email3: [''],
        gender: [''],
        birthDate: [''],
        address: [''],
        city: [''],
        country: [''],
        zipCode: [''],
        phone: [''],
        phone2: [''],
        cellPhone: [''],
        commission: [''],
        notes: [''],
        deleted: [],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
    this.loginService
      .getAllPermits(this.userAdmin.id, this.userAdmin.login)
      .subscribe(
        (data) => {
          this.permitsList = data;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  ngOnInit(): void {
    this.jurisdictionLoginIsPlayer = false;
    switch (parseInt(localStorage.getItem('currentUserJurisdictionId'))) {
      case 1:
        this.jurisdictionLoginId2 = true;
        this.jurisdictionLoginId3 = true;
        this.jurisdictionLoginId4 = true;
        this.jurisdictionLoginId5 = true;
        this.jurisdictionLoginId6 = true;
        this.jurisdictionLoginId7 = true;
        break;
      case 2:
        this.jurisdictionLoginId2 = false;
        this.jurisdictionLoginId3 = true;
        this.jurisdictionLoginId4 = true;
        this.jurisdictionLoginId5 = true;
        this.jurisdictionLoginId6 = true;
        this.jurisdictionLoginId7 = true;
        break;
      case 3:
        this.jurisdictionLoginId2 = false;
        this.jurisdictionLoginId3 = false;
        this.jurisdictionLoginId4 = true;
        this.jurisdictionLoginId5 = true;
        this.jurisdictionLoginId6 = true;
        this.jurisdictionLoginId7 = true;
        break;
      case 4:
        this.jurisdictionLoginId2 = false;
        this.jurisdictionLoginId3 = false;
        this.jurisdictionLoginId4 = false;
        this.jurisdictionLoginId5 = true;
        this.jurisdictionLoginId6 = true;
        this.jurisdictionLoginId7 = true;
        break;
      case 5:
        this.jurisdictionLoginId2 = false;
        this.jurisdictionLoginId3 = false;
        this.jurisdictionLoginId4 = false;
        this.jurisdictionLoginId5 = false;
        this.jurisdictionLoginId6 = true;
        this.jurisdictionLoginId7 = true;
        break;
      case 6:
        this.jurisdictionLoginId2 = false;
        this.jurisdictionLoginId3 = false;
        this.jurisdictionLoginId4 = false;
        this.jurisdictionLoginId5 = false;
        this.jurisdictionLoginId6 = false;
        this.jurisdictionLoginId7 = true;
        break;
      case 7:
        this.jurisdictionLoginId2 = false;
        this.jurisdictionLoginId3 = false;
        this.jurisdictionLoginId4 = false;
        this.jurisdictionLoginId5 = false;
        this.jurisdictionLoginId6 = false;
        this.jurisdictionLoginId7 = false;
        break;
      default:
        break;
    }
    this.userAdmin = this.loginService.getUserInfoFromCookie();
    this.LockAccessJuri =
      this.userAdmin.permits.length > 0
        ? this.userAdmin.permits.find((x) => x.name === 'locklowerlevels') ==
          null
          ? false
          : true
        : true;
    this.UnlockAccessJuri =
      this.userAdmin.permits.length > 0
        ? this.userAdmin.permits.find((x) => x.name === 'unlocklowerlevels') ==
          null
          ? false
          : true
        : true;
    this.CreateNewJurisdiction =
      this.userAdmin.permits.length > 0
        ? this.userAdmin.permits.find((x) => x.name === 'createjurisdiction') ==
          null
          ? false
          : true
        : true;
    this.EditJurisdictionPermit =
      this.userAdmin.permits.length > 0
        ? this.userAdmin.permits.find(
          (x) => x.name === 'jurisdictioneditpermit'
        ) == null
          ? false
          : true
        : true;
    this.countryService.getCountries().subscribe(
      (data: Country[]) => {
        this.countries = data;
      },
      (error) => {
        console.log(error);
      }
    );
    this.playerService
      .getClubParent(
        this.userAdmin.id,
        this.userAdmin.login
      )
      .subscribe(
        (data: DropDownList[]) => {
          this.clubParents = data;
        },
        (error) => {
          console.log(error);
        }
      );
    this.getJurisdictions();
    this.loginService.setGlobalCurrency();
    this.interactionService.globalCurrency.subscribe((data) => {
      if (data) {
        this.Currency = data;
      }
    });
    this.listTree();
    setTimeout(() => {
      this.getJurisdictionLevel();
    }, 4000);
    this.generateCoupon();
  }

  getJurisdictionLevel() {
    let jurisdictionLevel = parseInt(
      localStorage.getItem('currentUserJurisdictionId')
    );
    switch (jurisdictionLevel) {
      case 1:
        this.selectJurisdictionLevel(2, "Nacional");
        break;
      case 2:
        this.selectJurisdictionLevel(3, "Regional");
        break;
      case 3:
        this.selectJurisdictionLevel(4, "Distrito");
        break;
      case 4:
        this.selectJurisdictionLevel(5, "Local");
        break;
      case 5:
        this.selectJurisdictionLevel(6, "Clube");
        break;
      case 6:
        this.selectJurisdictionLevel(7, "Jogador");
        break;
    }
  }

  verifyIfPrefixNameWasErased(text: string) {
    const prefixValues = {
      2: 'nac',
      3: 'reg',
      4: 'dis',
      5: 'loc',
      6: 'club',
      7: '',
    };
    const selectedJurisdictionLevel = this.selectedJurisdictionLevel;
    const userNameField: AbstractControl = this.createPlayerForm.get('userName');
    if (text.includes(prefixValues[selectedJurisdictionLevel])) {
      if (!text.startsWith(prefixValues[selectedJurisdictionLevel])) {
        userNameField.setValue('');
        userNameField.setValue(prefixValues[selectedJurisdictionLevel]);
      }
    } else {
      userNameField.setValue(prefixValues[selectedJurisdictionLevel]);
    }
  }

  setPrefixName(id: Number) {
    switch (id) {
      case 2:
        this.createPlayerForm.get('userName').setValue('nac');
        break;
      case 3:
        this.createPlayerForm.get('userName').setValue('reg');
        break;
      case 4:
        this.createPlayerForm.get('userName').setValue('dis');
        break;
      case 5:
        this.createPlayerForm.get('userName').setValue('loc');
        break;
      case 6:
        this.createPlayerForm.get('userName').setValue('club');
        break;
      case 7:
        this.createPlayerForm.get('userName').setValue('');
        break;
    }
  }

  getNumberArray(length: number): void {
    this.commissionArray = Array.from({ length: length + 1 }, (_, index) => index).reverse();
  }

  validateCommission(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const commissionValue = parseFloat(control.value);
    if (isNaN(commissionValue)) {
      return null;
    }
    if (commissionValue > this.parentComission) {
      return { commissionTooHigh: true };
    }
    if (commissionValue > this.parentComission) {
      return { commissionTooHigh: true }; // Return validation error if the value is greater than the parent commission
    }
    return null; // Validation passed
  }

  onSelectChange(selectedValue: any) {
    this.loginService.getUser(selectedValue)
      .subscribe((user: User) => {
        this.parentComission = user.commission;
      });
  }

  listTree() {
    var userAdmin = this.loginService.getUserInfoFromCookie();
    this.loginService.getUserTree(userAdmin.id, userAdmin.login).subscribe(
      (data) => {
        this.TREE_USERS = [];
        this.TREE_USERS.push({
          name: data.login,
          login: data.login,
          id: data.id,
          jurisdictionId: data.jurisdictionId,
          children: data.children,
          credit: data.credit,
          credit_right_below: this.getBelow(data.children).credit_right_below,
          credit_below: this.getBelow(data.children).credit_below,
        });
        this.dataSource.data = this.TREE_USERS;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getJurisdictions() {
    this.loginService.getJurisdictions(parseInt(localStorage.getItem("currentUserJurisdictionId"))).subscribe(res => {
      this.jurisdictions = res
      this.selectedJurisdictionLevel = parseInt(localStorage.getItem("currentUserJurisdictionId"))
      this.getUsersList();
    })
  }


  getJurisdictionName(id) {
    return this.jurisdictions.find((e) => e.id == id);
  }

  getBelow(children) {
    let credit_right_below = 0;
    let credit_below = 0;
    children.forEach((element) => {
      credit_right_below += element.credit;
      credit_below += element.credit;
      credit_below += this.getBelow(element.children).credit_below;
      if (element.children.length > 0) {
        element['credit_right_below'] = this.getBelow(
          element.children
        ).credit_right_below;
        element['credit_below'] = this.getBelow(element.children).credit_below;
      }
    });
    return { credit_right_below, credit_below };
  }

  getBackground(id) {
    switch (id) {
      case 1:
        return '';
        break;
      case 2:
        return '#FF9999';
        break;
      case 3:
        return '#EF99FF';
        break;
      case 4:
        return '#FFC199';
        break;
      case 5:
        return '#BAFFBA';
        break;
      case 6:
        return '#A6DADB';
        break;
      case 7:
        return '';
        break;

      default:
        break;
    }
  }

  getUsersList() {
    this.loginService.GetUsersList(this.userAdmin.id).subscribe(res => {
      let temUser = res.find(element => element.jurisdictionId == this.selectedJurisdictionLevel && element.id == this.userAdmin.id && element.login == this.userAdmin.login)
      if (temUser == null) {
        this.loginService.GetUserAssist(this.userAdmin.id).subscribe(x => {
          temUser = res.find(element => element.id == x.adminParentID && x.userName == this.userAdmin.login)
          this.allowUsersList.push(temUser)
          this.usersList.push(temUser)

          this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
          this.loginService.getUsersTreeList(x.adminParentID)
            .subscribe(res => {
              res.forEach(element => {
                this.usersList.push(element)
              });
              this.seletedJurisdiction = -1
            })
        })
      } else {
        this.allowUsersList.push(temUser)
        this.usersList.push(temUser)

        this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
        this.loginService.getUsersTreeList(temUser.id)
          .subscribe(res => {
            res.forEach(element => {
              this.usersList.push(element)
            });
            this.seletedJurisdiction = -1
          })
      }
    })
  }

  selectJurisdictionLevel(jurisdictionLevel, jurisdictionName) {
    this.resetComissionArray();
    switch (jurisdictionLevel) {
      case 1:
        this.selectedJurisdictionLevel = jurisdictionLevel;
        this.jurisdictionName = jurisdictionName;
        this.isClub = false;
        this.isPlayer = false;
        break;
      case 2:
        this.selectedJurisdictionLevel = jurisdictionLevel;
        this.jurisdictionName = jurisdictionName;
        this.isClub = false;
        this.isPlayer = false;
        break;
      case 3:
        this.selectedJurisdictionLevel = jurisdictionLevel;
        this.jurisdictionName = jurisdictionName;
        this.isClub = false;
        this.isPlayer = false;
        break;
      case 4:
        this.selectedJurisdictionLevel = jurisdictionLevel;
        this.jurisdictionName = jurisdictionName;
        this.isClub = false;
        this.isPlayer = false;
        break;
      case 5:
        this.selectedJurisdictionLevel = jurisdictionLevel;
        this.jurisdictionName = jurisdictionName;
        this.isClub = false;
        this.isPlayer = false;
        break;
      case 6:
        this.selectedJurisdictionLevel = jurisdictionLevel;
        this.jurisdictionName = jurisdictionName;
        this.generateCoupon();
        this.createPlayerForm.get('jurisdictionCoupon').setValue(this.jurisdictionCoupon);
        this.isClub = true;
        this.isPlayer = false;
        break;
      case 7:
        this.selectedJurisdictionLevel = jurisdictionLevel;
        this.jurisdictionName = jurisdictionName;
        this.isClub = false;
        this.isPlayer = true;
        break;
    }
    this.setPrefixName(this.selectedJurisdictionLevel);
    this.allowUsersList = []
    this.usersList.forEach(element => {
      if (element.jurisdictionId == this.selectedJurisdictionLevel - 1) {
        if (parseInt(localStorage.getItem("currentUserJurisdictionId")) == this.selectedJurisdictionLevel - 1) {
          if (element.id == this.userAdmin.id) {
            this.allowUsersList = [element]
          }
        } else {
          this.allowUsersList.push(element)
        }
      }
    });
    this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
    this.seletedJurisdiction = -1
    this.loginService.getJurisdictions(this.selectedJurisdictionLevel).subscribe(res => {
      this.selectedJurisdictions = res
    });
  }

  jurisdictionLevelChange(event) {
    let userId = event;
    // this.selectedJurisdictionLevel = event;
    this.loginService.getUser(userId)
      .subscribe((user: User) => {
        this.parentComission = user.commission;
      });
  }

  jurisdictionChange(event) {
    this.seletedJurisdiction = event.target.value;
  }

  validateForm(formGroup: FormGroup): void {
    this.errors = [];
    Object.keys(formGroup.controls).forEach((field) => {
      let control = formGroup.controls[field];
      let errors = control.errors;
      if (errors === null || errors.count === 0) {
        return;
      }
      // Handle the 'required' case
      if (errors.required) {
        this.errors.push(`${field} is required`);
      }
      // Handle 'minlength' case
      if (errors.minlength) {
        this.errors.push(
          `${field} minimum length is ${errors.minlength.requiredLength}.`
        );
      }
      // Handle custom messages.
      if (errors.message) {
        this.errors.push(`${field} ${errors.message}`);
      }
    });
  }

  validateEditForm(formGroup: FormGroup): boolean {
    if (
      formGroup.controls['password'].value !=
      formGroup.controls['confirmPassword'].value ||
      formGroup.controls['password'].value == ''
    ) {
      return false;
    } else if (formGroup.controls['email'].value == '') {
      return false;
    } else {
      return true;
    }
  }

  resetComissionArray() {
    this.commissionArray = [];
    this.parentComission = null;
    this.createPlayerForm.get('parentAdminId').setValue('');
  }

  createPlayer(formData): void {
    if (this.createPlayerForm.valid) {
      this.loading = true;
      this.createPlayerRequest.parentId = formData.parentAdminId;
      this.createPlayerRequest.city = formData.city;
      this.createPlayerRequest.lastName = formData.lastName;
      this.createPlayerRequest.password = formData.password;
      this.createPlayerRequest.confirmPassword = formData.confirmPassword;
      this.createPlayerRequest.gender = formData.gender;
      this.createPlayerRequest.contact = formData.phone;
      this.createPlayerRequest.birthDate = formData.birthDate;
      this.createPlayerRequest.name = formData.name;
      this.createPlayerRequest.email2 = formData.email2;
      this.createPlayerRequest.email3 = formData.email3;
      this.createPlayerRequest.country = formData.country;
      this.createPlayerRequest.email = formData.email;
      this.createPlayerRequest.notes = formData.notes;
      this.createPlayerRequest.address = formData.address;
      this.createPlayerRequest.zipCode = formData.zipCode;
      this.createPlayerRequest.whatsapp = formData.whatsapp;
      this.createPlayerRequest.login = formData.userName;
      this.createPlayerRequest.userName = formData.userName;
      this.createPlayerRequest.cellPhone = formData.cellPhone;
      this.createPlayerRequest.commission = formData.commission;
      this.createPlayerRequest.phone = formData.phone;
      this.createPlayerRequest.phone2 = formData.phone2;
      this.createPlayerRequest.deleted = formData.deleted ? true : false;
      this.createPlayerRequest.jurisdictionId = this.selectedJurisdictions[0].id;
      this.createPlayerRequest.skinPartnerId = 1;
      if (this.IDCardFront != '') {
        const doc: PlayerDocs = {
          id: 0,
          fileName: this.createPlayerRequest.cNICFront,
          type: 1,
          status: this.IDCardFrontStatus,
          description: this.IDCardFrontNotes,
          userId: 0,
          statusDesc: '',
          typeDesc: '',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr: '',
          responseDateStr: '',
        };
        this.playerDocs.push(doc);
      }

      if (this.IDCardBack != '') {
        const doc: PlayerDocs = {
          id: 0,
          fileName: this.createPlayerRequest.cNICBack,
          type: 5,
          status: this.IDCardBackStatus,
          description: this.IDCardBackNotes,
          userId: 0,
          statusDesc: '',
          typeDesc: '',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr: '',
          responseDateStr: '',
        };
        this.playerDocs.push(doc);
      }
      if (this.ProofOfAddress != '') {
        const doc: PlayerDocs = {
          id: 0,
          fileName: this.createPlayerRequest.proofOfAddress,
          type: 2,
          status: this.ProofOfAddressStatus,
          description: this.ProofOfAddressNotes,
          userId: 0,
          statusDesc: '',
          typeDesc: '',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr: '',
          responseDateStr: '',
        };
        this.playerDocs.push(doc);
      }
      if (this.SelfieWithIdentity != '') {
        const doc: PlayerDocs = {
          id: 0,
          fileName: this.createPlayerRequest.selfieWithIdentity,
          type: 3,
          status: this.SelfieWithIdentityStatus,
          description: this.SelfieWithIdentityNotes,
          userId: 0,
          statusDesc: '',
          typeDesc: '',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr: '',
          responseDateStr: '',
        };
        this.playerDocs.push(doc);
      }
      if (this.SelfieWithPaper != '') {
        const doc: PlayerDocs = {
          id: 0,
          fileName: this.createPlayerRequest.selfieWithPaper,
          type: 4,
          status: this.SelfieWithPaperStatus,
          description: this.SelfieWithPaperNotes,
          userId: 0,
          statusDesc: '',
          typeDesc: '',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr: '',
          responseDateStr: '',
        };
        this.playerDocs.push(doc);
      }

      this.createPlayerRequest.docs = this.playerDocs;
      this.playerService.addUserAdmin(this.createPlayerRequest, this.isClub ? this.jurisdictionCoupon : '').subscribe(
        (message: any) => {
          this.toastService.showNotification({
            message: this.translateService.instant(message.msg),
            type: 'success',
          });
          this.reloadComponent();
        },
        (error) => {
          this.toastService.showNotification({
            message: this.translateService.instant(error.error.msg),
            type: 'danger',
          });
          this.loading = false;
        }
      );
      this.listTree();
    } else {
      this.validateForm(this.createPlayerForm);
      this.validationModal.show();
    }
  }

  public uploadFile = (files, field) => {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files[0] as File;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.http
      .post(this.apiUrl + '/PlayerAction/UploadFiles', formData, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe((event) => {
        if (event.type === HttpEventType.Response) {
          if (field === 'cnicF') {
            this.createPlayerRequest.cNICFront =
              event.body['dbPath'].toString();
            this.IDCardFront =
              this.imageUrl + '/' + this.createPlayerRequest.cNICFront;
            //this.IDCardFrontMsg = 'ID card (front) uploaded successfully.';
            this.IDCardFrontStatus = -1;
          }
          if (field === 'cnicB') {
            this.createPlayerRequest.cNICBack = event.body['dbPath'].toString();
            this.IDCardBack =
              this.imageUrl + '/' + this.createPlayerRequest.cNICBack;
            this.IDCardBackStatus = -1;
          }
          if (field === 'Taxcode') {
            this.createPlayerRequest.proofOfAddress =
              event.body['dbPath'].toString();
            this.ProofOfAddress =
              this.imageUrl + '/' + this.createPlayerRequest.proofOfAddress;
            this.ProofOfAddressStatus = -1;
            //this.ProofOfAddressMsg = 'Proof of address uploaded successfully.';
          }
          if (field === 'Photo') {
            this.createPlayerRequest.selfieWithIdentity =
              event.body['dbPath'].toString();
            this.SelfieWithIdentity =
              this.imageUrl + '/' + this.createPlayerRequest.selfieWithIdentity;
            this.SelfieWithIdentityStatus = -1;
            //this.SelfieWithIdentityMsg = 'Selfie With Identity uploaded successfully.';
          }
          if (field === 'SelfieWithPaper') {
            this.createPlayerRequest.selfieWithPaper =
              event.body['dbPath'].toString();
            this.SelfieWithPaper =
              this.imageUrl + '/' + this.createPlayerRequest.selfieWithPaper;
            this.SelfieWithPaperStatus = -1;
            //this.SelfieWithPaperMsg = 'Selfie With Paper uploaded successfully.';
          }
        }
      });
  };

  onNewAdminSubmit(formData): void {
    let newJurisdiction: JurisdictionEntity = {
      name: formData.name,
      status: 1,
      phone: formData.phone,
      notes: formData.notes,
      address: formData.address,
      id: 0,
    };
    this.jurisdictionsService.addJurisdictionEntity(newJurisdiction).subscribe(
      () => {
        this.toastService.showNotification({
          message: this.translateService.instant('success'),
          type: 'success',
        });
      },
      (error) => {
        console.log(error);
        this.toastService.showNotification({
          message: this.translateService.instant('failed'),
          type: 'danger',
        });
      }
    );
  }

  openEditModal(editableJurisdictionID) {
    this.loginService.getUser(editableJurisdictionID).subscribe(
      (data) => {
        this.editableJurisdiction = data;

        this.loginService.getCoupon(data.id).subscribe((data) => {
          this.jurisdictionCoupon = data != null ? data.code : '';
          if (data == null) {
          } else this.CouponValue = data.availableValue;
        });

        if (data.id == this.userAdmin.id) {
          this.router.navigate(['/settings/index']);
        } else if (data.jurisdictionId == 7) {
          this.router.navigate(['/players/edit/' + editableJurisdictionID]);
        } else {
          this.userEdit = data;
          this.editJurisdictionForm.controls['name'].setValue(data.name);
          this.editJurisdictionForm.controls['lastName'].setValue(
            data.lastName
          );
          this.editJurisdictionForm.controls['password'].setValue(
            data.password
          );
          this.editJurisdictionForm.controls['confirmPassword'].setValue(
            data.password
          );
          this.editJurisdictionForm.controls['confirmPassword'].setValue(
            data.password
          );
          this.editJurisdictionForm.controls['email'].setValue(data.email);
          this.editJurisdictionForm.controls['email2'].setValue(data.email2);
          this.editJurisdictionForm.controls['email3'].setValue(data.email3);
          this.editJurisdictionForm.controls['address'].setValue(data.address);
          this.editJurisdictionForm.controls['country'].setValue(data.country);
          this.editJurisdictionForm.controls['city'].setValue(data.city);
          this.editJurisdictionForm.controls['zipCode'].setValue(data.zipCode);
          this.editJurisdictionForm.controls['phone'].setValue(data.phone);
          this.editJurisdictionForm.controls['phone2'].setValue(data.phone2);
          this.editJurisdictionForm.controls['cellPhone'].setValue(
            data.cellPhone
          );
          this.editJurisdictionForm.controls['commission'].setValue(
            data.commission
          );
          this.editJurisdictionForm.controls['notes'].setValue(data.notes);
          this.editJurisdictionForm.controls['whatsapp'].setValue(
            data.whatsapp
          );
          this.editJurisdictionForm.controls['gender'].setValue(data.gender);
          this.editJurisdictionForm.controls['birthDate'].setValue(
            data.birthDate
          );
          this.editJurisdictionForm.controls['deleted'].setValue(data.deleted);
          this.modalEditJurisdiction.show();
          this.editableJurisdiction.permits = data.permits;
          this.newPermitChk1.toArray().forEach((chkItem) => {
            if (chkItem.checked) {
              chkItem.toggle();
            }
          });
          this.newPermitChk1.toArray().forEach((chkItem) => {
            if (data.permits != null && data.permits.length > 0) {
              data.permits.forEach((permit) => {
                if ('new.' + permit.name == chkItem.id && !chkItem.checked) {
                  chkItem.toggle();
                }
              });
            }
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  PermitLockAndUnlock() {
    if (this.userEdit == undefined) return false;
    if (!this.userEdit.deleted) {
      if (this.LockAccessJuri) return true;
    } else if (this.userEdit.deleted) {
      if (this.UnlockAccessJuri) return true;
    } else return false;
  }

  closeEditModal() {
    this.modalEditJurisdiction.hide();
  }

  editJursdiction(formData, jurisdiction): void {
    let permit = new Array<Permit>();
    let PermitAdjust = new Array<Permit>();
    if (this.validateEditForm(this.editJurisdictionForm)) {
      this.editableJurisdiction.password = formData.password;
      this.editableJurisdiction.name = formData.name;
      this.editableJurisdiction.lastName = formData.lastName;
      this.editableJurisdiction.email = formData.email;
      this.editableJurisdiction.email2 = formData.email2;
      this.editableJurisdiction.email3 = formData.email3;
      this.editableJurisdiction.gender = formData.gender;
      this.editableJurisdiction.birthDate = formData.birthDate;
      this.editableJurisdiction.country = formData.country;
      this.editableJurisdiction.city = formData.city;
      this.editableJurisdiction.address = formData.address;
      this.editableJurisdiction.zipCode = formData.zipCode;
      this.editableJurisdiction.whatsapp = formData.whatsapp;
      this.editableJurisdiction.cellPhone = formData.cellPhone;
      this.editableJurisdiction.commission = formData.commission;
      this.editableJurisdiction.phone = formData.phone;
      this.editableJurisdiction.phone2 = formData.phone2;
      this.editableJurisdiction.deleted = formData.deleted;
      this.editableJurisdiction.notes = formData.notes;
      //this.editableJurisdiction.notes = formData.notes;
      this.newPermitChk1.toArray().forEach((element) => {
        if (element.checked) {
          if (this.permitsList) {
            permit.push(
              this.permitsList.find((x) => 'new.' + x.name == element.id)
            );
          }
        }
      });
      this.editableJurisdiction.permits.forEach((x) => {
        if (this.permitsList.find((y) => y.name == x.name) == null)
          PermitAdjust.push(x);
      });
      permit.forEach((x) => {
        PermitAdjust.push(x);
      });
      if (this.EditJurisdictionPermit)
        this.editableJurisdiction.permits = PermitAdjust;
      else this.editableJurisdiction.permits = this.userEdit.permits;
      // if (jurisdiction == 6) {
      //   var CouponValue = Number(
      //     (document.getElementById('CouponValueId') as HTMLInputElement).value
      //   );
      //   this.jurisdictionsService
      //     .saveJurisdictionCoupon(
      //       this.editableJurisdiction.id,
      //       this.jurisdictionCoupon,
      //       CouponValue
      //     )
      //     .subscribe(
      //       (data) => {
      //         //...
      //       },
      //       (error) => {
      //         //...
      //       }
      //     );
      // }

      this.loginService
        .updateUser(this.editableJurisdiction.id, this.editableJurisdiction)
        .subscribe(
          (message: any) => {
            this.toastService.showNotification({
              message: this.translateService.instant(
                'pg-jurisdiction.edited-successfull-info'
              ),
              type: 'success'
            });
            this.modalEditJurisdiction.hide();
            this.hasChangedRoundStylePermitts = false;
          },
          (error) => {
            this.toastService.showNotification({
              message: this.translateService.instant(
                'pg-jurisdiction.edited-failed'
              ),
              type: 'danger'
            });
            this.hasChangedRoundStylePermitts = false;
          }
        );
    } else {
      this.toastService.showNotification({
        message: this.translateService.instant('pg-jurisdiction.edited-failed'),
        type: 'danger'
      });

      this.hasChangedRoundStylePermitts = false;
    }
  }

  generateCoupon() {
    this.jurisdictionsService.generateJurisdictionCoupon().subscribe(
      (data) => {
        this.jurisdictionCoupon = data.code;
      },
      (error) => {
        this.toastService.showNotification({
          message: 'Não foi possível gerar novo código de cupom.',
          type: 'danger',
        });
      }
    );
  }

  reloadComponent() {
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentRoute);
    })
  }

  onPermitClick(permit) {
    if (permit.name == "manageStyles") {
      this.openManageStylePermitModal();
    }
  }

  openManageStylePermitModal() {
    document.getElementById('StyleConfig')!.style.display = 'none';
    this.manageStyleModal.show();
    this.roundStyleService.GetRoundStyles()
      .subscribe((res: any) => {
        this.roundsStylesToPermit = this.fixPermittedUsers(res);
        this.tempRoundsStylesToPermit = JSON.parse(JSON.stringify(this.roundsStylesToPermit));
      });
  }

  onChangeCheckboxValue(roundStyle) {
    let editableJurisdictionId = this.editableJurisdiction.id;
    let alreadyPermittedUser = roundStyle.permittedUsers.find(x => Number(x) === editableJurisdictionId) ? true : false;

    if (roundStyle.checked && !alreadyPermittedUser) {
      roundStyle.permittedUsers.push(editableJurisdictionId.toString());
    } else {
      roundStyle.permittedUsers = roundStyle.permittedUsers.filter(x => Number(x) != editableJurisdictionId);
    }
  }

  closeManageStylePermitModal() {
    this.verifyManageStylePermit(this.roundsStylesToPermit);
    this.manageStyleModal.hide();
    document.getElementById('StyleConfig')!.style.display = 'block';
    this.hasChangedRoundStylePermitts = false;
  }

  verifyManageStylePermit(roundStyleList) {
    for (let index = 0; index < roundStyleList.length; index++) {
      let roundStyle = roundStyleList[index];
      let editableJurisdictionId = this.editableJurisdiction.id;
      let hasManageRoundStylePermmit = roundStyle.permittedUsers.find(x => Number(x) === editableJurisdictionId) ? true : false;
      let checkbox = this.newPermitChk1["_results"].find(x => x.id === "new.manageStyles");

      if (hasManageRoundStylePermmit) {
        if (!checkbox.checked) {
          checkbox.toggle();
        } 

        return
      } 

      if (checkbox.checked) {
        checkbox.toggle();
      } 
    }
  }

  onSaveRoundStylePermit() {
    for (let index = 0; index < this.tempRoundsStylesToPermit.length; index++) {
      const roundStyle = this.tempRoundsStylesToPermit[index];
      roundStyle.permittedUsers = roundStyle.permittedUsers.join("|");

      if (!roundStyle.permittedUsers.endsWith("|")) {
        roundStyle.permittedUsers += "|";
      }
    }

    this.roundStyleService.UpdateRoundStylePermits(this.tempRoundsStylesToPermit)
    .subscribe(updatedRoundStyles => {
      this.roundsStylesToPermit = this.fixPermittedUsers(updatedRoundStyles);
      this.tempRoundsStylesToPermit = JSON.parse(JSON.stringify(this.roundsStylesToPermit));

      this.manageStyleModal.hide();
      document.getElementById('StyleConfig')!.style.display = 'block';
      this.verifyManageStylePermit(this.roundsStylesToPermit);
      this.hasChangedRoundStylePermitts = true;
    }, error => {
      console.log(error);
      this.hasChangedRoundStylePermitts = false;
    });
  }

  fixPermittedUsers(roundStylesList) {
    for (let index = 0; index < roundStylesList.length; index++) {
      let roundStyle = roundStylesList[index];
      roundStyle.permittedUsers = roundStyle.permittedUsers.split("|").filter(Boolean);
      roundStyle.checked = roundStyle.permittedUsers.find(x => Number(x) === this.editableJurisdiction.id) ? true : false;
    }

    return roundStylesList;
  }

}
