<style>
    .tdUserName {
        cursor: pointer;
    }
</style>
<div id="topbar">
    <ol class="breadcrumb">
        <li class="crumb-active">
            <a href="#">{{ 'Withdrawals Requests' | translate }}</a>
        </li>
    </ol>
</div>
<div id="content">
    <div class="panel">
        <div class="panel-heading">
            <div class="panel-title row">
                <div class="col-md-9">
                    <span class="material-icons default-icon">euro</span>
                    {{ 'Withdrawals Requests' | translate }}
                </div>

            </div>
        </div>
        <div class="panel-body">
            <div style="text-align: right;">
                <mat-form-field>
                    <mat-label>{{ 'Search' | translate }}...</mat-label>
                    <input matInput (keyup)="applyFilter($event)" #input>
                </mat-form-field>
            </div>

            <div class="table-responsive" style="overflow: auto;white-space: normal;word-wrap: break-word !important;">
                <table class="table table-bordered" mat-table [dataSource]="dataSource" matSort style="width:100%;">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('ID' | translate).toUpperCase() }}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="userName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('username' | translate).toUpperCase()
                            }} </th>
                        <td style="cursor: pointer;" mat-cell *matCellDef="let element"
                            (click)="userDetail(element.userId)">
                            {{element.userName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="createdDateStr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('pg-home.creation' |
                            translate).toUpperCase()}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdDateStr}} </td>
                    </ng-container>

                    <ng-container matColumnDef="responseDateStr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('Last Update' |
                            translate).toUpperCase()}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.responseDateStr}} </td>
                    </ng-container>



                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('Amount' | translate).toUpperCase()}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.amount *
                            Currency.quote | currency:'':'' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="statusStr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('Status' | translate).toUpperCase()}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.statusStr}} </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('Description' |
                            translate).toUpperCase()}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> {{ ('Action' | translate).toUpperCase()}} </th>
                        <td mat-cell *matCellDef="let element">
                            <button style="width: 27px;" type="button" (click)="getWithdrawDetail(element.id)"
                                data-toggle="modal" data-target="#exampleModal2" class="btn btn-success btn-sm px-2"><i
                                    class="fas fa-edit" aria-hidden="true"></i></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [style.background]="getTableBackground(row.type)"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="exampleModalLabel2">{{'Attention' | translate}}</h4>
                <button #confirmationModel type="button" class="close pull-right" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form">
                    <div class="row">
                        <div class="col-md-2 font-weight-bold">ID</div>
                        <div class="col-md-3">
                            {{request?.id}}
                        </div>
                        <div class="col-md-3 font-weight-bold">{{'username' | translate}}</div>
                        <div class="col-md-4">
                            <label for="custNameIDD"> {{request?.userName}}</label>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-2 font-weight-bold">{{'Amount' | translate}}</div>
                        <div class="col-md-3">
                            <label for="custNameIDD">{{Currency.currencySymbol}} {{ requestAmount *
                                Currency.quote | currency:'':''}}</label>
                            <!--<label for="custNameIDD">{{Currency.currencySymbol}} {{request?.amount * Currency.quote |
                                currency:'':''}}</label>-->
                        </div>
                        <div class="col-md-3 font-weight-bold">{{'Last Update' | translate}}</div>
                        <div class="col-md-4">
                            <label for="custNameIDD"> {{request?.responseDateStr}}</label>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-2 font-weight-bold">{{ 'Bank' | translate }}</div>
                        <div class="col-md-3">
                            <label for="custNameIDD">{{requestBank | translate }}</label>
                        </div>
                        <div class="col-md-3 font-weight-bold">{{ 'Agency' | translate }}</div>
                        <div class="col-md-4">
                            <label for="custNameIDD"> {{ requestAgency | translate }}</label>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-2 font-weight-bold">{{ 'Type' | translate }}</div>
                        <div class="col-md-3">
                            <label for="custNameIDD">{{requestAccountType | translate }}</label>
                        </div>
                        <div class="col-md-3 font-weight-bold">{{ 'Account' | translate }}</div>
                        <div class="col-md-4">
                            <label for="custNameIDD"> {{requestAccountNumber | translate }}</label>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-2">
                            <label for="ttIDD"> {{'Status' | translate}}</label>
                        </div>
                        <div class="col-md-10">
                            <select [(ngModel)]="requestStatus" class="form-control">
                                <option [ngValue]="-1">Please select</option>
                                <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                                <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                                <option [ngValue]="2" style="background-color: red;">Rejected</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-12">
                            <label for="ATDIDD">{{'Description' | translate}}</label>
                            <textarea [(ngModel)]="requestDescription" class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" data-dismiss="modal">{{'closebtn' | translate}}</button>
                <button type="button" class="btn btn-success" (click)="updateWithdrawRequest()" data-dismiss="Save"
                    data-toggle="modal" data-target="#exampleModal3">{{'Confirm' | translate}}</button>
            </div>
        </div>
    </div>
</div>