<style>
  .sortable tr {
    cursor: pointer;
  }
</style>
<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'Document List' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title row">
        <div class="col-md-9">
          <span class="material-icons default-icon">search</span>
          {{ 'Document List' | translate }}
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div style="text-align: right;">
        <mat-form-field>
          <mat-label>{{ 'Search' | translate }}...</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
      </div>
      <div class="table-responsive" style="overflow: auto;">
        <table class="sortable" mat-table [dataSource]="dataSource" style="width:100%;">

          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef> Username </th>
            <td mat-cell *matCellDef="let element">{{element.userName}} </td>
          </ng-container>
          <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef> {{'common.fileName' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.fileName}} </td>
          </ng-container>
          <ng-container matColumnDef="typeDesc">
            <th mat-header-cell *matHeaderCellDef> {{'Type' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.typeDesc}} </td>
          </ng-container>
          <ng-container matColumnDef="statusDesc">
            <th mat-header-cell *matHeaderCellDef> {{'Status' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.statusDesc}} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{ ('Description' | translate) }} </th>
            <td mat-cell *matCellDef="let element">
              {{element.description}}

            </td>
          </ng-container>

          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef> {{ ('CreatedDate' | translate)}} </th>
            <td mat-cell *matCellDef="let element"> {{element.createdDateStr}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <ng-container *ngIf="PlayerEditPermit; else templateA">
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="userDetail(row.userId)"></tr>
          </ng-container>
          <ng-template #templateA>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: default;"></tr>
          </ng-template>
          <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="userDetail(row.userId)"></tr> -->
        </table>

        <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>