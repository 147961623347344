import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDirective } from 'angular-bootstrap-md';
import { Partner} from  '../../interface/Partner/Partner';
import { PartnersService } from '../../services/partners.service'
import { PlayerService } from 'src/app/services/player.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-apipartners',
  templateUrl: './apipartners.component.html',
  styleUrls: ['./apipartners.component.css'],
})
export class APIPartnersComponent implements OnInit {
  @ViewChild('modalPartner', { static: true }) modalPartner: ModalDirective;

  constructor(private partnerService: PartnersService,
              private playerService: PlayerService,
              private toastService: ToastService,
              private translateService: TranslateService,
              private route: Router,
              private datePipe: DatePipe) { }

  statusDictionary : {[key : number]: string} = {
    0: "Active",
    1: "Closed",
    2: "ToReceive",
    3: "Suspended",
    99: "Test"
  };

  errorMessage:string = '';

  partnerBase: Partner = {
    id: null,
    country: null,
    partnerId: null,
    userAdminId: null,
    callbackURL: null,
    name: null,
    contractStartDate: null,
    contractEndDate: null,
    status: 1,
    ipAddress: null,
    userName: null
  };

  partner: Partner = this.partnerBase;

  newPartner: boolean = true;

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['Name', 'Juridisction', 'Status', 'Actions'];

  usersList: any[] = []
  userSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.getPartners();
    this.getPlayers();
  }

  async getPartners(){
    let baseData =  await this.partnerService.GetAllPartners();
    this.dataSource = new MatTableDataSource<any>(baseData);
  }

  async getPlayers(){
    this.usersList = await this.playerService.getClubs().toPromise();
    this.userSource = new MatTableDataSource<any>(this.usersList);
  }

  applyFilter(event) {
    let filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openModal(newPartner: boolean, partner: Partner = null){
    this.newPartner = newPartner;

    if(newPartner){
      this.partner = this.partnerBase;
    }else{
      this.partner.id = partner.id
      this.partner.name = partner.name;
      this.partner.userAdminId = partner.userAdminId;
      this.partner.contractStartDate = partner.contractStartDate;
      this.partner.contractEndDate = partner.contractEndDate;
      this.partner.ipAddress = partner.ipAddress;
      this.partner.callbackURL = partner.callbackURL;
      this.partner.status = partner.status;
      (document.getElementById("pickerEnd")as HTMLInputElement).value = this.datePipe.transform(partner.contractEndDate, 'dd/MM/yyyy');
    }

    this.modalPartner.show();
  }

  applyFilterUsers(event){
    let filterValue = (event.target as HTMLInputElement).value;
    this.userSource.filter = filterValue.trim().toLowerCase();
    this.partner.userAdminId = null;
  }

  setPartnerUserId(id:number){
    this.partner.userAdminId = id;
  }

  createPartner(){
    if(!this.validatePartner()){
      this.toastService.showNotification({ message: this.translateService.instant(this.errorMessage), type: 'danger' });
      return;
    }

    this.partnerService.CreatePartner(this.partner)
    .subscribe( resp =>{
      this.reloadComponent();
    }, error =>{
      console.log(error);
      this.toastService.showNotification({ message: "Falha ao salvar parceiro", type: 'danger'});
    });
  }

  validatePartner():boolean{
    var dateStart = (document.getElementById("pickerStart")as HTMLInputElement).value;
    var dateEnd = (document.getElementById("pickerEnd")as HTMLInputElement).value;
    
    if(this.partner.userAdminId == null){
      var userPartner = this.userSource.filteredData.find(x =>  x.login == this.partner.userName);

      if(userPartner == null){
        this.errorMessage = "Jurisdiction is null";
        return false;
      }
      
      this.partner.userAdminId = userPartner.id;
    }

    if(this.partner.name == null){
      this.errorMessage = "Name is null";
      return false;
    }

    if(this.partner.callbackURL == null){
      this.errorMessage = "Callback Url is null";
      return false;
    }

    if(this.partner.ipAddress == null){
      this.errorMessage = "IP Address is null";
      return false;
    }

    if(dateStart == ''){
      this.errorMessage = "Date Start is null";
      return false;
    }

    if(dateEnd == ''){
      this.errorMessage = "Date End is null";
      return false;
    }

    this.partner.contractStartDate = this.parseDate(dateStart);
    this.partner.contractEndDate = this.parseDate(dateEnd);

    return true;
  }

  parseDate(dateStr){
    let [day, month, year] = dateStr.split('/');
    let baseDate = `${year}-${month}-${day}`;
    return baseDate;
  }

  reloadComponent() {
    const currentRoute = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigateByUrl(currentRoute);
    })
  }

  updatePartner(){
    if(!this.validateUpdatePartner()){
      this.toastService.showNotification({ message: this.translateService.instant(this.errorMessage), type: 'danger' });
      return;
    }

    this.partnerService.UpdatePartner(this.partner)
    .subscribe( resp =>{
      this.reloadComponent();
    }, error =>{
      console.log(error);
      this.toastService.showNotification({message: this.translateService.instant(error.message), type: 'danger'});
    });
  }

  validateUpdatePartner(){
    var dateEnd = (document.getElementById("pickerEnd")as HTMLInputElement).value;

    if(this.partner.callbackURL == null){
      this.errorMessage = "CallbackURL is null";
      return false;
    }

    if(this.partner.ipAddress == null){
      this.errorMessage = "Ip Address is null";
      return false;
    }

    if(this.partner.name == null){
      this.errorMessage = "Name is null";
      return false;
    }

    if(dateEnd == ''){
      this.errorMessage = "Date End is null";
      return false;
    }

    this.partner.contractEndDate = this.parseDate(dateEnd);

    return true
  }
}
