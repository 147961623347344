<div id="topbar">
    <ol class="breadcrumb">
        <li class="crumb-active">
            <a href="#">{{ 'Post Result' | translate }}</a>
        </li>
    </ol>
</div>
<div id="content">
    <div class="panel">
        <div class="panel-heading">
            <div class="panel-title row">
                <div class="col-md-9">
                    <span class="material-icons default-icon">done</span>
                    {{ 'Post Result' | translate }}
                </div>


            </div>
        </div>
        <mat-tab-group>
            <mat-tab label="{{ 'Post Result' | translate }}">
                <div class="row">
                    <mat-form-field class="postFilter filterField ml-3 mt-3" appearance="fill">
                        <mat-label>{{ 'Round' | translate }}</mat-label>
                        <input type="text" matInput (keyup)="filterRoundsToResult($event)" [matAutocomplete]="Jorge">
                        <mat-autocomplete autoActiveFirstOption #Jorge="matAutocomplete">
                            <mat-option *ngFor="let round of roundsToResultHelper" [value]="round.name"
                                (click)="SetEventsOrMatchs(round)">
                                {{round.name}} - {{round.number}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!--Static Style-->
                <div *ngIf="matchsToResult.length > 0">
                    <!--Rank Round-->
                    <div *ngIf="matchsToResult[0].awayName == ''">
                        <div class="panel-heading mb-2">
                            <div class="panel-title row">
                                <div class="col-md-9">
                                    <span class="material-icons default-icon">visibility</span>
                                    {{ "Rank Round" | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="gridRankRound fontweightChampionShip border-bottom pt-2 pb-2 marginChampionship">
                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{'Competitor' | translate}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{'Placing' | translate}}
                                </span>
                            </div>

                            <!-- <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{'Cancel' | translate}}
                                </span>
                            </div> -->
                        </div>

                        <div *ngFor="let match of matchsToResult; let i = index"
                            class="gridRankRound border-bottom pt-2 pb-2 marginChampionship">
                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{match.homeName}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <input type="number" min="0" [(ngModel)]="match.result">
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <mat-slide-toggle checked (click)="cancelMatch(i)"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>

                    <!--Championship-->
                    <div *ngIf="matchsToResult[0].awayName != ''">
                        <div class="panel-heading mb-2">
                            <div class="panel-title row">
                                <div class="col-md-9">
                                    <span class="material-icons default-icon">visibility</span>
                                    {{ "Championship" | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="gridChampionship fontweightChampionShip border-bottom pt-2 pb-2 marginChampionship">
                            <div>
                                <span>
                                    Date
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{'pg-round.homeTeam' | translate}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{'Placar' | translate}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    VS
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{'Placar' | translate}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{'pg-round.awayTeam' | translate}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{'Cancel' | translate}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{'pg-result.result' | translate}}
                                </span>
                            </div>
                        </div>

                        <div *ngFor="let match of matchsToResult; let i = index"
                            class="gridChampionship border-bottom pt-2 pb-2  marginChampionship">
                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{datePipe.transform(match.dateTime, 'dd/MM HH:mm')}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{match.homeName}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <input type="number" min="0" [(ngModel)]="match.goalsHomeTeam" style="width: 80%;"
                                    (keyup)="upDateResultChampionship(i)">
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    -
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <input type="number" min="0" [(ngModel)]="match.goalsAwayTeam" style="width: 80%;"
                                    (keyup)="upDateResultChampionship(i)">
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{match.awayName}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <mat-slide-toggle checked (click)="cancelMatch(i)"></mat-slide-toggle>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{match.result}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Dynamic Style-->
                <div *ngIf="eventsToResult.length > 0">
                    <div class="panel-heading mb-2">
                        <div class="panel-title row">
                            <div class="col-md-9">
                                <span class="material-icons default-icon">visibility</span>
                                {{ result.styleName | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="gridDynamicStyle border-bottom pt-2 pb-2 marginDefault fontweightChampionShip">

                        <div class="d-flex align-items-center justify-content-center">
                            <span>
                                {{"EventStyleName" | translate}}
                            </span>
                        </div>

                        <div class="d-flex align-items-center justify-content-center">
                            <span>
                                {{"average" | translate}}
                            </span>
                        </div>

                        <div class="d-flex align-items-center justify-content-center">
                            <span>
                                {{'pg-result.result' | translate}}
                            </span>
                        </div>
                    </div>

                    <div *ngFor="let event of eventsToResult; let i = index" class="defaultPadding">
                        <div class="gridDynamicStyle border-bottom pt-3 pb-3">
                            <div class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{event.name | translate}}
                                </span>
                            </div>

                            <div *ngIf="event.type != 2">
                                <span>

                                </span>
                            </div>

                            <div *ngIf="event.type == 2" class="d-flex align-items-center justify-content-center">
                                <span>
                                    {{event.odd}}
                                </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <mat-select [(ngModel)]="event.result" class="w-25 text-center backGroundSelectDefault"
                                    placeholder="{{'pg-result.result' | translate}}">

                                    <mat-option *ngIf="event.type == 0 || event.type == 1"  [value]="'1'">{{"1" | translate}}</mat-option>
                                    <mat-option *ngIf="event.type == 0"  [value]="'X'">{{"X" | translate}}</mat-option>
                                    <mat-option *ngIf="event.type == 0 || event.type == 1"  [value]="'2'">{{"2" | translate}}</mat-option>

                                    <mat-option *ngIf="event.type == 2" [value]="'1'">{{"Over" | translate}}</mat-option>
                                    <mat-option *ngIf="event.type == 2" [value]="'2'">{{"Under" | translate}}</mat-option>

                                    <mat-option *ngIf="event.type == 3" [value]="'1'">{{"Yes" | translate}}</mat-option>
                                    <mat-option *ngIf="event.type == 3" [value]="'2'">{{"No" | translate}}</mat-option>

                                    <mat-option *ngIf="event.type == 4" [value]="'1'">{{"Even" | translate}}</mat-option>
                                    <mat-option *ngIf="event.type == 4" [value]="'2'">{{"Odd" | translate}}</mat-option>

                                    <mat-option *ngIf="event.type == 5 || event.type == 6"  [value]="'1'">{{"Home" | translate}}</mat-option>
                                    <mat-option *ngIf="event.type == 6"  [value]="'X'">{{"Draw" | translate}}</mat-option>
                                    <mat-option *ngIf="event.type == 5 || event.type == 6"  [value]="'2'">{{"Visitant" | translate}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" mdbBtn class="btn btn-success marginChampionship mt-3 mb-3" mdbWavesEffect
                    (click)="frame.show()" *ngIf="matchsToResult.length > 0 || eventsToResult.length > 0">
                    {{ 'pg-post-result.publish-result' | translate }}
                </button>
            </mat-tab>

            <mat-tab label="{{'cancelPostTitle' | translate }}" id="test">
                <ng-container *ngIf="cancelResultPostedRound; else noPermitToCancelRound">
                    <div class="row mt-3 w-100">

                        <div class="row ml-4">
                            <mat-form-field class="postFilter" appearance="fill">
                                <mat-label>{{ 'Round' | translate }}</mat-label>
                                <input type="text" matInput (keyup)="fiterPostedRounds($event)"
                                    [matAutocomplete]="auto">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let roundsPt of finishedHelper" [value]="roundsPt.name"
                                        (click)="RoundPostedSelected = roundsPt">
                                        {{roundsPt.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="col-md-5">
                            <a type="button" color="waves-light button-font" style="background-color:#ff4b4b;"
                                class="btn mb-3" mdbWavesEffect data-dismiss="modal" ng-disabled="true"
                                (click)="modalPostedRound.show();getMatchFromRoundSelected(RoundPostedSelected.id)"
                                *ngIf="!loading">
                                {{'PostCancel' | translate }}</a>
                            <span class="spinner-border spinner-border-sm red-white ml-2 mb-3" *ngIf="loading"></span>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noPermitToCancelRound>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            <h1 style="color: black;">{{'PermissionMissing' | translate }} {{'AssistContat' | translate
                                }}</h1>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div mdbModal #frame="mdbModal" class="modal fade" scrollY="true" maxHeight="200" id="frameModalTop" tabindex="-2"
        role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
        [config]="{backdrop: false, ignoreBackdropClick: true}">
        <div class="modal-dialog moda-notify" role="document">
            <div class="modal-content bg-theme" style="height: 411px;max-height: 411px;overflow-x: auto;">
                <div class="modal-header text-center">
                    <h4 style="color: black;" class="modal-title w-100 font-weight-bold">{{
                        'pg-post-result.confirm-result' | translate }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body mx-3">
                    <div class="row">
                        <div *ngIf="this.result.style != 1" class="col-9 center-content">
                            <h4 style="color: black;">{{ 'pg-post-result.match' | translate }}</h4>
                        </div>

                        <div *ngIf="this.result.style == 1" class="col-9 center-content">
                            <h4 style="color: black;">{{ 'pg-post-result.match' | translate }}</h4>
                        </div>

                        <div class="col-3 center-content">
                            <h4 style="color: black;">{{ 'pg-post-result.result' | translate }}</h4>
                        </div>
                    </div>

                    <ng-container *ngIf="this.result.style != 1">
                        <div class="row" *ngFor="let game of games; let i = index">
                            <ng-container *ngIf="game.status == 1;else cancelledGame">
                                <div *ngIf="!isRankRound" class="col-md-9">
                                    {{game.homeName}} {{game.homeTeamScore}} - {{game.awayTeamScore}} {{game.awayName}}
                                </div>
                                <div *ngIf="isRankRound" class="col-md-9">
                                    {{game.homeName}}
                                </div>
                                <div *ngIf="!isRankRound" class="col-md-3 center-content">
                                    {{setResult(game.homeTeamScore, game.awayTeamScore)}}
                                </div>
                                <div *ngIf="isRankRound" class="col-md-3 center-content">
                                    {{scoreArray[i]}}°
                                </div>
                            </ng-container>
                            <ng-template #cancelledGame>
                                <div *ngIf="!isRankRound" class="col-md-9">
                                    <strong style="color: #ff0000;">{{game.homeName}} X {{game.awayName}}</strong>
                                </div>
                                <div *ngIf="isRankRound" class="col-md-9">
                                    <strong style="color: #ff0000;">{{'pg-manage-ticket.canceled' | translate}}</strong>
                                    {{game.homeName}}
                                </div>
                                <div *ngIf="!isRankRound" class="col-md-3 center-content">
                                    <strong style="color: #ff0000;">{{'pg-manage-ticket.canceled' | translate}}</strong>
                                </div>
                                <div *ngIf="isRankRound" class="col-md-3 center-content">
                                    <strong style="color: #ff0000;">{{'pg-manage-ticket.canceled' | translate}}</strong>
                                    {{scoreArray[i]}}°
                                </div>
                            </ng-template>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="this.result.style == 1">
                        <div class="row" *ngFor="let event of eventsToResult; let i = index">
                            <div class="col-md-7">
                                {{ event.name | translate }}
                            </div>
                            <div class="col-md-2">
                                &nbsp;
                            </div>
                            <div class="col-md-3 center-content">
                                {{event.result}}
                            </div>
                        </div>
                    </ng-container>

                </div>
                <div class="modal-footer d-flex justify-content-center">
                    <a style="cursor: pointer; color: blue" (click)="checkData()">
                        PIN
                    </a>

                    <button mdbBtn style="background-color:#ff8e38;color: white" class="waves-light button-font"
                        (click)="reloadComponent()" mdbWavesEffect>{{ 'pg-post-result.back' | translate
                        }}</button><br />
                    <button mdbBtn style="background-color:#ff8e38;color: white" class="waves-light button-font"
                        [disabled]="submitResult" (click)="postResultRound()" mdbWavesEffect>{{ 'pg-post-result.confirm'
                        | translate }}</button><br />
                </div>

            </div>
        </div>
    </div>

    <div mdbModal #CancelPostedRound="mdbModal" class="modal fade" scrollY="true" maxHeight="250" id="frameModalTop"
        tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
        [config]="{backdrop: false, ignoreBackdropClick: true}">

        <mat-form-field class="competition-full-width code-field mb-2">
            <mat-select [(value)]="PostedRoundList" id="ddlRound">
                <mat-option *ngFor="let round of PostedRoundList" [value]="PostedRoundList.id"
                    (click)="loadGames(round.id, round.rankRound,round.singleMatchRound, round.jiuJitsuRound)">
                    {{round.name}} - {{round.number}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="modal-dialog moda-notify" role="document">
            <div class="modal-content bg-theme" id="ModalStyleCancelPost">
                <div class="modal-header text-center">
                    <h4 id="TitleCancelPost" class="modal-title w-100 font-weight-bold">{{ 'PostCancel' | translate }}
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="CancelPostedRound.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body mx-3">
                    <div class="row">
                        <div *ngIf="!isPostedSingleRound" class="col-9 center-content">
                            <h4 style="color: black;">{{ 'pg-post-result.match' | translate }}</h4>
                        </div>
                        <div *ngIf="!isPostedSingleRound" class="col-3 center-content">
                            <h4 style="color: black;">{{ 'pg-post-result.result' | translate }}</h4>
                        </div>
                        <div class="col-md-3" *ngIf="isPostedSingleRound"></div>
                        <div class="col-md-6" *ngIf="isPostedSingleRound">
                            &nbsp;
                        </div>
                        <div class="col-md-3" *ngIf="isPostedSingleRound">
                            <h4 style="color: black;">{{ 'pg-post-result.result' | translate }}</h4>
                        </div>
                    </div>
                    <ng-container *ngIf="!isPostedSingleRound">
                        <div class="row" *ngFor="let game of RoundPostedMatch; let i = index">
                            <div *ngIf="!isPostedRankRound" class="col-md-9">
                                {{game.homeName}} {{game.homeTeamScore}} - {{game.awayTeamScore}} {{game.awayName}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-9">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResult(game.homeTeamScore, game.awayTeamScore)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{i+1}}°
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isPostedSingleRound">
                        <div class="row" *ngFor="let game of RoundPostedMatch; let i = index">

                            <div class="col-md-7">
                                {{ 'Final Score' | translate }} ({{game.homeTeamScore}} - {{game.awayTeamScore}})
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResult(game.homeTeamScore, game.awayTeamScore, 0)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'More Ball Possession' | translate }} ({{game.moreBallPossessionHome}}% -
                                {{game.moreBallPossessionAway}}%)
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResult(game.moreBallPossessionHome, game.moreBallPossessionAway, 1)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Total Goals' | translate }} ({{game.totalGoals}})
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{ setResultOverUnder((game.homeTeamScore + game.awayTeamScore),
                                game.totalGoals,
                                2)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Total Corners' | translate }} ({{game.totalCorners}})
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{ setResultOverUnder((game.totalCornerHome + game.totalCornerAway),
                                game.totalCorners, 3)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Yellow Cards' | translate }} ({{game.yellowCards}})
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{ setResultOverUnder((game.yellowCardsHome + game.yellowCardsAway),
                                game.yellowCards,
                                4)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Corner Home Team' | translate }} ({{game.cornerHomeTeam}})
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{ setResultOverUnder(game.totalCornerHome, game.cornerHomeTeam, 5)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Corner Away Team' | translate }} ({{game.cornerAwayTeam}})
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{ setResultOverUnder(game.totalCornerAway, game.cornerAwayTeam, 6)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Total Faults' | translate }} ({{ game.totalFaults }})
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResultOverUnder((game.totalFaultHome + game.totalFaultAway), game.totalFaults, 7)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Shoots On Target' | translate }} ({{game.shootsOnTarget}})
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResultOverUnder((game.shootsOnTargetHome + game.shootsOnTargetAway),
                                game.shootsOnTarget, 8)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Penalty on Favor (90 min)' | translate }}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResultYN(game.penaltyOnFavor, 9)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Goal in the 1st Half' | translate }}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResultYN(game.goalIn1stHalf, 10)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'Goal in the 2nd Half' | translate }}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResultYN(game.goalIn2ndHalf, 11)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                            <div class="col-md-7">
                                {{ 'GG (Both Score)' | translate }}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResultYN(game.ggBothScore, 12)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>
                            <div class="col-md-7">
                                {{ 'Red Card' | translate }}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-2">
                                &nbsp;
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-6">
                                {{game.homeName}}
                            </div>
                            <div *ngIf="!isPostedRankRound" class="col-md-3 center-content">
                                {{setResultYN(game.redCards, 13)}}
                            </div>
                            <div *ngIf="isPostedRankRound" class="col-md-3 center-content">
                                {{scoreArray[i]}}°
                            </div>

                        </div>
                    </ng-container>

                </div>
                <div class="modal-footer d-flex justify-content-center">
                    <button mdbBtn style="background-color:#ff8e38;color: white" class="waves-light button-font"
                        (click)="modalPostedRound.hide()" mdbWavesEffect>{{ 'pg-post-result.back' | translate
                        }}</button><br />
                    <button mdbBtn style="background-color:#ff8e38;color: white" class="waves-light button-font"
                        (click)="modalPostedRound.hide();cancelPostedRound(RoundPostedSelected.id); loading = true;"
                        mdbWavesEffect>{{
                        'pg-post-result.confirm' | translate }}</button><br />
                </div>

            </div>
        </div>
    </div>

    <div mdbModal #avoidCreation="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
        <div class="modal-dialog modal-notify modal-danger" role="document">
            <div class="modal-content bg-theme">
                <div class="modal-header text-center">
                    <h4 class="modal-title w-100 font-weight-bold">{{ 'pg-round.fail' | translate }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="closeAvoidCreation()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-3">
                    <div class="md-form mb-5">

                        <p style="color: black">
                            {{ 'pg-round.fail-avoid' | translate }}
                        </p>

                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-center">
                    <button mdbBtn class="waves-light button-bg-theme button-font" (click)="closeAvoidCreation()"
                        mdbWavesEffect>OK</button><br />
                </div>

            </div>
        </div>
    </div>

    <!--Modal Standard Danger Message-->
    <div mdbModal #frameMessageDanger="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
        <div class="modal-dialog modal-notify modal-danger" role="document" id="msgModalStandardDanger">
            <!--<div class="modal-content  bg-theme" style="height: 378px;
max-height: 400px;">-->
            <div class="modal-content  bg-theme">
                <div class="modal-header text-center">
                    <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="frameMessageDanger.hide();">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-3 text-center">


                    <p style="color: black" id="msg" [innerHtml]="msgToAdd"></p>


                </div>
                <div class="modal-footer d-flex justify-content-center">

                    <a type="button" color="waves-light button-font" style="background-color:#ff4b4b;" class="btn"
                        mdbWavesEffect (click)="reloadComponent();" data-dismiss="modal">OK</a>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal Password -->
<div mdbModal #framePassword="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify" role="document" id="msgModalStandard">
        <div class="modal-content  bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold">{{ 'pg-post-result.insert-pin' |
                    translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="framePassword.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">
                <input type="password" id="password" class="form-control" placeholder="******">
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <div class="row">
                    <div class="col-sm-6 justify-content-end">
                        <a type="button" color="waves-light button-font"
                            style="padding:.54rem 2.14rem; background-color:#33a0df;" class="btn" mdbWavesEffect
                            (click)="checkPIN()" data-dismiss="modal">OK</a>
                    </div>
                    <div class="col-sm-6">
                        <a style="cursor: pointer;" (click)="sendPinEmail()" data-dismiss="modal">Recuperar
                            PIN</a><br />
                        <a style="cursor: pointer;" (click)="openChangePin()" data-dismiss="modal">Alterar Pin</a><br />
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Modal Change PIN -->
<div mdbModal #frameChangePin="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify" role="document" id="msgModalStandard">
        <div class="modal-content  bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold">{{ 'pg-post-result.change-pin-title' | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="frameChangePin.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">
                <div class="row">
                    <div class="col-4 text-right">
                        {{ 'pg-post-result.change-pin-old' | translate }}
                    </div>

                    <div class="col-8">
                        <input type="password" id="old-pin" class="form-control" placeholder="******">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 text-right">
                        {{ 'pg-post-result.change-pin-new' | translate }}
                    </div>
                    <div class="col-8">
                        <input type="password" id="new-pin" class="form-control" placeholder="******">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 text-right">
                        {{ 'pg-post-result.change-pin-confirm' | translate }}
                    </div>
                    <div class="col-8">
                        <input type="password" id="confirm-pin" class="form-control" placeholder="******">
                    </div>
                </div>

            </div>
            <div class="modal-footer d-flex justify-content-center">
                <div class="row">
                    <div class="col-sm-6 justify-content-end">
                        <a type="button" color="waves-light button-font"
                            style="padding:.54rem 2.14rem; background-color:#ff4b4b;" class="btn" mdbWavesEffect
                            (click)="changePIN()" data-dismiss="modal">
                            {{ 'pg-post-result.change-pin-title' | translate }}</a>
                    </div>
                    <div class="col-sm-6">
                        <a type="button" color="waves-light button-font"
                            style="padding:.54rem 2.14rem; background-color:#33a0df;" class="btn" mdbWavesEffect
                            (click)="frameChangePin.hide()" data-dismiss="modal">
                            {{ 'pg-post-result.back' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal Change PIN -->

<!--Modal Jackpot Preview-->
<div mdbModal #framePreview="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify" role="document" id="msgModalStandard">
        <div class="modal-content  bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold">
                    {{ 'Preview' | translate }}
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="framePreview.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">
                <p style="color: black; text-align: center; font-weight: bold;">
                    {{ 'pg-post-result.jackpot-winners' | translate }}
                </p>
                <p *ngFor="let winner of possibleWinners" style="color: black">
                    {{winner.coupon}} - {{winner.name}} - {{Currency.currencySymbol}} {{winner.value.toFixed(2) *
                    Currency.quote | currency:'':''}}
                </p>
                <p style="color: black" id="msg" [innerHtml]="msgToAdd"></p>
            </div>
            <div class="modal-footer d-flex justify-content-center">

                <a type="button" color="waves-light button-font"
                    style="padding:.54rem 2.14rem; background-color:#33a0df;" class="btn" mdbWavesEffect
                    (click)="openBets()" data-dismiss="modal">{{ 'pg-post-result.bets-access' | translate
                    }}</a>
            </div>

        </div>
    </div>
</div>

<!--Modal Standard Message-->
<div mdbModal #frameMessage="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
        <div class="modal-content  bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="frameMessage.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">


                <p style="color: black" id="msg" [innerHtml]="msgToAdd"></p>


            </div>
            <div class="modal-footer d-flex justify-content-center">

                <a type="button" color="waves-light button-font"
                    style="padding:.54rem 2.14rem; background-color:#33a0df;" class="btn" mdbWavesEffect
                    (click)="frameMessage.hide()" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>
<!--Modal Standard Message-->

<!--Modal Desfazer postagem-->
<div mdbModal #CancelResultModal="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
        <div class="modal-content bg-theme">

            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" style="color: white; padding-top: 1rem;">
                    {{"PostCancelOk" | translate}}
                </h4>
            </div>

            <div class="modal-body mx-3">
                {{"PostCancelMessage" | translate}}
            </div>

            <div class="modal-footer d-flex justify-content-center">
                <a type="button" color="waves-light button-font"
                    style="padding: .54rem 2.14rem; background-color: #33a0df;" class="btn" mdbWavesEffect
                    (click)="reloadComponent()">
                    OK
                </a>
            </div>
        </div>
    </div>
</div>
<!--Modal Desfazer postagem--->
