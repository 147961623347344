<div class="text-break p-0" id="app-sidebar-main" class="height-body">
  <aside id="sidebar_left" class="height-body">
    <ng-container *ngIf="userAdmin.jurisdictionId == 6;else refCodeDissable">
      <div class="d-flex flex-column mb-2" *ngIf="domains.length > 0">
        <div class="domain-title">
          <div> Skin</div>
        </div>
        <div class="qrcode-area">
          <div class="qrCode-area_text">
            {{domains[domainsIndex].name}}
          </div>
          <div class="qrcode-area_actions">
            <button (click)="addInDomainsIndex(-1)" [disabled]="DisableButton(0)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="17">
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
              </svg> 
            </button>
            <div class="qrCode-area_img">
              <img src="{{domains[domainsIndex].qrCodeLink}}" class="QrCodeWidith">
            </div>
            <button (click)="addInDomainsIndex(1)" [disabled]="DisableButton(domains.length - 1)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="17">
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
              </svg>
            </button>
          </div>
          <div>
            <button (click)="downloadImage(domains[domainsIndex].qrCodeLink)" class="buttonStyle">
              {{ 'friendCode.qrDownload' | translate}}
            </button>
          </div>
        </div>
        <div class="link-area">
          <div class="link-area_view">
            <p>Link:</p>
            <input class="link-area_input" type="text" id="LinkCodeCopy" value="{{domains[domainsIndex].url}}" disabled>
          </div>
          <button class="buttonStyle" (click)="copyLinkRefCode()">
            {{"friendCode.copyLink" | translate}}
          </button>
        </div>
      </div>
      <div *ngIf="qrCoceLoading" class="are-loader">
        <div class="loader"></div>
      </div>
    </ng-container>
    <ng-template #refCodeDissable>
      <div class="user-info">
        <h5 class="mb-0 fontStyle" style="cursor: default;" *ngIf="userAdmin">{{ userAdmin.login }}</h5>
        <span class="cursor fs12 fontStyle" (click)="userLogout()">{{ 'logout.title' | translate }}</span>
      </div>
    </ng-template>
    <div class="user-divider"></div>
    <ul class="sidebar-menu">
      <li routerLinkActive="active" class="nav-item">
        <a class="nav-link" [routerLink]="'/home'">
          <i class="material-icons">dashboard</i>
          <span>{{ 'dashboard.title' | translate }}</span>
        </a>
      </li>
      <li *ngFor="let menuItem of menuItems" class="nav-item">
        <ng-container *ngIf="CheckElement(menuItem);else default">
          <a class="nav-link" type="button" data-toggle="collapse" [attr.data-target]="'#' + menuItem.title"
            aria-expanded="false" aria-controls="collapseExample" (click)="onNavClicked(menuItem.title)">
            <i class="material-icons">{{menuItem.icon}}</i>
            <span>{{menuItem.title | translate }}</span>
          </a>
        </ng-container>
        <ng-template #default>

        </ng-template>
        <ul class="collapse list-unstyled" [id]="menuItem.title">
          <li routerLinkActive="active" *ngFor="let submenu of menuItem.children" class="nav-subitem">
            <a class="nav-link" [routerLink]="[submenu.path]">
              <p>{{submenu.title | translate}}</p>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </aside>
</div>
<div class="p-0 h-100" id="app-sidebar-out" (click)="fadeoutSidebar()">
</div>