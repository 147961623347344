import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Currency } from 'src/app/interface/currency.interface';
import { InteractionService } from 'src/app/services/interaction.service';
@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class WithdrawComponent implements OnInit {
  displayedColumns: string[] = ['userName', 'date', 'amount', 'status', 'description'];
  dataSource = new MatTableDataSource<any>([]);

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  start_date: any = new Date();
  end_date: any = new Date();
  seletedJurisdiction: any;
  allowUsersList: any = [];
  PresentUserLoger: any =[];
  jurisdictions: any;
  selectedJurisdictionLevel: any;
  Currency: Currency = <Currency>{};
  data: any;

  startDate = new FormControl(new Date());
  picker1: any;
  endDate = new FormControl(new Date());
  picker2: any;

  selected: { startDate: Moment, endDate: Moment }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  userAdmin: any;
  usersList: any = [];
  constructor(
    public loginService: LoginService,
    private _snackBar: MatSnackBar,
    private _adapter: DateAdapter<any>,
    private interactionService: InteractionService
  ) {
    this.userAdmin = loginService.getUserInfoFromCookie();
  }
  ngOnInit(): void {
    this._adapter.setLocale('br');
    this.getJurisdictions()
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
        else {
          this.loginService.setGlobalCurrency();
        }
      });
  }

  applyBtn() {

  }

  getUsersList() {
    this.loginService.GetUsersList(this.userAdmin.id).subscribe(res => {
      let temUser = res.find(element => element.jurisdictionId == this.selectedJurisdictionLevel && element.id == this.userAdmin.id && element.login == this.userAdmin.login)
      if (temUser == null) {
        this.loginService.GetUserAssist(this.userAdmin.id).subscribe(x => {
          temUser = res.find(element => element.id == x.adminParentID && x.userName == this.userAdmin.login)
          this.PresentUserLoger.push(temUser)
          this.allowUsersList.push(temUser)
          this.usersList.push(temUser)

          this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
          this.loginService.getUsersTreeList(x.adminParentID)
            .subscribe(res => {
              res.forEach(element => {
                this.usersList.push(element)
              });
              this.seletedJurisdiction = -1
            })
        })
      } else {
        this.PresentUserLoger.push(temUser)
        this.allowUsersList.push(temUser)
        this.usersList.push(temUser)

        this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
        this.loginService.getUsersTreeList(temUser.id)
          .subscribe(res => {
            res.forEach(element => {
              this.usersList.push(element)
            });
            this.seletedJurisdiction = -1
          })
      }
    })
  }

  getJurisdictions() {
    this.loginService.getJurisdictions(parseInt(localStorage.getItem("currentUserJurisdictionId"))).subscribe(res => {
      this.jurisdictions = res
      this.selectedJurisdictionLevel = parseInt(localStorage.getItem("currentUserJurisdictionId"))
      this.getUsersList()
    })
  }

  filterBtn() {
    this.start_date = this.startDate.value
    this.end_date = this.endDate.value

    if (!this.start_date || !this.end_date) {
      this.message("Please select Date Range")
      return
    }
    else if (this.start_date > this.end_date) {
      this.message("Start Date cannot be higher than End Date")
      return
    }
    this.data = []
    if (this.seletedJurisdiction != -1) {
      this.getWithdrawFilter(this.seletedJurisdiction)
    } else {
      this.allowUsersList.forEach(element => {
        this.getWithdrawFilter(element.id)
      });
    }

  }

  getWithdrawFilter(id) {
    let withdrawFilter = {};
    withdrawFilter['jurisdiction'] = parseInt(id);
    withdrawFilter['dateStart'] = this.start_date;
    withdrawFilter['dateEnd'] = this.end_date;
    this.loginService.getWithdrawFilter(withdrawFilter).subscribe(res => {
      res.forEach(element => {
        element["date_date"] = new Date(element.responseDate).toLocaleDateString()
        element["date_time"] = new Date(element.responseDate).toLocaleTimeString()
      });
      this.data = this.data.concat(res)
      this.dataSource.data = this.data
      this.dataSource.paginator = this.paginator;
    })
  }

  jurisdictionLevelChange(event) {
    this.selectedJurisdictionLevel = event.target.value
    this.allowUsersList = []
    this.usersList.forEach(element => {
      if (element.jurisdictionId == this.selectedJurisdictionLevel) {
        if (parseInt(localStorage.getItem("currentUserJurisdictionId")) == this.selectedJurisdictionLevel) {
          if (element.id == this.PresentUserLoger[0].id) {
            this.allowUsersList = [element]
          }
        } else {
          this.allowUsersList.push(element)
        }
      }
    });
    this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
    this.seletedJurisdiction = -1
  }

  jurisdictionChange(event) {
    this.seletedJurisdiction = event.target.value
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getTableBackground(type) {
    switch (type) {
      case 1:
        return "lightgreen"
        break;
      case 2:
        return "cyan"
        break;
      case 3:
        return "pink"
        break;

      default:
        break;
    }
  }

  message(message) {
    this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
