import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { Bet } from 'src/app/interface/bet.interface';
import { Round } from 'src/app/interface/round.interface';
import { CouponServiceService } from 'src/app/services/coupon-service.service';
import { PostResultService } from 'src/app/services/post-result.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-manage-bets',
  templateUrl: './manage-bets.component.html',
  styleUrls: ['./manage-bets.component.css']
})
export class ManageBetsComponent implements OnInit {
  @ViewChild('frameMessage', { static: true }) modalMessages: ModalDirective;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['coupon', 'player', 'result', 'action'];
  dataSource = new MatTableDataSource<any>([]);

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger']
  currentRoundId
  round: Round
  roundName: string
  msgToAdd: string = ''
  titleToAdd: string = ''

  constructor(private route: ActivatedRoute,
    private translateService: TranslateService,
    private _snackBar: MatSnackBar,
    private couponService: CouponServiceService,
    private datePipe: DatePipe,
    private toastService: ToastService,
    private postResultService: PostResultService) {
    this.route.params.subscribe((params: Params): void => {
      this.currentRoundId = params['roundId']
    });
  }

  ngOnInit(): void {
    let pinAccess = localStorage.getItem('pinAccess');
    if (pinAccess == "true") {
      localStorage.setItem('pinAccess', '');
      this.loadBets();
    }
    else {
      alert('Acesso não permitido.');
    }
  }

  loadBets() {
    this.postResultService.getBetsByRound(this.currentRoundId)
      .subscribe(data => {
        this.dataSource.data = data
        this.dataSource.paginator = this.paginator
      }, error => {
        console.log(error)
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  couponDetail(ticketCode) {

    this.couponService.getBet(ticketCode)
      .subscribe(bet => {
        if (bet == null) {
          this.msgStandard(this.translateService.instant("pg-tickets.ticket-not-found"), this.translateService.instant("comp-search-ticket.fail-info1") + ticketCode + this.translateService.instant("comp-search-ticket.fail-info2"), 4);
        }
        else {
          //this.configService.getUser(bet.userAdminId)
          let rankResult = [];
          this.couponService.getRoundById(bet.roundId)
            .subscribe(round => {
              this.roundName = round.name;
              if (round && round.rankRound && round.rankResult)
                rankResult = this.round.rankResult.split('|');
            });
          this.couponService.getBasicUser(bet.userAdminId)
            .subscribe(user => {
              /*let stringTable = "<table border='1'><tr><td>" + this.translate.instant("pg-result.ticket") + "</td><td>" + this.translate.instant("pg-tickets.operator") + "</td><td>" + this.translate.instant("pg-tickets.player") + "</td><td>" + this.translate.instant("pg-tickets.results") + "</td></tr>" +
                "<tr><td>" + ticketCode + "</td><td>" + user.login + "</td><td>" + bet.playerName + "</td>" +
                "<td>" + bet.results + "</td></tr>" +  "<td>" + user.country + "</td></tr>" + 
                "</table>";*/
              this.couponService.getGames(bet.roundId)
                .subscribe(matchs => {
                  let results = bet.results.split("|");
                  let total_hit = 0;
                  let total_cancel = 0;
                  if (bet.rankRound && results.length) {
                    rankResult.forEach((element, i) => {
                      if (results[i] == element) {
                        total_hit++;
                      }
                    });
                  }
                  matchs.forEach((element, i) => {
                    if (element.status == 0) {
                      total_cancel++;
                    } else {
                      if (results[i].includes(element.matchResult) && !bet.rankRound) {
                        total_hit++;
                      }
                    }
                  });
                  let legend: string = "<div class='col-12'>";
                  legend += "<div class='row legend'><div class='col-3'><div class='running'></div>&nbsp;" + this.translateService.instant("pg-tickets.running") + "</div>";
                  legend += "<div class='col-3'><div class='hit'></div>&nbsp;" + this.translateService.instant("pg-tickets.hit") + "</div>";
                  legend += "<div class='col-3'><div class='wrong'></div>&nbsp;" + this.translateService.instant("pg-tickets.wrong") + "</div>";
                  legend += "<div class='col-3'><div class='cancel'></div>&nbsp;" + this.translateService.instant("pg-tickets.cancelled") + "</div>";
                  legend += "</div></div>";


                  let resultMats: string = "<div class='col-12 total-show'><div class='row legend'>" + /*legend + */
                    "<div class='col-6 total-show text-center'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-round.events") + "</strong>: " + matchs.length +
                    "</div><div class='col-6 text-center total-cancel'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-manage-ticket.canceled") + "</strong>: " + total_cancel +
                    "</div><div class='col-6 text-center total-hit'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-result.number-hits") + "</strong>: " + total_hit + "</div>" +
                    "<div class='col-6 text-center no-hit'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-result.number-error") + "</strong>: 0</div></div></div> <div class='mt-4 col-12 search-under-border'></div>" +
                    "<div class='col-12 text-center'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-round.events") + "</strong></div>";
                  let count: number = 0;
                  if (bet.rankRound)
                    results.forEach((element, i) => {
                      if (i < rankResult.length && rankResult[i] == element) {
                        resultMats += "<div class='col-6 search-game-list correct'>" + element + "</div><div class='col-6 search-game-list correct'> " + rankResult[i] + "</div>";
                      } else if (i < rankResult.length && rankResult[i] != element) {
                        resultMats += "<div class='col-6 search-game-list no-hit'>" + element + "</div><div class='col-6 search-game-list no-hit'> " + rankResult[i] + "</div>";
                      }
                      else {
                        resultMats += "<div class='col-9 search-game-list'>" + element + "</div><div class='col-3 search-game-list'> " + (i + 1) + "°</div>";
                      }
                    });
                  else
                    matchs.forEach(element => {
                      if (element.status == 0) {
                        resultMats += "<div class='col-9 search-game-list cancelled'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list cancelled'> " + results[count] + "</div>";
                      } else {
                        if (element.matchResult == "0") {
                          resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list'> " + results[count] + "</div>";
                        } else {
                          if (results[count].includes(element.matchResult)) {
                            resultMats += "<div class='col-9 search-game-list correct'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list correct'> " + results[count] + "</div>";
                          } else {
                            resultMats += "<div class='col-9 search-game-list no-hit'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list no-hit'> " + results[count] + "</div>";
                          }
                        }
                      }
                      count++;
                    });
                  let betDate = (new Date(bet.betDate)).getTime() - (new Date()).getTimezoneOffset() * 60000;
                  let stringTable = "<div class='row'>" +
                    "<div class='col-3 search-head search-under-border'>" + this.translateService.instant("pg-tickets.operator") + "</div>" +
                    "<div class='col-3 search-under-border'>" + user.login + "</div>" +
                    "<div class='col-3 search-head search-under-border'>" + this.translateService.instant("pg-tickets.player") + "</div>" +
                    "<div class='col-3 search-under-border'>" + bet.playerName + "</div>" +

                    "<div class='col-3 search-head search-under-border'>" + this.translateService.instant("pg-credit-transfer.value") + "</div>" +
                    "<div class='col-3 search-under-border'>" + this.couponService.formatCurrencyValue(bet.value) + "</div>" +
                    "<div class='col-3 search-head search-under-border'>" + this.translateService.instant("pg-credit-transfer.RoundName") + "</div>" +
                    "<div class='col-3 search-under-border'> " + this.roundName + " </div>" +

                    "<div class='col-3 search-head search-under-border'>" + this.translateService.instant("pg-home.quantity-tickets-value") + "</div>" +
                    "<div class='col-3 search-under-border'>" + bet.quantity + "</div>" +
                    "<div class='col-3 search-head search-under-border'>" + this.translateService.instant("pg-credit-transfer.TotalValue") + "</div>" +
                    "<div class='col-3 search-under-border'>" + this.couponService.formatCurrencyValue(bet.value * bet.quantity) + "</div>" +

                    "<div class='col-3 search-head search-under-border'>" + this.translateService.instant("pg-jurisdiction.country") + "</div>" +
                    "<div class='col-3 search-under-border'>" + user.country + "</div>" +
                    "<div class='col-3 search-head search-under-border'>" + this.translateService.instant("pg-jurisdiction.city") + "</div>" +
                    "<div class='col-3 search-under-border'>" + (user.city != null ? user.city : '') + "</div>" +

                    "<div class='col-3 search-head search-under-border'>" + this.translateService.instant("pg-post-result.date") + "</div>" +
                    "<div class='col-6 search-under-border'>" + this.datePipe.transform(betDate, 'MM/dd/y HH:mm') + "</div>" +
                    "<div class='col-3 search-under-border'></div>" +
                    "<div class='col-12 text-center search-head'><strong style='font-weight: bold;'>" + this.translateService.instant("pg-round.Resume") + "</strong></div>" +

                    resultMats +
                    // "<div class='col-6 mt-3 search-head search-under-border'>" + this.translate.instant("pg-result.ticket") + "</div>" +
                    // "<div class='col-6 mt-3 search-under-border'>" + ticketCode + "</div>" +
                    // "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-credit-transfer.value") + "</div>" +
                    // "<div class='col-6 search-under-border'>" + this.couponService.formatCurrencyValue(bet.value) + "</div>" +
                    // "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-post-result.date") + "</div>" +
                    // "<div class='col-6 search-under-border'>" + this.datePipe.transform(betDate, 'MM/dd/y HH:mm') + "</div>" +
                    // "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-jurisdiction.country") + "</div>" +
                    // "<div class='col-6 search-under-border'>" + user.country + "</div>" +
                    // "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-home.quantity-tickets") + "</div>" +
                    // "<div class='col-6 search-under-border'>" + bet.quantity + "</div>" +
                    "</div>";

                  this.msgStandard(this.translateService.instant("pg-tickets.resume-ticket") + ticketCode, stringTable, 1);
                  //<HTMLInputElement>document.getElementById("code-search")).value = "";
                }, error => {
                  this.msgStandard(this.translateService.instant("pg-tickets.ticket-not-found"), this.translateService.instant("comp-search-ticket.fail-info1") + ticketCode + this.translateService.instant("comp-search-ticket.fail-info2"), 4);
                });
            }, error => {
              this.msgStandard(this.translateService.instant("pg-tickets.ticket-not-found"), this.translateService.instant("comp-search-ticket.fail-info1") + ticketCode + this.translateService.instant("comp-search-ticket.fail-info2"), 4);
            });
        }
      }, error => {
        this.msgStandard(this.translateService.instant("pg-tickets.ticket-not-found"), this.translateService.instant("comp-search-ticket.fail-info1") + ticketCode + this.translateService.instant("comp-search-ticket.fail-info2"), 4);
      });
  }

  msgStandard(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandard').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandard').classList.add(this.msgType[type]);
    }

    this.modalMessages.show();
  }

  getTableBackground(type) {
    switch (type) {
      case 1:
        return "lightgreen"
        break;
      case 2:
        return 'rgba(243, 83, 92, 1)'
        break;
      case 3:
        return 'rgb(255, 255, 105)'
        break;

      case 4:
        return 'rgb(0, 255, 186)'
        break;

      default:
        break;
    }
  }

  changeBet(betId: number) {
    let currentBet: Bet = this.dataSource.data.find(x => x.id == betId)
    this.postResultService.updateBet(currentBet)
      .subscribe(data => {
        //this.message("Modified Success")
        this.toastService.showNotification({ message: this.translateService.instant("pg-manage-bets.success"), type: 'success' });
      }, error => {
        this.toastService.showNotification({ message: this.translateService.instant("pg-manage-bets.fail") });
        //this.message("Error on Changing")
      })
  }

}
