<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'domain.domain' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">format_align_left</span>
        {{ 'domain.domain' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <button class="btn btn-success btn-sm" (click)="onClickAdd()">{{ 'domain.addsite' | translate }}</button>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>{{ 'domain.domain' | translate }}</th>
              <th>Owner</th>
              <th>{{ 'domain.national' | translate }}</th>
              <th>{{ 'domain.active' | translate }}</th>
              <th>Casino</th>
              <th>Live Casino</th>
              <th>Crash Games</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let domain of domainList" #domainView>
              <th class="table-minwidth">
                <input type="text" class="form-control" spellcheck="false" value="{{ domain.url }}" />
              </th>
              <th>
                <select class="form-control" [(ngModel)]="domain.userId">
                  <option *ngFor="let casino of casinosList" value="{{casino.id}}">
                    {{casino.login}}
                  </option>
                </select>
              </th>
              <th class="table-minwidth">
                <select class="form-control">
                  <option *ngFor="let language of languageList" value="{{ language.id }}"
                    [selected]="domain.nationalId == language.id">{{ language.languageName }}</option>
                </select>
              </th>
              <th class="table-minwidth">
                <mdb-checkbox #domainChk [inline]="true" [default]="true" class="d-flex"
                  [checked]="domain.activated==1?true:false"></mdb-checkbox>
              </th>
              <th class="table-minwidth">
                <mat-slide-toggle [(ngModel)]="domain.casino"></mat-slide-toggle>
              </th>
              <th class="table-minwidth">
                <mat-slide-toggle [(ngModel)]="domain.liveCasino"></mat-slide-toggle>
              </th>
              <th class="table-minwidth">
                <mat-slide-toggle [(ngModel)]="domain.crashGames"></mat-slide-toggle>
              </th>
              <th class="table-minwidth">
                <button (click)="deleteDomain(domain.id)" class="btn btn-danger btn-sm"
                  style="white-space: none !important;">{{ 'domain.removeassociatedsite' | translate }}</button>
              </th>
            </tr>
            <tr *ngFor="let newDomain of newDomainList" #newDomainView>
              <th class="table-minwidth">
                <input type="text" class="form-control" spellcheck="false" />
              </th>
              <th>
                <select class="form-control" [(ngModel)]="newDomain.userId">
                  <option *ngFor="let casino of casinosList" value="{{casino.id}}">
                    {{casino.login}}
                  </option>
                </select>
              </th>
              <th class="table-minwidth">
                <select class="form-control">
                  <option *ngFor="let language of languageList" value="{{ language.id }}">{{ language.languageName }}
                  </option>
                </select>
              </th>
              <th class="table-minwidth">
                <mdb-checkbox #newDomainChk [inline]="true" [default]="true" class="d-flex"></mdb-checkbox>
              </th>
              <th class="table-minwidth">
                <mat-slide-toggle [(ngModel)]="newDomain.casino"></mat-slide-toggle>
              </th>
              <th class="table-minwidth">
                <mat-slide-toggle [(ngModel)]="newDomain.liveCasino"></mat-slide-toggle>
              </th>
              <th class="table-minwidth">
                <mat-slide-toggle [(ngModel)]="newDomain.crashGames"></mat-slide-toggle>
              </th>
              <th class="table-minwidth">
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <button class="btn btn-success btn-sm" (click)="saveAllDomains()">{{ 'domain.saveeverything' | translate
        }}</button>
    </div>
  </div>
</div>