import { Injectable } from '@angular/core';
import { IpConfigService } from './Ip-Config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PostRoundStyle } from '../interface/RoundStyle/PostRoundStyle';
import { RoundStyle } from '../interface/RoundStyle/RoundStyle';

@Injectable({
  providedIn: 'root'
})
export class RoundStyleService {

  apiUrl = '';
  constructor(private http: HttpClient, private ipConfigService: IpConfigService) {
    this.apiUrl = ipConfigService.getApiUrl();
   }

   CreateRoundStyle(SendRoundStyle: PostRoundStyle){
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/roundStyles`,SendRoundStyle ,{ headers: header });
   }

   UpdateRoundStyle(SendRoundStyle: RoundStyle){
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.put<any>(`${this.apiUrl}/roundStyles`,SendRoundStyle ,{ headers: header });
   }

  UpdateRoundStylePermits(SendRoundStyle: RoundStyle) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.put<any>(`${this.apiUrl}/roundStyles/UpdateRoundStylesPermit`, SendRoundStyle, { headers: header });
  }

   GetRoundStyles(){
    let userId = +localStorage.getItem("creatorId");

    return this.http.get<RoundStyle[]>(`${this.apiUrl}/roundStyles/Active?userId=${userId}`);
   }

   GetUpgradeableRoundStyles(){
    return this.http.get<RoundStyle[]>(`${this.apiUrl}/roundStyles/Upgradeables`);
   }

   RemoveRoundStyle(id: string){
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<string>(`${this.apiUrl}/RoundStyles/Disable`, `"${id}"` ,{ headers: header });
   }
}
