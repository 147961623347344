import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})

export class AdminLayoutComponent implements OnInit {
  userAdmin: any;
  constructor(private loginService:LoginService) {
    this.userAdmin = loginService.getUserInfoFromCookie();
  }

  // updateNotificationCount(val): void {
  //   if(val){
  //     this.getPendingUserNotifications();

  //     this.getPendingUserWithdrawals();
  
  //     this.getPendingUserDocs();
  //   }
  // }

  // getPendingUserNotifications(){
  //   this.loginService.getUserPendingNotifications(this.userAdmin.id).subscribe((data: number) => {
  //     return data;
  //   });
  // }

  // getPendingUserDocs(){
  //   this.loginService.GetPendingUserDocument(this.userAdmin.id).subscribe((data: number) => {
  //     return data;      
  //   });
  // }

  // getPendingUserWithdrawals(){
  //   this.loginService.GetPendingUserWithdrawals(this.userAdmin.id).subscribe((data: number) => {
  //     return data;
  //   });
  // }

  ngOnInit(): void {
  }

}
