import { DropDownList, Player, PlayerDocs } from './../interface/player.interface';
import { PlayerTransaction, Transaction } from './../interface/transaction.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InteractionService } from './interaction.service';
import { DepositFunds } from '../interface/deposit-funds.interface';
import { IpConfigService } from './Ip-Config.service';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  apiUrl = ''; // local test
  constructor(private http: HttpClient, private interactionService: InteractionService, private ipConfigService: IpConfigService, private loginService: LoginService) {
    this.apiUrl = ipConfigService.getApiUrl();
  }

  getClubs(){
    return this.http.get<Player[]>(this.apiUrl + '/users/GetClubs');
  }

  getPlayers(userId: number, login: string) {
    return this.http.get<Player[]>(this.apiUrl + '/players?userId=' + userId + '&Userloggin=' + login);
  }

  getPlayerByFilter(userId: number, jurisdinctionId: number, parentId: number, filterType: string) {
    return this.http.get<Player[]>(`${this.apiUrl}/players/GetPlayersByFilter?userId=${userId}&jurisdictionId=${jurisdinctionId}&parentId=${parentId}&type=${filterType}`);
  }

  getPlayer(playerId: number) {
    return this.http.get<Player>(this.apiUrl + '/players/' + playerId);
  }

  getPlayerDetails(playerId: number): Observable<any> {
    return this.http.get<Player>(this.apiUrl + '/players/GetPlayerInfo?id=' + playerId);
  }

  addPlayer(player: Player) {
    return this.http.post<any>(`${this.apiUrl}/players/`, player);
  }

  addUserAdmin(player: Player, jurisdictionCoupon?: string) {
    let userLoged = this.loginService.getUserInfoFromCookie();
    let requestObject = {
      player: player,
      agentId: userLoged.id,
      agentLogin: userLoged.login,
      ipAddress: localStorage.getItem("currentUserIp"),
      skinId: parseInt(localStorage.getItem("creatorId")!),
      IsAssistant: parseInt(localStorage.getItem("isAssistant")!)
    }
    return this.http.post<any>(`${this.apiUrl}/JurisdictionEntity/?jurisdictionCouponCode=${jurisdictionCoupon}`, requestObject);
  }

  updatePlayer(playerId: number, player: Player) {
    return this.http.put<any>(`${this.apiUrl}/players/` + playerId, player);
  }

  resetPassword(playerId: number) {
    const header = new HttpHeaders({
      'Content-type': 'text/plain',
    });
    return this.http.get<any>(`${this.apiUrl}/PlayerAction/ResetPassword?userID=` + playerId);
  }

  depositFunds(depositFunds: DepositFunds) {
    return this.http.post<any>(`${this.apiUrl}/PlayerAction/depositFunds/`, depositFunds);
  }

  transferCredit(depositFunds: DepositFunds) {
    return this.http.post<any>(`${this.apiUrl}/PlayerAction/TransferCredit/`, depositFunds);
  }

  blockPlayer(playerId: number) {
    return this.http.get<any>(`${this.apiUrl}/PlayerAction/blockUser?playerId=` + playerId);
  }
  unBlockPlayer(playerId: number) {
    return this.http.get<any>(`${this.apiUrl}/PlayerAction/unBlockUser?playerId=` + playerId);
  }

  getPlayerTransaction(playerId: number) {
    return this.http.get<any[]>(this.apiUrl + '/players/GetPlayerTransactions?id=' + playerId);
  }

  getClubParent(userId: number, userLoggin: string) {
    return this.http.get<DropDownList[]>(this.apiUrl + '/players/GetClubParent?userId=' + userId + '&Loggin=' + userLoggin);
  }

  deletePlayerDoc(playerId: number, docType: number) {
    return this.http.get<any>(`${this.apiUrl}/Players/DeletePlayerDoc?playerId=${playerId}&docType=${docType}`);
  }

  updateUserDoc(doc: PlayerDocs) {
    return this.http.post<any>(`${this.apiUrl}/players/UploadDocs/`, doc);
  }
}
