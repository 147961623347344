import { Injectable }      from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { Round } from '../interface/round.interface';
import { Login } from '../interface/login.interface';
import { Ticket } from '../interface/ticket.interface';
import { Currency } from '../interface/currency.interface';

@Injectable({
    providedIn: 'root'
})
export class InteractionService {
    homeVisibleRound = new BehaviorSubject<Round>(null);

    setHomeVisibleRound(round: Round) {
        this.homeVisibleRound.next(round);
    }

    loginData = new BehaviorSubject<Login>(null);

    setLoginData(login: Login){
        this.loginData.next(login);
    }

    ticketPanelState = new BehaviorSubject<Ticket>(null);
    mTicketPanelState = new Subject<Ticket>();

    setTicketPanelState(ticketState: Ticket){
        this.ticketPanelState.next(ticketState);
        this.mTicketPanelState.next(ticketState);
    }

    globalCurrency = new BehaviorSubject<Currency>(null);

    setGlobalCountry(globalCurrency: Currency){
        this.globalCurrency.next(globalCurrency);
    }

    clickX = new BehaviorSubject<number>(null);
    setClickX(clickX: number){
        this.clickX.next(clickX);
    }

    clickY = new BehaviorSubject<number>(null);
    setClickY(clickY: number){
        this.clickY.next(clickY);
    }

    playBallAnimation = new BehaviorSubject<boolean>(null);
    setPlayBallAnimation(playBallAnimation: boolean){
        this.playBallAnimation.next(playBallAnimation);
    }
}
