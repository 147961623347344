import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { Currency } from 'src/app/interface/currency.interface';
import { Game } from 'src/app/interface/game.interface';
import { Round } from 'src/app/interface/round.interface';
import { InteractionService } from 'src/app/services/interaction.service';
import { LoginService } from 'src/app/services/login.service';
import { PostResultService } from 'src/app/services/post-result.service';
import { coin } from 'src/app/services/coin.interface';
import { ViewResultMatch } from '../../interface/Result/ViewResultMatch';
import { Result } from 'src/app/interface/Result/Result';
import { GetRound } from 'src/app/interface/Round/GetRound';
import { DatePipe } from '@angular/common';
import { GetRoundEvent } from 'src/app/interface/RoundEvent/GetRoundEvent';
import { ViewResultEvent } from 'src/app/interface/Result/ViewResultEvent';
import { event } from 'jquery';
import { ResultMatch } from 'src/app/interface/Result/ResultMatch';
import { ResultEvent } from 'src/app/interface/Result/ResultEvent';
import { ok } from 'assert';


@Component({
  selector: 'app-postresult',
  templateUrl: './postresult.component.html',
  styleUrls: ['./postresult.component.css']
})
export class PostresultComponent implements OnInit {

  @ViewChild('frame', { static: true }) modalPost: ModalDirective;
  @ViewChild('frame2', { static: true }) modalCancel: ModalDirective;
  @ViewChild('successCreation', { static: true }) modalCreationSuccess: ModalDirective;
  @ViewChild('avoidCreation', { static: true }) modalAvoidCreatioon: ModalDirective;
  @ViewChild('frameMessage', { static: true }) modalMessages: ModalDirective;
  @ViewChild('frameMessageDanger', { static: true }) modalMessagesDanger: ModalDirective;
  @ViewChild('framePassword', { static: true }) modalPassword: ModalDirective;
  @ViewChild('framePreview', { static: true }) modalPreview: ModalDirective;
  @ViewChild('frameChangePin', { static: true }) modalChangePin: ModalDirective;
  @ViewChild('CancelPostedRound', { static: true }) modalPostedRound: ModalDirective;
  @ViewChild("CancelResultModal", { static: true }) modalCancelResult: ModalDirective;

  isLoaded: boolean = false;
  isLoadingGames: boolean = false;
  roundsFinished: Round[] = [];
  info: any = [];
  roundsFiltered: any = [];
  round = '';
  roundName: string;
  public activeLang;
  isSingleRound: boolean = false;
  isNewRound: boolean = false;
  isChampionship: boolean = false;
  games: Game[] = [];
  singleMatch: Game;
  jiuJitsuMatch: any = [];
  scoreArray: number[] = [0];
  sortIndices: number[] = [0];
  isRankRound: boolean = false;
  msgToAdd: string = '';
  titleToAdd: string = '';
  possibleWinners: any = null;
  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];
  Currency: Currency = <Currency>{};
  gameCancel = {} as Game;
  loading: boolean = false;
  submitResult: boolean = false;

  PostedRoundList: any = [];
  PostedRoundFinished: Round[] = [];
  finishedHelper: Round[] = [];
  RoundPostedSelected: any;
  RoundPostedMatch: Game[];
  userAdmin: any;
  isPostedRankRound: boolean = false;
  isPostedSingleRound: boolean = false;
  RankRundPosted: any;
  scorePostedArray: Game[];
  cancelResultPostedRound: boolean = false;

  matchCanceled: number[] = []

  tempCornerHome;
  tempCornerAway;

  jiuJitsuWinnerSelected: number;
  advantagesDifferenceSelected: number;
  winnerPointsSelected: number;
  loserPointsSelected: number;
  pointsDifferenceSelected: number;
  finishingAtMinuteSelected: number;
  winByFinishingSelected: number;
  drawInPointsSelected: number;
  willBePunishmentSelected: number;
  willBeDisqualifiedSelected: number;
  neckFinishingSelected: number;
  armFinishingSelected: number;
  legFinishingSelected: number;
  fallPointsSelected: number;
  sweepPointsSelected: number;
  guardPointsSelected: number;
  mountPointsSelected: number;
  kneeOnBellyPointsSelected: number;
  gripOnBackPointsSelected: number;

  roundsToResult: any[] = []
  roundsToResultHelper: GetRound[] = []
  result: Result =
    {
      roundId: 0,
      style: 0,
      styleName: "",
      matches: [],
      events: []
    };

  matchsToResult: ViewResultMatch[] = []
  eventsToResult: ViewResultEvent[] = []
  roundType: string = "";


  completeResult: string[] = ['', '', '', '', '', '', '', '', '', '', '', '', '', ''];
  jiuJitsuCompleteResult: string[] = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
  isJiuJitsuRound: boolean;
  constructor(
    private http: HttpClient, public loginService: LoginService,
    private translate: TranslateService,
    private postresultService: PostResultService,
    private interactionService: InteractionService,
    private route: Router,
    private datePipe: DatePipe) {
    this.userAdmin = this.loginService.getUserInfoFromCookie();
    this.cancelResultPostedRound = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'cancelPostedResult') == null ? false : true : true;
  }

  setGlobalCurrency() {
    this.loginService.GetCountryCurrency(localStorage.getItem('currentUserCountry'))
      .subscribe(data => {

        var DataURL: string = '';
        if (data.currencyId == "BRL") {
          DataURL = DataURL = 'https://economia.awesomeapi.com.br/json/USD-BRL';
        } else DataURL = 'https://economia.awesomeapi.com.br/json/BRL-' + data.currencyId;

        //this.http.get('https://free.currconv.com/api/v7/convert?q=BRL_' + data.currencyId + '&apiKey=085a5c4bbbd6e6750bfc')
        this.http.get(DataURL)
          .subscribe((obj: any) => {
            var Eco = JSON.stringify(obj)
            Eco = Eco.replace("[", "").replace("]", "")
            let response: coin = JSON.parse(Eco)
            if (response /*&& response.data*/) {
              //var price = (response.data.results['BRL_' + data.currencyId].val);
              var price = parseFloat(response.ask);
              this.Currency.quote = price;
              this.Currency.coin = 'BRL_' + data.currencyId;
              this.Currency.currencySymbol = data.currencySymbol;
              this.Currency.currency = data.currencyId;
              this.interactionService.setGlobalCountry(this.Currency);
              return;
            }
          }, error => {
            this.Currency.coin = 'BRL_BRL';
            this.Currency.quote = 1;
            this.Currency.currencySymbol = 'R$';
            this.Currency.country = 'Brazil';
            this.Currency.currency = 'BRL';
          });
      });
    this.Currency.coin = 'BRL_BRL';
    this.Currency.quote = 1;
    this.Currency.currencySymbol = 'R$';
    this.Currency.country = 'Brazil';
    this.Currency.currency = 'BRL';
    this.interactionService.setGlobalCountry(this.Currency);
  }

  ngOnInit(): void {
    if (localStorage.getItem('language') == null) {
      this.activeLang = 'ptbr';
    } else {
      this.activeLang = localStorage.getItem('language');
    }

    this.translate.setDefaultLang(this.activeLang);
    this.getPostedRound();

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
        else {
          this.setGlobalCurrency();
        }
      });

    this.getRoundsToResult();
  }

  getRoundsFinished() {
    this.postresultService.getRoundsFinished()
      .subscribe(data => {
        this.roundsFinished = data;
        for (let index = 0; index < this.roundsFinished.length; index++) {
          switch (this.activeLang) {
            case 'ptbr':
              this.roundName = this.roundsFinished[index].name;
              break;
            case 'en':
              this.roundName = this.roundsFinished[index].nameEng;
              break;
            case 'it':
              this.roundName = this.roundsFinished[index].nameItaly;
              break;
            case 'es':
              this.roundName = this.roundsFinished[index].nameSpan;
              break;
            default:
              break;
          }
          this.info.push({
            id: this.roundsFinished[index].id,
            startDateTime: this.roundsFinished[index].startDateTime,
            endDateTime: this.roundsFinished[index].endDateTime,
            name: this.roundName,
            number: this.roundsFinished[index].number,
            rankRound: this.roundsFinished[index].rankRound,
            singleMatchRound: this.roundsFinished[index].singleMatchRound,
            jiuJitsuRound: this.roundsFinished[index].jiuJitsuRound
          });
        }
        this.roundsFiltered = this.info;
        //document.getElementById("spinner-loading").classList.add("hidden");
        //this.isLoaded = true;
      }, error => {
        console.log(error);
        this.msgStandard(this.translate.instant("pg-post-result.failed-round"), this.translate.instant("pg-post-result.failed-round-info") +
          this.translate.instant("pg-post-result.assistence-contact"), 4)
      })
  }

  msgStandardDanger(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardDanger').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardDanger').classList.add(this.msgType[type]);
    }

    this.modalMessagesDanger.show();
  }

  msgStandard(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandard').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandard').classList.add(this.msgType[type]);
    }

    this.modalMessages.show();
  }

  loadGames(roundId: number, rankRound: number, singleMatchRound: number, jiuJitsuRound: number) {
    this.games = [];
    this.isLoadingGames = true;
    this.isRankRound = rankRound && !singleMatchRound && !jiuJitsuRound ? true : false;
    this.isChampionship = !rankRound && !singleMatchRound && !jiuJitsuRound ? true : false;
    this.isNewRound = rankRound && singleMatchRound && jiuJitsuRound ? true : false;
    document.getElementById("spinner-loading2").classList.remove("hidden");
    if (this.isJiuJitsuRound == false) {
      this.getGamesWithoutJiuJitsu(roundId)
    }
    if (this.isJiuJitsuRound == true && this.isSingleRound == false) {
      this.getJiuJitsuGames(roundId)
    };
  }

  getGamesWithoutJiuJitsu(roundId: number) {
    this.postresultService.getGames(roundId)
      .subscribe(data => {
        this.getBetradarResults(data);
      }, error => {
        console.log(error);
        this.msgStandard(this.translate.instant("pg-post-result.failed-matches"), this.translate.instant("pg-post-result.failed-matches-info") +
          this.translate.instant("pg-post-result.assistence-contact"), 4)
      });
  }

  getJiuJitsuGames(roundId: number) {
    this.postresultService.getJiuJitsuGames(roundId)
      .subscribe(data => {
        this.jiuJitsuMatch = data;
      }, error => {
        console.log(error);
        this.msgStandard(this.translate.instant("pg-post-result.failed-matches"), this.translate.instant("pg-post-result.failed-matches-info") +
          this.translate.instant("pg-post-result.assistence-contact"), 4)
      });

    this.isLoadingGames = false;
    document.getElementById("spinner-loading2").classList.add("hidden");
  }

  closeAvoidCreation() {
    this.modalAvoidCreatioon.hide();
    location.reload();
  }

  checkCancelledMatch(MachId) {
    return this.matchCanceled.find(x => x == MachId) == null ? true : false;
  }

  getBetradarResults(currentGames: Game[]) {
    this.tempCornerHome = currentGames[0].cornerHomeTeam;
    this.tempCornerAway = currentGames[0].cornerAwayTeam;

    this.postresultService.getBetRadarResults(currentGames)
      .subscribe(data => {
        this.games = data;
        data.forEach(x => {
          if (x.status == 0) this.matchCanceled.push(x.id)
        })
        for (let i = 0; data && i < this.games.length; i++) {
          const gameDateTime = (new Date(this.games[i].dateTime)).getTime() - (new Date()).getTimezoneOffset() * 60000;
          this.games[i].dateTime = new Date(gameDateTime);
        }
        if (this.isRankRound) {
          for (let i = 0; i < data.length; i++) {
            this.scoreArray[i] = this.games[i].homeTeamScore;
          }
          this.games[0].matchWinner = this.setRank();
        }
        if (this.isSingleRound) {
          this.singleMatch = this.games[0];
          if (this.games[0].cornerAwayTeam != '' && this.games[0].cornerAwayTeam != null && this.games[0].cornerAwayTeam !== undefined) {
            this.singleMatch.totalCornerAway = this.games[0].cornerAwayTeam.split(',')[0];

          }
          this.singleMatch.cornerAwayTeam = this.tempCornerAway;

          if (this.games[0].cornerHomeTeam != '' && this.games[0].cornerHomeTeam != null && this.games[0].cornerHomeTeam !== undefined) {
            this.singleMatch.totalCornerHome = this.games[0].cornerHomeTeam.split(',')[0];
          }
          this.singleMatch.cornerHomeTeam = this.tempCornerHome;

          this.singleMatch.totalCornerHome = "";
          this.singleMatch.totalCornerAway = "";

          //if (this.games[0].goalIn1stHalf != '' && this.games[0].goalIn1stHalf != null && this.games[0].goalIn1stHalf !== undefined && this.games[0].resultHalfTime != null) {
          if (this.games[0].resultHalfTime != null) {
            //this.singleMatch.goalIn1stHalfAway = this.games[0].resultHalfTime.split(':')[1];
            //this.singleMatch.goalIn1stHalfHome = this.games[0].resultHalfTime.split(':')[0];
            this.singleMatch.goalIn1stHalf = (+this.games[0].resultHalfTime.split(':')[0] > 0 && +this.games[0].resultHalfTime.split(':')[1] > 0) ? '1' : '2';
          }
          else {
            this.singleMatch.goalIn1stHalf = '';
          }

          //if (this.games[0].goalIn2ndHalf != '' && this.games[0].goalIn2ndHalf != null && this.games[0].goalIn2ndHalf !== undefined && this.games[0].resultHalfTime != null) {
          if (this.games[0].resultHalfTime != null) {
            this.singleMatch.goalIn2ndHalfAway = (this.games[0].awayTeamScore - (+this.games[0].resultHalfTime.split(':')[1])).toString();
            this.singleMatch.goalIn2ndHalfHome = (this.games[0].homeTeamScore - (+this.games[0].resultHalfTime.split(':')[0])).toString();
            this.singleMatch.goalIn2ndHalf = (+this.singleMatch.goalIn2ndHalfHome > 0 && +this.singleMatch.goalIn2ndHalfAway > 0) ? '1' : '2';
          }
          else {
            this.singleMatch.goalIn2ndHalf = '';
          }

          if (this.games[0].penaltyOnFavor != '' && this.games[0].penaltyOnFavor != null && this.games[0].penaltyOnFavor !== undefined) {
            this.singleMatch.penaltyOnFavorHome = this.games[0].penaltyOnFavor.split(',')[0];
            this.singleMatch.penaltyOnFavorAway = this.games[0].penaltyOnFavor.split(',')[1];
          }
          this.singleMatch.penaltyOnFavor = '';

          if (this.games[0].totalFaults != '' && this.games[0].totalFaults != null && this.games[0].totalFaults !== undefined) {
            this.singleMatch.totalFaultAway = this.games[0].totalFaults.split(',')[1];
            this.singleMatch.totalFaultHome = this.games[0].totalFaults.split(',')[0];
          }
          this.singleMatch.totalFaultHome = "";
          this.singleMatch.totalFaultAway = "";

          if (this.games[0].totalGoals != '' && this.games[0].totalGoals != null && this.games[0].totalGoals !== undefined) {
            this.singleMatch.totalGoalAway = this.games[0].totalGoals.split(',')[1];
            this.singleMatch.totalGoalHome = this.games[0].totalGoals.split(',')[0];
          }
          if (this.games[0].yellowCards != '' && this.games[0].yellowCards != null && this.games[0].yellowCards !== undefined) {
            this.singleMatch.yellowCardsAway = this.games[0].yellowCards.split(',')[1];
            this.singleMatch.yellowCardsHome = this.games[0].yellowCards.split(',')[0];
          }
          this.singleMatch.yellowCardsHome = "";
          this.singleMatch.yellowCardsAway = "";

          if (this.games[0].redCards != '' && this.games[0].redCards != null && this.games[0].redCards !== undefined) {
            this.singleMatch.redCardAway = this.games[0].redCards.split(',')[1];
            this.singleMatch.redCardHome = this.games[0].redCards.split(',')[0];
          }
          this.singleMatch.redCards = '';

          if (this.games[0].moreBallPossession != '' && this.games[0].moreBallPossession != null && this.games[0].moreBallPossession !== undefined) {
            this.singleMatch.moreBallPossessionAway = this.games[0].moreBallPossession.split(',')[1];
            this.singleMatch.moreBallPossessionHome = this.games[0].moreBallPossession.split(',')[0];
          }

          this.singleMatch.moreBallPossessionHome = "";
          this.singleMatch.moreBallPossessionAway = "";

          /*if (this.games[0].ggBothScore != '' && this.games[0].ggBothScore != null && this.games[0].ggBothScore !== undefined) {
            this.singleMatch.ggBothScoreAway = this.games[0].ggBothScoreHome.split(',')[1];
            this.singleMatch.ggBothScoreHome = this.games[0].ggBothScoreHome.split(',')[0];
          }*/
        }
        this.isLoadingGames = false;
        document.getElementById("spinner-loading2").classList.add("hidden");

      }, error => {
        this.msgStandardDanger(this.translate.instant("pg-post-result.failed-post"), this.translate.instant("pg-post-result.failed-post-info") +
          this.translate.instant("pg-post-result.assistence-contact"), 4);
        console.log(error);
      });
  }

  setChampions(): string {
    var r = "";
    this.games.forEach(game => {
      if (game.homeTeamScore > game.awayTeamScore)
        r += "1|";

      if (game.homeTeamScore == game.awayTeamScore)
        r += "X|";

      if (game.homeTeamScore < game.awayTeamScore)
        r += "2|";
    })

    r = r.substring(0, r.length - 1);
    return r;
  }

  setRank() {
    let toSort = [];

    for (var i = 0; i < this.games.length; i++) {
      toSort[i] = [this.scoreArray[i], i];
    }
    toSort.sort(function (left, right) {
      return left[0] > right[0] ? 1 : -1;
    });
    let ret = "";
    for (var j = 0; j < toSort.length; j++) {
      ret += this.games[toSort[j][1]].homeName + '|';
    }

    return ret.substring(0, ret.length - 1);
  }

  setResult(homeScore: number, awaySocore: number, index?: number) {
    let ret = "0";
    if (homeScore > awaySocore) {
      ret = "1";
    } else if (homeScore < awaySocore) {
      ret = "2";
    } else if (homeScore == awaySocore) {
      ret = "X"
    }

    if (typeof index !== 'undefined') {
      this.completeResult[index] = ret;
    }

    return ret;
  }

  setResultOverUnder(homeScore: number, parameter, index?: number) {
    let param: number = +parameter.replace(",", ".");
    let ret = "Over";
    let num;
    if (homeScore > param) {
      ret = "Over";
      num = '1';
    } if (homeScore < param) {
      ret = "Under";
      num = '2';
    }

    if (typeof index !== 'undefined') {
      this.completeResult[index] = num;
    }

    return ret;
  }

  setResultYN(item, index?: number) {

    if (typeof index !== 'undefined') {
      this.completeResult[index] = item;
    }

    return item == '1' ? 'Yes' : 'No'
  }

  checkData() {
    this.modalPassword.show();
  }

  checkPIN() {
    this.msgToAdd = "";
    this.titleToAdd = "";
    this.modalPassword.hide();
    let pin = (<HTMLInputElement>document.getElementById('password')).value;
    (<HTMLInputElement>document.getElementById('password')).value = "";

    this.postresultService.checkPin(pin)
      .subscribe(res => {
        if (res.access) {

          this.modalPost.hide();
          this.titleToAdd = this.translate.instant("pg-post-result.not-allowed");
          this.postresultService.preCheckingResult(this.games)
            .subscribe(data => {
              //data.forEach(element => {
              //   this.msgToAdd += element.name + " - " + element.coupon + "\n<br/>";
              // });
              localStorage.setItem('pinAccess', res.access);
              this.possibleWinners = data;
              if (data.length == 0) {
                this.msgToAdd = this.translate.instant("pg-post-result.no-one");
              }
              this.modalPreview.show();
            }, erro => {
              this.msgToAdd = this.translate.instant("pg-post-result.wrong-pin");
              this.modalPreview.show();
            });
        }
        else {
          this.titleToAdd = this.translate.instant("pg-post-result.not-allowed");
          this.msgToAdd = this.translate.instant("pg-post-result.wrong-pin");
          //this.modalPreview.show();
          this.modalMessagesDanger.show();
        }
      }, error => {
        console.log(error);
      });
  }

  openBets() {
    this.modalPreview.hide();
    this.route.navigate(['/manage-bets/' + this.games[0].roundId]);
  }

  sendPinEmail() {
    this.postresultService.sendPinViaEmail()
      .subscribe(data => {
        if (data) {
          this.msgToAdd = this.translate.instant("pg-post-result.recovery-pin-msg");
          this.titleToAdd = "Info";
          this.modalMessages.show();
        }
      }, error => {
        //alert("O PIN não pôde ser enviado por e-mail.");
        this.msgToAdd = "O PIN não pôde ser enviado por e-mail.";
        this.titleToAdd = "Info";
        this.modalMessages.show();
        console.log(error);
      });
  }

  openChangePin() {
    this.modalPost.hide();
    this.modalPassword.hide();
    this.modalChangePin.show();
  }

  changePIN() {
    let oldPin = (<HTMLInputElement>document.getElementById('old-pin')).value;
    let newPin = (<HTMLInputElement>document.getElementById('new-pin')).value;
    let confirmPin = (<HTMLInputElement>document.getElementById('confirm-pin')).value;
    this.titleToAdd = "INFO";

    if (oldPin == "" || newPin == "" || confirmPin == "") {
      this.msgToAdd = this.translate.instant("pg-post-result.change-pin-fields-required");
      this.modalMessages.show();
    }
    else if (newPin != confirmPin) {
      this.msgToAdd = this.translate.instant("pg-post-result.change-pin-conflicts");
      this.modalMessages.show();
    }
    else {
      this.postresultService.changePIN(oldPin, newPin)
        .subscribe(data => {
          if (data) {
            this.modalChangePin.hide();
            this.msgToAdd = this.translate.instant("pg-post-result.change-pin-success");
            this.modalMessages.show();
          }
          else {
            this.msgToAdd = this.translate.instant("pg-post-result.change-pin-wrong");
            this.modalMessages.show();
          }
        }, error => {
          this.msgToAdd = this.translate.instant("pg-post-result.change-pin-error");
          this.modalMessages.show();
          console.log(error);
        });
    }
  }

  getPostedRound() {
    this.postresultService.getPostedRounds()
      .subscribe(data => {
        this.PostedRoundFinished = data;
        this.finishedHelper = this.PostedRoundFinished;
        for (let index = 0; index < this.PostedRoundFinished.length; index++) {
          switch (this.activeLang) {
            case 'ptbr':
              this.roundName = this.PostedRoundFinished[index].name;
              break;
            case 'en':
              this.roundName = this.PostedRoundFinished[index].nameEng;
              break;
            case 'it':
              this.roundName = this.PostedRoundFinished[index].nameItaly;
              break;
            case 'es':
              this.roundName = this.PostedRoundFinished[index].nameSpan;
              break;
            default:
              break;
          }
          this.PostedRoundList.push({
            id: this.PostedRoundFinished[index].id,
            startDateTime: this.PostedRoundFinished[index].startDateTime,
            endDateTime: this.PostedRoundFinished[index].endDateTime,
            name: this.roundName,
            number: this.PostedRoundFinished[index].number,
            rankRound: this.PostedRoundFinished[index].rankRound,
            singleMatchRound: this.PostedRoundFinished[index].singleMatchRound,
            jiuJitsuRound: this.PostedRoundFinished[index].jiuJitsuRound
          });
        }
      }, error => {
        console.log(error);
        this.msgStandard(this.translate.instant("pg-post-result.failed-round"), this.translate.instant("pg-post-result.failed-round-info") +
          this.translate.instant("pg-post-result.assistence-contact"), 4)
      })
  }

  getMatchFromRoundSelected(roundId: number) {
    this.postresultService.getOrderedResultMatches(roundId).subscribe(data => {
      this.RoundPostedMatch = data;
      const round = this.PostedRoundFinished.find(x => x.id == roundId)
      if (round.singleMatchRound != 0) {
        this.isPostedRankRound = false;
        this.isPostedSingleRound = true;
        var BollPoss
        data.forEach(element => {
          BollPoss = element.moreBallPossession.split(",");
        })
        this.RoundPostedMatch[0].moreBallPossessionHome = BollPoss[0];
        this.RoundPostedMatch[0].moreBallPossessionAway = BollPoss[1];
      } else if (round.rankRound != 0) {
        this.isPostedRankRound = true;
        this.isPostedSingleRound = false;
      } else {
        this.isPostedRankRound = false;
        this.isPostedSingleRound = false;
      }
    })
  }

  cancelPostedRound(roundId: number) {
    this.loading = true;
    this.postresultService.cancelPostedRound(roundId, this.userAdmin.login).subscribe(data => {
      this.loading = false;
      this.modalCancelResult.show();
    }, error => {
      this.loading = false;
      this.msgStandardDanger(this.translate.instant("PostCancelError"), this.translate.instant("PostCancelMessageError") +
        this.translate.instant("pg-post-result.assistence-contact"), 4);
      console.log(error);
    })
  }

  filterRounds(event: Event) {
    let baseFilter = this.info;
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    baseFilter = baseFilter.filter(x => x.name.toLowerCase().includes(filterValue));
    this.roundsFiltered = baseFilter;
  }

  fiterPostedRounds(event: Event) {
    let baseFilter = this.PostedRoundFinished;
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    baseFilter = baseFilter.filter(x => x.name.toLowerCase().includes(filterValue));
    this.finishedHelper = baseFilter;
  }

  reloadComponent() {
    const currentRoute = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigateByUrl(currentRoute);
    })
  }

  getRoundsToResult() {
    this.postresultService.getRoundToResult()
      .subscribe(rounds => {
        rounds.forEach(round => {
          this.roundsToResult.push(round);
        });
      });
    this.roundsToResultHelper = this.roundsToResult;
  }

  filterRoundsToResult(event: Event) {
    let baseFilter = this.roundsToResult;
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    baseFilter = baseFilter.filter(x => x.name.toLowerCase().includes(filterValue));
    this.roundsToResultHelper = baseFilter;
  }

  SetEventsOrMatchs(round) {
    this.result.roundId = round.id;
    this.result.style = 3;
    this.result.styleName = round.styleName;
    this.isChampionship = !round.rankRound && !round.jiuJitsuRound && !round.singleMatchRound ? true : false;
    if (round.jiuJitsuRound == 0 && round.singleMatchRound == 0 && round.rankRound == 1) {
      this.result.style = 2;
    }

    if (round.jiuJitsuRound == 1 && round.singleMatchRound == 1 && round.rankRound == 1) {
      this.result.style = 1;
    }

    if (round.matches != null && round.matches.length > 0) {
      this.SetMatches(round.matches);
    } else {
      this.SetEvents(round.events)
    }
  }

  SetMatches(matchs) {
    this.matchsToResult = [];
    this.eventsToResult = [];

    matchs.forEach(match => {
      let viewMatch: ViewResultMatch = {
        id: match.id,
        two_options: match.two_options,
        homeName: match.homeName,
        awayName: match.awayName,
        dateTime: match.dateTime,
        result: 'X',
        goalsAwayTeam: "",
        goalsHomeTeam: "",
        status: 1
      };

      this.matchsToResult.push(viewMatch);
    })
  }

  SetEvents(events) {
    this.matchsToResult = [];
    this.eventsToResult = [];

    events.forEach(event => {
      let viewEvent: ViewResultEvent = {
        id: event.id,
        odd: event.odd,
        homePoints: "",
        awayPoints: "",
        name: event.name,
        type: event.type,
        result: ""
      }
      this.eventsToResult.push(viewEvent);
    });
  }

  cancelMatch(i: number) {
    switch (this.matchsToResult[i].status) {
      case 0:
        this.matchsToResult[i].status = 1;
        this.matchsToResult[i].result = "";
        break;

      case 1:
        this.matchsToResult[i].status = 0;
        this.matchsToResult[i].goalsHomeTeam = '';
        this.matchsToResult[i].goalsAwayTeam = '';
        this.matchsToResult[i].result = "¢" + this.matchsToResult[i].homeName;
        break;
    }
  }

  checkStatusMatch(i: number) {
    return this.matchsToResult[i].status == 1 ? true : false;
  }

  postResultRound() {
    this.submitResult = true;

    this.result.events = [];
    this.result.matches = [];
    var result = '';

    if (this.matchsToResult.length > 0) {

      if (!this.isChampionship) {
        this.matchsToResult.sort((a, b) => {
          if (a.result[0] === "¢" && b.result[0] !== "¢") return 1;
          if (a.result[0] !== "¢" && b.result[0] === "¢") return -1;

          return parseInt(a.result) > parseInt(b.result) ? 1 : -1;
        })

        this.matchsToResult.forEach(matchToResult => {
          let match: ResultMatch = {
            id: matchToResult.id,
            status: matchToResult.status,
            matchWinner: matchToResult.result
          };

          result += matchToResult.result[0] == "¢" ? matchToResult.result + '|' : matchToResult.homeName + '|';

          this.result.matches.push(match);
          this.result.style = 1;
        });
      } else {
        this.matchsToResult.forEach(matchToResult => {
          let match: ResultMatch = {
            id: matchToResult.id,
            status: matchToResult.status,
            matchWinner: matchToResult.result
          };

          result += matchToResult.result + '|';

          this.result.matches.push(match);
          this.result.style = 1;
        });
      }
    }

    if (this.eventsToResult.length > 0) {
      this.eventsToResult.forEach(eventToResult => {
        let event: ResultEvent = {
          id: eventToResult.id,
          result: eventToResult.result
        };

        result += eventToResult.result + '|';
        this.result.events.push(event);
        this.result.style = 2;
      });
    }

    result = result.substring(0, result.length - 1);
    let userLoged = this.loginService.getUserInfoFromCookie();

    let resultRequest = {
      result: result,
      roundId: this.result.roundId,
      SkinId: parseInt(localStorage.getItem("creatorId")),
      agentLogin: userLoged.login,
      ipAddress: localStorage.getItem("currentUserIp"),
      userId: this.userAdmin.id,
      IsAssistant: Number(localStorage.getItem("isAssistant"))
    }
    this.postresultService.PostRoundResult(resultRequest)
      .subscribe(data => {
        this.modalPost.hide();
        this.submitResult = false;
        this.msgToAdd = "Result has been posted";
        this.modalMessages.show();
      }, error => {
        this.submitResult = false;
        this.msgToAdd = this.translate.instant("pg-result." + error.error);
        this.titleToAdd = this.translate.instant("pg-result.number-error")
        this.modalMessagesDanger.show();
        console.log(error);
      });

  }

  upDateResultChampionship(i: number) {
    if (this.matchsToResult[i].goalsHomeTeam > this.matchsToResult[i].goalsAwayTeam) {
      this.matchsToResult[i].result = "1"
    }

    if (this.matchsToResult[i].goalsHomeTeam == this.matchsToResult[i].goalsAwayTeam) {
      this.matchsToResult[i].result = "X"
    }

    if (this.matchsToResult[i].goalsHomeTeam < this.matchsToResult[i].goalsAwayTeam) {
      this.matchsToResult[i].result = "2"
    }
  }
}
