import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent, MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/datepicker';
import { InteractionService } from 'src/app/services/interaction.service';
import { Currency } from 'src/app/interface/currency.interface';
import { User } from 'src/app/interface/user.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-financial-activity',
  templateUrl: './financial-activity.component.html',
  styleUrls: ['./financial-activity.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class FinancialActivityComponent implements OnInit {
  // displayedColumns: string[] = ['districts', 'financial', 'activities', 'final', 'detail'];
  displayedColumns: string[] = ['financial', 'activities', 'final', 'detail'];
  dataSource = new MatTableDataSource<any>([]);

  displayedColumnsDetails: string[] = ['clubs', 'financial', 'activities', 'jurisdiction', 'contability', 'value', 'percentual'];
  dataSourceDetails = new MatTableDataSource<any>([]);

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  Currency: Currency = <Currency>{};

  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  userAdmin: User;

  start_date: any = new Date();
  end_date: any = new Date();
  seletedJurisdiction: any;
  dataListUser: any;
  usersList: any = [];
  allowUsersList: any = [];
  jurisdictions: any = [];
  selectedJurisdictionLevel: any;
  data: any;
  selected: { startDate: Moment, endDate: Moment }
  group: any = "6";
  selectedJuris: any;
  selectJurisId: any;
  selectedJurisId: any;
  selectJurisIdName: any = null;
  missingJurisName: boolean = true;

  startDate = new FormControl(new Date());
  picker1: any;
  endDate = new FormControl(new Date());
  picker2: any;
  userAssis: any;
  final: any;
  allBet: number = 0;
  toPay: string = '';
  profit: any;
  UserJurisLoguer: boolean = false;

  constructor(
    public loginService: LoginService,
    private _snackBar: MatSnackBar,
    public spinner: NgxSpinnerService,
    private _adapter: DateAdapter<any>,
    private interactionService: InteractionService,
    private translate: TranslateService
  ) {
    this.userAdmin = loginService.getUserInfoFromCookie();
  }
  ngOnInit(): void {
    this._adapter.setLocale('br')
    this.getJurisdictions();
    this.loginService.setGlobalCurrency();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  getUsersList() {
    var UserId: number = null;
    this.loginService.GetUsersAssisList().subscribe(data => {
      data.forEach(us => {
        if (us.userName == this.userAdmin.login && us.id == this.userAdmin.id) {
          UserId = us.adminParentID
        }
        else if (UserId == null) {
          UserId = this.userAdmin.id;
        }
      })
      this.loginService.GetUsersList(UserId).subscribe(res => {
        this.dataListUser = res;
        res.forEach(element => {
          if (element.jurisdictionId == this.selectedJurisdictionLevel && element.id == UserId) {
            this.allowUsersList.push(element)
            this.usersList.push(element)
          }
        });
        this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
        this.loginService.getUsersTreeList(UserId)
          .subscribe(res => {
            res.forEach(element => {
              this.usersList.push(element)
            });
            this.seletedJurisdiction = -1
          })
      })
    });
  }

  getJurisdictions() {
    this.loginService.getJurisdictions(parseInt(localStorage.getItem("currentUserJurisdictionId"))).subscribe(res => {
      //this.jurisdictions = res
      for (let juris of res) {
        if (juris.id != 7) this.jurisdictions.push(juris);
      }
      this.selectedJurisdictionLevel = parseInt(localStorage.getItem("currentUserJurisdictionId"))
      this.getUsersList()
    })
  }

  filterBtn() {
    this.start_date = this.startDate.value
    this.end_date = this.endDate.value
    this.selectedJurisId = this.selectJurisId;
    if (!this.start_date || !this.end_date) {
      this.message("Please select Date Range")
      return
    }
    else if (this.start_date > this.end_date) {
      this.message("Start Date cannot be higher than End Date")
      return
    }
    this.dataSourceDetails.data = []
    this.dataSourceDetails.paginator = this.paginator.toArray()[1];
    this.data = []
    if (this.seletedJurisdiction != -1) {
      this.getFinancialActivityFilter(this.seletedJurisdiction)
    } else {
      this.allowUsersList.forEach(element => {
        this.getFinancialActivityFilter(element.id)
      });
    }
  }

  setLabelPerValue(value: any, jurisdictionId: any): string {
    if (value < 0) {
      if (jurisdictionId != "1") {
        return this.translate.instant('Incoming');
      }
      return this.translate.instant('To Pay');
    } else {
      if (jurisdictionId == "1") {
        return this.translate.instant('Incoming');
      }
      return this.translate.instant('To Pay');
    }
  }

  setLabel(element: any) {
    if (element.activity.children[0].financial < 0) {
      return this.translate.instant('To Pay');
    } else {
      return this.translate.instant('Incoming');
    }
  }

  getFinancialActivityFilter(jurisdiction) {
    let financialActivityFilter = {};
    financialActivityFilter['jurisdiction'] = parseInt(jurisdiction);
    financialActivityFilter['dateStart'] = this.start_date;
    financialActivityFilter['dateEnd'] = this.end_date;
    let clubs = [];
    this.loginService.getFinancialActivityFilter(financialActivityFilter).subscribe((res: any) => {
      let deposit = 0
      let withdraw = 0
      let bet = 0
      let rake = 0
      let commission = 0
      let ori_commission = 0
      let children = []
      res.forEach(item => {
        if (item.type == 1) {
          deposit += item.amount
        } else if (item.type == 2) {
          withdraw += item.amount
        } else if (item.type == 3) {
          bet += item.amount
          rake += item.realRake
          this.allBet += bet
        }
        children.push(item);
        clubs.push(item);
      });
      clubs = clubs.filter((item, index, arr) => arr.indexOf(item) === index && item != null);
      commission = rake * this.getJurisdictionById(jurisdiction).commission / 100;
      ori_commission = this.getJurisdictionById(jurisdiction).commission;
      if (deposit != 0 || withdraw != 0 || bet != 0) {
        this.data.push({
          districts: this.getJurisdiction(jurisdiction).login,
          financial: [deposit, withdraw],
          activities: [bet, rake, commission, ori_commission],
          final: deposit - withdraw - commission,
          children: children
        })
      }
      if(this.getJurisdiction(jurisdiction).jurisdictionId != 6) {
        this.final = undefined;
        this.calcFinalContability(clubs, res, financialActivityFilter['jurisdiction']);
      };
      if(this.getJurisdiction(jurisdiction).jurisdictionId == 6) {
        this.final = deposit - withdraw - commission;
      };
      this.dataSource.data = this.data
      this.dataSource.paginator = this.paginator.toArray()[0];
    })
  }

  async calcFinalContability(clubs, financial, jurisdictionId) {
    let commission: number = 0;
    let profit: number = 0;
    this.loginService.getUserFinancialDetail(jurisdictionId).subscribe(details => {
      for (let i = 0; i < clubs.length; i++) {
        details.forEach(x => {
          if (x.club == clubs[i].club) {
            if (clubs[i].type == 1) {
              x.financial.deposit += clubs[i].amount;
            } else if (clubs[i].type == 2) {
              x.financial.withdraw += clubs[i].amount;
            } else if (clubs[i].type == 3) {
              x.activity.bet += clubs[i].amount;
              x.activity.rake += clubs[i].realRake;
            }
          };
        });
      };
      details = details.filter(data => data.financial.deposit != 0 || data.activity.bet != 0);
      details.forEach(jurisdiction => {
        jurisdiction.activity.children = jurisdiction.activity.children.reverse();
        if (jurisdiction.activity.children[1].commission === 0) {
          if (jurisdiction.activity.children[0].commission === 100) {
            jurisdiction.activity.children[0].commission = jurisdiction.activity.children[0].commission - jurisdiction.activity.children[jurisdiction.activity.children.length - 1].ori_commission;
          }
          for(let i = 0; i < jurisdiction.activity.children.length; i++) {
            if(jurisdiction.activity.children[i].commission != 0 && i != 0) {
              commission += jurisdiction.financial.deposit - jurisdiction.financial.withdraw - ((jurisdiction.activity.rake * jurisdiction.activity.children[i].ori_commission) / 100);
            }
          }
        } else {
          if(jurisdiction.activity.children[0].jurisdictionId == 1) {
            commission += jurisdiction.financial.deposit - jurisdiction.financial.withdraw - ((jurisdiction.activity.rake * jurisdiction.activity.children[1].ori_commission) / 100);
          }
          if(jurisdiction.activity.children[0].jurisdictionId > 1 && jurisdiction.activity.children[0].jurisdictionId < 6) {
            commission += jurisdiction.financial.deposit - jurisdiction.financial.withdraw - ((jurisdiction.activity.rake * jurisdiction.activity.children[0].ori_commission) / 100);
            if(jurisdiction.activity.children[0].commission == 0 && jurisdiction.activity.children[1].jurisdictionId == 6) {
              commission = 0;
              commission += jurisdiction.financial.deposit - jurisdiction.financial.withdraw - ((jurisdiction.activity.rake * jurisdiction.activity.children[1].ori_commission) / 100);
            }
          }
          if(jurisdiction.activity.children[0].jurisdictionId == 6) {
            commission += jurisdiction.financial.deposit - jurisdiction.financial.withdraw - ((jurisdiction.activity.rake * jurisdiction.activity.children[1].ori_commission) / 100);
          }
        }
      })
      this.final = commission;
    });
  }


  async detailsBtn(children, jurisdictionName) {
    if (children.length == 0) {
      this.dataSourceDetails.data = []
      this.dataSourceDetails.paginator = this.paginator.toArray()[1];
      return
    }
    let details = []
    this.spinner.show()
    this.loginService.getUserFinancialDetail(this.getJurisdictionByName(jurisdictionName).id).subscribe(details => {
      this.spinner.hide()
      for (let i = 0; i < children.length; i++) {
        let element = children[i]
        if (element.jurisdictionId == 6 && this.group == "6") {
          // let flag = false
          let deposit = 0
          let withdraw = 0
          let bet = 0
          let rake = 0
          let commission = 0
          let jurisdictionId = 0
          let player = ""
          jurisdictionId = element.jurisdictionId
          player = element.player
          if (element.type == 1) {
            deposit = element.amount
          } else if (element.type == 2) {
            withdraw = element.amount
          } else if (element.type == 3) {
            bet = element.amount
            rake = element.realRake
            commission = element.realRake * element.commission / 100
          }
          details.forEach(item => {
            if (element.player == item.club) {
              // flag = true
              item.financial.deposit += deposit
              item.financial.withdraw += withdraw
              item.activity.bet += bet
              item.activity.rake += rake
            }
          })
        } else if (element.jurisdictionId == 7) {
          let deposit = 0
          let withdraw = 0
          let bet = 0
          let rake = 0
          let commission = 0
          let jurisdictionId = 0
          jurisdictionId = element.jurisdictionId
          if (element.type == 1) {
            deposit = element.amount
          } else if (element.type == 2) {
            withdraw = element.amount
          } else if (element.type == 3) {
            bet = element.amount
            rake = element.realRake
            commission = element.realRake * element.commission / 100
          }
          if (this.group == "6") {
            details.forEach(item => {
              if (element.club == item.club) {
                item.financial.deposit += deposit
                item.financial.withdraw += withdraw
                item.activity.bet += bet
                item.activity.rake += rake
              }
            });
          } else if (this.group == "7") {
            details.forEach(item => {
              if (element.player == item.club) {
                item.financial.deposit += deposit
                item.financial.withdraw += withdraw
                item.activity.bet += bet
                item.activity.rake += rake
              }
            });
          }
        }
      }
      let final = []
      let finalContability: number = 0;
      let financial = { financial: 0 };
      details.forEach((element, i) => {
        let contability = []
        element.activity.children = element.activity.children.reverse();
        element.activity.children.forEach((item, i) => {
          finalContability = element.financial.deposit - element.financial.withdraw - ((element.activity.rake * item.ori_commission) / 100);
          if (jurisdictionName === item.player) {
            financial = { financial: finalContability + (element.activity.rake * item.commission / 100) };
            Object.assign(item, financial);
          };
          this.calcJurisdictionDetails(element);
          if (i > 0) {
            let plus = contability[i - 1].contability
            contability.push({
              jurisdictionId: item.jurisdictionId,
              player: item.player,
              contability: plus - element.activity.rake * item.commission / 100
            })
          } else {
            contability.push({
              jurisdictionId: item.jurisdictionId,
              player: item.player,
              contability: element.financial.deposit - element.financial.withdraw - ((element.activity.rake * item.commission) / 100),
            })
          }
        })
        if (element.financial.deposit != 0 || element.financial.withdraw != 0 || element.activity.bet != 0) {
          final.push(element);
        }
      });
      this.dataSourceDetails.data = final;
      this.selectedJuris = final;
      this.dataSourceDetails.paginator = this.paginator.toArray()[1];
    })
  }

  calcJurisdictionDetails(element) {
    if(element.activity.children[1].commission === 0) {
      if(element.activity.children[0].commission === 100) {
        element.activity.children[0].commission = element.activity.children[0].commission - element.activity.children[element.activity.children.length - 1].ori_commission;
      }

      for(let i = 0; i < element.activity.children.length; i++) {
        if(element.activity.children[1].commission == 0) {
          element.activity.children[0].financial = element.financial.deposit - element.financial.withdraw - ((element.activity.rake * element.activity.children[element.activity.children.length - 1].commission) / 100);
        }
      }
    } else {
      if(element.activity.children[0].jurisdictionId == 5) {
        element.activity.children[0].financial = element.financial.deposit - element.financial.withdraw - ((element.activity.rake * element.activity.children[element.activity.children.length - 1].commission) / 100);
      }
    }
  }

  jurisdictionLevelChange(event) {
    this.selectedJurisdictionLevel = event.value;
    this.allowUsersList = []
    var UserId: number = null;
    this.loginService.GetUsersAssisList().subscribe(data => {
      data.forEach(us => {
        if (us.userName == this.userAdmin.login && us.id == this.userAdmin.id) {
          UserId = us.adminParentID
        }
        else if (UserId == null) {
          UserId = this.userAdmin.id;
        }
      })
      this.usersList.forEach(element => {
        if (element.jurisdictionId == this.selectedJurisdictionLevel) {
          if (parseInt(localStorage.getItem("currentUserJurisdictionId")) == this.selectedJurisdictionLevel) {
            if (element.id == UserId) {
              this.allowUsersList = [element];
              this.selectJurisIdName = element.id;
              this.missingJurisName = false;
              this.jurisdictionChange()
            }
          } else {
            this.allowUsersList.push(element);
            this.selectJurisIdName = null;
            this.seletedJurisdiction = -1
            this.missingJurisName = true;
          }
        }
      });
      this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
      //this.seletedJurisdiction = -1
    });
  }

  jurisdictionChange() {
    this.seletedJurisdiction = this.selectJurisIdName;
    if (this.seletedJurisdiction == -1) {
      this.missingJurisName = true;
    }
    else this.missingJurisName = false;
  }

  getActiveJurisName(element: any): string {
    return element.find(x => x.jurisdictionId === (Number(this.selectedJurisId) + 1));
  }

  async getClubs(children, details, jurisdictionName) {
    for (let i = 0; i < children.length; i++) {
      let element = children[i]
      if (element.jurisdictionId == 6) {
        let activities = [{
          jurisdictionId: element.jurisdictionId,
          player: element.login,
          commission: element.commission,
          ori_commission: element.commission
        }]
        let result = await this.getUser(element.parentId, this.getJurisdictionByName(jurisdictionName).id, activities);
        details.push({
          club: element.login,
          financial: { deposit: 0, withdraw: 0 },
          activity: { bet: 0, rake: 0, children: result },
        })
      } else {
        if (element.children.length > 0) {
          details = await this.getClubs(element.children, details, jurisdictionName)
        }
      }
    }
    return details
  }

  async getPlayers(children, details, jurisdictionName) {
    for (let i = 0; i < children.length; i++) {
      let element = children[i]
      if (element.jurisdictionId == 7) {
        let activities = [{
          jurisdictionId: element.jurisdictionId,
          player: element.login,
          commission: element.commission,
          ori_commission: element.commission
        }]
        let result = await this.getUser(element.parentId, this.getJurisdictionByName(jurisdictionName).id, activities)
        details.push({
          club: element.login,
          financial: { deposit: 0, withdraw: 0 },
          activity: { bet: 0, rake: 0, children: result },
        })
      } else {
        if (element.children.length > 0) {
          details = await this.getPlayers(element.children, details, jurisdictionName)
        }
      }
    }
    return details
  }

  async getUser(id, finalId, activities) {
    return await this.loginService.getUser(id).toPromise().then(async data => {
      activities.push({
        jurisdictionId: data.jurisdictionId,
        player: data.login,
        ori_commission: data.commission,
        commission: data.commission - activities[activities.length - 1].ori_commission
      })
      if (data.id == finalId) {
        return activities
      } else {
        activities = await this.getUser(data.parentId, finalId, activities)
        return activities
      }
    })
  }

  getJurisdictionName(id) {
    return this.jurisdictions.find(e => e.id == id)
  }

  getJurisdiction(id) {
    return this.allowUsersList.find(e => e.id == id)
  }

  getJurisdictionByName(name) {
    return this.usersList.find(e => e.login == name)
  }

  getJurisdictionById(id) {
    let user = this.usersList.find(e => e.id == id);
    return user;
  }

  getJurisdictionLoginById(id) {
    if (this.usersList.find(e => e.id == id) && id != -1) {
      let jurisdiction = this.usersList.find(e => e.id == id);
      return jurisdiction.login;
    }
  }

  checkedUser() {
    let jurisdiction = this.usersList.find(e => e.id == this.userAdmin.id);
    if (jurisdiction.jurisdictionId != this.selectedJurisdictionLevel) return true;
    else return false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterDetails(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceDetails.filter = filterValue.trim().toLowerCase();
  }

  commafy(num) {
    var str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }

  formattNumber(num) {
    if (num != undefined) {
      if (num) {
        let str = num.toString().split('.');
        if (str[0].length >= 5) {
          str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
          str[1] = str[1].replace(/(\d{3})/g, '$1');
        }
        return this.Currency.currencySymbol + ' ' + str.join('.');
      }
    }
  }

  handleChange(event) {
    this.group = event.target.value
  }

  message(message) {
    this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
}
