import { Country } from './../interface/country.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InteractionService } from './interaction.service';
import { IpConfigService } from './Ip-Config.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  apiUrl = '';
  constructor(private http: HttpClient, private interactionService: InteractionService, private ipConfigService: IpConfigService) {
    this.apiUrl = ipConfigService.getApiUrl();
   }

  getCountries() {
    return this.http.get<Country[]>(this.apiUrl + '/country');
  }

  getCountry(countryId: number) {
    return this.http.get<Country>(this.apiUrl + '/country/' + countryId);
  }

  addCountry(country: Country) {
    return this.http.post<any>(`${this.apiUrl}/country/`, country);
  }

  updateCountry(countryId: number, country: Country) {
    return this.http.put<any>(`${this.apiUrl}/country/` + countryId, country);
  }
}
