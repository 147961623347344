import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Transactionsfilter } from '../../../interface/transactions-filter.interface';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Currency } from 'src/app/interface/currency.interface';
import { InteractionService } from 'src/app/services/interaction.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-entity-credit',
  templateUrl: './entity-credit.component.html',
  styleUrls: ['./entity-credit.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class EntityCreditComponent implements OnInit {
  displayedColumns: string[] = ['id', 'coupon', 'date', 'movement', 'description', 'input', 'output', 'balance'];
  dataSource2 = new MatTableDataSource<any>([]);
  jurisdictions: any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  userAdmin: any;
  TREE_USERS: any[] = [];
  treeControl = new NestedTreeControl<JurisdictionNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<JurisdictionNode>();
  Currency: Currency = <Currency>{};

  hasChild = (_: number, node: JurisdictionNode) => !!node.children && node.children.length > 0;

  selectedId: any;
  start_date: any;
  end_date: any;
  credit_below: any = 0;
  selected: { startDate: Moment, endDate: Moment }
  usersList: any;

  startDate = new FormControl(new Date());
  picker1: any;
  endDate = new FormControl(new Date());
  picker2: any;

  constructor(
    public loginService: LoginService,
    private _snackBar: MatSnackBar,
    private interactionService: InteractionService,
    private _adapter: DateAdapter<any>,
    public spinner: NgxSpinnerService
  ) {
    this.userAdmin = loginService.getUserInfoFromCookie();
  }
  ngOnInit(): void {
    this._adapter.setLocale('br')
    this.listTree()
    this.getJurisdictions()
    //debugger;
    this.loginService.setGlobalCurrency()
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  listTree() {
    this.spinner.show();
    this.loginService.GetUsersList(this.userAdmin.id).subscribe(res => {
      this.usersList = res
    })
    var userAdmin = this.loginService.getUserInfoFromCookie();
    this.loginService.getUserTree(userAdmin.id, userAdmin.login)
      .subscribe(data => {


        this.TREE_USERS.push({
          name: data.login,
          login: data.login,
          id: data.id,
          jurisdictionId: data.jurisdictionId,
          children: data.children,
          credit: data.credit,
          credit_right_below: this.getBelow(data.children).credit_right_below,
          credit_below: this.getBelow(data.children).credit_below
        });


        this.dataSource.data = this.TREE_USERS;
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });
  }

  getJurisdictions() {
    this.loginService.getJurisdictions(parseInt(localStorage.getItem("currentUserJurisdictionId"))).subscribe(res => {
      this.jurisdictions = res
    })
  }

  getJurisdictionName(id) {
    return this.jurisdictions.find(e => e.id == id)
  }

  getBelow(children) {
    let credit_right_below = 0
    let credit_below = 0
    children.forEach(element => {
      credit_right_below += element.credit
      credit_below += element.credit
      credit_below += this.getBelow(element.children).credit_below
      if (element.children.length > 0) {
        element['credit_right_below'] = this.getBelow(element.children).credit_right_below
        element["credit_below"] = this.getBelow(element.children).credit_below
      }
    });
    return { credit_right_below, credit_below }
  }

  detailBtn(user) {
    this.selectedId = user.id
    this.start_date = null
    this.end_date = null
    this.selected = null
  }

  getUserNameById(id) {
    return this.usersList.find(e => e.id == id)
  }

  getTableBackground(type) {
    switch (type) {
      case 1:
        return "lightgreen"
        break;
      case 2:
        return "pink"
        break;
      case 3:
        return "cyan"
        break;

      default:
        break;
    }
  }

  /*changeDate(event) {
    if (this.selected) {
      if (this.selected.startDate) {
        this.start_date = this.selected.startDate.toDate()
        this.end_date = this.selected.endDate.toDate()
        let transactionstFilter = {} as Transactionsfilter; 
        transactionstFilter.userId = this.selectedId;    
        transactionstFilter.dateStart = this.start_date;
        transactionstFilter.dateEnd = this.end_date;
        transactionstFilter.activeLang = localStorage.getItem("Bolao.locale");
        this.loginService.getTransactionsFilter(transactionstFilter).subscribe(data => {
          data.forEach(element => {
            element["date_date"] = new Date(element.date).toLocaleDateString()
            element["date_time"] = new Date(element.date).toLocaleTimeString()
          });
          this.dataSource2.data = data;
          this.dataSource2.paginator = this.paginator;
        }, error => {
          console.log(error);
        });
      }
    }
  }*/

  changeDate() {

    //this.start_date = this.selected.startDate.toDate()
    //this.end_date = this.selected.endDate.toDate()

    this.start_date = this.startDate.value
    this.end_date = this.endDate.value

    if (!this.start_date || !this.end_date) {
      this.message("Please select Date Range")
      return
    }
    else if (this.start_date > this.end_date) {
      this.message("Start Date cannot be higher than End Date")
      return
    }

    let transactionstFilter = {} as Transactionsfilter;
    transactionstFilter.userId = this.selectedId;
    transactionstFilter.dateStart = this.start_date;
    transactionstFilter.dateEnd = this.end_date;
    transactionstFilter.activeLang = localStorage.getItem("Bolao.locale");
    this.loginService.getTransactionsFilter(transactionstFilter).subscribe(data => {
      data.forEach(element => {
        element["date_date"] = new Date(element.date).toLocaleDateString()
        element["date_time"] = new Date(element.date).toLocaleTimeString()
      });
      this.dataSource2.data = data;
      this.dataSource2.paginator = this.paginator;
    }, error => {
      console.log(error);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  getBackground(id) {
    switch (id) {
      case 1:
        return ""
        break;
      case 2:
        return "#FF9999"
        break;
      case 3:
        return "#EF99FF"
        break;
      case 4:
        return "#FFC199"
        break;
      case 5:
        return "#BAFFBA"
        break;
      case 6:
        return "#A6DADB"
        break;
      case 7:
        return ""
        break;

      default:
        break;
    }
  }

  commafy(num) {
    var str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }

  message(message) {
    this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}

interface JurisdictionNode {
  name: string;
  login: string;
  jurisdictionId: number;
  id: number;
  children?: JurisdictionNode[];
  credit: number;
  credit_right_below: number;
}
