<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'editcasino.title' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">person</span>
        {{ 'data.title' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <form [formGroup]="settingForm" (ngSubmit)="onSettingSubmit(settingForm.value)">
        <div class="input-group mb-3">
          <label for="Casino Name">{{ 'casinoname.title' | translate }}</label>
          <input id="Casino Name" formControlName="login" value="{{ userAdmin.login}}" type="text" class="form-control"
            placeholder="Casino World" aria-label="CasinoName" aria-describedby="basic-addon1" spellcheck="false"
            style="max-width: 400px;" />
        </div>
        <div class="input-group mb-3">
          <label for="username">{{ 'admin.username' | translate }}</label>
          <input id="username" formControlName="username" type="text" class="form-control" aria-label="username"
            aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;" />
        </div>
        <div class="input-group mb-3">
          <label for="lastname">{{ 'admin.lastname' | translate }}</label>
          <input id="lastname" formControlName="lastname" type="text" class="form-control" aria-label="lastname"
            aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;" />
        </div>
        <div class="input-group mb-3">
          <label for="coupon">{{ 'admin.coupon' | translate }}</label>
          {{jurisdictionCoupon}}
        </div>
        <div class="input-group mb-3">
          <label for="city">{{ 'settting.city' | translate }}</label>
          <input id="city" type="text" class="form-control" formControlName="city" aria-label="city"
            aria-describedby="basic-addon1" spellcheck="false" style="max-width: 600px;" />
        </div>
        <div class="input-group mb-3">
          <label for="nation">{{ 'admin.nation' | translate }}</label>
          <select class="form-control" formControlName="nation" name="nation" id="nation" style="max-width: 300px;">
            <option *ngFor="let country of countries" value="{{ country.name }}">{{ country.name }}</option>
          </select>
        </div>
        <div class="input-group mb-3" *ngIf="displayMinValue">
          <label for="city">{{ 'settting.minimumValue' | translate }}</label>
          <span class="margin-span">R$</span> <input id="minimumValue" type="number" formControlName="minimumValue"
            class="form-control" aria-label="minimumValue" aria-describedby="basic-addon1" spellcheck="false"
            style="max-width: 250px;" /> <span class="margin-left">,00</span>
        </div>
        <!--       <div class="input-group mb-3">
          <label for="Phone number">{{ 'phonenumber.title' | translate }}</label>
          <input id="Phone number" type="text" class="form-control" placeholder="32546554"
            aria-label="CasinoName" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 300px;"/>
        </div>
        <div class="input-group mb-3">
          <label for="Creation Date">{{ 'creationdate.title' | translate }}</label>
          <input id="Creation Date" value="" type="text" class="form-control"
            aria-label="CasinoName" aria-describedby="basic-addon1" spellcheck="false" style="max-width: 400px;" disabled/>
        </div>
        <div class="input-group">
          <label for="Note">{{ 'notes.title' | translate }}</label>
          <textarea id="Note" class="form-control rounded" aria-label="With textarea" placeholder="nessuna" style="max-width: 600px;"></textarea>
        </div> -->
        <button type="submit" mdbBtn color="primary" class="relative waves-light btn-sm" mdbWavesEffect>{{ 'savebtn' |
          translate }}
          <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="savingSetting"></span>
        </button>
        <!--         <a mdbBtn color="danger" class="relative waves-light btn-sm" mdbWavesEffect (click)="onClickChangePass()">{{ 'changepassword.title' | translate }}
          <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="savingSetting"></span>
        </a> -->
      </form>
    </div>
  </div>
</div>