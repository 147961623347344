import { Withdraw } from './../interface/withdraw.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InteractionService } from './interaction.service';
import { IpConfigService } from './Ip-Config.service';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalService {

  apiUrl = ''; // local test
  constructor(private http: HttpClient, private interactionService: InteractionService, private ipConfigService: IpConfigService) {
    this.apiUrl = ipConfigService.getApiUrl();
  }

  getWithdrawals(statusId: number, userId: number, loggin: string) {
    return this.http.get<Withdraw[]>(`${this.apiUrl}/common/GetPendingWithdrawal?status=${statusId}&userId=${userId}&loggin=${loggin}`);
  }

  getWithdrawalById(id: number) {
    return this.http.get<Withdraw>(`${this.apiUrl}/common/GetWithdrawal?id=${id}`);
  }

  updateWithdrawRequest(request: Withdraw) {
    return this.http.post<any>(`${this.apiUrl}/common/UpdateWithdrawRequest/`, request);
  }
}
