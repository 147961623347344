<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ "Choose Round" | translate }}</a> <br />
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title row">
        <div class="col-md-9">
          <span class="material-icons default-icon">list</span>
          {{ "Choose Round" | translate }}
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <mat-tab-group dynamicHeight>
            <mat-tab label="{{ 'Create Round' | translate }}">
              <div class="w-100">
                <div class="row mt-3 roundCreate">
                  <div class="col-md-6">
                    <div class="float-md-left roundCreateStyle">
                      <mat-radio-group class="row" (change)="handleChange($event)">
                        <mat-radio-button class="col" color="primary" value="NewRound" id="newRound" [checked]="true">
                          {{ "New Round" | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="col" color="primary" value="Continuation" id="continuation">
                          {{ "Continuation" | translate }}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>

                  <!--Choose round style-->
                  <div class="col-md-6">
                    <div class="float-md-right roundCreateStyle">
                      <mat-form-field appearance="fill">
                        <mat-label class="font-weight-bold">
                          {{ "RoundStyle" | translate}}
                        </mat-label>

                        <mat-select>
                          <mat-option value="championship" (click)="SetRoundStyle(0, null)">
                            {{"Rank Round" | translate}}
                          </mat-option>

                          <mat-option value="Rank Round" (click)="SetRoundStyle(1, null)">
                            {{"championship" | translate}}
                          </mat-option>

                          <mat-option [value]="style" *ngFor="let style of roundStyles"
                            (click)="SetRoundStyle(2, style)">
                            {{ style.name | translate }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col-sm-3" *ngIf="roundType == 1">
                  <mat-form-field class="" appearance="fill">
                    <mat-label>{{ "Base Round" | translate }} *</mat-label>
                    <input type="text" matInput [formControl]="myControlListBaseRound" [matAutocomplete]="auto" />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option *ngFor="let rounds of roundsFinished" [class.bg-red]="rounds.posted == 0"
                        (click)="onChangeCurrentCompetition(rounds.id)" [value]="rounds.name">
                        {{ rounds.name }} - {{ rounds.number }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>

                <div *ngIf="roundStyle != null || createRound.roundStyleId == 'gui'">
                  <!--Set Dates and Names for Dynamic Styles-->
                  <div class="rowDefault" *ngIf="roundStyle != null && createRound.roundStyleId != 'gui'">
                    <!--Set Names-->
                    <div class="d-flex align-items-center">
                      <div class="mr-3">
                        <mat-form-field class="example-form-field" appearance="fill">
                          <mat-label>{{ "pg-round.homeTeam" | translate }}</mat-label>
                          <input #inputSingleMatchHome matInput type="text" id="round-name-single-home" name="HomeName"
                            (blur)=" generateIcon( 'SingleMatchHomeIcon',  inputSingleMatchHome.value, false  ) "
                            [(ngModel)]="nameHomeTeamToCreate" />
                          <button matSuffix mat-icon-button aria-label="label" matTooltip="Adicionar Escudo"
                            (click)=" openModalSingleMatchShieldSelect( inputSingleMatchHome.value, 'SingleMatchHomeIcon' ) ">
                            <mat-icon>
                              <div #SingleMatchHomeIcon id="SingleMatchHomeIcon"></div>
                              <mat-icon class="addTeam">add</mat-icon>
                            </mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div>
                        <mat-form-field class="example-form-field" appearance="fill">
                          <mat-label>{{ "pg-round.awayTeam" | translate }}</mat-label>
                          <input #inputSingleMatchAway matInput type="text" id="round-name-single-away" name="AwayName"
                            (blur)=" generateIcon( 'SingleMatchAwayIcon', inputSingleMatchAway.value, false )  "
                            [(ngModel)]="nameAwayTeamToCreate" />
                          <button matSuffix mat-icon-button aria-label="label" matTooltip="Adicionar Escudo"
                            (click)=" openModalSingleMatchShieldSelect( inputSingleMatchAway.value, 'SingleMatchAwayIcon' ) ">
                            <mat-icon>
                              <div id="SingleMatchAwayIcon"></div>
                              <mat-icon class="addTeam">add</mat-icon>
                            </mat-icon>
                          </button>
                        </mat-form-field>
                      </div>
                    </div>

                    <!--Set Dates-->
                    <div class="columnDates pr-3">
                      <div class="row">
                        <mat-form-field appearance="fill" class="mr-3">
                          <mat-label>{{ "startDate" | translate }}</mat-label>
                          <input matInput [matDatepicker]="dateStartRound" #customdate id="dateStartRound" />
                          <mat-datepicker-toggle matSuffix [for]="dateStartRound"></mat-datepicker-toggle>
                          <mat-datepicker #dateStartRound></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                          <mat-label>Hora</mat-label>
                          <input matInput aria-label="24hr format" #customtime [ngxTimepicker]="hourInputStart"
                            id="hourInputStart" />
                          <ngx-material-timepicker-toggle matSuffix
                            [for]="hourInputStart"></ngx-material-timepicker-toggle>
                          <ngx-material-timepicker matSuffix controlOnly="false" #hourInputStart>
                          </ngx-material-timepicker>
                        </mat-form-field>
                      </div>

                      <div class="row">
                        <mat-form-field appearance="fill" class="mr-3">
                          <mat-label>{{ "endDate" | translate }}</mat-label>
                          <input matInput [matDatepicker]="dateEndRound" #customdate id="dateEndRound" />
                          <mat-datepicker-toggle matSuffix [for]="dateEndRound"></mat-datepicker-toggle>
                          <mat-datepicker #dateEndRound></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                          <mat-label>Hora</mat-label>
                          <input matInput aria-label="24hr format" #customtime [ngxTimepicker]="hourInputEnd"
                            id="hourInputEnd" />
                          <ngx-material-timepicker-toggle matSuffix
                            [for]="hourInputEnd"></ngx-material-timepicker-toggle>
                          <ngx-material-timepicker matSuffix controlOnly="false" #hourInputEnd>
                          </ngx-material-timepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <!-- Set Round Name For Championchips or Rank Round-->
                  <div class="row justify-content-center" *ngIf="createRound.roundStyleId == 'gui'">
                    <div class="col-12 mt-3 text-center">
                      <span class="font-weight-bold">{{ "Names" | translate }} *</span>
                    </div>
                    <div class="col-md-1 mt-3">
                      <button (click)="openModalShieldTournament()" type="button" id="shieldBtn">
                        <img style="position: absolute; width: 45px" src="{{ httpIconTournomant }}"
                          onerror="this.src=`https://poolbet365.com/assets/shields/default-logo.png`;" alt="+"
                          alt="+" />
                        <i class="fa fa-plus addTournamentShield" aria-hidden="true"></i>
                      </button>
                    </div>

                    <div class="col-md-2 mt-3">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Portugese</mat-label>
                        <input matInput id="round-name" [(ngModel)]="RoundName" placeholder="Portugese" type="text"
                          name="roundNamePort" (keyup)="translateGoogle()" (blur)="checkTournamentIcon()" />
                      </mat-form-field>
                    </div>

                    <div class="col-md-2 mt-3">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>English</mat-label>
                        <input matInput id="round-name-Eng" [(ngModel)]="RoundNameEng" placeholder="English" type="text"
                          name="roundNameEng" />
                      </mat-form-field>
                    </div>

                    <div class="col-md-2 mt-3">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Spanish</mat-label>
                        <input matInput id="round-name-span" [(ngModel)]="RoundNameSpan" placeholder="Spanish"
                          type="text" name="roundNameSp" />
                      </mat-form-field>
                    </div>

                    <div class="col-md-2 mt-3">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Italian</mat-label>
                        <input matInput id="round-name-italy" [(ngModel)]="RoundNameItaly" placeholder="Italian"
                          type="text" name="roundNameIt" />
                      </mat-form-field>
                    </div>
                  </div>

                  <!-- Set Category-->
                  <div class="row titleSport">
                    <div class="col">
                      <mat-form-field appearance="fill">
                        <mat-label class="font-weight-bold">{{ "Sport" | translate }} *</mat-label>
                        <mat-select [(ngModel)]="sportChoosen" name="sportChoosen" [(value)]="sportChoosen" id="sport"
                          #custommatchSport>
                          <mat-option *ngFor="let sport of sports | async" [value]="sport.id"
                            (click)="getSportCategories(sport.id)">
                            {{ sport.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col">
                      <mat-form-field appearance="fill">
                        <mat-label class="font-weight-bold">
                          {{ "Category" | translate }}
                        </mat-label>
                        <mat-select id="category">
                          <mat-option *ngFor="let category of categories | async" [value]="category"
                            (click)="getCategoryTournaments(category.id)">
                            {{ category.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col">
                      <mat-form-field appearance="fill">
                        <mat-label class="font-weight-bold">
                          {{ "Tournament" | translate }}
                        </mat-label>
                        <mat-select id="tournament">
                          <mat-option *ngFor="let tournament of filteredOptions | async" [value]="tournament"
                            (click)="showTournamentMatches(tournament.id)">
                            {{ tournament.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col">
                      <mat-form-field appearance="fill">
                        <mat-label class="font-weight-bold">
                          {{ "Country" | translate }}
                        </mat-label>
                        <mat-select id="Country" [(ngModel)]="createRound.countryId">
                          <mat-option *ngFor="let country of countries" [value]="country.id"
                            (click)="countryId = country.id">
                            {{ country.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <!--Set Prizes Start-->
                  <div class="rowDefault">
                    <div>
                      <mat-form-field class="example-full-width" appearance="outline" style="width: 200px">
                        <mat-label class="font-weight-bold">{{ "Jackpot" | translate }} *</mat-label>
                        <span matPrefix>{{ Currency.currencySymbol }} &nbsp;</span>
                        <input matInput type="number" id="new-jackpot" class="ml-1 text-center" name="language" min="0"
                          [(ngModel)]="createRound.firstJackpot" (blur)="changeJackpotData()"/>
                        <span matSuffix>,00 &nbsp;</span>
                      </mat-form-field>
                    </div>

                    <div>
                      <mat-form-field matTooltip="Valor Recuperado" class="example-full-width" appearance="outline"
                        style="width: 200px">
                        <mat-label class="font-weight-bold">{{
                          "Recovered Jackpot" | translate
                          }}</mat-label>
                        <span matPrefix>{{ Currency.currencySymbol }} &nbsp;</span>
                        <input matInput class="ml-1 text-center" disabled value="{{
                          recoverdJackpot  * Currency.quote | currency : '' : ''
                        }}" />
                        <!-- <span matSuffix>,00 &nbsp;</span> -->
                      </mat-form-field>
                    </div>

                    <div>
                      <mat-form-field matTooltip="Valor para ser Recuperado" class="example-full-width"
                        appearance="outline" style="width: 200px">
                        <mat-label class="font-weight-bold">
                          {{ "To Recover" | translate }}
                        </mat-label>
                        <span matPrefix class="text-danger">{{ Currency.currencySymbol }} &nbsp;</span>
                        <input matInput class="ml-1 text-center text-danger" disabled
                          value="{{ toRecover * Currency.quote | currency : '' : '' }}" />
                        <!-- <span matSuffix class="text-danger">,00 &nbsp;</span> -->
                      </mat-form-field>
                    </div>

                    <div>
                      <mat-form-field class="example-full-width" appearance="outline" style="width: 200px">
                        <mat-label class="font-weight-bold">{{ "Round Value" | translate }}*</mat-label>
                        <span matPrefix>{{ Currency.currencySymbol }} &nbsp;</span>
                        <input matInput id="round-value" class="ml-1 text-center" placeholder="" type="number" min="0"
                          name="value" [(ngModel)]="createRound.roundValue"/>
                        <span matSuffix>,00 &nbsp;</span>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--Set Prizes End-->

                  <!-- Broken Line -->
                  <div class="col-md-12 mt-3" style="border-bottom: 1px solid gainsboro; margin-bottom: 20px"></div>

                  <!--Stats Dynamic Styles Round-->
                  <div *ngIf="roundStyle != null && createRound.roundStyleId != 'gui'">
                    <div class="panel-heading mb-2">
                      <div class="panel-title row">
                        <div class="col-md-9">
                          <span class="material-icons default-icon">visibility</span>
                          {{ "stats" | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="gridStyle">
                      <div *ngFor="let style of eventStyles; let i = index" class="gridElement">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label class="font-weight-bold">{{ style.name | translate }} </mat-label>
                          <input matInput type="number" id="odd{{i}}" [(ngModel)]="eventStyles[i].odd" (focusout)="updatePreview(i)"
                            min="0" />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <!--Rank Rounds Members-->
                  <div *ngIf="createRound.roundStyleId == 'gui'">
                    <div class="panel-heading mb-2">
                      <div class="panel-title row">
                        <div class="col-md-9">
                          <span class="material-icons default-icon">visibility</span>
                          {{ "Participants" | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="rankRoundAndChampionchipsMembers">
                      <div class="row">
                        <mat-form-field appearance="fill" class="mr-3 w-20">
                          <mat-label>{{ "startDate" | translate }}</mat-label>
                          <input matInput [matDatepicker]="dateStartRankRound" #customdate id="dateStartRankRound" />
                          <mat-datepicker-toggle matSuffix [for]="dateStartRankRound"></mat-datepicker-toggle>
                          <mat-datepicker #dateStartRankRound></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="mr-3">
                          <mat-label>Hora</mat-label>
                          <input matInput aria-label="24hr format" #customtime [ngxTimepicker]="hourInputStartRankRound"
                            id="hourInputStartRankRound" />
                          <ngx-material-timepicker-toggle matSuffix
                            [for]="hourInputStartRankRound"></ngx-material-timepicker-toggle>
                          <ngx-material-timepicker matSuffix controlOnly="false" #hourInputStartRankRound>
                          </ngx-material-timepicker>
                        </mat-form-field>


                        <mat-form-field appearance="fill" class="w-20 mr-3">
                          <mat-label>{{ "Member" | translate }}</mat-label>
                          <input #inputSingleMatchHome matInput type="text" id="round-name-single-home" name="Member"
                            [(ngModel)]="RankRoundMemberName" />
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-20 mr-3" *ngIf="createRound.rankRound == 0">
                          <mat-label>{{ "Member 2" | translate }}</mat-label>
                          <input #inputSingleMatchHome matInput type="text" id="round-name-single-home" name="Member"
                            [(ngModel)]="RankRoundMemberName2" />
                        </mat-form-field>

                        <div class="col mr-3" *ngIf="createRound.rankRound == 0">
                          <b class="position-absolute">{{ "2 results" | translate }}</b>
                          <br />
                          <mat-slide-toggle [formControl]="is2ResultControl" class="ml-3">
                          </mat-slide-toggle>
                        </div>

                        <button matTooltip="Adicionar Partida" mat-fab class="greenDG" (click)="AddRankRoundGame()">
                          <mat-icon>add</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!--Preview-->
                  <div>
                    <div class="panel-heading mb-2">
                      <div class="panel-title row">
                        <div class="col-md-9">
                          <span class="material-icons default-icon">visibility</span>
                          {{ "Preview" | translate }}
                        </div>
                      </div>
                    </div>

                    <!--Dynamic Round Style preview-->
                    <div class="previwPadding" *ngIf="createRound.roundStyleId != null && createRound.roundStyleId != 'gui' ">
                      <div class="PreviewRankRound PreviewTittleBG">
                        <div class="previewItens previewItensPadding">
                          <span>
                            {{"Name" | translate}}
                          </span>
                        </div>

                        <div class="previewItens previewItensPadding">
                          <span>
                            {{"Type" | translate}}
                          </span>
                        </div>
                      </div>

                      <div class="PreviewRankRound" *ngFor="let event of eventsToPreview; let i = index">
                        <div class="previewItens">
                          <span class="mr-auto ml-auto">
                            {{event.name}}
                          </span>
                        </div>

                        <div class="previewItens">
                          <span class="mr-auto ml-auto" *ngIf="event.type == 2">
                            {{event.odd}}
                          </span>

                          <span class="mr-auto ml-auto" *ngIf="event.type == 3">
                            {{"Yes/No" | translate}}
                          </span>

                          <span class="mr-auto ml-auto" *ngIf="event.type == 1">
                            {{"2 Options" | translate}}
                          </span>

                          <span class="mr-auto ml-auto" *ngIf="event.type == 0">
                            {{"3 Options" | translate}}
                          </span>
                          
                          <span class="mr-auto ml-auto" *ngIf="event.type == 4">
                            {{"Even/Odd" | translate}}
                          </span>

                          <span class="mr-auto ml-auto" *ngIf="event.type == 5">
                            {{"Home/Away" | translate}}
                          </span>

                          <span class="mr-auto ml-auto" *ngIf="event.type == 6">
                            {{"Home/Draw/Away" | translate}}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!--Championchip Round preview-->
                    <div class="previwPadding" *ngIf="createRound.rankRound == 0 && createRound.roundStyleId == 'gui'">
                      <div class="PreviewRankChampionchip PreviewTittleBG">
                        <div class="previewItens previewItensPadding">
                          <span class="">
                            Name
                          </span>
                        </div>

                        <div class="previewItens previewItensPadding">
                          <span class="m-auto">
                            Date
                          </span>
                        </div>

                        <div class="previewItens previewItensPadding">
                          <span class="">
                            Delete
                          </span>
                        </div>
                      </div>

                      <div class="PreviewRankChampionchip" *ngFor="let member of RankRoudMembers; let i = index">
                        <div class="d-flex text-center previewItens">
                          <span *ngIf="member.awayName == ''" class="m-auto">
                            {{member.homeName}}
                          </span>

                          <span *ngIf="member.awayName != ''" style="height: 1vw !important;" class="m-auto d-flex" [innerHtml]="generateChampshipMatchPreview(member.homeName, member.awayName)">
                          </span>
                        </div>

                        <div class="previewItens" style="padding: .7rem !important;">
                          <span>
                            {{member.dateTime}}
                          </span>
                        </div>

                        <div class="previewItens">
                          <button matTooltip="Excluir membro" mat-icon-button color="warn" (click)="removeMember(i)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!--Rank preview-->
                    <div class="previwPadding" *ngIf="createRound.rankRound == 1 && createRound.roundStyleId == 'gui'">
                      <div class="PreviewRankRound PreviewTittleBG">
                        <div class="previewItens previewItensPadding">
                          <span class="">
                            Name
                          </span>
                        </div>

                        <div class="previewItens previewItensPadding">
                          <span class="">
                            Delete
                          </span>
                        </div>
                      </div>

                      <div class="PreviewRankRound" *ngFor="let member of RankRoudMembers; let i = index">
                        <div class="d-flex text-center previewItens">
                          <span *ngIf="member.awayName == ''" class="m-auto">
                            {{member.homeName}}
                          </span>

                          <span *ngIf="member.awayName != ''" class="m-auto">
                            {{member.homeName}} X {{member.awayName}}
                          </span>
                        </div>

                        <div class="previewItens">
                          <button matTooltip="Excluir membro" mat-icon-button color="warn" (click)="removeMember(i)">
                            <mat-icon>
                              delete
                            </mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-success px-2" mat-flat-button (click)="CreateRoundDynamicStyle()">
                      {{ "Create Round" | translate }} 
                    </button>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'Edit Round' | translate }}" style="overflow: hidden !important">
              <div class="row mt-3 basechoices">
                <div class="col-md-3">
                  <mat-form-field class="postFilter" appearance="fill">
                    <mat-label>{{ "Base Round" | translate }} </mat-label>
                    <input type="text" matInput (keyup)="filterRoundsToUpdate($event)" [matAutocomplete]="Teteu" />
                    <mat-autocomplete #Teteu="matAutocomplete">
                      <mat-option *ngFor="let roundsPt of RoundsToUpdate" [value]="roundsPt.name"
                        (click)="setRoundToUpdate(roundsPt)">
                        {{ roundsPt.name }} - {{ roundsPt.number }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="startNow">
                  <div class="startNowButton" [ngClass]="isStarted()" (click)="startRound()" *ngIf="RoundToUpdate.id != null">
                    {{"StartNow" | translate}}
                  </div>
                </div>
              </div>

              <div *ngIf="RoundToUpdate != null">
                <!--Set Dates and Names for Dynamic Styles-->
                <div class="rowDefault" *ngIf="checkTypeRound() == 1">
                  <!--Set Names-->
                  <div class="d-flex align-items-center">
                    <div class="mr-3">
                      <mat-form-field class="example-form-field" appearance="fill">
                        <mat-label>{{ "pg-round.homeTeam"| translate }}</mat-label>
                        <input #inputhomeNameToUpdate matInput type="text" id="round-name-single-home" name="HomeName"
                          (blur)=" generateIcon( 'homaNameToUpdateIcon',  inputhomeNameToUpdate.value, false  ) "
                          [(ngModel)]="homaNameToUpdate" />
                        <button matSuffix mat-icon-button aria-label="label" matTooltip="Adicionar Escudo"
                          (click)=" openModalSingleMatchShieldSelect( inputhomaNameToUpdate.value, 'homaNameToUpdateIcon' ) ">
                          <mat-icon>
                            <div id="homaNameToUpdateIcon"></div>
                            <mat-icon class="addTeam">add</mat-icon>
                          </mat-icon>
                        </button>
                      </mat-form-field>
                    </div>

                    <div>
                      <mat-form-field class="example-form-field" appearance="fill">
                        <mat-label>{{ "pg-round.awayTeam" | translate }}</mat-label>
                        <input #inputawayNameToUpdate matInput type="text" id="round-name-single-away" name="AwayName"
                          (blur)=" generateIcon( 'awayNameToUpdateIcon', inputawayNameToUpdate.value, false )  "
                          [(ngModel)]="awayNameToUpdate" />
                        <button matSuffix mat-icon-button aria-label="label" matTooltip="Adicionar Escudo"
                          (click)=" openModalSingleMatchShieldSelect( inputawayNameToUpdate.value, 'awayNameToUpdateIcon' ) ">
                          <mat-icon>
                            <div id="awayNameToUpdateIcon"></div>
                            <mat-icon class="addTeam">add</mat-icon>
                          </mat-icon>
                        </button>
                      </mat-form-field>
                    </div>
                  </div>

                  <!--Set Dates-->
                  <div class="columnDates pr-3">
                    <div class="row">
                      <mat-form-field appearance="fill" class="mr-3">
                        <mat-label>{{ "startDate" | translate }}</mat-label>
                        <input matInput [matDatepicker]="dateStartRound" #customdate id="dateStartRound"
                          [(ngModel)]="startDateToUpdate" />
                        <mat-datepicker-toggle matSuffix [for]="dateStartRound"></mat-datepicker-toggle>
                        <mat-datepicker #dateStartRound></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field appearance="fill">
                        <mat-label>Hora</mat-label>
                        <input matInput aria-label="24hr format" #customtime [ngxTimepicker]="hourInputStart"
                          [(ngModel)]="startHourToUpdate" id="hourInputStart" />
                        <ngx-material-timepicker-toggle matSuffix
                          [for]="hourInputStart"></ngx-material-timepicker-toggle>
                        <ngx-material-timepicker matSuffix controlOnly="false" #hourInputStart>
                        </ngx-material-timepicker>
                      </mat-form-field>
                    </div>

                    <div class="row">
                      <mat-form-field appearance="fill" class="mr-3">
                        <mat-label>{{ "endDate" | translate }}</mat-label>
                        <input matInput [matDatepicker]="dateEndRound" #customdate id="dateEndRound"
                          [(ngModel)]="endDateToUpdate" />
                        <mat-datepicker-toggle matSuffix [for]="dateEndRound"></mat-datepicker-toggle>
                        <mat-datepicker #dateEndRound></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field appearance="fill">
                        <mat-label>Hora</mat-label>
                        <input matInput aria-label="24hr format" #customtime [ngxTimepicker]="hourInputEnd"
                          [(ngModel)]="endHourToUpdate" id="hourInputEnd" />
                        <ngx-material-timepicker-toggle matSuffix [for]="hourInputEnd"></ngx-material-timepicker-toggle>
                        <ngx-material-timepicker matSuffix controlOnly="false" #hourInputEnd>
                        </ngx-material-timepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <!-- Set Round Name For Championchips for Rank Round-->
                <div class="row justify-content-center" *ngIf="checkTypeRound() == 2">
                  <div class="col-12 mt-3 text-center">
                    <span class="font-weight-bold">{{ "Names" | translate }} *</span>
                  </div>
                  <div class="col-md-1 mt-3">
                    <button (click)="openModalShieldTournament()" type="button" id="shieldBtn">
                      <img style="position: absolute; width: 45px" src="{{ httpIconTournomant }}"
                        onerror="this.src=`https://poolbet365.com/assets/shields/default-logo.png`;" alt="+" alt="+" />
                      <i class="fa fa-plus addTournamentShield" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div class="col-md-2 mt-3">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Portugese</mat-label>
                      <input matInput id="round-name" [(ngModel)]="RoundToUpdate.name" placeholder="Portugese"
                        type="text" name="roundNamePort" (keyup)="translateGoogleToUpdate()"
                        (blur)="checkTournamentIcon()" />
                    </mat-form-field>
                  </div>

                  <div class="col-md-2 mt-3">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>English</mat-label>
                      <input matInput id="round-name-Eng" [(ngModel)]="RoundToUpdate.nameEng" placeholder="English"
                        type="text" name="roundNameEng" />
                    </mat-form-field>
                  </div>

                  <div class="col-md-2 mt-3">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Spanish</mat-label>
                      <input matInput id="round-name-span" [(ngModel)]="RoundToUpdate.nameSpan" placeholder="Spanish"
                        type="text" name="roundNameSp" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-2 mt-3">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Italian</mat-label>
                      <input matInput id="round-name-italy" [(ngModel)]="RoundToUpdate.nameItaly" placeholder="Italian"
                        type="text" name="roundNameIt" />
                    </mat-form-field>
                  </div>
                </div>

                <!-- Set Category-->
                <div class="row titleSport">
                  <div class="col">
                    <mat-form-field appearance="fill">
                      <mat-label class="font-weight-bold">{{ "Sport" | translate }} *</mat-label>
                      <mat-select [(ngModel)]="RoundToUpdate.betradarSportId" name="sportChoosen"
                        [(value)]="sportChoosen" id="sport" #custommatchSport>
                        <mat-option *ngFor="let sport of sports | async" [value]="sport.id"
                          (click)="getSportCategories(sport.id)">
                          {{ sport.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col">
                    <mat-form-field appearance="fill">
                      <mat-label class="font-weight-bold">
                        {{ "Category" | translate }}
                      </mat-label>
                      <mat-select id="category">
                        <mat-option *ngFor="let category of categories | async" [value]="category"
                          (click)="getCategoryTournaments(category.id)">
                          {{ category.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col">
                    <mat-form-field appearance="fill">
                      <mat-label class="font-weight-bold">
                        {{ "Tournament" | translate }}
                      </mat-label>
                      <mat-select id="tournament">
                        <mat-option *ngFor="let tournament of filteredOptions | async" [value]="tournament"
                          (click)="showTournamentMatches(tournament.id)">
                          {{ tournament.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col">
                    <mat-form-field appearance="fill">
                      <mat-label class="font-weight-bold">
                        {{ "Country" | translate }}
                      </mat-label>
                      <mat-select id="Country" [(ngModel)]="RoundToUpdate.countryId">
                        <mat-option *ngFor="let country of countries" [value]="country.id"
                          (click)="countryId = country.id">
                          {{ country.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <!--Set Prizes Start-->
                <div class="rowDefault">
                  <div>
                    <mat-form-field class="example-full-width" appearance="outline" style="width: 200px">
                      <mat-label class="font-weight-bold">{{ "Jackpot" | translate }} *</mat-label>
                      <span matPrefix>{{ Currency.currencySymbol }} &nbsp;</span>
                      <input matInput type="number" id="new-jackpot" class="ml-1 text-center" name="language" min="0"
                        [(ngModel)]="RoundToUpdate.firstJackpot" />
                      <span matSuffix>,00 &nbsp;</span>
                    </mat-form-field>
                  </div>

                  <div>
                    <mat-form-field matTooltip="Valor Recuperado" class="example-full-width" appearance="outline"
                      style="width: 200px">
                      <mat-label class="font-weight-bold">
                        {{ "Recovered Jackpot" | translate }}
                      </mat-label>
                      <span matPrefix>{{ Currency.currencySymbol }} &nbsp;</span>
                      <input matInput class="ml-1 text-center" disabled value="{{
                        recoverdJackpot * Currency.quote | currency : '' : ''
                      }}" />
                      <!-- <span matSuffix>,00 &nbsp;</span> -->
                    </mat-form-field>
                  </div>

                  <div>
                    <mat-form-field matTooltip="Valor para ser Recuperado" class="example-full-width"
                      appearance="outline" style="width: 200px">
                      <mat-label class="font-weight-bold">
                        {{ "To Recover" | translate }}
                      </mat-label>
                      <span matPrefix class="text-danger">{{ Currency.currencySymbol }} &nbsp;</span>
                      <input matInput class="ml-1 text-center text-danger" disabled
                        value="{{ toRecover * Currency.quote | currency : '' : '' }}" />
                      <!-- <span matSuffix class="text-danger">,00 &nbsp;</span> -->
                    </mat-form-field>
                  </div>

                  <div>
                    <mat-form-field class="example-full-width" appearance="outline" style="width: 200px">
                      <mat-label class="font-weight-bold">{{ "Round Value" | translate }}*</mat-label>
                      <span matPrefix>{{ Currency.currencySymbol }} &nbsp;</span>
                      <input matInput id="round-value" class="ml-1 text-center" placeholder="" type="number" min="0"
                        name="value" [(ngModel)]="RoundToUpdate.value" />
                      <span matSuffix>,00 &nbsp;</span>
                    </mat-form-field>
                  </div>
                </div>

                <!-- Broken Line -->
                <div class="col-md-12 mt-3" style="border-bottom: 1px solid gainsboro; margin-bottom: 20px"></div>

                <!--Events Update-->
                <div *ngIf="eventsToUpdate.length != 0" class="previewUpdatePadding">
                  <div *ngFor="let event of eventsToUpdate">
                    <mat-form-field class="example-full-width" appearance="outline" *ngIf="event.odd != ''">
                      <mat-label class="font-weight-bold">{{ event.name | translate }} </mat-label>
                      <input matInput type="number" id="odd{{i}}" [(ngModel)]="event.odd" (keyup)="updatePreview(i)"
                        min="0" />
                    </mat-form-field>
                  </div>
                </div>

                <!--Match Updade-->
                <div *ngIf="matchsPreUpdate.length !=0">
                  <div *ngFor="let match of matchsPreUpdate; let i = index" class="rankRoundAndChampionchipsMembers">
                    <div class="row">
                      <mat-form-field appearance="fill" class="mr-3 w-20">
                        <mat-label>{{ "startDate" | translate }}</mat-label>
                        <input matInput [matDatepicker]="dateStartRankRoundUpdate" #customdate
                          [(ngModel)]="match.dateToUpdate" />
                        <mat-datepicker-toggle matSuffix [for]="dateStartRankRoundUpdate"></mat-datepicker-toggle>
                        <mat-datepicker #dateStartRankRoundUpdate></mat-datepicker>
                      </mat-form-field>
  
                      <mat-form-field appearance="fill" class="mr-3">
                        <mat-label>Hora</mat-label>
                        <input matInput aria-label="24hr format" #customtime
                          [ngxTimepicker]="hourInputStartRankRoundUpdate" id="hourInputStartRankRoundUpdate"
                          [(ngModel)]="match.hourToUpdate" />
                        <ngx-material-timepicker-toggle matSuffix
                          [for]="hourInputStartRankRoundUpdate"></ngx-material-timepicker-toggle>
                        <ngx-material-timepicker matSuffix controlOnly="false" #hourInputStartRankRoundUpdate>
                        </ngx-material-timepicker>
                      </mat-form-field>
  
                      <mat-form-field appearance="fill" class="w-20 mr-3">
                        <mat-label>{{ "Member" | translate }}</mat-label>
                        <input #inputSingleMatchHome matInput type="text" id="round-name-single-home" name="Member"
                          [(ngModel)]="match.homeName" />
                      </mat-form-field>
  
                      <mat-form-field appearance="fill" class="w-20 mr-3" *ngIf="noDynamicStyle == 0">
                        <mat-label>{{ "Member 2" | translate }}</mat-label>
                        <input #inputSingleMatchHome matInput type="text" id="round-name-single-home" name="Member"
                          [(ngModel)]="match.awayName" />
                      </mat-form-field>
  
                      <div class="col mr-3" *ngIf="noDynamicStyle == 0">
                        <b class="position-absolute">{{ "2 results" | translate }}</b>
                        <br />
                        <mat-slide-toggle [formControl]="match.two_options" class="ml-3">
                        </mat-slide-toggle>
                      </div>
  
                      <button mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon"
                        (click)="removeMatchPreUpdate(i)">
                        <mat-icon>highlight_off</mat-icon>
                      </button>
                    </div>
                  </div>
  
                  <div class="row rankRoundAndChampionchipsMembers">
                    <mat-form-field appearance="fill" class="mr-3 w-20">
                      <mat-label>{{ "startDate" | translate }}</mat-label>
                      <input matInput [matDatepicker]="dateForNewMatchUpdate" #customdate id="dateForNewMatchUpdate" />
                      <mat-datepicker-toggle matSuffix [for]="dateForNewMatchUpdate"></mat-datepicker-toggle>
                      <mat-datepicker #dateForNewMatchUpdate></mat-datepicker>
                    </mat-form-field>
  
                    <mat-form-field appearance="fill" class="mr-3">
                      <mat-label>Hora</mat-label>
                      <input matInput aria-label="24hr format" #customtime [ngxTimepicker]="hourForNewMatchUpdate"
                        id="hourForNewMatchUpdate" />
                      <ngx-material-timepicker-toggle matSuffix
                        [for]="hourForNewMatchUpdate"></ngx-material-timepicker-toggle>
                      <ngx-material-timepicker matSuffix controlOnly="false" #hourForNewMatchUpdate>
                      </ngx-material-timepicker>
                    </mat-form-field>
  
  
                    <mat-form-field appearance="fill" class="w-20 mr-3">
                      <mat-label>{{ "Member" | translate }}</mat-label>
                      <input #inputSingleMatchHome matInput type="text" id="round-name-single-home" name="Member"
                        [(ngModel)]="homeNameForNewMatchUpdate" />
                    </mat-form-field>
  
                    <mat-form-field appearance="fill" class="w-20 mr-3" *ngIf="noDynamicStyle == 0">
                      <mat-label>{{ "Member 2" | translate }}</mat-label>
                      <input #inputSingleMatchHome matInput type="text" id="round-name-single-home" name="Member"
                        [(ngModel)]="awayNameForNewMatchUpdate" />
                    </mat-form-field>
  
                    <div class="col mr-3" *ngIf="noDynamicStyle == 0">
                      <b class="position-absolute">{{ "2 results" | translate }}</b>
                      <br />
                      <mat-slide-toggle [formControl]="is2ResultControlForUpdate" class="ml-3">
                      </mat-slide-toggle>
                    </div>
  
                    <button matTooltip="Adicionar Partida" mat-fab class="greenDG" (click)="AddMatchInMatchsPreUpdate()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </div>

                <div class="d-flex justify-content-center align-items-center">
                  <div *ngIf = "matchesCount > 0" class="mr-2">
                    <span>{{matchesCount}} {{'pg-round.events'|translate}}</span>
                  </div>
                  <button class="btn btn-success px-2" mat-flat-button (click)="UpdateRound()">
                    {{ "Create Round" | translate }}
                  </button>
                </div>

              </div>
            </mat-tab>

            <mat-tab label="{{ 'Cancel Round' | translate }}">
              <div class="row mt-3">
                <div class="col-md-3">
                  <mat-form-field class="postFilter" appearance="fill">
                    <mat-label>{{ "Base Round" | translate }}</mat-label>
                    <input type="text" matInput (keyup)="filterActivatedRounds($event)" [matAutocomplete]="Garmo" />
                    <mat-autocomplete autoActiveFirstOption #Garmo="matAutocomplete">
                      <mat-option *ngFor="let roundsPt of activatedRoundHelper" [value]="roundsPt.name"
                        (click)="onEditCurrentCompetition(roundsPt.id)">
                        {{ roundsPt.name }} - {{ roundsPt.number }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="col-md-5">
                  <a type="button" color="waves-light button-font" style="background-color: #ff4b4b" class="btn"
                    mdbWavesEffect (click)="canceloRound()" data-dismiss="modal">
                    {{ "Cancel Round" | translate }}
                  </a>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>

<div mdbModal #frameMessageConfirmation="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{ backdrop: false, ignoreBackdropClick: true }">
  <div class="modal-dialog modal-notify modal-danger" role="document" id="msgModalStandardConfirmation">
    <div class="modal-content bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" style="color: white">
          {{ "Confirm Delete" | translate }}
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="frameMessageConfirmation.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <p>
          {{ "Confirm Message" | translate }}
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <a type="button" color="waves-light button-font" class="btn" mdbWavesEffect style="background-color: #33a0df"
          (click)="frameMessageConfirmation.hide()" data-dismiss="modal">
          {{ "Back" | translate }}
        </a>

        <a type="button" color="waves-light button-font" style="background-color: #ff4b4b" class="btn" mdbWavesEffect
          (click)="cancelConfirm()" data-dismiss="modal">
          {{ "Confirm Delete" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<div mdbModal #shieldModal="mdbModal" [config]="{ backdrop: false, ignoreBackdropClick: true }" class="modal fade"
  tabindex="-1" role="dialog" aria-labelledby="myshieldModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header headershieldmodal">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeShieldModal()">
          <span (click)="closeShieldModal()" aria-hidden="true">×</span>
        </button>
        <h4 style="margin: 0; font-weight: 500" class="modal-title w-100" id="myModalLabel">
          {{ "pg-manage-bets.modal-title" | translate }}
        </h4>
      </div>
      <div class="d-flex row justify-content-center modal-body">
        <div class="d-flex justify-content-center row">
          <label class="text-left" for="searchInput">{{ "pg-manage-bets.shield-name" | translate }}:</label>
          <input class="searchShieldInput" id="searchInput"
            placeholder="{{ 'pg-manage-bets.search-shield' | translate }}..." type="search" name="search"
            [(ngModel)]="teamName" /><br />
        </div>
        <div class="shieldBox d-flex align-items-center" *ngIf="isShield">
          <img src="{{ linkShield }}" alt="" />
          <!-- <p class="teamName" style="margin-bottom: 0;"> {{ teamName }} </p> -->
          <input class="saveShieldBtn" type="button" value="{{ 'pg-manage-bets.save-shield' | translate }}"
            (click)="saveShield()" />
        </div>
        <p style="margin-top: 10%" *ngIf="!isShield && teamName.length > 0">
          {{ "pg-manage-bets.not-found-shield" | translate }}
        </p>
      </div>
      <div class="d-flex justify-content-center footermodalShield">
        <button type="button" mdbBtn class="searchShieldBtn relative waves-light" mdbWavesEffect
          (click)="searchShield()">
          {{ "pg-manage-bets.search-shield" | translate }}
        </button>
        <!-- <button type="button" mdbBtn color="secondary" class="waves-light" (click)="closeShieldModal()" aria-label="Close" mdbWavesEffect>Cancelar</button> -->
      </div>
    </div>
  </div>
</div>

<div mdbModal #SingleMatchShieldSelect="mdbModal" [config]="{ backdrop: false, ignoreBackdropClick: true }"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myshieldModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header headershieldmodal">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalSingleMatchShield()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title" id="myModalLabel">
          {{ "iconSelect" | translate }}
        </h4>
      </div>
      <div class="upload">
        <h2>{{ "iconFinder" | translate }}:</h2>
        <label class="picture" for="picture_input">
          <input type="file" accept="image/*" id="picture_input" (change)="onFileSelected($event)" />
          <span class="picture_image">
            <img id="pictureShield" [src]="url" alt=""
              onerror="this.onerror=null;this.src='https://poolbet365.com/assets/shields/default-logo.png';" />
          </span>
        </label>

        <div class="file-upload text-center mt-2">
          {{ fileName || "No file uploaded yet." }}
        </div>
        <p style="margin-top: 10%" *ngIf="!isShield && teamName.length > 0">
          {{ "pg-manage-bets.not-found-shield" | translate }}
        </p>
      </div>
      <div class="d-flex justify-content-center footermodalShield">
        <button type="button" mdbBtn class="searchShieldBtn relative waves-light" mdbWavesEffect
          (click)="saveTournamentIcon()">
          {{ "saveShield" | translate }}
        </button>
        <!-- <button type="button" mdbBtn color="secondary" class="waves-light" (click)="closeShieldModal()" aria-label="Close" mdbWavesEffect>Cancelar</button> -->
      </div>
    </div>
  </div>
</div>

<div mdbModal #avoidCreation="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ backdrop: false, ignoreBackdropClick: true }">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <div class="modal-content bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">
          {{ "pg-round.fail" | translate }}
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeAvoidCreation()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="md-form mb-5">
          <p style="color: black">
            {{ "pg-round.fail-avoid" | translate }}
          </p>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn class="waves-light button-bg-theme button-font" (click)="closeAvoidCreation()" mdbWavesEffect>
          OK</button><br />
      </div>
    </div>
  </div>
</div>

<div mdbModal #successCreation="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ backdrop: false, ignoreBackdropClick: true }">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <div class="modal-content bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">
          {{ "pg-round.round-created" | translate }}
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModalConfirmation()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="md-form mb-5">
          <p style="color: black">
            {{ "pg-round.round-created-info" | translate }}
          </p>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn class="waves-light button-bg-theme button-font" (click)="closeModalConfirmation()"
          mdbWavesEffect>
          OK</button><br />
      </div>
    </div>
  </div>
</div>

<div mdbModal #frameMessage="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ backdrop: false, ignoreBackdropClick: true }">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
    <div class="modal-content bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frameMessage.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <p style="color: black" id="msg" [innerHtml]="msgToAdd"></p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <a type="button" color="waves-light button-font" style="padding: 0.54rem 2.14rem; background-color: #33a0df"
          class="btn" mdbWavesEffect (click)="frameMessage.hide()" data-dismiss="modal">OK</a>
      </div>
    </div>
  </div>
</div>