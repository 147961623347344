import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDirective } from 'angular-bootstrap-md';
import { Round } from 'src/app/interface/round.interface';
import { Bet } from 'src/app/interface/bet.interface';
import { User } from 'src/app/interface/user.interface';
import { Currency } from 'src/app/interface/currency.interface';
import { LoginService } from 'src/app/services/login.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { MatSort } from '@angular/material/sort';
import { CouponServiceService } from 'src/app/services/coupon-service.service';
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { CouponDetailComponent } from '../coupon-detail/coupon-detail.component';
import { CouponFilter, Transactionsfilter } from 'src/app/interface/transactions-filter.interface';
const formula1Shields = [
  { team: 'Alpha Romeo', shield: 'f1-alpha-romeo.png', drivers: 'guanyu valtteri bottas' },
  { team: 'AlphaTauri', shield: 'f1-alphatauri.png', drivers: 'pierre gasly yuki tsunda' },
  { team: 'Alpine', shield: 'f1-alpine.png', drivers: 'esteban ocon fernando alonso' },
  { team: 'Aston Martin', shield: 'f1-aston-martin.png', drivers: 'sebastian vettel lance stroll' },
  { team: 'Ferrari', shield: 'f1-ferrari.png', drivers: 'charles leclerc carlos sainz' },
  { team: 'Haas F1 Team', shield: 'f1-haas-f1-team.png', drivers: 'kevin magnussen mick schumacher' },
  { team: 'McLaren', shield: 'f1-mclaren.png', drivers: 'lando norris daniel ricciardo' },
  { team: 'Mercedes', shield: 'f1-mercedes.png', drivers: 'george russell lewis hamilton' },
  { team: 'Red Bull', shield: 'f1-red-bull.png', drivers: 'max verstappen sergio perez' },
  { team: 'Williams', shield: 'f1-williams.png', drivers: 'alexander albon nicholas latifi' }
];

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class CouponListComponent implements OnInit {
  @ViewChild('frame3', { static: true }) modalCancel: ModalDirective;
  @ViewChild('frame3', { static: true }) modalRegister: ModalDirective;
  @ViewChild('frame2', { static: true }) modalTicket: ModalDirective;
  @ViewChild('frame4', { static: true }) modalWarning: ModalDirective;
  @ViewChild('frameMessage', { static: true }) modalMessages: ModalDirective;
  @ViewChild('frameMessageDanger', { static: true }) modalMessagesDanger: ModalDirective;
  @ViewChild('frameMessageCredit', { static: true }) modalMessagesCredit: ModalDirective;
  @ViewChild('frameMessageBetting', { static: true }) modalMessagesBetting: ModalDirective;

  displayedColumns: string[] = ['id', 'status', 'betDateStr', 'operator', 'playerName', 'country', 'city', 'quantity', 'value'];
  dataSource = new MatTableDataSource<any>([]);

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];
  showCloseBtn: boolean = false;
  showCloseRoundBtn: boolean = false;
  msgToAdd: string = '';
  titleToAdd: string = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  userAdmin: any;

  start_date: any = new Date();
  end_date: any = new Date();
  seletedJurisdiction: any;
  usersList: any = [];
  allowUsersList: any = [];
  jurisdictions: any;
  selectedJurisdictionLevel: any;
  data: any;
  missingJurisName: boolean = true;
  selectJurisIdName: any = null;
  selectJurisId: any;

  PresentUserLoger: any = []

  selected: { startDate: Moment, endDate: Moment }

  startDate = new FormControl(new Date());
  picker1: any;
  endDate = new FormControl(new Date());
  picker2: any;

  round: Round;
  bets: Bet[] = [];
  users: User[] = [];
  userNameToShow = new Array<string>();
  isLoaded: boolean = false;
  searchReturn: boolean = false;
  resultSearch: string;
  info: any = [{ id: 0, name: 'All' }];
  roundName: string;
  indexActivated: number;
  viewTicket: string;
  Currency: Currency = <Currency>{};
  userAllBets: Bet[] = [];
  selecte = 0;
  searchString: string = '';
  dateStart: any = new Date('1970-01-01');
  dateEnd: any = new Date();
  radarSport: number;
  singleMatch: number;


  constructor(private loginService: LoginService, private _snackBar: MatSnackBar,
    private _adapter: DateAdapter<any>,
    private couponService: CouponServiceService,
    private interactionService: InteractionService,
    public spinner: NgxSpinnerService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private datePipe: DatePipe,
    private router: Router) {
    this.userAdmin = loginService.getUserInfoFromCookie();
  }

  ngOnInit(): void {
    // this.detailCoupon('ugex7pzh');
    //  this.couponService.getBet('b630te12').subscribe(bet => {
    //    this.couponService.getRoundById(624).subscribe(round => {
    //     console.log("A");
    //     console.log(round);
    //     console.log("B");
    //     console.log(bet);
    //     this.couponService.getGames(bet.roundId).subscribe(game => {
    //       let roundResult = game[0].matchWinner.split("|");
    //       let betResult = bet.results.split("|");
    //       let total = 0;
    //       // if (betResult[0].length > 1 || betResult[1].length > 1) {
    //       //   if (betResult[0].includes(roundResult[0])) {
    //       //     betResult[0] = roundResult[0];
    //       //     console.log("Foi alterado!");
    //       //   } else if (betResult[1].includes(roundResult[1])) {
    //       //     betResult[1] = roundResult[1];
    //       //     console.log("Foi alterado!");
    //       //   } else {
    //       //     console.log("Não foi alterado!");
    //       //   };
    //       // };
    //       // betResult.forEach((element, index) => {
    //       //   if (element.includes(roundResult[index])) {
    //       //     total++;
    //       //     console.log(total);
    //       //   }
    //       // });
    //    });
    //  });
    // });
    this.getJurisdictions();
    this.getUsers();
    //this.getRounds();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  jurisdictionLevelChange(event) {
    this.selectedJurisdictionLevel = event.value
    this.allowUsersList = []
    this.usersList.forEach(element => {
      if (element.jurisdictionId == this.selectedJurisdictionLevel) {
        if (parseInt(localStorage.getItem("currentUserJurisdictionId")) == this.selectedJurisdictionLevel) {
          if (element.id == this.PresentUserLoger[0].id) {
            this.allowUsersList = [element]
            this.selectJurisIdName = element.id;
            this.missingJurisName = false;
            this.jurisdictionChange()
          }
        } else {
          this.allowUsersList.push(element)
          this.selectJurisIdName = null;
          this.seletedJurisdiction = -1
          this.missingJurisName = true;
        }
      }
    });
    this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
    this.seletedJurisdiction = -1
  }

  jurisdictionChange() {
    //this.seletedJurisdiction = event.target.value
    this.seletedJurisdiction = this.selectJurisIdName
    if (this.seletedJurisdiction == -1) {
      this.missingJurisName = true;
    }
    else this.missingJurisName = false;
  }

  message(message) {
    this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  getUsersList() {
    this.loginService.GetUsersList(this.userAdmin.id).subscribe(res => {
      let temUser = res.find(element => element.jurisdictionId == this.selectedJurisdictionLevel && element.id == this.userAdmin.id && element.login == this.userAdmin.login)
      if (temUser == null) {
        this.loginService.GetUserAssist(this.userAdmin.id).subscribe(x => {
          temUser = res.find(element => element.id == x.adminParentID && x.userName == this.userAdmin.login)
          this.PresentUserLoger.push(temUser)
          this.allowUsersList.push(temUser)
          this.usersList.push(temUser)

          this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
          this.loginService.getUsersTreeList(x.adminParentID)
            .subscribe(res => {
              res.forEach(element => {
                this.usersList.push(element)
              });
              this.seletedJurisdiction = -1
            })
        })
      } else {
        this.PresentUserLoger.push(temUser)
        this.allowUsersList.push(temUser)
        this.usersList.push(temUser)

        this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
        this.loginService.getUsersTreeList(temUser.id)
          .subscribe(res => {
            res.forEach(element => {
              this.usersList.push(element)
            });
            this.seletedJurisdiction = -1
          })
      }
    })
  }

  getJurisdictions() {
    this.loginService.getJurisdictions(parseInt(localStorage.getItem("currentUserJurisdictionId"))).subscribe(res => {
      this.jurisdictions = res
      this.selectedJurisdictionLevel = parseInt(localStorage.getItem("currentUserJurisdictionId"))
      this.getUsersList()
    })
  }

  getUsers() {
    this.couponService.listCommonUsers()
      .subscribe(data => {
        this.users = data;
      }, error => {
        console.log(error);
      });
  }

  async getRounds() {
    this.info.length = 0;
    this.dataSource.filteredData.length = 0
    try {
      this.spinner.show();
      //debugger;
      let couponFilter = {} as CouponFilter;
      couponFilter['userId'] = parseInt(this.seletedJurisdiction);
      couponFilter['dateStart'] = this.startDate.value;
      couponFilter['dateEnd'] = this.endDate.value;
      couponFilter['jurisdictionId'] = parseInt(this.selectedJurisdictionLevel);
      //const allRounds = await this.couponService.getRoundsByFilter(couponFilter).toPromise();
      const allRounds = await this.couponService.GetBetsByFilter(couponFilter).toPromise();
      await Promise.all(allRounds.map(async (round): Promise<void> => {
        const userAdminId = this.userAdmin.id
        const userBets = round.roundBets; //await this.couponService.getBetsByUserTree(userAdminId, round.id).toPromise();
        const winners = round.winners; //await this.couponService.listRoundWinners(round.id).toPromise();
        if (userBets && userBets.length) {
          let roundBets = userBets.map(bet => ({ ...bet, winner: winners.filter(winner => winner.betId == bet.id).length }));
          this.userAllBets = this.userAllBets.concat(roundBets.map(e => ({ ...e, posted: round.posted })));
          this.userAllBets.sort((n1, n2) => {
            if (n1.betDate < n2.betDate) {
              return 1;
            }

            if (n1.betDate > n2.betDate) {
              return -1;
            }

            return 0;
          });
          let roundName = round.name;
          // switch (this.appComponent.activeLang) {
          //   case 'pt':
          //     roundName = round.name;
          //     break;
          //   case 'en':
          //     roundName = round.nameEng;
          //     break;
          //   case 'it':
          //     roundName = round.nameItaly;
          //     break;
          //   case 'es':
          //     roundName = round.nameSpan;
          //     break;
          //   default:
          //     break;
          // }
          roundName = roundName ? roundName : round.name;
          this.info.push({
            id: round.id,
            startDateTime: round.startDateTime,
            endDateTime: round.endDateTime,
            name: roundName,
            number: round.number
          });
        }
      }));
      this.getBets();
      this.spinner.hide();
    }
    catch (error) {
      console.log(error);
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getBets() {

    if (!this.round || this.selecte == 0) {
      this.bets = this.userAllBets;
      this.dataSource.data = this.userAllBets;
      this.dataSource.paginator = this.paginator;
      // document.getElementById("spinner-loading").classList.add("hidden");
      this.isLoaded = true;
      this.listUserNames();
      return;
    }

    this.couponService.getBetsByUserTree(this.userAdmin.id, this.round.id)
      .subscribe(async data => {
        const winners = await this.couponService.listRoundWinners(this.round.id).toPromise();
        this.bets = data.map(bet => ({ ...bet, posted: this.round.posted, winner: winners.filter(winner => winner.betId == bet.id).length }));
        this.dataSource.data = this.bets;
        this.dataSource.paginator = this.paginator;
        // document.getElementById("spinner-loading").classList.add("hidden");
        this.isLoaded = true;
        this.listUserNames();
      }, error => {
        console.log(error);
      });
  }

  getLastRound() {
    this.couponService.getLastRound()
      .subscribe(data => {
        this.round = data;
        this.selecte = this.round.id;
        this.getRounds();
      }, error => {
        console.log(error);
      });
  }

  changeRoundBets(roundId: number) {
    if (roundId == 0) {
      this.bets = this.userAllBets;
      this.dataSource.data = this.userAllBets;
      this.isLoaded = true;
      this.listUserNames();
      return;
    }
    this.selecte = roundId;

    this.couponService.getRound(roundId)
      .subscribe(data => {
        this.round = data;
        this.getBets();
      });
  }

  // getBets(){
  //   this.spinner.show();
  //   this.couponService.getBetsByUserTree(this.userAdmin.id)
  //     .subscribe(async data => {
  //       //const winners  = await this.couponService.listRoundWinners(this.round.id).toPromise();
  //       //this.bets = data.map(bet => ({...bet, posted: this.round.posted, winner: winners.filter(winner => winner.betId == bet.id).length}));
  //       this.dataSource.data = data; //this.bets;
  //       this.dataSource.paginator = this.paginator;
  //       // document.getElementById("spinner-loading").classList.add("hidden");
  //       this.isLoaded = true;
  //       this.listUserNames();
  //       this.spinner.hide();
  //     }, error => {
  //       console.log(error);
  //     });
  // }

  backgroundColor(bet) {
    if (bet.posted === 0) {
      return 'rgb(235, 192, 20)';
    }
    if (bet.winner || bet.results.split('|').length === bet.points) {
      return 'rgba(55, 178, 64, 1)';
    }
    if (bet.status === 0) {
      return 'rgba(0, 159, 198, 1)';
    }
    return 'rgba(243, 83, 92, 1)';
  }

  listUserNames() {
    let count = 0;
    let limit = this.bets.length;
    while (count < limit) {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].id == this.bets[count].userAdminId) {
          this.userNameToShow[count] = this.users[i].login;
        }
      }
      count++;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changeDateFrom(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (!filterValue) return;

    this.dateStart = new Date(filterValue);
  }

  changeDateEnd(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (!filterValue) return;

    this.dateEnd = new Date(filterValue);
  }

  applyFilter2() {
    this.getRounds();

    // this.dataSource.data = this.bets.filter(bet => {
    //   let betDate = new Date(bet.betDate);
    //   if ((this.seletedJurisdiction == "All" || this.seletedJurisdiction == bet.playerName) && (betDate.getTime() > this.dateStart.getTime() && betDate.getTime() < this.dateEnd.getTime()))
    //     return true;
    //   // else {
    //   //   if ()
    //   //     return true;
    //   // }
    //   return false;
    // });
    // this.dataSource.filter = this.searchString.trim().toLowerCase();
  }

  getChampionshipLogos(name: string) {
    //debugger;
    let searchText = this.remove_accents(name);
    for (var i = 0; i < searchText.length; i++) {
      if (searchText.charAt(i) == " ") searchText = searchText.replace(" ", "%20")
    }
    searchText = searchText.toLocaleLowerCase()
    let ret = 'https://poolbet365.com/assets/shields/' + searchText + '.png';
    if (name.toLocaleUpperCase().includes(("POKER").toLocaleUpperCase())) {
      if (name.toLocaleUpperCase().includes(("Israelita").toLocaleUpperCase())) {
        return ret = '../../../assets/images/ChampionShipFlags/estrDavy.png'
      }
      return ret = '../../../assets/images/ChampionShipFlags/poker.png'
    }
    if (name.toLocaleUpperCase().includes(("LA LIGA").toLocaleUpperCase())) return ret = '../../../assets/images/ChampionShipFlags/laliga.png'
    if (name.toLocaleUpperCase().includes(("campeonato BRASILEIRO").toLocaleUpperCase())) return ret = '../../../assets/images/ChampionShipFlags/brasileirao.png'
    if (name.toLocaleUpperCase().includes("MOTO GP")) return ret = '../../../assets/images/ChampionShipFlags/motogp.png'
    if (name.toLocaleUpperCase().includes("FÓRMULA 1")) return ret = '../../../assets/images/ChampionShipFlags/f1.png'
    if (name.toLocaleUpperCase().includes(("Bundesliga").toLocaleUpperCase())) return ret = '../../../assets/images/ChampionShipFlags/bl.png'
    if (name.toLocaleUpperCase().includes(("campeonato italiano").toLocaleUpperCase())) return ret = '../../../assets/images/ChampionShipFlags/it.png'
    if (name.toLocaleUpperCase().includes(("copa do mundo").toLocaleUpperCase())) return ret = '../../../assets/images/ChampionShipFlags/cp.png'
    if (name.toLocaleUpperCase().includes(("campeonato frances").toLocaleUpperCase())) return ret = '../../../assets/images/ChampionShipFlags/fr.png'
    if (name.toLocaleUpperCase().includes(("premier league").toLocaleUpperCase())) return ret = '../../../assets/images/ChampionShipFlags/pl.png'
    if (name.toLocaleUpperCase().includes(("uefa").toLocaleUpperCase())) return ret = '../../../assets/images/ChampionShipFlags/uefa.png'
    if (name.toLocaleUpperCase().includes(("Internacionais").toLocaleUpperCase())) return ret = '../../../assets/images/ChampionShipFlags/international.png'
    //  searchText.forEach(element => {
    //    let found = ChampionshipLogos.find(x => x.name.includes(element.toLowerCase()));
    //    if (found != null) {
    //      ret = '../../../assets/shields/' + found.shield;
    //    }
    //  });
    return ret;
  }

  getSingleMatchShield(elementName: String, team: number) {
    let nameTeam: string[]
    elementName = elementName.replace(" (", " X ")
    elementName = elementName.replace(" - ", " X ")
    nameTeam = elementName.toLocaleLowerCase().split((" X ").toLocaleLowerCase());
    let getTemLogo;
    getTemLogo = nameTeam[team];
    getTemLogo = this.remove_accents(getTemLogo)
    getTemLogo = getTemLogo.replaceAll(" ", "%20")
    return `https://poolbet365.com/assets/shields/${getTemLogo}.png`
  }

  remove_accents(str) {
    let accented = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    let withoutAccent = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let namewithoutAccent = "";
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < accented.length; a++) {
        if (str.substr(i, 1) == accented.substr(a, 1)) {
          namewithoutAccent += withoutAccent.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        namewithoutAccent += str.substr(i, 1);
      }
    }
    return namewithoutAccent;
  }

  getSelectedShield(name: string, betsport: number) {
    let searchText = name.split(" ");
    let ret = '../../../assets/shields/default-logo.png';
    if (betsport == 40) {
      searchText.forEach(element => {
        let found = formula1Shields.find(x => x.drivers.includes(element.toLowerCase()));
        if (found != null) {
          ret = 'https://poolbet365.com/assets/shields/' + found.shield;
        }
      });
    }
    if (betsport == 1000) {
      ret = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Naipe_ouros.png/216px-Naipe_ouros.png'
    }
    return ret;
  }

  detailCoupon(ticketCode) {
    this.couponDetail(ticketCode);
  }

  couponDetail(ticketCode) {
    this.couponService.getBet(ticketCode)
      .subscribe(bet => {
        if (bet == null) {
          this.msgStandard(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), 4);
        }
        else {
          let rankResult = [];
          let isSingleMatch = false;
          this.couponService.getRoundById(bet.roundId)
            .subscribe(round => {
              this.roundName = round.name;
              this.radarSport = round.betradarSportId;
              this.singleMatch = round.singleMatchRound;
              if (round && round.rankRound && round.rankResult) {
                rankResult = round.rankResult.split('|');
              }
              isSingleMatch = round.singleMatchRound == 1 ? true : false;
            });
          this.couponService.getBasicUser(bet.userAdminId)
            .subscribe(user => {
              this.couponService.getGames(bet.roundId)
                .subscribe((matchs) => {
                  let results = bet.results.split("|");
                  let total_hit = 0;
                  let total_cancel = 0;
                  let mWinner;
                  if (bet.rankRound && results.length) {
                    rankResult.forEach((element, i) => {
                      if (results[i] == element) {
                        total_hit++;
                      }
                    });
                  }
                  if (!isSingleMatch) {
                    matchs.forEach((element, i) => {
                      if (element.status == 0) {
                        total_cancel++;
                      } else {
                        if (results[i].includes(element.matchResult) && !bet.rankRound) {
                          total_hit++;
                        }
                      }
                    });
                  }
                  else {
                    if (matchs[0].matchWinner != null) {
                      mWinner = matchs[0].matchWinner.split('|');
                      results.forEach((element, index) => {
                        if (element.includes(mWinner[index])) {
                          total_hit++;
                        }
                      });
                    }
                  }
                  let legend: string = "<div class='col-12'>";
                  legend += "<div class='row legend'><div class='col-3'><div class='running'></div>&nbsp;" + this.translate.instant("pg-tickets.running") + "</div>";
                  legend += "<div class='col-3'><div class='hit'></div>&nbsp;" + this.translate.instant("pg-tickets.hit") + "</div>";
                  legend += "<div class='col-3'><div class='wrong'></div>&nbsp;" + this.translate.instant("pg-tickets.wrong") + "</div>";
                  legend += "<div class='col-3'><div class='cancel'></div>&nbsp;" + this.translate.instant("pg-tickets.cancelled") + "</div>";
                  legend += "</div></div>";

                  let resultMats: string = "<div class='col-12 total-show mb-1'>" + /*legend + */
                    "<div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translate.instant("pg-round.matches") + "</strong>: " + matchs.length +
                    "</div><div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translate.instant("pg-result.number-hits") + "</strong>: " + total_hit +
                    "</div><div class='col-12 total-cancel'><strong style='font-weight: bold;'>" + this.translate.instant("pg-manage-ticket.canceled") + "</strong>: " + total_cancel + "</div>" +
                    "<div class='col-12  total-hit text-center p-1 mb-2 mt-2'>" + `<img class='mr-1 mb-1' width='30px' src='${this.getChampionshipLogos(this.roundName)}'>` + this.roundName + "</div>" +
                    "</div>";
                  if (this.singleMatch == 1) {
                    resultMats = "<div class='col-12 total-show mb-1'>" + /*legend + */
                      "<div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translate.instant("pg-round.matches") + "</strong>: " + matchs.length +
                      "</div><div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translate.instant("pg-result.number-hits") + "</strong>: " + total_hit +
                      "</div><div class='col-12 total-cancel'><strong style='font-weight: bold;'>" + this.translate.instant("pg-manage-ticket.canceled") + "</strong>: " + total_cancel + "</div>" +
                      "<div class='col-12  total-hit text-center p-1 mb-2 mt-2'>" + `<img class='mr-1 mb-1' width='30px' src='${this.getSingleMatchShield(this.roundName, 0)}'>` + this.roundName + ` <img class='mr-1 mb-1' width='30px' src='${this.getSingleMatchShield(this.roundName, 1)}'>` + "</div>" +
                      "</div>";
                  }
                  let count: number = 0;
                  if (bet.rankRound) {
                    results.forEach((element, i) => {
                      if (i < rankResult.length && rankResult[i] == element) {
                        resultMats += "<div class='d-flex justify-content-around align-items-center col-9 search-game-list'>"
                          + `<div class='col-9 driver-name'>`
                          + "<img class='mr-3' width='22px' src='"
                          + this.getSelectedShield(element, this.radarSport)
                          + "'>"
                          + element
                          + `</div>`
                          + `<div class='col-3 d-flex justify-content-inherit align-items-center'><img width='5px' class='mr-2' src='../../assets/svgs/green-icon.svg'>`
                          + `<span class='goals'>${[i + 1]}</span>`
                          + `</div>`
                          + "</div><div class='col-2 result-game-list'>"
                          + rankResult[i]
                          + "</div>"
                          + "<div class='correct'></div>";
                      } else if (i < rankResult.length && rankResult[i] != element) {
                        resultMats += "<div class='d-flex justify-content-around align-items-center col-9 search-game-list'>"
                          + `<div class='col-9 driver-name'>`
                          + "<img class='mr-3' width='22px' src='"
                          + this.getSelectedShield(element, this.radarSport)
                          + "'>"
                          + element
                          + `</div>`
                          + `<div class='col-3 d-flex justify-content-inherit align-items-center'><img width='5px' class='mr-2' src='../../assets/svgs/green-icon.svg'>`
                          + `<span class='goals'>${[i + 1]}</span>`
                          + `</div>`
                          + "</div><div class='col-2 result-game-list'>"
                          + rankResult[i]
                          + "</div>"
                          + "<div class='no-hit'></div>";
                      }
                      else {
                        resultMats += "<div class='col-9 search-game-list'>" + element + "</div><div class='col-3 result-game-list'> " + (i + 1) + "°</div>" + "<div></div>";
                      }
                    });
                  } else {
                    if (isSingleMatch) {
                      matchs.forEach(element => {
                        if (element.status == 0) {
                          resultMats += "<div class='col-9 search-game-list cancelled'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list cancelled'> " + results[count] + "</div>";
                        } else {
                          if (element.matchResult == "0") {
                            // resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list'> " + results[count] + "</div>";
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Final Result") + "</div><div class='col-3 search-game-list'> " + results[0] + "</div>";
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("More Ball Possession") + "</div><div class='col-3 search-game-list'> " + results[1] + "</div>";
                            var data = results[count] == "12" ? this.translate.instant("Over-Under") : results[2] == "1" ? this.translate.instant("Over") : this.translate.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Total Goals") + "(" + element.totalGoals + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Over-Under") : results[3] == "1" ? this.translate.instant("Over") : this.translate.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Over-Under") : results[4] == "1" ? this.translate.instant("Over") : this.translate.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Over-Under") : results[5] == "1" ? this.translate.instant("Over") : this.translate.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Over-Under") : results[6] == "1" ? this.translate.instant("Over") : this.translate.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Over-Under") : results[7] == "1" ? this.translate.instant("Over") : this.translate.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Over-Under") : results[8] == "1" ? this.translate.instant("Over") : this.translate.instant("Under");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Yes-No") : results[9] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Will be Penalty (90 min)") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Yes-No") : results[10] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Goal in the 1st Half") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Yes-No") : results[11] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Goal in the 2nd Half") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Yes-No") : results[12] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("GG (Both Score)") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                            data = results[count] == "12" ? this.translate.instant("Yes-No") : results[13] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                            resultMats += "<div class='col-9 search-game-list'>" + this.translate.instant("Red Card") + "</div><div class='col-3 search-game-list'> " + data + "</div>";
                          } else {
                            //if (results[0].includes(element.matchResult) && !bet.rankRound) {
                            if (results[0].includes(mWinner[0]) && !bet.rankRound) {
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Final Result") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + results[0] + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Final Result") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + results[0] + "</div>" + "<div class='no-hit'></div>";
                            }
                            //if (results[1].includes(element.moreBallPossession != "" ? element.moreBallPossession.split(',')[0] > element.moreBallPossession.split(',')[1] ? "1" : "2" : "X") && !bet.rankRound) {
                            if (results[1].includes(mWinner[1]) && !bet.rankRound) {
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("More Ball Possession") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + results[1] + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("More Ball Possession") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + results[1] + "</div>" + "<div class='no-hit'></div>";
                            }
                            //if (results[2].includes(element.totalGoals.split(',')[0] > element.totalGoals.split(',')[1] ? "1" : "2") && !bet.rankRound) {
                            //var data = results[count] == "12" ? "Over-Under" : results[count] == "1" ? "Over" : "Under";
                            if (results[2].includes(mWinner[2]) && !bet.rankRound) {
                              var data = results[2] == "12" ? this.translate.instant("Over-Under") : results[2] == "1" ? this.translate.instant("Over") : this.translate.instant("Under");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Total Goals") + "(" + element.totalGoals + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[count] == "12" ? "Over-Under" : results[count] == "1" ? "Over" : "Under";
                              if (results[2] == '12') {
                                var data = results[2] == "12" ? this.translate.instant("Over-Under") : this.translate.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Total Goals") + "(" + element.totalGoals + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[2] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Total Goals") + "(" + element.totalGoals + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }
                            if (results[3].includes(mWinner[3]) && !bet.rankRound) {
                              var data = results[3] == '12' ? this.translate.instant("Over-Under") : results[3] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              // var data = results[3] == '12' ? this.translate.instant("Over-Under") : results[3] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              // resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[3] == '12') {
                                var data = results[3] == '12' ? this.translate.instant("Over-Under") : this.translate.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[3] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Total Corners") + "(" + element.totalCorners + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }

                            if (results[4].includes(mWinner[4]) && !bet.rankRound) {
                              var data = results[4] == '12' ? this.translate.instant("Over-Under") : results[4] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[4] == '12' ? this.translate.instant("Over-Under") : results[4] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              //resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[4] == '12') {
                                var data = results[4] == '12' ? this.translate.instant("Over-Under") : this.translate.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[4] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Yellow Cards") + "(" + element.yellowCards + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }

                            if (results[5].includes(mWinner[5]) && !bet.rankRound) {
                              var data = results[5] == '12' ? this.translate.instant("Over-Under") : results[5] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[5] == '12' ? this.translate.instant("Over-Under") : results[5] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              //resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[5] == '12') {
                                var data = results[5] == '12' ? this.translate.instant("Over-Under") : this.translate.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[5] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Corners Home Team") + "(" + element.cornerHomeTeam + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }

                            if (results[6].includes(mWinner[6]) && !bet.rankRound) {
                              var data = results[6] == '12' ? this.translate.instant("Over-Under") : results[6] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              // var data = results[6] == '12' ? this.translate.instant("Over-Under") : results[6] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              // resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[6] == '12') {
                                var data = results[6] == '12' ? this.translate.instant("Over-Under") : this.translate.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[6] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Corners Away Team") + "(" + element.cornerAwayTeam + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }
                            if (results[7].includes(mWinner[7]) && !bet.rankRound) {
                              var data = results[7] == '12' ? this.translate.instant("Over-Under") : results[7] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[7] == '12' ? this.translate.instant("Over-Under") : results[7] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              //resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[7] == '12') {
                                var data = results[7] == '12' ? this.translate.instant("Over-Under") : this.translate.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[7] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Total Faults") + "(" + element.totalFaults + ")</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }
                            if (results[8].includes(mWinner[8]) && !bet.rankRound) {
                              var data = results[8] == '12' ? this.translate.instant("Over-Under") : results[8] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              //var data = results[8] == '12' ? this.translate.instant("Over-Under") : results[8] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                              //resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              if (results[8] == '12') {
                                var data = results[8] == '12' ? this.translate.instant("Over-Under") : this.translate.instant("Over-Under");
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                              }
                              else {
                                var data = results[8] == "1" ? this.translate.instant("Over") : this.translate.instant(this.translate.instant("Under"));
                                resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Shoots On Target") + "(" + element.shootsOnTarget + ")</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                              }
                            }
                            if (results[9].includes(mWinner[9]) && !bet.rankRound) {
                              var data = results[9] == '12' ? this.translate.instant("Yes-No") : results[9] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Will be Penalty (90 min)") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[9] == '12' ? this.translate.instant("Yes-No") : results[9] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Will be Penalty (90 min)") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                            if (results[10].includes(mWinner[10]) && !bet.rankRound) {
                              var data = results[10] == '12' ? this.translate.instant("Yes-No") : results[10] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Goal in the 1st Half") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[10] == '12' ? this.translate.instant("Yes-No") : results[10] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("Goal in the 1st Half") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                            if (results[11].includes(mWinner[11]) && !bet.rankRound) {
                              var data = results[11] == '12' ? this.translate.instant("Yes-No") : results[11] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Goal in the 2nd Half") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[11] == '12' ? this.translate.instant("Yes-No") : results[11] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Goal in the 2nd Half") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                            if (results[12].includes(mWinner[12]) && !bet.rankRound) {
                              var data = results[12] == '12' ? this.translate.instant("Yes-No") : results[12] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("GG (Both Score)") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[12] == '12' ? this.translate.instant("Yes-No") : results[12] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg'>" + this.translate.instant("GG (Both Score)") + "</div><div class='col-2 single-match-bg couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                            if (results[13].includes(mWinner[13]) && !bet.rankRound) {
                              var data = results[13] == '12' ? this.translate.instant("Yes-No") : results[13] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Red Card") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='correct'></div>";
                            }
                            else {
                              var data = results[13] == '12' ? this.translate.instant("Yes-No") : results[13] == "1" ? this.translate.instant("Yes") : this.translate.instant("No");
                              resultMats += "<div class='col-9 single-match-bg-2'>" + this.translate.instant("Red Card") + "</div><div class='col-2 single-match-bg-2 couponResults-SingleMatch'> " + data + "</div>" + "<div class='no-hit'></div>";
                            }
                          }
                        }
                        count++;
                      });
                    }
                    else {
                      matchs.forEach(element => {
                        if (element.status == 0) {
                          resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " <br/> " + element.awayName + "</div><div class='col-2 result-game-list'> " + results[count] + "</div>" + "<div class='cancelled'></div>";
                        } else {
                          if (element.matchResult == "0") {
                            resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " <br/> " + element.awayName + "</div><div class='col-2 result-game-list'> " + results[count] + "</div>" + "<div class='cancelled'></div>";
                          } else {
                            if (results[count].includes(element.matchResult)) {
                              resultMats += "<div class='col-9 search-game-list'><span>" + `<img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="https://poolbet365.com/assets/shields/${element.homeName}.png">` + `<span>${element.homeName}</span></span>` + `<span class="couponScores">${element.homeTeamScore}</span>` + " </br> " + `<span><img class="mr-1" width="21px" src="https://poolbet365.com/assets/shields/${element.awayName}.png"` + `<span>${element.awayName}</span></span>` + `<span class="couponScores">${element.awayTeamScore}</span>` + "</div><div class='col-2 result-game-list couponResults'> " + results[count] + "</div>" + "<div class='correct'></div>";
                            } else {
                              resultMats += "<div class='col-9 search-game-list'><span>" + `<img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="https://poolbet365.com/assets/shields/${element.homeName}.png">` + `<span>${element.homeName}</span></span>` + `<span class="couponScores">${element.homeTeamScore}</span>` + " </br> " + `<span><img class="mr-1" width="21px" src="https://poolbet365.com/assets/shields/${element.awayName}.png"` + `<span>${element.awayName}</span></span>` + `<span class="couponScores">${element.awayTeamScore}</span>` + "</div><div class='col-2 result-game-list couponResults'> " + results[count] + "</div>" + "<div class='no-hit'></div>";
                            }
                          }
                        }
                        count++;
                      });
                    }
                  }

                  let betDate = (new Date(bet.betDate)).getTime() - (new Date()).getTimezoneOffset() * 60000;
                  let stringTable = "<div class='row justify-content-center'>" +
                    "<div class='col-6 font-bold search-head font-adjust text-right text-white pr-2'>" + this.translate.instant("pg-tickets.operator") + ":" + "</div>" +
                    "<div class='col-6 font-adjust p-0'>" + user.login + "</div>" +
                    "<div class='col-6 font-bold search-head font-adjust text-right text-white pr-2'>" + this.translate.instant("pg-tickets.player") + ":" + "</div>" +
                    "<div class='col-6 font-adjust p-0'>" + bet.playerName + "</div>" +
                    "<div class='col-6 font-bold search-head font-adjust text-right text-white pr-2'>" + this.translate.instant("pg-credit-transfer.value") + ":" + "</div>" +
                    "<div class='col-6 font-adjust p-0'>" + this.couponService.formatCurrencyValue(bet.value) + "</div>" +
                    "<div class='col-6 font-bold search-head font-adjust text-right text-white pr-2'>" + this.translate.instant("pg-post-result.date") + ":" + "</div>" +
                    "<div class='col-6 font-adjust p-0'>" + this.datePipe.transform(betDate, 'dd/MM/y HH:mm') + "</div>" +
                    resultMats
                  "</div>";
                  this.msgStandard(this.translate.instant("pg-tickets.resume-ticket") + ticketCode, stringTable, 1);
                  //(<HTMLInputElement>document.getElementById("code-search")).value = "";
                }, error => {
                  this.msgStandard(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), 4);
                });
            }, error => {
              this.msgStandard(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), 4);
            });
        }
      }, error => {
        this.msgStandard(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), 4);
      });
  }

  msgStandard(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandard').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandard').classList.add(this.msgType[type]);
    }

    this.modalMessages.show();
  }

  msgStandardDanger(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardDanger').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardDanger').classList.add(this.msgType[type]);
    }

    this.modalMessagesDanger.show();
  }

  msgStandardCredit(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardCredit').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardCredit').classList.add(this.msgType[type]);
    }

    this.modalMessagesCredit.show();
  }

  msgStandardBetting(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardBetting').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardBetting').classList.add(this.msgType[type]);
    }

    this.modalMessagesCredit.show();
  }

  CreditRedirect() {
    this.modalMessagesCredit.hide();
    this.router.navigate(['/payment']);
    //location.replace("/payment");
  }

  BettingRedirect() {
    this.modalMessagesCredit.hide();
    this.router.navigate(['/home']);
    //location.replace("/home");
  }

  makeTicket(index: number, whichModal: number) {
    this.indexActivated = index;

    let results: string = this.bets[index].results;

    let day = this.bets[index].betDate.toString().substring(8, 10);
    let month = this.bets[index].betDate.toString().substring(5, 7);
    let year = this.bets[index].betDate.toString().substring(0, 4);
    let hour = this.bets[index].betDate.toString().substring(11, 16);
    let completeDate: string = day + '/' + month + '/' + year;

    this.viewTicket =
      this.translate.instant("pg-manage-ticket.code") + ': ' + this.bets[index].id + ' - ' + this.translate.instant("pg-manage-ticket.round") + ': ' + this.bets[index].roundId + '<br/>' +
      ' ' + this.translate.instant("pg-manage-ticket.date") + ': ' + completeDate + ' - ' + this.translate.instant("pg-manage-ticket.hour") + ': ' + hour + '<br/>' +
      this.translate.instant("pg-manage-ticket.operator") + ': ' + this.userNameToShow[index] + ' - ' + this.translate.instant("pg-manage-ticket.player") + ': ' + this.bets[index].playerName + '<br/><br/>' +
      this.translate.instant("pg-manage-ticket.results") + ':<br/><br/>' + results;




    if (whichModal == 1) {
      this.modalTicket.show();
    }
    else {
      this.modalCancel.show();
    }
  }


  cancelTicket() {
    // ATTENTION: using this field for a while just to make it work for now
    this.bets[this.indexActivated].resultBetId = this.userAdmin.id;

    this.couponService.updateBet(this.bets[this.indexActivated])
      .subscribe(data => {
        this.msgStandard(this.translate.instant("pg-manage-ticket.ticket-canceled"), this.translate.instant("pg-manage-ticket.ticket-canceled-info"), 3);
        this.bets[this.indexActivated].status = 0;
      }, error => {
        let msgComplement: string;
        if (error.error == "Time exceeded!") {
          msgComplement = this.translate.instant("pg-manage-ticket.time-exceeded");
        }

        if (error.error == "You're not the owner.") {
          msgComplement = this.translate.instant("pg-manage-ticket.only-owner");
        }

        this.msgStandard(this.translate.instant("pg-manage-ticket.not-canceled"), this.translate.instant("pg-manage-ticket.not-canceled-info") + " " + msgComplement, 4);
      });

    this.modalCancel.hide();
  }
}
