<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-square-spin" [fullScreen]="true">
  <p style="color: white"></p>
</ngx-spinner>

<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'admin.financialactivity' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'admin.financialactivity' | translate }} {{ 'admin.search' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <div class="input-group mb-3" style="position: unset; display: inline;">
        <label style="margin-top: auto; display: inline-block;">{{ 'admin.daterange' | translate }}</label>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'startDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker1" [formControl]="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'endDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker2" [formControl]="endDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

      </div>
      <br>
      <div class="input-group mb-3" style="position: unset; display: inline;">
        <label style="margin-top: auto; display: inline-block;">{{ 'InfoJurisdiction' | translate }}</label>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'LevelJurisdiction' | translate }}</mat-label>
          <mat-select #jurisdictionLevelId style="max-width: 200px;" [(ngModel)]="selectJurisId"
            (selectionChange)="jurisdictionLevelChange($event)">
            <mat-option [value]="item.id" *ngFor="let item of jurisdictions">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="margin-left: 20px;" appearance="fill">
          <mat-label>{{ 'NameJurisdiction' | translate }}</mat-label>
          <mat-select #jurisdictionId style="max-width: 200px;" [(ngModel)]="selectJurisIdName"
            (selectionChange)="jurisdictionChange()">
            <mat-option [value]="item.id" *ngFor="let item of allowUsersList">{{item.login}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="btn btn-success btn-sm" style="margin-left: 2%; margin-top: auto;" (click)="filterBtn()"
          [disabled]="missingJurisName">{{ 'Filter' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'admin.financialactivitylist' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <div style="text-align: right;">
        <mat-form-field>
          <mat-label>{{"Search" | translate}}...</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
      </div>
      <div class="mat-elevation-z8" style="overflow: auto;">
        <div class="financialHeader">
          <span class="mr-2 jurisdiction">{{ 'Jurisdiction' | translate }}:</span>
          <span class="jurisdictionName">{{ getJurisdictionLoginById(jurisdictionId.value) }}</span>
        </div>
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="financial">
            <th mat-header-cell *matHeaderCellDef> {{ ('Financial' | translate).toUpperCase() }}({{ ('Deposit' |
              translate).toUpperCase() }}/{{ ('Withdraw' | translate).toUpperCase() }}/{{ ('Balance' |
              translate).toUpperCase() }}) </th>
            <td mat-cell *matCellDef="let element">
              <span class="mr-2 bolderFont">{{ 'pg-jurisdiction.deposit' | translate }}:</span>
              {{Currency.currencySymbol}} {{commafy((element.financial[0]).toFixed(2) * Currency.quote |
              currency:'':'')}}<br>
              <span class="mr-2 bolderFont">{{ 'pg-jurisdiction.withdraw' | translate }}:</span>
              {{Currency.currencySymbol}} {{commafy((element.financial[1]).toFixed(2) * Currency.quote |
              currency:'':'')}}<br>
              <span class="mr-2 bolderFont">{{ 'Balance' | translate }}:</span> {{Currency.currencySymbol}}
              {{commafy((element.financial[0] - element.financial[1]).toFixed(2) * Currency.quote | currency:'':'')}}
            </td>
          </ng-container>

          <ng-container matColumnDef="activities">
            <th mat-header-cell *matHeaderCellDef> {{ ('Activities' | translate).toUpperCase() }}({{ ('Bets' |
              translate).toUpperCase() }}/{{ ('Rake' | translate).toUpperCase() }}/{{ ('Commission' |
              translate).toUpperCase() }}) </th>
            <td mat-cell *matCellDef="let element">
              <span class="bolderFont">{{ 'Total Betting Amount' | translate }}:</span> {{Currency.currencySymbol}}
              {{commafy((element.activities[0]).toFixed(2) * Currency.quote | currency:'':'')}}<br>
              <span class="bolderFont">{{ 'Rake' | translate }}:</span> {{Currency.currencySymbol}}
              {{commafy((element.activities[1]).toFixed(2) * Currency.quote | currency:'':'')}}<br>
              <span class="bolderFont">{{ 'Commission' | translate }}:</span> {{element.activities[3]}}%<br>
            </td>
          </ng-container>

          <ng-container matColumnDef="final">
            <th mat-header-cell *matHeaderCellDef> {{ ('Final Contability' | translate).toUpperCase() }}</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="this.final === undefined ">{{ 'Loading' | translate }}...</span>
              <span class="bolderFont" *ngIf="this.final != undefined">{{ setLabelPerValue(this.final, jurisdictionId.value) }}: </span>
              <span *ngIf="this.final >= 0">
                {{ formattNumber((this.final) * Currency.quote | currency:'':'') }}
              </span>
              <span style="color: red;" *ngIf="this.final < 0">
                {{ formattNumber((this.final * (-1)) * Currency.quote | currency:'':'') }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef> {{ ('Net Details' | translate).toUpperCase() }} </th>
            <td mat-cell *matCellDef="let element">
              <img (click)="detailsBtn(element.children, element.districts)" src="../../../../assets/images/detail.png"
                width="20px" height="20px" style="margin-left: 21vh; cursor: pointer;">
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>

  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'admin.detailednetcontability' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <div style="text-align: right;">
        <mat-form-field>
          <mat-label>{{"Search" | translate}}...</mat-label>
          <input matInput (keyup)="applyFilterDetails($event)" #input>
        </mat-form-field>
      </div>
      <div class="mat-elevation-z8" style="overflow: auto;">

        <div class="financialHeader">
          <span class="mr-2 jurisdiction">{{ 'Jurisdiction' | translate }}:</span>
          <span class="jurisdictionName">{{ getJurisdictionLoginById(jurisdictionId.value) }}</span>
        </div>

        <table mat-table [dataSource]="dataSourceDetails">
          <ng-container matColumnDef="clubs">
            <th mat-header-cell *matHeaderCellDef>
              <span class="text-uppercase">{{ 'Jurisdiction' | translate }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ getActiveJurisName(element.activity.children).player }}
            </td>
          </ng-container>

          <ng-container matColumnDef="financial">
            <th mat-header-cell *matHeaderCellDef> {{ ('Financial' | translate).toUpperCase() }}({{ ('Deposit' |
              translate).toUpperCase() }}/{{ ('Withdraw' | translate).toUpperCase() }}/{{ ('Balance' |
              translate).toUpperCase() }}) </th>
            <td mat-cell *matCellDef="let element">
              <span class="bolderFont">{{ 'pg-jurisdiction.deposit' | translate }}:</span> {{Currency.currencySymbol}}
              {{commafy((element.financial.deposit).toFixed(2) * Currency.quote | currency:'':'')}}<br>
              <span class="bolderFont">{{ 'permits.withdrawal' | translate }}:</span> {{Currency.currencySymbol}}
              {{commafy((element.financial.withdraw).toFixed(2) * Currency.quote | currency:'':'')}}<br>
              <span class="bolderFont">{{ 'Total Betting Amount' | translate }}:</span> {{Currency.currencySymbol}}
              {{commafy((element.activity.bet).toFixed(2) * Currency.quote | currency:'':'')}}<br>
              <span class="bolderFont">{{ 'Rake' | translate }}:</span> {{Currency.currencySymbol}}
              {{commafy((element.activity.rake).toFixed(2) * Currency.quote | currency:'':'')}}
            </td>
          </ng-container>

          <ng-container matColumnDef="activities">
            <th mat-header-cell *matHeaderCellDef> {{ ('Activities' | translate).toUpperCase() }}({{ ('Bets' |
              translate).toUpperCase() }}/{{ ('Rake' | translate).toUpperCase() }}/{{ ('Commission' |
              translate).toUpperCase() }})
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="bolderFont"> {{this.setLabel(element)}}: </span>
              <ng-container *ngFor="let item of element.activity.children">
                <ng-container *ngIf="(item.jurisdictionId > 0 && item.jurisdictionId != 7)">
                  <span style="color: red" *ngIf="item.financial < 0">{{ formattNumber((item.financial * (-1)) *
                    Currency.quote | currency:'':'') }}</span>
                  <span *ngIf="item.financial >= 0">{{ formattNumber((item.financial) * Currency.quote | currency:'':'')
                    }}</span>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="jurisdiction">
            <th mat-header-cell *matHeaderCellDef></th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <ng-container class="text-right" *ngFor="let item of element.activity.children">
                <ng-container class="text-right" *ngIf="(item.jurisdictionId > 0 && item.jurisdictionId != 7)">
                  <span class="d-inline-flex pt-2 pb-3">{{getJurisdictionName(item.jurisdictionId).name}}:</span> <br>
                  <hr>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="contability">
            <th class="text-left" mat-header-cell *matHeaderCellDef>{{'Jurisdiction.network' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container class="text-right" *ngFor="let item of element.activity.children">
                <ng-container class="text-right" *ngIf="(item.jurisdictionId > 0 && item.jurisdictionId != 7)">
                  <span class="d-inline-flex pt-2 pb-3 pl-3 w-100">{{item.player}}</span> <br>
                  <hr>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th class="text-left" mat-header-cell *matHeaderCellDef>{{'gain' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container class="text-right" *ngFor="let item of element.activity.children">
                <ng-container class="text-right" *ngIf="(item.jurisdictionId > 0 && item.jurisdictionId != 7)">
                  <span class="d-inline-flex pt-2 pb-3 w-100">{{Currency.currencySymbol}}
                    {{commafy((element.activity.rake * item.commission / 100).toFixed(2) * Currency.quote |
                    currency:'':'')}}</span> <br>
                  <hr>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="percentual">
            <th style="width: 90px; text-align: center; padding-right: 2% !important;" mat-header-cell
              *matHeaderCellDef>
              %
            </th>
            <td class="text-center" mat-cell *matCellDef="let element">
              <ng-container *ngFor="let item of element.activity.children">
                <ng-container *ngIf="(item.jurisdictionId > 0 && item.jurisdictionId != 7)">
                  <span class="d-inline-flex justify-content-center pt-2 pb-3 w-100">{{item.commission}}%</span> <br>
                  <hr>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsDetails"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDetails;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>

</div>
