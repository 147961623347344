<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'Credits Transfer' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
    <div class="panel">
      <div class="panel-heading">
        <div class="panel-title row">
          <div class="col-md-9">
            <span class="material-icons default-icon">euro</span>
            {{ 'Credits Transfer' | translate }}
          </div>
        </div>
      </div>
      <div class="panel-body">
        <ng-container *ngIf="!loading">
          <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <label for="transferType"> {{'Transfer Type' | translate}}</label>
            </div>
            <div class="col-md-4">
              <select id="transferType" [(ngModel)]="transferType" class="form-control">
                <option [value]="creditCard">{{'Extend Credit' | translate}}</option>
                <option [value]="debitCard">{{'Withdraw Credit' | translate}}</option>
              </select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <label for="JurisdictionType"> {{'Jurisdiction Type' | translate}}</label>
            </div>
            <div class="col-md-4">
              <select class="form-control" [(ngModel)]="JurisdictionType" (change)="jurisdictionLevelChange($event)">
                <option value="-1">{{ 'Select' | translate }}</option>
                <option [value]="item.id" *ngFor="let item of jurisdictions">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-2 d-flex align-items-center">
              <div style="font-weight: 500;"> {{'Jurisdiction' | translate}}</div>
            </div>
            <div class="col-md-4">
              <!-- <select id="Jurisdiction" class="form-control" [(ngModel)]="Jurisdiction"
                (change)="jurisdictionChange($event)">
                <option value="-1">{{ 'Select' | translate }}</option>
                <option [value]="item.id" *ngFor="let item of allowUsersList">
                  {{item.login}} [{{Currency.currencySymbol}} {{item.credit.toFixed(2) * Currency.quote | currency:'':''}}]
                </option>
              </select> -->
              <mat-form-field class="postFilter w-100" appearance="fill">
                <mat-label>{{ 'Select' | translate }} </mat-label>
                <input type="text" matInput (keyup)="FilterUsers($event)" [(ngModel)]="inputAlt" [matAutocomplete]="auto" [value]="targetJurisdiction?.login">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                    <mat-option *ngFor="let item of allowUsersHelper" [value]="item"  (click)="jurisdictionChange(item.id);">
                      {{item.login}} [{{Currency.currencySymbol}} {{item.credit.toFixed(2) * Currency.quote | currency:'':''}}]
                    </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <label for="transferAmount"> {{'Amount' | translate}}</label>
            </div>
            <div class="col-md-4">
              <input type="number" [(ngModel)]="transferAmount" class="form-control" name="transferAmount"
                id="transferAmount" placeholder="{{'Amount' | translate}}">
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-1"></div>
            <div class="col-md-2">
            </div>
            <div class="col-md-4">
              <button class="btn btn-info btn-sm" data-toggle="modal" data-target="#exampleModal2"
                (click)="transferCredit()">{{ 'Save' | translate }}</button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="loading">
          <div class="spinner-border ml-4" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </ng-container>
      </div>
    </div>

  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true" aria-modal="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title w-100" id="exampleModalLabel2">{{'Attention' | translate}}</h4>
          <button type="button" #confirmationModel class="close pull-right" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label for="custNameIDD"> {{'Jurisdiction' | translate}}</label>
              </div>
              <div class="col-md-4">
                <p id="custNameIDD">{{ currentJurisdiction }}</p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label for="balanceIDD"> {{'pg-jurisdiction.current-credit' | translate}}</label>
              </div>
              <div class="col-md-4">
                <p id="balanceIDD">{{Currency.currencySymbol}} {{ currentCredit * Currency.quote | currency:'':''}}</p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label for="ttIDD"> {{'Transfer Type' | translate}}</label>
              </div>
              <div class="col-md-4">
                <p id="balanceIDD">{{ creditType}}</p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label for="ATDIDD">{{'Credit Amount' | translate}}</label>
              </div>
              <div class="col-md-4">
                <p id="balanceIDD">{{Currency.currencySymbol}} {{ creditedAmount * Currency.quote | currency:'':''}}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label for="balanceIDD"> {{'New Credit' | translate}}</label>
              </div>
              <div class="col-md-4">
                <p id="balanceIDD">{{Currency.currencySymbol}} {{ newCredit * Currency.quote | currency:'':''}}</p>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn" data-dismiss="modal">{{'Cancel' | translate}}</button>
          <button type="button" class="btn btn-primary" (click)="transferCreditConfirm()" data-dismiss="modal">
            {{'Confirm' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel3" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title w-100" id="exampleModalLabel3">{{'Success' | translate}}</h4>
          <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-9">
              <p>{{message}}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">{{'Ok' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
