<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'Refund' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'Filter' | translate }}
      </div>
    </div>
    <div class="panel-body">

      <div class="input-group mb-3" style="position: unset; display: inline;">
        <label style="margin-top: auto; display: inline-block;">{{ 'admin.daterange' | translate }}</label>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'startDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker1" [formControl]="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'endDate' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker2" [formControl]="endDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="input-group mb-3">
        <label>{{ 'Jurisdiction' | translate }}</label>
        <select class="form-control" style="max-width: 200px;" (change)="jurisdictionLevelChange($event)">
          <option [value]="item.id" *ngFor="let item of jurisdictions">{{item.name}}</option>
        </select>
        <select class="form-control" style="max-width: 200px; margin-left: 30px;" (change)="jurisdictionChange($event)">
          <option value="-1">{{ 'All' | translate }}</option>
          <option [value]="item.id" *ngFor="let item of allowUsersList">{{item.login}}</option>
        </select>
      </div>
      <div style="text-align: center;">
        <button class="btn btn-success btn-sm" (click)="filterBtn()">{{ 'Filter' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <span class="material-icons default-icon">search</span>
        {{ 'admin.withdrawallist' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <div style="text-align: right;">
        <mat-form-field>
          <mat-label>{{"Search" | translate}}...</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
      </div>
      <div class="mat-elevation-z8" style="overflow: auto;">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef> UserId </th>
            <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
          </ng-container>

          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef> {{ ('Name' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> {{ ('date.title' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{element.date_date}} {{element.date_time}} </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> {{ ('Amount' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.amount.toFixed(2) *
              Currency.quote | currency:'':''}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{ ('Status' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.status==1">{{ 'refund.paid' | translate }}</span>
              <span *ngIf="element.status==2">{{ 'refund.waiting' | translate }}</span>
              <span *ngIf="element.status==3">{{ 'refund.refused' | translate }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{ ('Description' | translate).toUpperCase()}} </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [style.background]="getTableBackground(row.status)"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>