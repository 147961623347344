import { Injectable } from '@angular/core';
import {StorageService} from './storage.service';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  storageKey = 'locale';
  currentLang = new BehaviorSubject<string>('en');

  constructor(
    private storageService: StorageService,
    private translateService: TranslateService,
  ) { }

  initialize(): void {
    const prevLanguage = this.storageService.get(this.storageKey);
    if (prevLanguage) {
      this.currentLang.next(prevLanguage);
      this.translateService.use(prevLanguage);
    } else {
      this.currentLang.next('en');
      this.translateService.use('en');
      this.storageService.set(this.storageKey, 'en');
    }
  }

  changeLanguage(lang): void {
    this.storageService.set(this.storageKey, lang);
    this.currentLang.next(lang);
    this.translateService.use(lang);
  }
}
