import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { InteractionService } from '../../../../app/services/interaction.service';
import { Currency } from '../../../../app/interface/currency.interface';
import { userAssistant } from 'src/app/interface/user.interface';
import { ModalDirective } from 'angular-bootstrap-md';
import { TranslateService } from '@ngx-translate/core';
import { User, UserLoginForm } from '../../../interface/user.interface';
import { ToastService } from '../../../services/toast.service';
import { Permit } from '../../../interface/permit.interface';
import { CheckboxComponent } from 'angular-bootstrap-md';
@Component({
  selector: 'app-list-jurisdiction',
  templateUrl: './list-jurisdiction.component.html',
  styleUrls: ['./list-jurisdiction.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class ListJurisdictionComponent implements OnInit {
  displayedColumns: string[] = [ 'username', 'fullname', 'level', 'access' ,'detail','id' ];
  dataSource = new MatTableDataSource<any>([]);


  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  Currency: Currency = <Currency>{};
  @ViewChildren('newPermitChk1') newPermitChk1: QueryList<CheckboxComponent>;
  @ViewChildren('editPermitChk1') editPermitChk1: QueryList<CheckboxComponent>;
  @ViewChild('newAdminView') newAdminView: ModalDirective;
  @ViewChild('editAdminView') editAdminView: ModalDirective;
  @ViewChild('deleteConfirm') deleteConfirm: ModalDirective;
  @ViewChild('notificationModal') notificationModal: ModalDirective;
  //@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  userAdmin: any;
  newAdminForm: FormGroup;
  savingNewAdmin = false;
  editAdminForm: FormGroup;
  start_date: any = new Date();
  end_date: any = new Date();
  seletedJurisdiction: any;
  usersList: any = [];
  allowUsersList: any = [];
  jurisdictions: any;
  selectedJurisdictionLevel: any;
  selectedJuridictionParentName: any;
  selectedJuridictionParentId: any;
  data: any;
  selected: { startDate: Moment, endDate: Moment }
  group: any = "6"
  userEdit: userAssistant;
  newEdit: userAssistant;
  editId: any;
  deleteId: number;
  startDate = new FormControl(new Date());
  picker1: any;
  endDate = new FormControl(new Date());
  picker2: any;
  permitsList: Permit[];

  EditAdministratorPermit : boolean = false;
  constructor(private formBuilder: FormBuilder, private toastService: ToastService,private translateService: TranslateService,
    public loginService: LoginService,
    private _snackBar: MatSnackBar,
    public spinner: NgxSpinnerService,
    private _adapter: DateAdapter<any>,
    private interactionService: InteractionService
  ) {
    this.newEdit = {} as userAssistant;
    this.userAdmin = loginService.getUserInfoFromCookie();
    this.newAdminForm = this.formBuilder.group({
      new_username: ['', Validators.required],
      new_name: [''],
      new_password: ['', Validators.required],
      new_email: ['', Validators.required],
      new_type: [''],
      new_jurisdiction: [''],
      new_parentId:[''],
      new_Access:[''],
    }, {
    });
    this.editAdminForm = this.formBuilder.group({
      edit_username: ['', Validators.required],
      edit_name: [''],
      edit_password: ['', Validators.required],
      edit_email: ['', Validators.required],
      edit_type: [''],
      whatsApp: [''],
      edit_jurisdiction: [''],
      edit_parentId:[''],
      edit_Access:[''],
    }, {
    });
    this.loginService.getAllPermits(this.userAdmin.id, this.userAdmin.login)
    .subscribe(data => {
      this.permitsList = data;
    }, error => {
      console.log(error);
    });
  }
  ngOnInit(): void {
    this.EditAdministratorPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'myadministrators') == null ? false : true : true;
    this._adapter.setLocale('br')
    this.getJurisdictions()
    this.loginService.setGlobalCurrency()
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  applyBtn() {

  }

  getUsersList() {
    this.loginService.GetUsersList(this.userAdmin.id).subscribe(res => {
      
      let temUser = res.find(element => element.jurisdictionId == this.selectedJurisdictionLevel && element.id == this.userAdmin.id && element.login == this.userAdmin.login)
      if (temUser == null) {
        this.loginService.GetUserAssist(this.userAdmin.id).subscribe(x => {
          temUser = res.find(element => element.id == x.adminParentID && x.userName == this.userAdmin.login)
          this.allowUsersList.push(temUser)
          this.usersList.push(temUser)

          this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
          this.loginService.getUsersTreeList(x.adminParentID)
            .subscribe(res => {
              res.forEach(element => {
                this.usersList.push(element)
              });
              this.seletedJurisdiction = -1
            })
        })
      } else {
        this.allowUsersList.push(temUser)
        this.usersList.push(temUser)

        this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
        this.loginService.getUsersTreeList(temUser.id)
          .subscribe(res => {
            res.forEach(element => {
              this.usersList.push(element)
            });
            this.seletedJurisdiction = -1
          })
      }
    })
  }

  getJurisdictions() {
    this.loginService.getJurisdictions(parseInt(localStorage.getItem("currentUserJurisdictionId"))).subscribe(res => {
      this.jurisdictions = res
      this.selectedJurisdictionLevel = parseInt(localStorage.getItem("currentUserJurisdictionId"))
      this.getUsersList()
    })
  }


  getUserAssistantFilter(jurisdictionLevel, jurisdiction) {
    let userAssistantFilter = {};
    var LogUserToFindAssis = this.allowUsersList.find(x => x.id == jurisdictionLevel).login
    userAssistantFilter['jurisdiction'] = parseInt(jurisdictionLevel);
    userAssistantFilter['jurisdictionlevel'] = parseInt(jurisdiction);
    // userAssistantFilter['LogUser'] = this.userAdmin.login;
    userAssistantFilter['LogUser'] = LogUserToFindAssis;
    this.loginService.getUserAssistantFilter(userAssistantFilter).subscribe((res: any) => {
      //debugger;
      res.forEach(item => {
        this.data.push({
          id :  item.id,
          username : item.userName,
          fullname :item.name,
          level : item.jurisdiction.name + "|(" + item.userAdmin.login + ")",
          access: item.active == 1 ? "Enabled": "Disabled"
        })


      });

      this.dataSource.data = this.data
      this.dataSource.paginator = this.paginator.toArray()[0];
    })
  }

  jurisdictionLevelChange(event) {
    this.selectedJurisdictionLevel = event.target.value
    this.allowUsersList = []
    this.usersList.forEach(element => {
      if (element.jurisdictionId == this.selectedJurisdictionLevel) {
        if (parseInt(localStorage.getItem("currentUserJurisdictionId")) == this.selectedJurisdictionLevel) {
          if (element.id == this.userAdmin.id) {
            this.allowUsersList = [element]
          }
        } else {
          this.allowUsersList.push(element)
        }
      }
    });
    this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
    this.seletedJurisdiction = -1
  }

  jurisdictionChange(event) {
    this.seletedJurisdiction = event.target.value
  }



  async getClubs(children, details, jurisdictionName) {
    for (let i = 0; i < children.length; i++) {
      let element = children[i]
      if (element.jurisdictionId == 6) {
        let activities = [{
          jurisdictionId: element.jurisdictionId,
          player: element.login,
          commission: element.commission,
          ori_commission: element.commission
        }]
        let result = await this.getUser(element.parentId, this.getJurisdictionByName(jurisdictionName).id, activities)
        details.push({
          club: element.login,
          financial: { deposit: 0, withdraw: 0 },
          activity: { bet: 0, rake: 0, children: result },
        })
      } else {
        if (element.children.length > 0) {
          details = await this.getClubs(element.children, details, jurisdictionName)
        }
      }
    }
    return details
  }

  async getPlayers(children, details, jurisdictionName) {
    for (let i = 0; i < children.length; i++) {
      let element = children[i]
      if (element.jurisdictionId == 7) {
        let activities = [{
          jurisdictionId: element.jurisdictionId,
          player: element.login,
          commission: element.commission,
          ori_commission: element.commission
        }]
        let result = await this.getUser(element.parentId, this.getJurisdictionByName(jurisdictionName).id, activities)
        details.push({
          club: element.login,
          financial: { deposit: 0, withdraw: 0 },
          activity: { bet: 0, rake: 0, children: result },
        })
      } else {
        if (element.children.length > 0) {
          details = await this.getPlayers(element.children, details, jurisdictionName)
        }
      }
    }
    return details
  }

  async getUser(id, finalId, activities) {
    return await this.loginService.getUser(id).toPromise().then(async data => {
      activities.push({
        jurisdictionId: data.jurisdictionId,
        player: data.login,
        ori_commission: data.commission,
        commission: data.commission - activities[activities.length - 1].ori_commission
      })
      if (data.id == finalId) {
        return activities
      } else {
        activities = await this.getUser(data.parentId, finalId, activities)
        return activities
      }
    })
  }

  getJurisdictionName(id) {
    return this.jurisdictions.find(e => e.id == id)
  }

  getJurisdiction(id) {
    return this.allowUsersList.find(e => e.id == id)
  }

  getJurisdictionByName(name) {
    return this.usersList.find(e => e.login == name)
  }

  getJurisdictionById(id) {
    let user = this.usersList.find(e => e.id == id)
    if(user == null){
      let userAssist = this.dataSource.filteredData.find(x => x.id == this.userAdmin.id)
      user = this.usersList.find(e => e.id == userAssist.adminParentID)
      return user
    }
    else return user
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  filterBtn() {
    this.data = []
    //if (this.seletedJurisdiction != -1) {
      this.getUserAssistantFilter(this.seletedJurisdiction, this.selectedJurisdictionLevel)
    // } else {
    //   this.allowUsersList.forEach(element => {
    //     this.getUserAssistantFilter(this.selectedJurisdictionLevel,element.id)
    //   });
    // }

  }

  commafy(num) {
    var str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }

  handleChange(event) {
    this.group = event.target.value
  }

  message(message) {
    this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  onClickEdit(id) {
    this.userEdit = null;
    this.editId = id;

    this.loginService.getUserAssistant(this.editId)
      .subscribe(data => {
        this.userEdit = data;

        // this.loginService.getAllPermitsByUser(data.adminParentID)
        // .subscribe(data => {
        //   this.permitsList = data;
        // }, error => {
        //   console.log(error);
        // });
        var juridictionid = this.getJurisdictionById(data.adminParentID).jurisdictionId;
        this.editAdminForm.controls["edit_parentId"].setValue(data.adminParentID);
        this.editAdminForm.controls["edit_jurisdiction"].setValue(this.getJurisdictionName(juridictionid).name);
        this.editAdminForm.controls["edit_username"].setValue(data.userName);
        this.editAdminForm.controls["edit_name"].setValue(data.name);
        this.editAdminForm.controls["edit_email"].setValue(data.email);
        this.editAdminForm.controls['edit_password'].setValue(data.password);
        this.editAdminForm.controls['edit_Access'].setValue(data.active == 1 ? true :false);
        this.editPermitChk1.toArray().forEach(chkItem => {
          if (chkItem.checked) {
            chkItem.toggle();
          }
        });
        this.editPermitChk1.toArray().forEach(chkItem => {
          if (this.userEdit.permits != null && this.userEdit.permits.length > 0) {
            this.userEdit.permits.forEach(permit => {
              if (('edit.' + permit.name) == chkItem.id && !chkItem.checked) {
                chkItem.toggle();
              }
            })
          }
        });
        this.editAdminView.show();
      }, error => {
        console.log(error);
      });
  }

 createNew() {
  if(this.seletedJurisdiction == undefined || this.seletedJurisdiction == -1)
   {
    this.notificationModal.show();

    }
    else
    {
      this.newAdminForm.controls["new_jurisdiction"].setValue(this.getJurisdictionById(parseInt(this.seletedJurisdiction)).login);
      // this.loginService.getAllPermitsByUser(parseInt(this.seletedJurisdiction))
      // .subscribe(data => {
      //   this.permitsList = data;
      // }, error => {
      //   console.log(error);
      // });

      this.newAdminView.show();
    }
  }

  onEditAdminSubmit(formData): void {
    if (this.editAdminForm.valid) {
    this.userEdit.permits = null;
    this.userEdit.name = formData.edit_name;
    this.userEdit.userName = formData.edit_username;
    this.userEdit.email = formData.edit_email;
    this.userEdit.adminParentID = formData.edit_parentId;
    this.userEdit.password = formData.edit_password;
    this.userEdit.active = formData.edit_Access ? 1 : 0;
    this.userEdit.type = 'Manager';
    this.editPermitChk1.toArray().forEach(element => {
      if (element.checked) {
        if (this.permitsList) {
          let permit: Permit;
          permit = this.permitsList.find(x => ('edit.' + x.name) == element.id);
          if (this.userEdit.permits == null) {
            this.userEdit.permits = new Array<Permit>();
          }
          this.userEdit.permits.push(permit);
        }
      }
    });
    this.loginService.updateUserAssistant(this.userEdit.id, this.userEdit)
      .subscribe(data => {
        this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });
        //this.refreshUserList();
        this.editAdminView.hide();
        this.filterBtn();
      }, error => {
        console.log(error);
        this.toastService.showNotification({ message: this.translateService.instant('failed'), type: 'danger' });
      });
    }
  }

  onNewAdminSubmit(formData): void {
    if (this.newAdminForm.valid) {
    let newUserPermits = new Array<Permit>();
    this.newPermitChk1.toArray().forEach(element => {
      if (element.checked) {
        if (this.permitsList) {
          let permit: Permit;
          permit = this.permitsList.find(x => ('new.' + x.name) == element.id);
          newUserPermits.push(permit);
        }
      }
    });
    this.newEdit.name = formData.new_name;
    this.newEdit.userName = formData.new_username;
    this.newEdit.email = formData.new_email;
    this.newEdit.adminParentID = this.seletedJurisdiction;
    this.newEdit.password = formData.new_password;
    this.newEdit.active = formData.new_Access ? 1 : 0;
    this.newEdit.type ='Manager';
    this.newEdit.permits =  newUserPermits;
    this.loginService.addUserAssistant( this.newEdit)
      .subscribe(data => {
        this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });

        this.newAdminView.hide();
        this.newAdminForm.controls["new_parentId"].setValue('');
        this.newAdminForm.controls["new_jurisdiction"].setValue('');
        this.newAdminForm.controls["new_username"].setValue('');
        this.newAdminForm.controls["new_name"].setValue('');
        this.newAdminForm.controls["new_email"].setValue('');
        this.newAdminForm.controls['new_password'].setValue('');
        this.newAdminForm.controls['new_Access'].setValue(0);
        this.filterBtn();
      }, error => {
        console.log(error);
        this.toastService.showNotification({ message: this.translateService.instant('failed'), type: 'danger' });
      });
    }
  }

  onClickRemove(id) {
    this.deleteId = id;
    this.deleteConfirm.show();
  }

  deleteAdmin() {
    this.loginService.removeUserAssistant(this.deleteId)
      .subscribe(data => {
        this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });

        this.deleteConfirm.hide();
        this.filterBtn();
      }, error => {
        console.log(error);
        this.toastService.showNotification({ message: this.translateService.instant('failed'), type: 'danger' });
      });
  }
}
