import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../../../services/login.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  userAdmin: any;
  settingForm: FormGroup;
  countries: any;
  savingSetting: boolean = false;
  loginService: LoginService;
  displayMinValue: boolean = false;
  jurisdictionCoupon: string = "";

  constructor(loginService: LoginService, private formBuilder: FormBuilder, private toastService: ToastService,
    private translateService: TranslateService, private cookieService: CookieService) {
    this.loginService = loginService;
    this.userAdmin = loginService.getUserInfoFromCookie();
    this.displayMinValue = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'streamingminimumvalue') == null ? false : true : true;
    this.settingForm = this.formBuilder.group({
      login: ['', Validators.required],
      username: ['', Validators.required],
      lastname: ['', Validators.required],
      city: ['', Validators.required],
      nation: ['', Validators.required],
      minimumValue: [0, Validators.required]
    }, {
    });
    this.settingForm.controls['login'].setValue(this.userAdmin.login);
    this.settingForm.controls['username'].setValue(this.userAdmin.name);
    this.settingForm.controls['lastname'].setValue(this.userAdmin.lastName);
    this.settingForm.controls['city'].setValue(this.userAdmin.city);
    this.settingForm.controls['minimumValue'].setValue(this.userAdmin.minimumValue);
    loginService.GetCountry()
      .subscribe(data => {
        this.countries = data;
        if (this.countries.length > 0) {
          this.settingForm.controls['nation'].setValue(this.userAdmin.country);
        }
      }, error => {
        console.log(error);
      });
  }

  ngOnInit(): void {
    this.getJurisdictionCoupon()
  }

  getJurisdictionCoupon() {
    this.loginService.getCoupon(this.userAdmin.id)
      .subscribe(data => {
        this.jurisdictionCoupon = data != null ? data.code : "";
      }, error => {
        console.log(error)
      })
  }

  onSettingSubmit(formValue) {
    this.userAdmin.login = formValue.login;
    this.userAdmin.name = formValue.username;
    this.userAdmin.lastName = formValue.lastname;
    this.userAdmin.city = formValue.city;
    this.userAdmin.country = formValue.nation;
    this.userAdmin.minimumValue = formValue.minimumValue;
    this.loginService.updateUser(this.userAdmin.id, this.userAdmin)
      .subscribe(data => {
        this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });
        localStorage.setItem('user', JSON.stringify(this.userAdmin));
        //document.cookie = "user=" + JSON.stringify(this.userAdmin);
        //this.cookieService.set('user', JSON.stringify(this.userAdmin)); //not working from chrome version 80
      }, error => {
        this.toastService.showNotification({ message: this.translateService.instant(error.error.msg), type: 'danger' });
      });
  }

  onClickChangePass() {
    this.loginService.openChangePass();
  }

}
