<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title row">
        <div class="col-md-9">
          <span class="material-icons default-icon">list</span>
          {{ "Logs List" | translate }}
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <mat-tab-group dynamicHeight (selectedTabChange)="tabChange($event)">
            <mat-tab label="{{ 'My Administrators' | translate }}">
              <div class="w-100">
                <div>
                  <div class="panel">
                    <div class="panel-body">
                      <div style="text-align: right;">
                        <mat-form-field>
                          <mat-label>{{ 'Search' | translate }}...</mat-label>
                          <input matInput (keyup)="applyFilter($event, 'dataSource')" #input>
                        </mat-form-field>
                      </div>
                      <div class="table-responsive" style="overflow: auto;">
                        <table class="sortable" mat-table [dataSource]="dataSource" matSort #sortUser="matSort" style="width:100%;">
                          <ng-container matColumnDef="LoginDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="loginDate" class="text-white"> {{'Date' | translate}} </th>
                            <td mat-cell *matCellDef="let element">{{element.loginDate | date:'dd/MM/yyyy HH:mm:ss'}} </td>
                            </ng-container>
                          <ng-container matColumnDef="UserName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="userName" class="text-white">
                              {{'UserName' | translate}} </th>
                            <td mat-cell *matCellDef="let element">{{element.userName}} </td>
                          </ng-container>
                          <ng-container matColumnDef="UserId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="userId" class="text-white">
                              {{'User Id' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
                          </ng-container>
                          <ng-container matColumnDef="IpAddress">
                            <th mat-header-cell *matHeaderCellDef class="text-white"> {{'IP Address' | translate}}
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.ipAddress | translate}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef class="text-white"> {{'Status' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{ element.status | translate}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsUserAssistant" class="bg-dark"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsUserAssistant;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'pg-round.rounds' | translate }}">
              <div class="w-100">
                <div class="row mt-3 roundCreate">
                  <!--Choose round style-->
                  <div class="col-md-6">
                    <div class="float-md-right">
                      <mat-form-field appearance="fill">
                        <mat-label class="font-weight-bold">
                          {{'Actions' | translate}}
                        </mat-label>
                        <mat-select #roundSelect>
                          <mat-option value="championship" (click)="setDynamicRoundTable('Canceled')">
                            {{'Canceled' | translate}}
                          </mat-option>
                          <mat-option value="Rank Round" (click)="setDynamicRoundTable('Created')">
                            {{'Created' | translate}}
                          </mat-option>
                          <mat-option value="Rank Round" (click)="setDynamicRoundTable('Modified')">
                            {{ 'Modified' | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div *ngIf="tableType == 0">

                </div>
                <div *ngIf="tableType != 0">
                  <div class="panel">
                    <div class="panel-body">
                      <div style="text-align: right;">
                        <mat-form-field>
                          <mat-label>{{ 'Search' | translate }}...</mat-label>
                          <input matInput (keyup)="applyFilter($event, 'roundDataSource')" #input>
                        </mat-form-field>
                      </div>
                      <div class="table-responsive" style="overflow: auto;">
                        <table class="sortable" mat-table [dataSource]="roundDataSource"  matSort #sortRound="matSort" style="width:100%;">
                          <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="date" class="text-white"> {{'Date' |
                              translate}} </th>
                            <td mat-cell *matCellDef="let element">{{element.date | date:'dd/MM/yyyy HH:mm:ss'}} </td>
                          </ng-container>
                          <ng-container matColumnDef="roundId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="roundId" class="text-white"> {{'Round
                              Id' | translate}} </th>
                            <td mat-cell *matCellDef="let element">{{element.roundId}} </td>
                          </ng-container>
                          <ng-container matColumnDef="roundName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="roundName" class="text-white">
                              {{'Round Name' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.roundName}} </td>
                          </ng-container>
                          <ng-container matColumnDef="userName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="userName" class="text-white">
                              {{'UserName' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
                          </ng-container>
                          <ng-container matColumnDef="actionType">
                            <th mat-header-cell *matHeaderCellDef class="text-white"> {{'Action Type' | translate}}
                            </th>
                            <td mat-cell *matCellDef="let element"> {{actionTypes[element.actionType] | translate}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="ipAddress">
                            <th mat-header-cell *matHeaderCellDef class="text-white"> {{'IP Address' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.userIpAddress | translate}} </td>
                          </ng-container>
v
                          <tr mat-header-row *matHeaderRowDef="displayedColumnsRound" class="bg-dark"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsRound;"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>