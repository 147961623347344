import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'angular-bootstrap-md';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription, timer } from 'rxjs';
import { ConfirmedValidator } from '../../helper/confirmed.validator';
import { Currency } from '../../interface/currency.interface';
import { DepositFunds } from '../../interface/deposit-funds.interface';
import { UserAssistantPasswordChange } from '../../interface/UserAssistantPasswordChange.interface';
import { InteractionService } from '../../services/interaction.service';
import { LoginService } from '../../services/login.service';
import { ToastService } from '../../services/toast.service';
import { PlayerService } from './../../services/player.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @ViewChild('passChangeModal') passChangeModal: ModalDirective;
  @ViewChild('notificationModal') notificationModal: ModalDirective;
  // @Input() item = 0;
  // @Input() item = 0;
  // @Input() item = 0;

  currentTime: string;
  updateTimer: Observable<number> = timer(0, 1000);
  subscription: Subscription;
  userAdmin: any;
  loginService: LoginService;
  notifications: number;
  pendingDocs: number;
  Currency: Currency = <Currency>{};
  pendingWithdrawls: number;
  width: number;
  displayBadge: boolean = false;
  DocumentListPermit: boolean = false;
  WithdrawalPermit: boolean = false;
  changePassForm: FormGroup;
  loading = false;
  passMinLength = 6;
  changePassSuccess = false;
  checkPassFailed = false;
  changePassFailed = false;

  jurisdictionCoupon: string = "";

  constructor(loginService: LoginService,
    private router: Router,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    private playerService: PlayerService,
    private interactionService: InteractionService,
    private toastService: ToastService,
    private translateService: TranslateService) {
    this.loginService = loginService;
    this.userAdmin = loginService.getUserInfoFromCookie();
    this.DocumentListPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'editplayerdocuments') == null ? false : true : true;
    this.WithdrawalPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'withdrawal') == null ? false : true : true;
    if (!this.DocumentListPermit && !this.WithdrawalPermit) this.displayBadge = false;
    else this.displayBadge = true;
    loginService.notifications.subscribe(notification => {
      this.notifications = notification;
    });

    loginService.pendingDocs.subscribe(doc => {
      this.pendingDocs = doc;
    });

    loginService.pendingWithdrawls.subscribe(withdrawal => {
      this.pendingWithdrawls = withdrawal;
    });
    // this.loginService.GetUserPendingWithdraw(this.userAdmin.id).subscribe((data: number) => {
    //   this.pendingWithdrawls = data;
    // });
    this.changePassForm = this.formBuilder.group({
      oldpass: ['', Validators.compose([Validators.required, Validators.minLength(this.passMinLength)])],
      newpass: ['', Validators.compose([Validators.required, Validators.minLength(this.passMinLength)])],
      repass: ['', Validators.compose([Validators.required, Validators.minLength(this.passMinLength)])],
    }, {
      validator: ConfirmedValidator('newpass', 'repass')
    })

    localStorage.setItem('currency', 'BRL');
    this.loginService.setGlobalCurrency();
  }

  redirect() {
    this.notificationModal.hide();
    this.router.navigate(['/documents']);
  }

  redirectToWithdrawal() {
    this.notificationModal.hide();
    this.router.navigate(['/withdrawals']);
  }

  ngOnInit(): void {
    this.loginService.changePass.subscribe(isOpen => {
      this.passChangeModal.show()
    });
    this.loginService.setGlobalCurrency();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    //console.log("notifications = " + this.notifications);
    this.subscription = this.updateTimer.subscribe(() => {
      const localTime = moment().format('DD/MM/YYYY HH:mm:ss');
      const utc = moment().utc().format('DD/MM/YYYY HH:mm:ss');
      this.currentTime = `${localTime} - (${utc} UTC)`;
    });
    this.updateNotificationCount();
    //   setTimeout(()=>{                           // <<<---using ()=> syntax
    //     this.getNotifications();
    // }, 3000);
    this.width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    var sidevar = document.getElementById("app-sidebar")!;
    if (this.width >= 768) {
      sidevar.setAttribute('style', 'display: block');
    } else {
      sidevar.setAttribute('style', 'display: none');
    }

    this.getJurisdictionCoupon();
  }

  getJurisdictionCoupon() {
    this.loginService.getCoupon(this.userAdmin.id)
      .subscribe(data => {
        this.jurisdictionCoupon = data != null ? data.code : "";
      }, error => {
        console.log(error)
      })
  }

  updateLocalStorageUser() {
    this.loginService.getUser(this.userAdmin.id).
      subscribe(user => {
        this.userAdmin = user;
        localStorage.setItem('user', JSON.stringify(user));
      });
  }

  showWarning(): void {

  }

  updateNotificationCount(): void {
    // debugger;
    this.loginService.setNotificationCount(this.userAdmin.id, this.userAdmin.login);
    this.loginService.setPendingDocCount(this.userAdmin.id, this.userAdmin.login);
    this.loginService.setPendingWithdrawal(this.userAdmin.id);

    // this.loginService.GetPendingUserDocument(this.userAdmin.id).subscribe((data: number) => {
    //   this.pendingDocs = data;
    // });

    // this.loginService.GetPendingUserWithdrawals(this.userAdmin.id).subscribe((data: number) => {
    //   this.pendingWithdrawls = data;
    // });
  }

  // getNotifications(): void{
  //   debugger;
  //   this.notifications = this.loginService.notifications;
  //   this.pendingDocs = this.loginService.pendingDocs;
  //   this.pendingWithdrawls = this.loginService.pendingWithdrawls;
  // }

  userLogout(): void {
    this.loginService.logout();
    location.reload();
  }

  public navbarToggle() {
    var sidevar = document.getElementById("app-sidebar")!;
    if (sidevar.style.display == "none") {
      sidevar.setAttribute('style', 'display: block');
    } else {
      sidevar.setAttribute('style', 'display: none');
    }
  }

  openChangePasswordDlg() {
    this.passChangeModal.show()
  }

  clossePassCahnge() {
    setTimeout(() => {
      this.changePassSuccess = false;
      this.changePassFailed = false;
      this.checkPassFailed = false;
      this.changePassForm.reset();
      this.passChangeModal.hide();
    }, 3000);
  }

  onSubmit(formData): void {
    if (this.changePassForm.valid) {
      this.loading = true;
      if (this.userAdmin) {
        const passwordChangeObj: UserAssistantPasswordChange = {
          Id: this.userAdmin.id,
          OldPassword: this.changePassForm.get("oldpass")!.value,
          NewPassword: this.changePassForm.get("newpass")!.value,
        }
        this.loginService.updateUserAssistantPassword(passwordChangeObj).subscribe(res => {
          this.loading = false;
          this.changePassSuccess = true;
          this.changePassFailed = false;
          this.checkPassFailed = false;
          this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });
          this.clossePassCahnge()
        }, error => {
          if(error.status == 400 && error.error == "Invalid Id.") {
            this.toastService.showNotification({ message: this.translateService.instant('failed'), type: 'danger' });
            setTimeout(() => {
              location.reload();
            }, 1000);
            return;
          }
          if(error.status == 500) {
            this.toastService.showNotification({ message: this.translateService.instant('failed'), type: 'danger' });
          } else {
            this.toastService.showNotification({ message: this.translateService.instant(error.error), type: 'danger' });
          }
          this.loading = false;
          this.changePassSuccess = false;
          this.changePassFailed = true;
          this.checkPassFailed = false;
          
        });
      }
    }
  }

  resetFunds() {
    var depositfunds: DepositFunds = {} as DepositFunds;
    depositfunds.playerId = this.userAdmin.id;
    depositfunds.amount = this.userAdmin.credit;
    depositfunds.balance = 0;
    depositfunds.userAdminId = this.userAdmin.id;
    depositfunds.transactionType = "ResetCredit";
    depositfunds.assUsername = this.userAdmin.login;
    this.playerService.depositFunds(depositfunds)
      .subscribe((data) => {
        //console.log(data);
      }, error => {
        console.log(error)
      });
  }

  showCurrencyDropDown(){
    document.getElementById("currencyDropdown")!.style.display= 'block';
  }

  setCurrency(currency: string){
    localStorage.setItem('currency', currency);
    this.loginService.setGlobalCurrency();
  }
}
