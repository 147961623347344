import { Bet } from './../interface/bet.interface';
import { HttpClient , HttpHeaders, HttpParams} from '@angular/common/http';
import { Round } from '../interface/round.interface';
import { Injectable } from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { InteractionService } from './interaction.service';
import { IpConfigService } from './Ip-Config.service';
import { User } from '../interface/user.interface';
import { Game } from '../interface/game.interface';
import { Currency } from '../interface/currency.interface';
import { formatCurrency } from '@angular/common';
import { LoginService } from './login.service';
import { CouponFilter } from '../interface/transactions-filter.interface';
import { coin } from 'src/app/services/coin.interface';

@Injectable({
  providedIn: 'root'
})
export class CouponServiceService {

  
  apiUrl = ''; // local test
  Currency: Currency = <Currency>{};

  constructor(private http: HttpClient,private loginService:LoginService, private interactionService: InteractionService, private ipConfigService: IpConfigService
    ,@Inject(LOCALE_ID) public locale: string
    ) {
    this.apiUrl = ipConfigService.getApiUrl();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
        else {
          this.setGlobalCurrency()
        }
      });
  }

  setGlobalCurrency() {
    this.loginService.GetCountryCurrency(localStorage.getItem('currentUserCountry'))
      .subscribe(data => {

        var DataURL: string =  undefined;
        if(data.currencyId == "BRL"){
          DataURL = DataURL = 'https://economia.awesomeapi.com.br/json/USD-BRL';
        }else DataURL = 'https://economia.awesomeapi.com.br/json/BRL-' + data.currencyId;

        //this.http.get('https://free.currconv.com/api/v7/convert?q=BRL_' + data.currencyId + '&apiKey=085a5c4bbbd6e6750bfc')
        this.http.get(DataURL)
          .subscribe((obj: any) => {
            var Eco = JSON.stringify(obj)
            Eco = Eco.replace("[", "").replace("]", "")
            let response: coin = JSON.parse(Eco)
            if (response && data.currencyId != 'BRL') {
              //var price = (response.data.results['BRL_' + data.currencyId].val);
              var price = parseFloat (response.ask);
              this.Currency.quote = price;
              this.Currency.coin = 'BRL_' + data.currencyId;
              this.Currency.currencySymbol = data.currencySymbol;
              this.Currency.currency = data.currencyId;
              this.interactionService.setGlobalCountry(this.Currency);
              return;
            } else {
              this.Currency.coin = 'BRL_BRL';
              this.Currency.quote = 1;
              this.Currency.currencySymbol = 'R$';
              this.Currency.country = 'Brazil';
              this.Currency.currency = 'BRL';
              this.interactionService.setGlobalCountry(this.Currency);
            }
          }, error => {
            this.Currency.coin = 'BRL_BRL';
            this.Currency.quote = 1;
            this.Currency.currencySymbol = 'R$';
            this.Currency.country = 'Brazil';
            this.Currency.currency = 'BRL';
          });
      });
    this.Currency.coin = 'BRL_BRL';
    this.Currency.quote = 1;
    this.Currency.currencySymbol = 'R$';
    this.Currency.country = 'Brazil';
    this.Currency.currency = 'BRL';
    this.interactionService.setGlobalCountry(this.Currency);
  }
  // getBetsByUserTree(userAdminId: number) {
  //   const header = new HttpHeaders({
  //     'Content-type': 'application/json',
  //     'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
  //   });
//new comment added.
  //   return this.http.get<Bet[]>(`${this.apiUrl}/common/GetBetsByUserIDTree/?userAdminId=` + userAdminId, { headers: header });
  // }

  getBetsByUserTree(userAdminId: number, roundId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<Bet[]>(`${this.apiUrl}/common/GetBetsByUserTree/?userAdminId=` + userAdminId + "&roundId=" + roundId, { headers: header });
  }

  getLastRound() {
    return this.http.get<Round>(`${this.apiUrl}/common/getlastround`);
  }

  getRound(roundId: number) {
    return this.http.get<Round>(`${this.apiUrl}/rounds/` + roundId);
  }

  listRoundWinners(roundId: number) {
    return this.http.get<any[]>(`${this.apiUrl}/common/GetWinnersByRound/?roundId=` + roundId);
  }

  getRounds() {
    return this.http.get<Round[]>(`${this.apiUrl}/rounds/`);
  }

  getRoundsByFilter(filter: CouponFilter) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<Round[]>(`${this.apiUrl}/Rounds/GetRoundsByFilter/`, filter, { headers: header });
	}

  GetBetsByFilter(filter: CouponFilter) {
		return this.http.post<Round[]>(`${this.apiUrl}/Rounds/GetBetsByFilter/`, filter);
  }

  listCommonUsers() {
    return this.http.get<User[]>(`${this.apiUrl}/common/CommonGetUsers/`)
  }

  getBet(betId: string) {
    return this.http.get<Bet>(`${this.apiUrl}/bets/` + betId);
  }

  getRoundById(id: number) {
    return this.http.get<Round>(`${this.apiUrl}/common/GetRoundById?id=` + id);
  }

  getBasicUser(userId: number) {
    return this.http.get<User>(`${this.apiUrl}/common/GetBasicUser/?userId=` + userId);
  }

  getGames(roundId: number) {
    return this.http.get<Game[]>(`${this.apiUrl}/common/GetMatchesByRound/?roundId=` + roundId);
  }

  formatCurrencyValue(value: number) {
    return formatCurrency(parseFloat((value * this.Currency.quote).toFixed(2)), this.locale, this.Currency.currencySymbol, this.Currency.currency);
  }

  updateBet(bet: Bet) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    let jsonToPass = {
      "id": bet.id,
      "betDate": bet.betDate,
      "resultBetId": bet.resultBetId,
      "playerId": bet.playerId,
      "playerName": bet.playerName,
      "roundId": bet.roundId,
      "userAdminId": bet.userAdminId,
      "results": bet.results,
      "value": bet.value
    }

    return this.http.put<Bet>(`${this.apiUrl}/bets/` + bet.id, bet, { headers: header });
  }
}
