<div id="content">
    <div class="row">
        <div class="col-md-6">
            <div class="panel">
                <div class="panel-heading">
                    <div class="panel-title">
                        <span class="material-icons default-icon">person</span>
                        {{'Edit player' | translate}}
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="editPlayerForm" (submit)="editPlayer(editPlayerForm.value)">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="login"> {{'Club Father' | translate}} *</label>
                            </div>
                            <div class="col-md-7">
                                <select class="form-control " id="clubParents" formControlName="clubParents" *ngIf="PlayerEditPermit">
                                    <option [value]="''">{{ 'Select' | translate }}</option>
                                    <option *ngFor="let item of clubParents" [ngValue]="item.id">{{item.name
                                        }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-7 mt-3">
                                <div *ngIf="editPlayerForm.get('clubParents').invalid && (editPlayerForm.get('clubParents').touched || editPlayerForm.get('clubParents').dirty)"
                                    class="text-danger error-msg">
                                    <span><b>{{'Club Father' | translate}}</b> {{'isRequired' | translate}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="login"> {{'Login' | translate}} *</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="login" formControlName="login" placeholder="Login"
                                    class="form-control">
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-7 mt-3">
                                <div *ngIf="editPlayerForm.get('login').invalid && (editPlayerForm.get('login').touched || editPlayerForm.get('login').dirty)"
                                    class="text-danger error-msg">
                                    <span><b>Login</b> is required</span>
                                </div>
                            </div>
                        </div> -->

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="userName"> {{'UserName' | translate}} *</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="userName" formControlName="userName" placeholder="Username"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-7 mt-3">
                                <div *ngIf="editPlayerForm.get('userName').invalid && (editPlayerForm.get('userName').touched || editPlayerForm.get('userName').dirty)"
                                    class="text-danger error-msg">
                                    <span><b>{{'username' | translate}}</b> {{'isRequired' | translate}}</span>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="name"> {{'Name' | translate}} *</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="name" formControlName="name" placeholder="Name"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-7 mt-3">
                                <div *ngIf="editPlayerForm.get('name').invalid && (editPlayerForm.get('name').touched || editPlayerForm.get('name').dirty)"
                                    class="text-danger error-msg">
                                    <span><b>{{'Name' | translate}}</b></span>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="lastname"> {{'Last Name' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="lastname" formControlName="lastname" placeholder="Last name"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="password"> {{'Password' | translate}} *</label>
                            </div>
                            <div class="col-md-7">
                                <ng-container *ngIf="PassWordChangePermit; else PassWordChangeBlocked">
                                    <input type="password" id="password" formControlName="password" placeholder="Password"
                                    class="form-control" *ngIf="PlayerEditPermit">
                                  </ng-container>
                                  <ng-template #PassWordChangeBlocked>
                                    <input type="password" id="password" formControlName="password" placeholder="Password"
                                    class="form-control" *ngIf="PlayerEditPermit" disabled>
                                  </ng-template>
                            </div>
                            <div class="col-md-4"></div>
                            <div *ngIf="editPlayerForm.get('password').invalid && (editPlayerForm.get('password').dirty || editPlayerForm.get('password').touched)"
                                class="text-danger error-msg col-md-7 mt-3">
                                <div *ngIf="editPlayerForm.get('password').errors?.required">
                                    <span><b>{{'Password' | translate}}</b> {{'isRequired' | translate}}</span>
                                </div>
                                <div *ngIf="editPlayerForm.get('password').errors?.minlength">
                                    {{'Password Should be 8 Character Length.' | translate}}
                                </div>

                            </div>

                        </div>
                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="confirmPassword"> {{'Confirm Password' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <ng-container *ngIf="PassWordChangePermit; else PassWordConfirmChangeBlocked">
                                    <input type="password" id="confirmPassword" formControlName="confirmPassword"
                                    placeholder="Confirm Password" class="form-control" *ngIf="PlayerEditPermit">
                                  </ng-container>
                                  <ng-template #PassWordConfirmChangeBlocked>
                                    <input type="password" id="confirmPassword" formControlName="confirmPassword"
                                    placeholder="Confirm Password" class="form-control" *ngIf="PlayerEditPermit" disabled>
                                  </ng-template>

                            </div>

                            <div class="col-md-4"></div>
                            <div *ngIf="editPlayerForm.get('confirmPassword').invalid && (editPlayerForm.get('confirmPassword').dirty || editPlayerForm.get('confirmPassword').touched)"
                                class="text-danger error-msg col-md-7 mt-2">
                                <div *ngIf="editPlayerForm.get('confirmPassword').errors?.required">
                                    <span><b>{{'Confirm Password' | translate}}</b> {{'isRequired'} | translate}</span>
                                </div>
                                <div *ngIf="editPlayerForm.get('confirmPassword').errors?.minlength">
                                    {{'Password Should be 8 Character Length.' | translate}}
                                </div>
                                <div *ngIf="editPlayerForm.get('confirmPassword').errors?.confirmedValidator">
                                    {{'PasswordMisMatch' | translate}}
                                </div>

                            </div>

                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="email"> {{'Email' | translate}} *</label>
                            </div>
                            <div class="col-md-7">
                                <input type="email" id="email" formControlName="email" placeholder="Email"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-7 mt-3">
                                <div *ngIf="editPlayerForm.get('email').invalid && (editPlayerForm.get('email').touched || editPlayerForm.get('email').dirty)"
                                    class="text-danger error-msg">
                                    <span><b>{{'Email' | translate}}</b> {{'isRequired' | translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="email2"> {{'Email 2' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="email" id="email2" formControlName="email2" placeholder="Email 2"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="email3"> {{'Email 3' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="email" id="email3" formControlName="email3" placeholder="Email 3"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="GenderCP"> {{'Gender' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="radio" formControlName="gender" id="GenderMaleCP" value="Male"
                                    name="gender" *ngIf="PlayerEditPermit"> Male
                                <input type="radio" formControlName="gender" id="GenderFemaleCP" value="FeMale"
                                    name="gender" *ngIf="PlayerEditPermit"> Fe-male
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="birthDate"> {{'Birthday' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <mat-form-field appearance="fill">
                                    <input matInput [matDatepicker]="picker1" formControlName="birthDate" *ngIf="PlayerEditPermit">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <!-- <input type="date" id="birthDate" formControlName="birthDate" placeholder="Birthday"
                                    class="form-control"> -->
                            </div>
                        </div>


                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="address"> {{'Address' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="address" formControlName="address" placeholder="Address"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="country"> {{'Country' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <select class="form-control " id="country" formControlName="country" *ngIf="PlayerEditPermit">
                                    <option [value]="''" disabled>{{ 'Select' | translate}}</option>
                                    <option *ngFor="let country of countries" [value]="country.name">{{country.name |
                                        translate }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="city"> {{'City' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="city" formControlName="city" placeholder="City"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="zipCode"> {{'Zip-code' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="zipCode" formControlName="zipCode" placeholder="Zip code"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="phone"> {{'Phone' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="phone" formControlName="phone" placeholder="Phone"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="phone2"> {{'Phone 2' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="phone2" formControlName="phone2" placeholder="phone 2"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="cellPhone"> {{'Cell Phone' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="cellPhone" formControlName="cellPhone" placeholder="Phone"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                        </div>


                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="whatsApp"> {{'Whatsapp' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" id="whatsApp" formControlName="whatsApp" placeholder="Whatsapp"
                                    class="form-control" *ngIf="PlayerEditPermit">
                            </div>
                            <div class="col-md-4"></div>
                            <!-- <div class="col-md-7 mt-3">
                                <div *ngIf="editPlayerForm.get('whatsApp').invalid && (editPlayerForm.get('whatsApp').touched || editPlayerForm.get('whatsApp').dirty)"
                                    class="text-danger error-msg">
                                    <span><b>{{'WhatsApp' | translate}}</b> {{'isRequired' | translate}}</span>
                                </div>
                            </div> -->
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="notes"> {{'Notes' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <textarea id="notes" formControlName="notes" placeholder="Notes" class="form-control"
                                    rows="3" *ngIf="PlayerEditPermit"></textarea>

                                <!-- <input type="text" id="notes" formControlName="notes" placeholder="Notes"
                                    class="form-control"> -->
                            </div>
                        </div>
                        <div class="row mt-3 mb-3" *ngIf="BlockPlayerPermit">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">
                                <label for="notes"> {{'Access' | translate}}</label>
                            </div>
                            <div class="col-md-7">
                                <input type="checkbox" id="access" formControlName="access" placeholder="access"
                                    class="checkbox">
                            </div>
                        </div>
                        <div class="row">
                            <button *ngIf="PlayerEditPermit" type="submit" mdbBtn color="primary" mdbWavesEffect class="btn btn-primary"
                                data-bs-dismiss="Save">{{'savebtn' |
                                translate}}
                                <span class="spinner-border spinner-border-sm float-right text-white ml-2"
                                    *ngIf="savingPlayer"></span>
                            </button>

                            <button routerLink="/players/list" mdbBtn color="danger" class="relative waves-light btn-sm"
                                mdbWavesEffect>{{'closebtn' | translate}}
                                <span class="spinner-border spinner-border-sm float-right text-white ml-2"
                                    *ngIf="savingPlayer"></span>
                            </button>


                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="panel">
                <div class="panel-heading">
                    <div class="panel-title">
                        <span class="material-icons default-icon">euro</span>
                        {{'Account Activity Summary' | translate}}
                    </div>
                </div>
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-5">
                            {{'Account Access' | translate}}
                        </div>
                        <div class="col-md-7">
                            {{accountSummary?.accountAccess}}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            {{'IP Address' | translate}}
                        </div>
                        <div class="col-md-7">
                            {{accountSummary?.ip}}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            {{'Registration Date Time' | translate}}
                        </div>
                        <div class="col-md-7">
                            {{accountSummary?.registrationDateTime}}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            {{'Last Access' | translate}}
                        </div>
                        <div class="col-md-7">
                            {{accountSummary?.lastAccess}}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            {{'Number of Logins' | translate}}
                        </div>
                        <div class="col-md-7">
                            {{accountSummary?.nrLogin}}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            {{'Account Balance' | translate}}
                        </div>
                        <div class="col-md-7">
                            {{Currency.currencySymbol}} {{accountSummary?.accountBalance * Currency.quote |
                            currency:'':'' }}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            {{'Total Deposits' | translate}}
                        </div>
                        <div class="col-md-7">
                            {{Currency.currencySymbol}} {{accountSummary?.totalDeposits * Currency.quote |
                            currency:'':'' }}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            {{'Total Withdrawals' | translate}}
                        </div>
                        <div class="col-md-7">
                            {{Currency.currencySymbol}} {{accountSummary?.totalWithdrawals * Currency.quote |
                            currency:'':'' }}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            {{'Last 3 Access' | translate}}
                        </div>
                        <div class="col-md-7">
                            <span class="mb-2" *ngFor="let item of accountSummary?.last3Access">{{item}} <br></span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5">
                            {{'IP Access' | translate}}
                        </div>
                        <div class="col-md-7">
                            <span class="mb-2" *ngFor="let IPitem of accountSummary?.last3IP">{{IPitem}} <br></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel">
                <div class="panel-heading">
                    <div class="panel-title">
                        <span class="material-icons default-icon">description</span>
                        {{'Document List' | translate}}
                    </div>
                </div>
                <div class="panel-body">
                    <div class="row" *ngIf="IDCardFront != ''">
                        <div class="col-md-8">

                            <div class="row">
                                <div class="col-md-12 d-flex flex-column">
                                    <label for="LoginCP"> {{'ID card(front)' | translate}}</label>
                                    <img [src]="IDCardFront" alt="" height="190" width="300" (click)="showImage(IDCardFront); imgModal.show()">
                                </div>
                            </div>


                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Notes' | translate}}</label>
                                    <textarea [(ngModel)]="IDCardFrontNotes" class="form-control" rows="5" *ngIf="PlayerEditPermit"></textarea>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Status' | translate}}</label>
                                    <select [(ngModel)]="IDCardFrontStatus" class="form-control" *ngIf="PlayerEditPermit">
                                        <option [ngValue]="-1">Please select</option>
                                        <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                                        <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                                        <option [ngValue]="2" style="background-color: red;">Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-2" >
                        <div class="col-md-12" *ngIf="PlayerEditPermit">
                            <button type="button" class="btn btn-sm btn-primary" (click)="fileCNICF.click()">
                                <i class="fa fa-file"> {{'Choose ID Card (Front)' | translate}}</i>
                            </button>
                            <button type="button" class="btn btn-sm btn-danger" (click)="deleteDoc(1)"
                                *ngIf="IDCardFront != ''">
                                <i class="fa fa-trash"> {{'Delete' | translate}}</i>
                            </button>
                            <button type="button" class="btn btn-sm btn-success" (click)="updateDoc(1)"
                                *ngIf="IDCardFront != ''">
                                {{'savebtn' | translate}}
                            </button>
                            <input type="file" #fileCNICF placeholder="Choose file"
                                (change)="uploadFile(fileCNICF.files,'cnicF')" style="display:none;"
                                class="form-control">
                        </div>
                    </div>

                    <div class="row mt-3" *ngIf="IDCardBack != ''">
                        <div class="col-md-8">

                            <div class="row">
                                <div class="col-md-12 d-flex flex-column">
                                    <label for="LoginCP"> {{'ID card(Back)' | translate}}</label>
                                    <img [src]="IDCardBack" alt="" height="190" width="300" (click)="showImage(IDCardBack); imgModal.show()">
                                </div>
                            </div>


                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Notes' | translate}}</label>
                                    <textarea [(ngModel)]="IDCardBackNotes" class="form-control" rows="5" *ngIf="PlayerEditPermit"></textarea>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Status' | translate}}</label>
                                    <select [(ngModel)]="IDCardBackStatus" class="form-control" *ngIf="PlayerEditPermit">
                                        <option [ngValue]="-1">Please select</option>
                                        <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                                        <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                                        <option [ngValue]="2" style="background-color: red;">Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12" *ngIf="PlayerDocumenteEditPermit">
                            <button type="button" class="btn btn-sm btn-primary" (click)="fileCNICB.click()">
                                <i class="fa fa-file"> {{'Choose ID Card (Back)' | translate}}</i>
                            </button>
                            <button class="btn btn-sm btn-danger" (click)="deleteDoc(5)" *ngIf="IDCardBack != ''">
                                <i class="fa fa-trash"> {{'Delete' | translate}}</i>
                            </button>
                            <button class="btn btn-sm btn-success" (click)="updateDoc(5)" *ngIf="IDCardBack != ''">
                                {{'savebtn' | translate}}
                            </button>
                            <input type="file" #fileCNICB placeholder="Choose file"
                                (change)="uploadFile(fileCNICB.files,'cnicB')" style="display:none;"
                                class="form-control">
                        </div>
                    </div>

                    <div class="row mt-3" *ngIf="ProofOfAddress != ''">
                        <div class="col-md-8">

                            <div class="row">
                                <div class="col-md-12 d-flex flex-column">
                                    <label for="LoginCP"> {{'Proof Of Address' | translate}}</label>
                                    <img [src]="ProofOfAddress" alt="" height="190" width="300" (click)="showImage(ProofOfAddress); imgModal.show()">
                                </div>
                            </div>


                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Notes' | translate}}</label>
                                    <textarea [(ngModel)]="ProofOfAddressNotes" class="form-control"
                                        rows="5" *ngIf="PlayerEditPermit"></textarea>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Status' | translate}}</label>
                                    <select [(ngModel)]="ProofOfAddressStatus" class="form-control" *ngIf="PlayerEditPermit">
                                        <option [ngValue]="-1">Please select</option>
                                        <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                                        <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                                        <option [ngValue]="2" style="background-color: red;">Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12" *ngIf="PlayerDocumenteEditPermit">
                            <button type="button" class="btn btn-sm btn-primary" (click)="fileTaxCode.click()">
                                <i class="fa fa-file"> {{'Choose Proof Of Address' | translate}}</i>
                            </button>
                            <button class="btn btn-sm btn-danger" (click)="deleteDoc(2)" *ngIf="ProofOfAddress != ''">
                                <i class="fa fa-trash"> {{'Delete' | translate}}</i>
                            </button>
                            <button class="btn btn-sm btn-success" (click)="updateDoc(2)" *ngIf="ProofOfAddress != ''">
                                {{'savebtn' | translate}}
                            </button>
                            <input type="file" #fileTaxCode placeholder="Choose file"
                                (change)="uploadFile(fileTaxCode.files,'Taxcode')" style="display:none;"
                                class="form-control">
                        </div>
                    </div>

                    <!--
                    <div class="row mt-3" *ngIf="SelfieWithIdentity != ''">
                        <div class="col-md-8">

                            <div class="row">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Selfie With Identity' | translate}}</label>
                                    <img [src]="SelfieWithIdentity" alt="" height="190" width="300">
                                </div>
                            </div>


                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Notes' | translate}}</label>
                                    <textarea [(ngModel)]="SelfieWithIdentityNotes" class="form-control"
                                        rows="5"></textarea>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Status' | translate}}</label>
                                    <select [(ngModel)]="SelfieWithIdentityStatus" class="form-control">
                                        <option [ngValue]="-1">Please select</option>
                                        <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                                        <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                                        <option [ngValue]="2" style="background-color: red;">Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <button type="button" class="btn btn-sm btn-primary" (click)="filePhoto.click()">
                                <i class="fa fa-file"> {{'Choose Selfie With Identity' | translate}}</i>
                            </button>
                            <button class="btn btn-sm btn-danger" (click)="deleteDoc(3)"
                                *ngIf="SelfieWithIdentity != ''">
                                <i class="fa fa-trash"> {{'Delete' | translate}}</i>
                            </button>
                            <button class="btn btn-sm btn-success" (click)="updateDoc(3)"
                                *ngIf="SelfieWithIdentity != ''">
                                {{'savebtn' | translate}}
                            </button>
                            <input type="file" #filePhoto placeholder="Choose file"
                                (change)="uploadFile(filePhoto.files,'Photo')" style="display:none;"
                                class="form-control">
                        </div>
                    </div>

                    <div class="row mt-3" *ngIf="SelfieWithPaper != ''">
                        <div class="col-md-8">

                            <div class="row">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Selfie With Paper' | translate}}</label>
                                    <img [src]="SelfieWithPaper" alt="" height="190" width="300">
                                </div>
                            </div>


                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Notes' | translate}}</label>
                                    <textarea [(ngModel)]="SelfieWithPaperNotes" class="form-control"
                                        rows="5"></textarea>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <label for="LoginCP"> {{'Status' | translate}}</label>
                                    <select [(ngModel)]="SelfieWithPaperStatus" class="form-control">
                                        <option [ngValue]="-1">Please select</option>
                                        <option [ngValue]="0" style="background-color: lightgrey;">Waiting</option>
                                        <option [ngValue]="1" style="background-color: lightgreen;">Accepted</option>
                                        <option [ngValue]="2" style="background-color: red;">Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <button type="button" class="btn btn-sm btn-primary" (click)="fileSelfieWithPaper.click()">
                                <i class="fa fa-file"> {{'Choose Selfie With Paper' | translate}}</i>
                            </button>
                            <button class="btn btn-sm btn-danger" (click)="deleteDoc(4)" *ngIf="SelfieWithPaper != ''">
                                <i class="fa fa-trash"> {{'Delete' | translate}}</i>
                            </button>
                            <button class="btn btn-sm btn-success" (click)="updateDoc(4)" *ngIf="SelfieWithPaper != ''">
                                {{'savebtn' | translate}}
                            </button>
                            <input type="file" #fileSelfieWithPaper placeholder="Choose file"
                                (change)="uploadFile(fileSelfieWithPaper.files,'SelfieWithPaper')" style="display:none;"
                                class="form-control">
                        </div>
                    </div>-->

                    <div class="row mt-3" *ngIf="PlayerDocumenteEditPermit">
                        <div class="col-md-10">
                            <span class="upload" *ngIf="progress > 0">
                                {{'Progress' | translate}}
                            </span>
                            <span class="upload" *ngIf="progress > 0">
                                {{progress}}%
                            </span>
                            <span class="upload" *ngIf="message">
                                {{message}}
                            </span>
                        </div>
                    </div>
                </div>
                <!--  <div class="panel-body">
                 <div class="table-responsive" style="overflow: auto;">
                        <table class="table" style="width:100%;">
                            <thead>
                                <tr>

                                    <th>File Type</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Created Date</th>
                                    <th>Last Updated</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of playerDocs"
                                    [style.background]="getTableBackground(item.status)">
                                    <td>{{item.typeDesc}}</td>
                                    <td>{{item.description}}</td>
                                    <td>{{item.statusDesc}}</td>
                                    <td>{{item.createdDate}}</td>
                                    <td>{{item.responseDate}}</td>
                                    <td>
                                        <button data-toggle="modal" (click)="getDocDetail(item.id)"
                                            class="btn btn-sm btn-success px-2" data-target="#editModel">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>-->
            </div>


        </div>
    </div>
    <div id="myModal" mdbModal #validationModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="myBasicModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">

            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="validationModal.hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title w-100" id="myModalLabel">{{ 'Attention' | translate }}</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <p>{{'Please fix the following errors:' | translate}}</p>
                            <ul>
                                <li *ngFor="let error of errors">{{ error | translate }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
                        (click)="validationModal.hide()" mdbWavesEffect>{{ 'OK' | translate }}</button>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- <div class="modal fade" id="editModel" tabindex="-1" aria-labelledby="exampleModalLabel8" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content" style="width: 150%; position: absolute; top: 50%; left: -23%;">
            <div class="modal-header">
                <h1 class="modal-title text-danger" id="exampleModalLabel5">{{'Identity Documents'}}
                </h1>
                <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" style="overflow: overlay !important; height: 325px !important;">
                <div class="row">
                    <div class="col-md-12">
                        {{'Identity Card'}}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-7">

                        <div class="row">
                            <div class="col-md-12">
                                <img [src]="" alt="" height="150" width="400">
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <button class="btn btn-sm btn-primary">
                                    <i class="fa fa-file"> Choose</i>
                                </button>
                                <button class="btn btn-sm btn-danger">
                                    <i class="fa fa-trash"> Delete</i>
                                </button>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <button class="btn btn-sm btn-success">
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-2">
                                {{'Notes'}}
                            </div>
                            <div class="col-md-10">
                                <textarea class="form-control" rows="5">
                                </textarea>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-2">
                                {{'Status'}}
                            </div>
                            <div class="col-md-10">
                                <select class="form-control">
                                    <option [value]="-1">Please select</option>
                                    <option [value]="0">Waiting</option>
                                    <option [value]="1">Accepted</option>
                                    <option [value]="2">Rejected</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div mdbModal #imgModal="mdbModal" class="modal fade" id="frameModaltop" tabeindex="-1" role="dialog"
  aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
    <div class="modal-dialog modal-dialog-centred modal-primary">
      <div class="modal-content">
        <button type="button" class="close btn-imgModal" data-dismiss="modal" aria-label="Close" (click)="imgModal.hide()">
          <span aria-hidden="true" class="red-text">&times;</span>
        </button>
        <img [src]="imgPath">
      </div>
    </div>
</div>
