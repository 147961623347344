import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Withdraw } from './../../interface/withdraw.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective, NavbarComponent } from 'angular-bootstrap-md';
import { ToastService } from 'src/app/services/toast.service';
import { WithdrawalService } from 'src/app/services/withdrawal.service';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { Currency } from 'src/app/interface/currency.interface';
import { InteractionService } from 'src/app/services/interaction.service';
import { LoginService } from 'src/app/services/login.service';


@Component({
  selector: 'app-withdrawals',
  templateUrl: './withdrawals.component.html',
  styleUrls: ['./withdrawals.component.css']
})
export class WithdrawalsComponent implements OnInit {
  @ViewChild('confirmationModel') confirmationModel: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['id', 'userName', 'createdDateStr', 'responseDateStr', 'amount', 'statusStr', 'description', 'actions'];
  dataSource = new MatTableDataSource<any>([]);
  defaultStatus: number = 0;
  request: Withdraw;
  requestId: number;
  userAdmin: any;
  requestStatus: number;
  requestDescription: string;
  requestUserName: string;
  requestUserId: number;
  requestUpdateTime: string;
  requestAmount: number;
  requestBank: string;
  requestAgency: string;
  requestAccountNumber: string;
  requestAccountType: string;
  accountTypes: string[] = ["", "Conta Poupança", "Conta Corrente", "Email", "Telefone", "CPF", "Chave PIX"];
  Currency: Currency = <Currency>{};
  UserIsLoggin : any = []

  constructor(private translateService: TranslateService,
    private cookieService: CookieService,
    private toastr: ToastService,
    private withdrawService: WithdrawalService,
    private route: Router,
    private router: ActivatedRoute,
    private loginService: LoginService,
    private interactionService: InteractionService) {
    this.userAdmin = loginService.getUserInfoFromCookie();
  }

  ngOnInit(): void {
    this.BindWithdrawal(this.defaultStatus);
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
        else {
          this.loginService.setGlobalCurrency();
        }
      });
      this.loginService.GetUsersList(this.userAdmin.id).subscribe(res => {
        let temUser = res.find(element => element.id == this.userAdmin.id && element.login == this.userAdmin.login)
        if (temUser == null) {
          this.loginService.GetUserAssist(this.userAdmin.id).subscribe(x => {
            temUser = res.find(element => element.id == x.adminParentID && x.userName == this.userAdmin.login)
            this.UserIsLoggin.push(temUser)
          })
        } else {
          this.UserIsLoggin.push(temUser)
        }
      })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  BindWithdrawal(status: number) {
    this.withdrawService.getWithdrawals(status, this.userAdmin.id,this.userAdmin.login).subscribe((data: Withdraw[]) => {
      //this.dataSource.data = data
      //this.dataSource.data = data.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1)
      this.dataSource.data = data.filter(x => x.status == 0).sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1);
      //.filter(x => x.status != 1).sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1)
      this.dataSource.paginator = this.paginator;

    }, error => {
      console.log(error);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getWithdrawDetail(reqId) {
    this.withdrawService.getWithdrawalById(reqId).subscribe((data: Withdraw) => {
      this.request = data;
      this.requestId = data.id;
      this.requestDescription = data.description;
      this.requestStatus = data.status;
      this.requestAmount = data.amount;
      this.requestUserName = data.userName;
      this.requestUserId = data.userId;
      this.requestUpdateTime = data.responseDateStr;
      this.requestBank = data.user.bank;
      this.requestAgency = data.user.agency;
      this.requestAccountNumber = data.user.account;
      this.requestAccountType = this.accountTypes[data.user.accountType].toString();
    });
  }

  updateWithdrawRequest() {
    this.request.description = this.requestDescription;
    this.request.status = this.requestStatus;
    this.withdrawService.updateWithdrawRequest(this.request).subscribe((data: any) => {
      this.toastr.showNotification({ message: this.translateService.instant(data.msg), type: 'success' });
      this.BindWithdrawal(this.defaultStatus);
      this.loginService.setPendingDocCount(this.userAdmin.id, this.userAdmin.login);
      this.loginService.setNotificationCount(this.userAdmin.id, this.userAdmin.login);
      this.loginService.setPendingWithdrawal(this.userAdmin.id);
      this.confirmationModel.nativeElement.click();
      // if (this.request.status == 2) {
      //   // this.loginService.updateUserCredit(this.userAdmin.id, this.requestAmount, 1, this.requestUserId)
      //   this.loginService.updateUserCredit(this.UserIsLoggin[0].id, this.requestAmount, 1, this.requestUserId)
      //     .subscribe(userCredit => {
      //     }, err => {
      //       this.toastr.showNotification({ message: this.translateService.instant(err.error.msg), type: 'error' });
      //     })
      // };
      /*this.toastr.showNotification({ message: this.translateService.instant(data.msg), type: 'success' });
      this.BindWithdrawal(this.defaultStatus);
      this.loginService.setPendingDocCount(this.userAdmin.id);
      this.loginService.setNotificationCount(this.userAdmin.id);
      this.loginService.setPendingWithdrawal(this.userAdmin.id);
      this.confirmationModel.nativeElement.click();*/
    }, error => {
      this.toastr.showNotification({ message: this.translateService.instant(error.error.msg), type: 'error' });
    });
  }

  userDetail(userId: number) {
    this.route.navigate(['/players/edit/' + userId]);
  }


  getTableBackground(type) {
    switch (type) {
      case 1:
        return "lightgreen"
        break;
      case 2:
        return "pink"
        break;
      case 3:
        return "cyan"
        break;

      default:
        break;
    }
  }
}
