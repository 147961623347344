import { Component, OnInit } from '@angular/core';
import { SlidesComponent } from '../slides/slides.component';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css']
})
export class BannersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
