import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ButtonsModule, CardsModule, InputsModule, WavesModule, IconsModule } from 'angular-bootstrap-md';
import { AppTranslateModule } from '../../app.translate';
import { ComponentsModule } from '../../components/components.module';
import { AuthLayoutRoutingModule } from './auth-layout.routing';
import { LoginComponent } from '../../auth/login/login.component';

@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    AppTranslateModule,
    CardsModule,
    IconsModule,
    InputsModule,
    ButtonsModule,
    WavesModule,
    AuthLayoutRoutingModule,
  ]
})
export class AuthLayoutModule { }
