import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { finalize } from 'rxjs/operators';
import { ModalDirective } from 'angular-bootstrap-md';
import {
  CdkDragDrop,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { FormControl, FormGroup } from '@angular/forms';
import { LoginService } from '../../../services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { Slides } from '../../../interface/slide.interface';
import { CheckboxComponent } from 'angular-bootstrap-md';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '../../../services/toast.service';
import { SlideService } from 'src/app/services/slide.service';

@Component({
  selector: 'app-slides',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.css']
})

export class SlidesComponent implements OnInit {
  @ViewChild('visibleChk') visibleChk: CheckboxComponent;
  @ViewChild('cropVisibleChk') cropVisibleChk: CheckboxComponent;
  @ViewChild('deleteConfirm') deleteConfirm: ModalDirective
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageBase64: any = '';
  files: File[];
  fileNames = [];
  formSlides: FormGroup;
  formCropSlides: FormGroup;
  userAdmin: any;
  languageList: any;
  language: any;
  slides: Slides[];
  deleteId: number;
  editSlideId: number;
  prefixImgPath = "http://poolbet365.com/assets/banners/";
  // prefixImgPath = "http://localhost/assets/banners/"; //local test
  slideType = '';
  isEditing = false;
  SlidAndBannerListOwner : any = [];
  currentFileName: string;

  isFileSelect: boolean = false;
  processingClick: boolean = false; 

  constructor(private http: HttpClient, private loginService: LoginService, private translateService: TranslateService,
    private activatedroute: ActivatedRoute, private toastService: ToastService, private slideService: SlideService) {
    this.formSlides = new FormGroup({
      uploadtitle: new FormControl(''),
      uploadsubtitle: new FormControl(''),
      uploadurl: new FormControl(''),
      cropuploadtitle: new FormControl(''),
      cropuploadsubtitle: new FormControl(''),
      cropuploadurl: new FormControl(''),
      languageSelect: new FormControl('1'),
    });
    this.userAdmin = loginService.getUserInfoFromCookie();
    loginService.getLanguages().subscribe(
      data => {
        this.languageList = data;
      }, error => {
        console.log(error);
      }
    );
    this.language = Number('1');
  }

  ngOnInit(): void {
    this.isEditing = false;
    this.formSlides.controls['uploadtitle'].setValue("");
    this.formSlides.controls['uploadsubtitle'].setValue("");
    this.formSlides.controls['uploadurl'].setValue("");
    this.formSlides.controls['cropuploadtitle'].setValue("");
    this.formSlides.controls['cropuploadsubtitle'].setValue("");
    this.activatedroute.data.subscribe(data => {
      this.slideType = data.slideType;
      this.loginService.GetUsersList(parseInt(localStorage.getItem("creatorId"))).subscribe(res => {
        let temUser = res.find(element => element.id == parseInt(localStorage.getItem("creatorId")) && element.login == this.userAdmin.login)
        if (temUser == null) {
          this.loginService.GetUserAssist(parseInt(localStorage.getItem("creatorId"))).subscribe(x => {
            temUser = res.find(element => element.id == x.adminParentID && x.userName == this.userAdmin.login)
            this.SlidAndBannerListOwner.push(temUser)
            this.loginService.getOwnSlides(parseInt(localStorage.getItem("creatorId")), this.language, this.slideType).subscribe(
              data => {
                this.slides = data.reverse();
              }, error => {
                console.log(error);
              }
            );
          })
        } else {
          this.SlidAndBannerListOwner.push(temUser)
          this.loginService.getOwnSlides(parseInt(localStorage.getItem("creatorId")), this.language, this.slideType).subscribe(
            data => {
              this.slides = data.reverse();
            }, error => {
              console.log(error);
            }
          );
        }
      })
    });
  }

  changeLan($event) {
    this.slides = [];
    this.language = Number($event.target.value);
    // this.loginService.getOwnSlides(this.userAdmin.id, this.language, this.slideType).subscribe(
    //   data => {
    //     this.slides = data.reverse();
    //   }, error => {
    //     console.log(error);
    //   }
    // );
    this.loginService.GetUsersList(parseInt(localStorage.getItem("creatorId"))).subscribe(res => {
      let temUser = res.find(element => element.id == parseInt(localStorage.getItem("creatorId")) && element.login == this.userAdmin.login)
      if (temUser == null) {
        this.loginService.GetUserAssist(parseInt(localStorage.getItem("creatorId"))).subscribe(x => {
          temUser = res.find(element => element.id == x.adminParentID && x.userName == this.userAdmin.login)
          this.loginService.getOwnSlides(temUser.id, this.language, this.slideType).subscribe(
            data => {
              this.slides = data.reverse();
            }, error => {
              console.log(error);
            }
          );
        })
      } else {
        this.loginService.getOwnSlides(temUser.id, this.language, this.slideType).subscribe(
          data => {
            this.slides = data.reverse();
          }, error => {
            console.log(error);
          }
        );
      }
    })
  }

  

  get uploadtitle() {
    return this.formSlides.get('uploadtitle');
  }

  get uploadsubtitle() {
    return this.formSlides.get('uploadsubtitle');
  }

  get uploadurl() {
    return this.formSlides.get('uploadurl');
  }

  get cropuploadtitle() {
    return this.formSlides.get('cropuploadtitle');
  }

  get cropuploadsubtitle() {
    return this.formSlides.get('cropuploadsubtitle');
  }

  get cropuploadurl() {
    return this.formSlides.get('cropuploadurl');
  }

  get languageSelect() {
    return this.formSlides.get('languageSelect');
  }

  async setCropperImage(slide: Slides) {
    try {
      this.isEditing = true;
      this.editSlideId = slide.id;
      this.formSlides.controls['cropuploadtitle'].setValue(slide.title);
      this.formSlides.controls['cropuploadsubtitle'].setValue(slide.subtitle);
      this.formSlides.controls['cropuploadurl'].setValue(slide.url);

      this.slideService.getSlideByFileName(slide.fileName).subscribe(response => {
        this.imageBase64 = 'data:image/jpeg;base64, ' + response;
        this.currentFileName = slide.fileName;
      }, error => {
        return new Error(error);
      });

    } catch (error) {
      return new Error(error);
    }
  }

  onFileSelected(event) {
    this.files = event.target.files;
    if (this.files) {
      this.fileNames = [];
      for (let file of this.files) {
        this.fileNames.push({ 'name': file.name, 'size': Math.round(file.size / 1024) + " KB", 'type': file.type });
      }
    }
    this.isFileSelect = true;
  }

  reset() {
    this.files = null;
    this.fileNames = [];
  }

  clearFiles() {
    this.files = null;
    this.fileNames = [];
    this.formSlides.controls['uploadtitle'].setValue("");
    this.formSlides.controls['uploadsubtitle'].setValue("");
    this.isFileSelect = false;
    this.processingClick = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.slides.length > event.previousIndex && this.slides.length > event.currentIndex) {
      /* this.loginService.changeSlidesOrder(this.userAdmin.id, this.language, this.slideType, this.slides[event.previousIndex].id, this.slides[event.currentIndex].id)
      .pipe(
        finalize(() => this.reset())
      ).subscribe(data => {
        this.slides = data;
      }, error => {
        console.log(error);
      }); */
      if (this.slides[event.currentIndex].locked == 0) {
        moveItemInArray(this.slides, event.previousIndex, event.currentIndex);
      }
    }
  }

  uploadFile = (files) => {
    this.processingClick = true;
    if (files.length == 0) {
      this.clearFiles();
      return this.toastService.showNotification({ message: this.translateService.instant('select-file-erro') });;
    }
    if(this.isFileSelect == false){ 
      this.clearFiles();
      return this.toastService.showNotification({ message: this.translateService.instant('select-file-erro') });
    } 
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('Type', this.slideType);
    formData.append('Title', this.formSlides.value.uploadtitle);
    formData.append('Subtitle', this.formSlides.value.uploadsubtitle);
    formData.append('LanguageId', this.language.toString());
    let visible = (this.visibleChk.checked ? 1 : 0);
    formData.append('Visible', visible.toString());
    if (this.slideType == 'left') {
      formData.append('Url', this.formSlides.value.uploadurl);
    } else {
      formData.append('Url', '');
    }
    formData.append('Locked', '0');
    // this.loginService.postSlides(formData, this.userAdmin.id)
    this.loginService.postSlides(formData, this.SlidAndBannerListOwner[0].id)
      .pipe(
        finalize(() => this.reset())
      ).subscribe(data => {
        this.ngOnInit();
      }, error => {
        console.log(error);
      });
      this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });
      this.clickLoading();
  }

  clickLoading(){
    setTimeout(() => {
      this.clearFiles();
      // console.log(this.processingClick)
    }, 2000);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  uploadCrop() {
    if (this.croppedImage != null && this.croppedImage != '') {
      let blob = this.dataURItoBlob(this.croppedImage);
      const formData = new FormData();
      let nickName = this.currentFileName;
      formData.append('file', blob, nickName);
      formData.append('Type', this.slideType);
      formData.append('Title', this.formSlides.value.cropuploadtitle);
      formData.append('Subtitle', this.formSlides.value.cropuploadsubtitle);
      formData.append('LanguageId', this.language.toString());
      let visible = (this.cropVisibleChk.checked ? 1 : 0);
      formData.append('Visible', visible.toString());
      if (this.slideType == 'left') {
        formData.append('Url', this.formSlides.value.cropuploadurl);
      } else {
        formData.append('Url', '');
      }
      formData.append('Locked', '0');
      // this.loginService.updateSlides(formData, this.userAdmin.id, this.editSlideId)
      this.loginService.updateSlides(formData, this.editSlideId)
        .pipe(
          finalize(() => { })
        ).subscribe(data => {
          this.ngOnInit();
        }, error => {
          console.log(error);
        });
      // this.clearFiles();
      // this.reset();
    }
  }
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  setSlideLocked(slideId: number) {
    var slide = this.slides.find(
      element => {
        return element.id == slideId
      });
    slide.locked = 1;
    // this.loginService.putSlide(slideId, this.userAdmin.id, slide)
    this.loginService.putSlide(slideId, this.SlidAndBannerListOwner[0].id, slide)
      .pipe(
        finalize(() => { })
      ).subscribe(data => {
        this.ngOnInit();
        this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });
      }, error => {
        this.toastService.showNotification({ message: this.translateService.instant('failed'), type: 'danger' });
        console.log(error);
      });
  }

  setSlideUnlocked(slideId: number) {
    var slide = this.slides.find(
      element => {
        return element.id == slideId
      });
    slide.locked = 0;
    // this.loginService.putSlide(slideId, this.userAdmin.id, slide)
    this.loginService.putSlide(slideId, this.SlidAndBannerListOwner[0].id, slide)
      .pipe(
        finalize(() => { })
      ).subscribe(data => {
        this.ngOnInit();
        this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });
      }, error => {
        this.toastService.showNotification({ message: this.translateService.instant('failed'), type: 'danger' });
        console.log(error);
      });
  }

  onClickDelete(id: number) {
    this.deleteId = id;
    this.deleteConfirm.show();
  }

  deleteSlide() {
    // this.loginService.deleteSlide(this.deleteId, this.userAdmin.id)
    this.loginService.deleteSlide(this.deleteId, this.SlidAndBannerListOwner[0].id)
      .pipe(
        finalize(() => { })
      ).subscribe(data => {
        this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });
        this.deleteConfirm.hide();
        this.ngOnInit();
      }, error => {
        this.toastService.showNotification({ message: this.translateService.instant('not-owner') });
        this.deleteConfirm.hide();
        console.log(error);
      });
  }

  saveBannersState() {
    // this.loginService.saveSlidesState(this.userAdmin.id, this.language, this.slideType, this.slides)
    this.loginService.saveSlidesState(parseInt(localStorage.getItem("creatorId")), this.language, this.slideType, this.slides)
      .subscribe(data => {
        this.toastService.showNotification({ message: this.translateService.instant('success'), type: 'success' });
      }, error => {
        console.log(error);
        this.toastService.showNotification({ message: this.translateService.instant('failed'), type: 'danger' });
      })
  }

}
