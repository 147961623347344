import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css']
})
export class LanguageSwitcherComponent implements OnInit {
  @Input() switchType: string;
  currentLang: string;
  subscription: Subscription = new Subscription();
  visibleLanguageDropdown = false;

  //public appSettings: AppSettings = appSettings;

  constructor(private languageService: LanguageService) {
    this.subscription.add(
      this.languageService.currentLang.subscribe(lang => {
        this.currentLang = lang;
      })
    );
  }

  ngOnInit(): void {
  }

  getLanguageName(): string {
    switch (this.currentLang) {
      case 'it':
        return 'Italiano';
      case 'ptbr':
        return 'Português';
      case 'en':
        return 'English';
      case 'es':
        return 'Español';
      // case 'Fr':
      //   return 'Frances';
      default:
        break;
    }
  }

  toggleDropDown(): void {
    this.visibleLanguageDropdown = !this.visibleLanguageDropdown;
  }

  changeLanguage(lang): void {
    this.languageService.changeLanguage(lang);
  }
}
