<div id="topbar">
    <ol class="breadcrumb">
        <li class="crumb-active">
            <a href="#">{{ 'pg-manage-bets.manage-bets' | translate }}</a>
        </li>
    </ol>
</div>
<div id="content">
    <div class="panel">
        <div class="panel-heading">
            <div class="panel-title row">
                <div class="col-md-9">
                    <span class="material-icons default-icon">fact_check</span>
                    {{ 'pg-manage-bets.manage-bets' | translate }}
                </div>

            </div>
        </div>
        <div class="panel-body">
            <div class="row mt-3">

                <div class="col-md-12">

                    <div style="text-align: right;">
                        <mat-form-field>
                            <mat-label>{{ 'Search' | translate }}...</mat-label>
                            <input matInput (keyup)="applyFilter($event)" #input>
                        </mat-form-field>
                    </div>

                    <div class="mat-elevation-z8" style="overflow: auto;">
                        <table mat-table [dataSource]="dataSource">

                            <ng-container matColumnDef="coupon">
                                <th mat-header-cell *matHeaderCellDef> {{ 'pg-manage-bets.coupon' | translate }} </th>
                                <td mat-cell *matCellDef="let element">
                                    <span style="cursor: pointer;color:#003771;" (click)="couponDetail(element.uuid8)">
                                        {{element.uuid8}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="player">
                                <th mat-header-cell *matHeaderCellDef> {{ 'pg-manage-bets.player' | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.playerName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="result">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'pg-manage-bets.result' |
                                    translate }} </th>
                                <td mat-cell *matCellDef="let element" class="text-center">
                                    <input class="center-content" type="text" [(ngModel)]="element.results">
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'pg-manage-bets.action' |
                                    translate }} </th>
                                <td mat-cell *matCellDef="let element" class="text-center">
                                    <button type="button" mdbBtn class="btn btn-success" mdbWavesEffect
                                        (click)="changeBet(element.id)">
                                        {{ 'pg-manage-bets.change' | translate }}
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                [style.background]="getTableBackground(row.type)"></tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Message -->
<div mdbModal #frameMessage="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
        <div class="modal-content modal-lg  bg-theme" style="height: auto; max-height: 700px;">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="frameMessage.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">


                <p style="color: black" id="msg" [innerHtml]="msgToAdd"></p>


            </div>
            <div class="modal-footer d-flex justify-content-center">

                <a type="button" color="waves-light button-font"
                    style="padding:.54rem 2.14rem; background-color:#33a0df;" class="btn" mdbWavesEffect
                    (click)="frameMessage.hide()" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>
<!-- Modal Message -->