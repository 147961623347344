import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { WhatsappLogs } from 'src/app/interface/whatsapp-logs';

@Component({
  selector: 'app-whatsapp-list',
  templateUrl: './whatsapp-list.component.html',
  styleUrls: ['./whatsapp-list.component.css']
})
export class WhatsappListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['Id', 'PlayerName', 'PhoneNumber', 'BetUuid8']
  dataSource: WhatsappLogs[];

  constructor(private loginService: LoginService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.getWhatsappNumbers()
  }

  getWhatsappNumbers() {
    this.loginService.getWhatsappNumbers()
      .subscribe(data => {
        this.dataSource = data
      }, error => {
        console.log(error)
      })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    //this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
