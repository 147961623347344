<div class="login-page">
  <div class="row">
    <div class="page-logo justify-content-center flex-wrap d-flex">
      <img src="../../../assets/images/brasilbolao-admin.png" style="max-width: 52%" width="70%" alt="logo" class="img-fluid"/>
    </div>
  </div>
  <div class="row">
    <div class="login-form">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
        <div class="panel">
          <mdb-card>
            <mdb-card-header>
              <mdb-icon fas icon="lock" class="card-header-icon"></mdb-icon>
              <span class="form-title">{{ 'login.title' | translate}}</span>
            </mdb-card-header>
            <mdb-card-body>
              <app-language-switcher switchType="dropdown"></app-language-switcher>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="username">
                    <mdb-icon fas icon="user-alt"></mdb-icon>
                  </span>
                </div>
                <input type="text" id="name" class="form-control" placeholder="Username" formControlName="name" spellcheck="false" autocomplete="off">
              </div>
              <div *ngIf="loginForm.get('name').invalid && loginForm.get('name').touched"
                  class="text-danger error-msg" >
              <span><b>Name</b> is required</span>
            </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="password">
                    <mdb-icon fas icon="link"></mdb-icon>
                  </span>
                </div>
                <input [type]="show_password ? 'text' : 'password'" class="form-control" placeholder="Password" formControlName="password">
                <div class="input-group-append ">
                  <span class="input-group-text">
                    <i [class]="show_password ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()" ></i>
                  </span>
                </div>
              </div>
              <div *ngIf="loginForm.get('password').invalid && loginForm.get('password').touched"
                    class="text-danger error-msg" >
                <span><b>Password</b> is required</span>
              </div>
              <div class="captcha-container">
                <div class="captcha" [innerHTML]="captchaImage"></div>
                <div class="captcha-action ml-3 text-center">
                  <img src="assets/images/refresh.png" alt="refresh" (click)="generateCaptcha()" class="img-fluid">
                  <input type="text" class="form-control mt-2 text-center" formControlName="captcha" spellcheck="false"/>
                </div>
              </div>
            </mdb-card-body>
            <mdb-card-footer class="text-muted">
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <span style="font-size: 12px">  {{ 'forgotpassword.title' | translate }}</span>
                <button mdbBtn rounded="true" type="submit" mdbWavesEffect size="md" class="bg-purple p-2">
                  <mdb-icon fas icon="home" class="text-white mr-1"></mdb-icon>
                  <span>Enter</span>
                  <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="loading"></span>
                </button>
              </div>
            </mdb-card-footer>
          </mdb-card>
        </div>
      </form>
    </div>
  </div>
</div>
