import { AccountSummary, DropDownList, Player, PlayerDocs } from './../../../interface/player.interface';
import { CountryService } from './../../../services/country.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Country } from 'src/app/interface/country.interface';

import { PlayerService } from 'src/app/services/player.service';
import { HttpClient, HttpEventType } from '@angular/common/http';

import { IpConfigService } from 'src/app/services/Ip-Config.service';
import { ConfirmedValidator } from '../../../helper/confirmed.validator';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/services/toast.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { LoginService } from '../../../services/login.service';
import { Observable } from 'rxjs';
import { InteractionService } from 'src/app/services/interaction.service';
import { Currency } from 'src/app/interface/currency.interface';
//import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-edit-player2',
  templateUrl: './edit-player2.component.html',
  styleUrls: ['./edit-player2.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class EditPlayer2Component implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('validationModal') validationModal: any;
  displayedColumns: string[] = ['fileName', 'fileType', 'status', 'description', 'responseDate', 'actions'];
  dataSourceDocs = new MatTableDataSource<any>([]);
  playerId: number;
  accountSummary: AccountSummary;
  playerInfo: Player;
  editPlayerForm: FormGroup;
  passMinLength = 8;
  createPlayerRequest: Player;
  clubParents: DropDownList[];
  playerDocs: PlayerDocs[];
  countries: Country[];
  apiUrl = ''; // local test
  imageUrl = '';
  public progress: number;
  public message: string;
  IDCardFront: string;
  IDCardFrontNotes: string;
  IDCardFrontStatus: number;
  IDCardBack: string;
  IDCardBackNotes: string;
  IDCardBackStatus: number;
  ProofOfAddress: string;
  ProofOfAddressNotes: string;
  ProofOfAddressStatus: number;
  SelfieWithIdentity: string;
  SelfieWithIdentityNotes: string;
  SelfieWithIdentityStatus: number;
  SelfieWithPaper: string;
  SelfieWithPaperNotes: string;
  SelfieWithPaperStatus: number;
  userAdmin: any;
  userDoc: PlayerDocs;
  picker1: any;
  errors: string[] = [];
  PlayerEditPermit: boolean = false;
  BlockPlayerPermit: boolean = false;
  PlayerDocumenteEditPermit: boolean = false;
  PassWordChangePermit: boolean = false;
  imgPath: string;

  idCardFrontPhoto: string;
  idCardBackPhoto: string;
  proofOfAddressPhoto: string;
  selfieWithIdentityPhoto: string;
  selfieWithPaperPhoto: string;
  Currency: Currency = <Currency>{};

  @Output() public onUploadFinished = new EventEmitter();
  savingPlayer: boolean = false;
  constructor(private playerService: PlayerService, private countryService: CountryService,
    private http: HttpClient,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private ipConfigService: IpConfigService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private reactiveFrom: ReactiveFormsModule,
    private loginService: LoginService,
    private interactionService: InteractionService,
    private _adapter: DateAdapter<any>) {

    this._adapter.setLocale('br');
    this.imageUrl = ipConfigService.getImageUrl();
    this.apiUrl = ipConfigService.getApiUrl();
    this.createPlayerRequest = {} as Player;
    this.editPlayerForm = this.formBuilder.group({
      clubParents: ['', Validators.required],
      login: [''],
      userName: ['', Validators.required],
      name: [''],
      email: ['', Validators.required],
      whatsApp: [''],
      password: ['', Validators.compose([Validators.required, Validators.minLength(this.passMinLength)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(this.passMinLength)])],
      lastname: [''],
      email2: [''],
      email3: [''],
      gender: [''],
      birthDate: [''],
      address: [''],
      city: [''],
      country: [''],
      zipCode: [''],
      phone: [''],
      phone2: [''],
      cellPhone: [''],
      notes: [''],
      access: [false]

    }, {
      validator: ConfirmedValidator('password', 'confirmPassword')
    });
    this.userAdmin = loginService.getUserInfoFromCookie();
    this.countryService.getCountries()
      .subscribe((data: Country[]) => {
        this.countries = data;
      }, error => {
        console.log(error);
      });
    this.playerService.getClubParent(this.userAdmin.id, this.userAdmin.login)
      .subscribe((data: DropDownList[]) => {
        this.clubParents = data;
      }, error => {
        console.log(error);
      });

    this.route.params.subscribe((params: Params): void => {
      this.playerId = params['id'];

      this.playerService.getPlayerDetails(this.playerId)
        .subscribe((data: Player) => {
          this.accountSummary = data.accountSummary;
          this.playerInfo = data;
          this.editPlayerForm.patchValue({
            clubParents: this.playerInfo.parentId,
            login: this.playerInfo.login,
            userName: this.playerInfo.login,
            name: this.playerInfo.name,
            email: this.playerInfo.email,
            whatsApp: this.playerInfo.whatsapp,
            password: this.playerInfo.password,
            confirmPassword: this.playerInfo.password,
            lastname: this.playerInfo.lastName,
            email2: this.playerInfo.email2,
            email3: this.playerInfo.email3,
            gender: this.playerInfo.gender,
            birthDate: this.playerInfo.birthDate,
            address: this.playerInfo.address,
            city: this.playerInfo.city,
            country: this.playerInfo.country,
            zipCode: this.playerInfo.zipCode,
            phone: this.playerInfo.phone,
            phone2: this.playerInfo.phone2,
            cellPhone: this.playerInfo.cellPhone,
            notes: this.playerInfo.notes,
            access: !this.playerInfo.deleted
          });
          //this.playerDocs = data.docs;
          this.IDCardFront = '';
          this.IDCardFrontStatus = -1;
          this.IDCardBack = '';
          this.IDCardBackStatus = -1;
          this.ProofOfAddress = '';
          this.ProofOfAddressStatus = -1;
          this.SelfieWithIdentity = '';
          this.SelfieWithIdentityStatus = -1;
          this.SelfieWithPaper = '';
          this.SelfieWithPaperStatus = -1;

          for (let i = 0; i < data.docs.length; i++) {
            if (data.docs[i].type == 1) {
              this.IDCardFront = this.imageUrl + '/' + data.docs[i].fileName;
              this.idCardFrontPhoto = data.docs[i].fileName;
              this.IDCardFrontNotes = data.docs[i].description;
              this.IDCardFrontStatus = data.docs[i].status;
            }
            else if (data.docs[i].type == 5) {
              this.IDCardBack = this.imageUrl + '/' + data.docs[i].fileName;
              this.idCardBackPhoto = data.docs[i].fileName;
              this.IDCardBackNotes = data.docs[i].description;
              this.IDCardBackStatus = data.docs[i].status;
            }
            else if (data.docs[i].type == 2) {
              this.ProofOfAddress = this.imageUrl + '/' + data.docs[i].fileName;
              this.proofOfAddressPhoto = data.docs[i].fileName;
              this.ProofOfAddressNotes = data.docs[i].description;
              this.ProofOfAddressStatus = data.docs[i].status;
            }
            else if (data.docs[i].type == 3) {
              this.SelfieWithIdentity = this.imageUrl + '/' + data.docs[i].fileName;
              this.selfieWithIdentityPhoto = data.docs[i].fileName;
              this.SelfieWithIdentityNotes = data.docs[i].description;
              this.SelfieWithIdentityStatus = data.docs[i].status;
            }
            else if (data.docs[i].type == 4) {
              this.SelfieWithPaper = this.imageUrl + '/' + data.docs[i].fileName;
              this.selfieWithPaperPhoto = data.docs[i].fileName;
              this.SelfieWithPaperNotes = data.docs[i].description;
              this.SelfieWithPaperStatus = data.docs[i].status;
            }
          }

          //this.dataSourceDocs.data = data.docs;
          //this.dataSourceDocs.paginator = this.paginator;
        }, error => {
          console.log(error);
        });
    });

  }

  ngOnInit(): void {
    // this.route.params.subscribe((params: Params): void => {
    //   this.playerId = params['id'];


    // });
    this.PlayerEditPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'editplayerdata') == null ? false : true : true;
    this.PlayerDocumenteEditPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'editplayerdocuments') == null ? false : true : true;
    this.PassWordChangePermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'resetpassword') == null ? false : true : true;
    this.BlockPlayerPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'blockplayer') == null ? false : true : true;
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

  }

  deleteDoc(docType: number) {
    this.playerService.deletePlayerDoc(this.playerId, docType).subscribe((data: any) => {
      if (docType == 1) {
        this.IDCardFront = '';
      }
      else if (docType == 5) {
        this.IDCardBack = '';
      }
      else if (docType == 2) {
        this.ProofOfAddress = '';
      }
      else if (docType == 3) {
        this.SelfieWithIdentity = '';
      }
      else if (docType == 4) {
        this.SelfieWithPaper = '';
      }
    })
    this.loginService.setPendingDocCount(this.userAdmin.id, this.userAdmin.login);
  }

  updateDoc(docType: number) {
    if (docType == 1) {
      this.userDoc = {
        id: 0,
        fileName: this.idCardFrontPhoto,
        type: docType,
        status: this.IDCardFrontStatus,
        description: this.IDCardFrontNotes,
        userId: this.playerId,
        statusDesc: '',
        typeDesc: '',
        responseDate: new Date(),
        createdDate: new Date(),
        createdDateStr: '',
        responseDateStr: ''
      }
    }
    else if (docType == 5) {
      this.userDoc = {
        id: 0,
        fileName: this.idCardBackPhoto,
        type: docType,
        status: this.IDCardBackStatus,
        description: this.IDCardBackNotes,
        userId: this.playerId,
        statusDesc: '',
        typeDesc: '',
        responseDate: new Date(),
        createdDate: new Date(),
        createdDateStr: '',
        responseDateStr: ''
      }
    }
    else if (docType == 2) {
      this.userDoc = {
        id: 0,
        fileName: this.proofOfAddressPhoto,
        type: docType,
        status: this.ProofOfAddressStatus,
        description: this.ProofOfAddressNotes,
        userId: this.playerId,
        statusDesc: '',
        typeDesc: '',
        responseDate: new Date(),
        createdDate: new Date(),
        createdDateStr: '',
        responseDateStr: ''
      }
    }
    else if (docType == 3) {
      this.userDoc = {
        id: 0,
        fileName: this.selfieWithIdentityPhoto,
        type: docType,
        status: this.SelfieWithIdentityStatus,
        description: this.SelfieWithIdentityNotes,
        userId: this.playerId,
        statusDesc: '',
        typeDesc: '',
        responseDate: new Date(),
        createdDate: new Date(),
        createdDateStr: '',
        responseDateStr: ''
      }
    }
    else if (docType == 4) {
      this.userDoc = {
        id: 0,
        fileName: this.selfieWithPaperPhoto,
        type: docType,
        status: this.SelfieWithPaperStatus,
        description: this.SelfieWithPaperNotes,
        userId: this.playerId,
        statusDesc: '',
        typeDesc: '',
        responseDate: new Date(),
        createdDate: new Date(),
        createdDateStr: '',
        responseDateStr: ''
      }
    }
    this.playerService.updateUserDoc(this.userDoc).subscribe((data: any) => {
      this.toastService.showNotification({ message: this.translateService.instant(data.msg), type: 'success' });
      if(data){
         this.loginService.setTotalPendingDocCount(this.userAdmin.id, this.userAdmin.login);
        }
    }, error => {
      this.toastService.showNotification({ message: this.translateService.instant(error.error.msg), type: 'success' });
    });
  }

  public uploadFile = (files, field) => {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files[0] as File;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.http.post(this.apiUrl + '/PlayerAction/UploadFiles', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        // if (event.type === HttpEventType.UploadProgress) {
        //   this.progress = Math.round(100 * event.loaded / event.total);
        // }
        if (event.type === HttpEventType.Response) {
          if (field === 'cnicF') {
            this.createPlayerRequest.cNICFront = event.body["dbPath"].toString();
            this.idCardFrontPhoto = event.body["dbPath"].toString();
            this.IDCardFront = this.imageUrl + "/" + this.createPlayerRequest.cNICFront;
            //this.IDCardFrontMsg = 'ID card (front) uploaded successfully.';

          }
          if (field === 'cnicB') {
            this.createPlayerRequest.cNICBack = event.body["dbPath"].toString();
            this.idCardBackPhoto = event.body["dbPath"].toString();
            this.IDCardBack = this.imageUrl + "/" + this.createPlayerRequest.cNICBack;

          }
          if (field === 'Taxcode') {
            this.createPlayerRequest.proofOfAddress = event.body["dbPath"].toString();
            this.proofOfAddressPhoto = event.body["dbPath"].toString();
            this.ProofOfAddress = this.imageUrl + "/" + this.createPlayerRequest.proofOfAddress;

            //this.ProofOfAddressMsg = 'Proof of address uploaded successfully.';
          }
          if (field === 'Photo') {
            this.createPlayerRequest.selfieWithIdentity = event.body["dbPath"].toString();
            this.selfieWithIdentityPhoto = event.body["dbPath"].toString();
            this.SelfieWithIdentity = this.imageUrl + "/" + this.createPlayerRequest.selfieWithIdentity;

            //this.SelfieWithIdentityMsg = 'Selfie With Identity uploaded successfully.';
          }
          if (field === 'SelfieWithPaper') {
            this.createPlayerRequest.selfieWithPaper = event.body["dbPath"].toString();
            this.selfieWithPaperPhoto = event.body["dbPath"].toString();
            this.SelfieWithPaper = this.imageUrl + "/" + this.createPlayerRequest.selfieWithPaper;

            //this.SelfieWithPaperMsg = 'Selfie With Paper uploaded successfully.';
          }
        }
      });
  }
  validateForm(formGroup: FormGroup): void {
    this.errors = [];
    Object.keys(formGroup.controls).forEach(field => {
      let control = formGroup.controls[field];
      let errors = control.errors;
      if (errors === null || errors.count === 0) {
        return;
      }
      // Handle the 'required' case
      if (errors.required) {
        this.errors.push(`${field} is required`);
      }
      // Handle 'minlength' case
      if (errors.minlength) {
        this.errors.push(`${field} minimum length is ${errors.minlength.requiredLength}.`);
      }
      // Handle custom messages.
      if (errors.message) {
        this.errors.push(`${field} ${errors.message}`);
      }


      // const control = formGroup.get(field);
      // control.markAsTouched({ onlySelf: true });
      // control.markAsDirty({ onlySelf: true });
    });
  }

  getTableBackground(type) {
    switch (type) {
      case 0:
        return "lightgrey"
        break;
      case 1:
        return "lightgreen"
        break;
      case 2:
        return "red"
        break;
      default:
        break;
    }
  }

  getDocDetail(docid) {

  }

  editPlayer(formData): void {
    //debugger;
    if (this.editPlayerForm.valid) {
      this.createPlayerRequest.id = this.playerId;
      this.createPlayerRequest.parentId = formData.clubParents;
      this.createPlayerRequest.city = formData.city;
      this.createPlayerRequest.lastName = formData.lastname;
      this.createPlayerRequest.password = formData.password;
      this.createPlayerRequest.confirmPassword = formData.confirmPassword;
      this.createPlayerRequest.gender = formData.gender;
      this.createPlayerRequest.contact = formData.phone;
      this.createPlayerRequest.birthDate = formData.birthDate;
      this.createPlayerRequest.name = formData.name;
      this.createPlayerRequest.email2 = formData.email2;
      this.createPlayerRequest.email3 = formData.email3;
      this.createPlayerRequest.country = formData.country;
      this.createPlayerRequest.email = formData.email;
      this.createPlayerRequest.notes = formData.notes;
      this.createPlayerRequest.address = formData.address;
      this.createPlayerRequest.zipCode = formData.zipCode;
      this.createPlayerRequest.whatsapp = formData.whatsApp;
      this.createPlayerRequest.login = formData.userName;
      this.createPlayerRequest.userName = formData.userName;
      this.createPlayerRequest.cellPhone = formData.cellPhone;
      this.createPlayerRequest.phone = formData.phone;
      this.createPlayerRequest.phone2 = formData.phone2
      this.createPlayerRequest.access = formData.access;

      this.playerService.updatePlayer(this.playerId, this.createPlayerRequest)
        .subscribe((message: any) => {

          this.toastService.showNotification({ message: this.translateService.instant(message.msg), type: 'success' });
        }, error => {
          this.toastService.showNotification({ message: this.translateService.instant(error.error.msg), type: 'success' });
        });

    }
    else {
      this.validateForm(this.editPlayerForm);
      this.validationModal.show();
    }
  }

  showImage(path){
    this.imgPath = path;
  }
}
