<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ 'dashboard.title' | translate }}</a>
    </li>
  </ol>
</div>
<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title">
        <i class="material-icons"></i>
        {{ 'latestaccess.title' | translate }}
      </div>
    </div>
    <div class="panel-body">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>{{ 'date.title' | translate }}</th>
            <th>{{ 'username.title' | translate }}</th>
            <th>IP</th>
            <th>{{ 'status.title' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of loginHistories">
            <td>{{ item.strLoginDate }}</td>
            <td>{{ item.userAdminName }}</td>
            <td>{{ item.ipAddress }}</td>
            <td style="color:green" *ngIf=" item.status == 'success' ">{{ item.status }}</td>
            <td style="color:red" *ngIf=" item.status != 'success' ">{{ item.status }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
