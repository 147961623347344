import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import axios from "axios";
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { Banner } from '../interface/banner.interface';
import { Bet } from '../interface/bet.interface';
import { Contact } from '../interface/contact.interface';
import { Currency } from '../interface/currency.interface';
import { data } from 'jquery';
// import { WhatsappLogs } from '../interface/whatsapp-logs';
// import { coin } from '../interface/co';
// import { debug } from 'console';
// import { UserIdentification } from '../interface/UserIdentification.interface';
// import { UserAssistantPasswordChange } from '../interface/UserAssistantPasswordChange.interface';
import { TokenService } from './token.service';
import { DocumentResponseDto } from '../interface/document.interface';
import { Domain } from '../interface/domain.interface';
import { Game } from '../interface/game.interface';
import { Jackpot } from '../interface/jackpot.interface';
import { JurisdictionCoupon } from '../interface/jurisdiction-coupon.interface';
import { Jurisdiction } from '../interface/jurisdiction.interface';
import { Language } from '../interface/language.interface';
import { LoginHistory } from '../interface/loginhistory.interface';
import { Permit } from '../interface/permit.interface';
import { PlayerDocs } from '../interface/player.interface';
import { Prize } from '../interface/prize.interface';
import { ReportFilter } from '../interface/report-filter.interface';
import { Report } from '../interface/report.interface';
import { RoundGroup } from '../interface/round-group.interface';
import { Round } from '../interface/round.interface';
import { Slides } from '../interface/slide.interface';
import { Ticket } from '../interface/ticket.interface';
import { Transaction } from '../interface/transaction.interface';
import { Transactionsfilter } from '../interface/transactions-filter.interface';
import { User, userAssistant, UserLoginForm } from '../interface/user.interface';
import { UserAssistantPasswordChange } from '../interface/UserAssistantPasswordChange.interface';
import { UserIdentification } from '../interface/UserIdentification.interface';
import { WhatsappLogs } from '../interface/whatsapp-logs';
import { Withdraw } from '../interface/withdraw.interface';
import { coin } from '../services/coin.interface';
import { InteractionService } from './interaction.service';
import { IpConfigService } from './Ip-Config.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
	//apiUrl = 'http://dev-backend.poolbet365.com'; // dev online
	apiUrl = 'http://backend.poolbet365.com'; // production
	//apiUrl = 'https://192.168.101.98:44341';
	//apiUrl = 'http://localhost:5001'; //local test
	// apiUrl = 'https://localhost:44341';
	changePass: EventEmitter<boolean> = new EventEmitter();
	private notificationSource = new Subject<number>();
	private pendingDocSource = new Subject<number>();
	private pendingWithdrawalSource = new Subject<number>();

	// Observable string streams
	notifications = this.notificationSource.asObservable();
	pendingDocs = this.pendingDocSource.asObservable();
	pendingWithdrawls = this.pendingWithdrawalSource.asObservable();
	//notifications: number;
	//pendingDocs: number;
	//pendingWithdrawls: number;
	userAdmin: any;
	Currency: Currency = <Currency>{};
	sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

	constructor(private http: HttpClient, private interactionService: InteractionService, private cookieService: CookieService, private ipConfigService: IpConfigService,
				private tokenService: TokenService) {
		this.apiUrl = ipConfigService.getApiUrl();
		this.sidebarVisibilityChange.subscribe((value) => {
			this.notifications = this.getNotificationCount();
			this.pendingDocs = this.getPendingDocCount();
			this.pendingWithdrawls = this.getPendingWithdrawal();
		});

	}

	openChangePass() {
		this.changePass.emit(true);
	}

	getUserInfoFromCookie() {
		let user = this.tokenService.getUserFromToken();

		if (user != 'undefined' && user != null && user != "") {
			return user;
		} else {
			return null;
		}
	}

	isLogin() {
		let user = this.getUserInfoFromCookie();
		if (user) {
			return true;
		} else {
			return false;
		}
	}

	logout() {
		localStorage.setItem('user', "");
		localStorage.removeItem('currentUser');
		localStorage.removeItem('currentUserToken');
		localStorage.removeItem('currentUserCountry');
	}

	getLogin(log: string, pass: string): Observable<any> {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		const loginObj = { login: log, password: pass, loginLevel: 101 };
		return this.http.post(this.apiUrl + '/users/login', loginObj, { headers: header });
	}

	checkLogged(key: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		return this.http.post(this.apiUrl + '/common/LoginDencryption/?key=' + key, { headers: header });
	}

	listUsers() {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<User[]>(`${this.apiUrl}/users/`, { headers: header });
	}

	listCommonUsers() {
		return this.http.get<User[]>(`${this.apiUrl}/common/CommonGetUsers/`)
	}

	getAllPermits(userId: number, userLog: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<Permit[]>(`${this.apiUrl}/Permits/?IdUser=` + userId + `&UserLogg=` + userLog, { headers: header });
	}

	getAllPermitsByUser(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<Permit[]>(`${this.apiUrl}/Permits/` + userId, { headers: header });
		//return this.http.get<User>(`${this.apiUrl}/users/` + userId);
	}


	getAllDomains() {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<Domain[]>(`${this.apiUrl}/Domains/`, { headers: header });
	}

	saveDomains(domains: Domain[]) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.post<Domain[]>(`${this.apiUrl}/Domains/`, domains, { headers: header });
	}

	deleteDomain(domainId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.delete(`${this.apiUrl}/Domains/` + domainId, { headers: header });
	}

	getUser(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<User>(`${this.apiUrl}/users/` + userId, { headers: header });
		//return this.http.get<User>(`${this.apiUrl}/users/` + userId);
	}

	getNac() {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<User>(`${this.apiUrl}/common/getNacional`);
	}

	getUserAssistant(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<userAssistant>(`${this.apiUrl}/UserAssistants/` + userId, { headers: header });
		//return this.http.get<User>(`${this.apiUrl}/users/` + userId);
	}

	getBasicUser(userId: number) {
		return this.http.get<User>(`${this.apiUrl}/common/GetBasicUser/?userId=` + userId);
	}

	getUserTree(userId: number, userName: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<User>(`${this.apiUrl}/common/GetUserTree?userId=` + userId + `&nameUser=` + userName, { headers: header });
	}

	getUserFinancialDetail(userId: number) {
		return this.http.get<any>(`${this.apiUrl}/users/userFinancialDetail/${userId}`);
	}

	getUsersTreeList(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			//'Accept'       : 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<User[]>(`${this.apiUrl}/common/GetUsersTreeList?userId=` + userId, { headers: header });
	}

	getMyUsersListPage(userId: number, pageNo: number, pageSize: Number, sortOrder: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<User[]>(`${this.apiUrl}/common/GetMyUsersListPage?userId=` + userId + '&pageNo=' + pageNo + '&pageSize=' + pageSize + '&sortOrder=' + sortOrder, { headers: header });
	}

	getMyUsersListCount(userId: number, sortOrder: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<any>(`${this.apiUrl}/common/GetMyUsersListCount?userId=` + userId + '&sortOrder=' + sortOrder, { headers: header });
	}

	getUsersTreeListPage(userId: number, pageNo: number, pageSize: Number, sortOrder: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<User[]>(`${this.apiUrl}/common/GetUsersTreeListPage?userId=` + userId + '&pageNo=' + pageNo + '&pageSize=' + pageSize + '&sortOrder=' + sortOrder, { headers: header });
	}

	getUsersTreeListCount(userId: number, sortOrder: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<any>(`${this.apiUrl}/common/GetUsersTreeListCount?userId=` + userId + '&sortOrder=' + sortOrder, { headers: header });
	}

	GetUsersList(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<User[]>(`${this.apiUrl}/common/GetUsersList?userId=` + userId, { headers: header });
	}

	GetJurisdictionChildren(jurisdictionId: number, jurisdictionLevel: number) {
		// const header = new HttpHeaders({
		// 	'Content-type': 'application/json',
		// 	'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		// });
		return this.http.get<any>(`${this.apiUrl}/jurisdictions/GetJurisdictionChildren?jurisdictionId=` + jurisdictionId + "&jurisdictionLevel=" + jurisdictionLevel);
	}

	GetUserAssist(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<userAssistant>(`${this.apiUrl}/common/GetUsersAssis?userId=` + userId, { headers: header });
	}

	GetUsersAssisList() {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<userAssistant[]>(`${this.apiUrl}/common/GetUsersAssistantList`, { headers: header });
	}

	GetJurisdictionUsersList(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<User[]>(`${this.apiUrl}/common/GetJurisdictionUsersList?userId=` + userId, { headers: header });
	}

	GetUserByJurisdiction(jurisdictionId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<User[]>(`${this.apiUrl}/common/GetUserByJurisdiction?jurisdictionId=` + jurisdictionId, { headers: header });
	}

	getJurisdictions(jurisdictionId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<User[]>(`${this.apiUrl}/common/getJurisdictions?jurisdictionId=` + jurisdictionId, { headers: header });
	}

	getJurisdictionsAbovePlayer(jurisdictionId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<User[]>(`${this.apiUrl}/common/getJurisdictionsAbovePlayer?jurisdictionId=` + jurisdictionId, { headers: header });
	}

	getLanguages() {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<Language[]>(`${this.apiUrl}/Languages`, { headers: header });
	}

	GetJurisdictionLevelByUser(userId: number, jurisditicionLevel: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<User[]>(`${this.apiUrl}/common/GetJurisdictionLevelByUser?userId=` + userId + '&jurisdictionLevel=' + jurisditicionLevel, { headers: header });
	}

	getJurisdictionsById(jurisdictionId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<Jurisdiction[]>(`${this.apiUrl}/common/GetJurisdictionsById?jurisdictionId=` + jurisdictionId, { headers: header });
	}

	updateUserCredit(fromUserId: number, credit: number, transactionType: number, toUserId: number = 0, uuid8: string = "") {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		//console.log(`${this.interactionService.loginData.value.token}`);

		return this.http.put<any>(`${this.apiUrl}/common/UpdateCreditTransfer/?fromUserId=` + fromUserId + '&toUserId=' + toUserId + '&credit=' + credit + '&transactionType=' + transactionType + '&bet_uuid8=' + uuid8, {}, { headers: header });
	}
	addUserCredit(fromUserId: number, credit: Number, transactionType: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		//console.log(`${this.interactionService.loginData.value.token}`);

		return this.http.put<any>(`${this.apiUrl}/common/AddUserCredit/?fromUserId=` + fromUserId + '&credit=' + credit + '&transactionType=' + transactionType, { headers: header });
	}


	updateUser(userId: number, user: User) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.put<User>(this.apiUrl + '/users/' + userId, user, { headers: header });
	}

	updateUserAssistant(userId: number, userAssistant: userAssistant) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		let jsonToPass = {
			"Id": userAssistant.id,
			"Name": userAssistant.name,
			"UserName": userAssistant.userName,
			"Email": userAssistant.email,
			"Password": userAssistant.password,
			"Type": userAssistant.type,
			"AdminParentId": userAssistant.adminParentID,
			"Active": userAssistant.active,
			"Permits": userAssistant.permits
		}

		return this.http.put<userAssistant>(this.apiUrl + '/UserAssistants/' + userId, jsonToPass, { headers: header });
	}

	addUserAssistant(userAssistant: userAssistant) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		let jsonToPass = {
			"Id": userAssistant.id,
			"Name": userAssistant.name,
			"UserName": userAssistant.userName,
			"Email": userAssistant.email,
			"Password": userAssistant.password,
			"Type": userAssistant.type,
			"AdminParentId": userAssistant.adminParentID,
			"Active": userAssistant.active,
			"Permits": userAssistant.permits
		}

		return this.http.post<userAssistant>(this.apiUrl + '/UserAssistants/', jsonToPass, { headers: header });
	}

	getUserAssistantLogsHistory(parentId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get(this.apiUrl + '/UserAssistantLogs/getLoginHistory?parentId=' + parentId, { headers: header })
	}

	getJackpot() {
		return this.http.get<Jackpot[]>(`${this.apiUrl}/jackpot/`);
	}

	deleteJackpotRemaining(roundId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.put<Jackpot>(`${this.apiUrl}/jackpot/` + roundId, { headers: header });
	}

	deleteAllRelatedRound(roundId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.put<Round>(`${this.apiUrl}/common/` + roundId, { headers: header });
	}

	getPrize() {
		return this.http.get<Prize[]>(`${this.apiUrl}/prizes/`);
	}

	updateQuote(quote: string, coin: string, currencySymbol: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		return this.http.put<any>(`${this.apiUrl}/common/UpdateQuote/?quote=` + quote + "&coin=" + coin + "&currencySymbol=" + currencySymbol, { headers: header });


	}

	getUpdateQuote(country: string) {
		return this.http.get<any>(`${this.apiUrl}/common/GetUpdateQuote/?country=` + country);
	}
	getUpdateQuoteBRL(country: string) {
		return this.http.get<any>(`${this.apiUrl}/common/getUpdateQuoteBRL/?country=` + country);
	}
	GetQuote(country: string) {
		return this.http.get<any>(`${this.apiUrl}/common/GetQuote/?country=` + country);
	}
	GetQuoteBRL(country: string) {
		return this.http.get<any>(`${this.apiUrl}/common/GetQuoteBRL/?country=` + country);
	}

	GetCountryCurrency(name: string) {

		return this.http.get<any>(`${this.apiUrl}/common/GetCountryCurrency/?name=` + name);

	}

	AddCountry(currencyId: string, name: string, currencySymbol: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.put<any>(`${this.apiUrl}/common/AddCountry/?currencyId=` + currencyId + "&name=" + name + "&currencySymbol=" + currencySymbol, { headers: header });


	}

	GetCountry() {
		return this.http.get<any>(`${this.apiUrl}/common/GetCountry/`);
	}

	GetCurrencyUser(Id: number) {
		return this.http.get<any>(`${this.apiUrl}/common/GetCurrencyUser/?Id=` + Id);
	}

	getRoundJackpot(roundId: number) {
		return this.http.get<Prize>(`${this.apiUrl}/common/GetRoundJackpot/?roundId=` + roundId);
	}

	updateRoundJackpot(newJackpot: Prize) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.put<Prize[]>(this.apiUrl + '/common/UpdateRoundJackpot/?roundId=' + newJackpot.roundId, newJackpot, { headers: header });
	}

	updateJackpot(newJackpot: Prize) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		return this.http.put<Prize>(this.apiUrl + '/prizes/' + newJackpot.id, newJackpot, { headers: header });
	}

	updateApportionment(newApportionment: Prize) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		return this.http.put<Prize>(this.apiUrl + '/prizes/' + newApportionment.id, newApportionment, { headers: header });
	}

	getRounds() {
		return this.http.get<Round[]>(`${this.apiUrl}/rounds/`);
	}

	getActivatedRoundGroups() {
		return this.http.get<RoundGroup[]>(`${this.apiUrl}/roundgroups/`);
	}

	getAllRoundGroups() {
		//return this.http.get<RoundGroup[]>(`${this.apiUrl}/common/GetAllRoundGroups/`);
		return this.http.get<RoundGroup[]>(`${this.apiUrl}/common/GetRoundGroupTreeList/`);
		//return this.http.get<RoundGroup[]>(`${this.apiUrl}/roundgroups/`);
	}

	addRoundGroup(newRoundGroup: string, jurisdictionFather: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<RoundGroup>(this.apiUrl + '/common/AddRoundGroup/?newRoundGroup=' + newRoundGroup + "&jurisdictionFather=" + jurisdictionFather, {}, { headers: header });
	}

	editRoundGroup(currentGroup: RoundGroup) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.put<RoundGroup>(`${this.apiUrl}/roundgroups/` + currentGroup.id, currentGroup, { headers: header });
	}

	deleteRoundGroup(groupId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.delete<RoundGroup>(`${this.apiUrl}/roundgroups/` + groupId, { headers: header });
	}

	getListedRoundGroups() {
		return this.http.get<RoundGroup[]>(`${this.apiUrl}/common/GetListedAllRoundGroups/`);
	}

	getRoundGroupRound() {
		return this.http.get<any[]>(`${this.apiUrl}/common/GetRoundGroupRound/`);
	}

	getLastRound() {
		return this.http.get<Round>(`${this.apiUrl}/common/getlastround`);
	}

	getLastActivatedRound() {
		return this.http.get<Round>(`${this.apiUrl}/common/getlastactivatedround`);
	}

	getRound(roundId: number) {
		return this.http.get<Round>(`${this.apiUrl}/rounds/` + roundId);
	}


	getRoundsFinished() {
		return this.http.get<Round[]>(`${this.apiUrl}/common/GetRoundFinished`);
	}

	// test 2 result
	getChampionshipsRounds() {
		return this.http.get<Round[]>(`${this.apiUrl}/common/GetChampionshipsRounds`);
	}

	getRoundsOpended() {
		return this.http.get<Round[]>(`${this.apiUrl}/common/GetRoundOpened`);
	}

	getRoundsPosted() {
		return this.http.get<Round[]>(`${this.apiUrl}/common/GetRoundPosted`);
	}

	getChampionshipRounds() {
		return this.http.get<Round[]>(`${this.apiUrl}/common/GetChampionshipRounds`);
	}

	GetManageChampionshipRounds() {
		return this.http.get<Round[]>(`${this.apiUrl}/common/GetManageChampionshipRounds?creatorId=${parseInt(localStorage.getItem("creatorId")!)}`);
	}

	createRound(groupParent: number, roundName: string, newJackpot: number, roundValue: number, parentId: number, newGames: Game[], two_options: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		var postedResul = this.http.post<string>(`${this.apiUrl}/common/PostNewRound/?groupParent=` + groupParent +
			'&roundName=' + roundName + '&newJackpot=' + newJackpot + '&roundValue=' + roundValue + '&parentId=' + parentId + '&two_options=' + two_options,
			newGames, { headers: header });


		return postedResul;
	}

	postResult(games: Game[]) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<any>(`${this.apiUrl}/common/PostResult/`, games, { headers: header });
	}

	getBet(betId: string) {
		return this.http.get<Bet>(`${this.apiUrl}/bets/` + betId);
	}

	getBets(roundId: number) {
		return this.http.get<Bet[]>(`${this.apiUrl}/common/GetActivatedBetsByRound/?roundId=` + roundId);
	}

	getBetsByUserTree(userAdminId: number, roundId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<Bet[]>(`${this.apiUrl}/common/GetBetsByUserTree/?userAdminId=` + userAdminId + "&roundId=" + roundId, { headers: header });
	}

	getAllBets(roundId: number) {
		return this.http.get<Bet[]>(`${this.apiUrl}/common/GetBetsByRound/?roundId=` + roundId);
	}

	getLastUserBet(userId: number) {
		return this.http.get<Bet>(`${this.apiUrl}/common/GetLastUserBet/?userId=` + userId);
	}

	postBet(bet: any) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		let jsonToPass = {
			"betDate": bet.date,
			"resultBetId": 1,
			"playerId": 1,
			"playerName": bet.playerName,
			"roundId": bet.roundId,
			"userAdminId": bet.userId,
			"results": bet.resultToPass,
			"status": 1,
			"value": bet.value
		}

		return this.http.post<Bet>(this.apiUrl + '/bets/', jsonToPass, { headers: header });
	}

	postTicket(bet: Ticket) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		let results: string = "";
		bet.matches.forEach(element => {
			results += element.matchResult + "|";
		});

		results = results.substring(0, results.length - 1);

		let jsonToPass = {
			"betDate": bet.date,
			"resultBetId": 1,
			"playerId": 1,
			"playerName": bet.playerName,
			"roundId": bet.round.id,
			"userAdminId": bet.userAdmin.id,
			"results": results,
			"status": 1,
			"value": bet.finalValue
		}

		return this.http.post<Bet>(this.apiUrl + '/bets/', jsonToPass, { headers: header });
	}

	updateBet(bet: Bet) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		let jsonToPass = {
			"id": bet.id,
			"betDate": bet.betDate,
			"resultBetId": bet.resultBetId,
			"playerId": bet.playerId,
			"playerName": bet.playerName,
			"roundId": bet.roundId,
			"userAdminId": bet.userAdminId,
			"results": bet.results,
			"value": bet.value
		}

		return this.http.put<Bet>(`${this.apiUrl}/bets/` + bet.id, bet, { headers: header });
	}

	getGames(roundId: number) {
		return this.http.get<Game[]>(`${this.apiUrl}/common/GetMatchesByRound/?roundId=` + roundId);
	}

	createGame(newGame: Game) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		let jsonToPass = {
			"betradarMatchId": newGame.betradarMatchId,
			"HomeName": newGame.homeName,
			//"AwayTeamId" : newGame.awayTeamId,
			"AwayName": newGame.awayName,
			"RoundId": newGame.roundId,
			"BetradarMatchId": 0,
			"AwayTeamScore": 0,
			"DateTime": newGame.dateTime
		};

		return this.http.post<Game>(this.apiUrl + '/matches/', jsonToPass, { headers: header });
	}

	/*
	updateMatches(games: Array<Game>){
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});
		return this.http.put<Game[]>(`${this.apiUrl}/matches/UpdateMatches/`, games, { headers: header });
	}
	*/

	listRoundWinners(roundId: number) {
		return this.http.get<any[]>(`${this.apiUrl}/common/GetWinnersByRound/?roundId=` + roundId);
	}

	addJurisdiction(newJurisdiction: Jurisdiction) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		return this.http.post<Jurisdiction>(this.apiUrl + '/jurisdictions/', newJurisdiction, { headers: header });
	}

	addNewUser(user: UserLoginForm) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<Jurisdiction>(`${this.apiUrl}/users/`, user, { headers: header });
	}

	createUser(user: User, jurisdictionCoupon: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		return this.http.post<User>(`${this.apiUrl}/users/?jurisdictionCoupon=` + jurisdictionCoupon, user, { headers: header });
	}

	sendPasswordToEmail(name: string, login: string, email: string, password: string) {
		return this.http.get(`${this.apiUrl}/common/SendPasswordMail/?name=` + name + "&login=" + login + "&email=" + email + "&password=" + password);
	}

	sendRecoveryPassword(email: string) {
		return this.http.get(`${this.apiUrl}/common/RecoveryPasswordMail/?email=` + email);
	}

	removeUser(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.delete<User>(`${this.apiUrl}/users/` + userId, { headers: header });
	}

	removeUserAssistant(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.delete<User>(`${this.apiUrl}/UserAssistants/` + userId, { headers: header });
	}

	sendContactMessage(contact: any) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		//onsole.log("nome: " + contact.name + " - email: " + contact.email + " - assunto: " + contact.subject + " - msg: " + contact.message);

		return this.http.get(`${this.apiUrl}/common/SendContactMessage/?name=` + contact.name +
			"&email=" + contact.email + "&subject=" + contact.subject + "&message=" + contact.message, { headers: header });
	}

	getWhatsappNumbers() {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<WhatsappLogs[]>(`${this.apiUrl}/common/GetWhatsappNumbers`, { headers: header });
		//return this.http.get<WhatsappLogs[]>(`${this.apiUrl}/WhatsappLogs/`);
	}

	cancelRound(round: number, userObj: UserIdentification) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<any>(`${this.apiUrl}/common/CancelRound?RoundId=${round}`, userObj, { headers: header });
	}

	getUnreadMessages() {
		return this.http.get<Contact[]>(`${this.apiUrl}/contacts/`);
	}

	getReport(filter: ReportFilter) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<Report[]>(`${this.apiUrl}/common/GetReport/`, filter, { headers: header });
	}
	getTransactionsFilter(filter: Transactionsfilter) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<Transaction[]>(`${this.apiUrl}/common/GetTransactionsFilter/`, filter, { headers: header });
	}

	getTransactionsFilterByJurisdiction(filter) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<[]>(`${this.apiUrl}/common/getTransactionsFilterByJurisdiction/`, filter, { headers: header });
	}

	getFinancialActivityFilter(filter) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<[]>(`${this.apiUrl}/common/getFinancialActivityFilter/`, filter, { headers: header });
	}

	getUserAssistantFilter(filter) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.post<[]>(`${this.apiUrl}/common/getUserAssistantFilter/`, filter, { headers: header });
	}


	postBanner(banner: FormData, userId: number, title: string, subtitle: string) {
		const header = new HttpHeaders({
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post(`${this.apiUrl}/common/SendSlider?userId=` + userId +
			"&title=" + title + "&subtitle=" + subtitle, banner, { headers: header });
	}

	postSlides(slide: FormData, userId: number) {
		const header = new HttpHeaders({
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.post(`${this.apiUrl}/common/v2/SendSlides?userId=` + userId, slide, { headers: header });
	}

	updateSlides(slide: FormData, slideId: number) {
		const header = new HttpHeaders({
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.post(`${this.apiUrl}/common/UpdateSlides?slideId=` + slideId, slide, { headers: header });
	}

	changeSlidesOrder(userId: number, lanId: number, type: string, prevIdx: number, curIdx: number) {
		const header = new HttpHeaders({
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.post<Slides[]>(`${this.apiUrl}/common/ChangeSlidesOrder?userId=` + userId +
			"&lanId=" + lanId + "&type=" + type + "&prevIdx=" + prevIdx + "&curIdx=" + curIdx, null, { headers: header });
	}

	postDocument(docInfo: FormData, userId: number, title: number) {
		const header = new HttpHeaders({
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post(`${this.apiUrl}/common/SendDocument?userId=` + userId +
			"&title=" + title, docInfo, { headers: header });
	}

	getDocument(userId: number): Observable<DocumentResponseDto[]> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<DocumentResponseDto[]>(`${this.apiUrl}/common/GetDocument?userId=` + userId, { headers: header });
	}

	GetDocumentPending(): Observable<DocumentResponseDto[]> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<DocumentResponseDto[]>(`${this.apiUrl}/common/GetDocumentPending?status=0`, { headers: header });
	}

	GetWithdrawPending(): Observable<Withdraw[]> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<Withdraw[]>(`${this.apiUrl}/common/GetWithdrawPending?status=0`, { headers: header });
	}

	getUserPendingNotifications(userId: number, userLogin: string): Observable<number> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<number>(`${this.apiUrl}/common/GetUserNotifications?userId=${userId}&status=0&loggin=${userLogin}`, { headers: header });
	}

	setNotificationCount(userid: number, userLogin: string) {
		this.getUserPendingNotifications(userid, userLogin).subscribe((data: number) => {
			this.notificationSource.next(data);
		});
	}
	getNotificationCount() {
		return this.notifications;
	}

	setPendingDocCount(userid: number, loggin: string) {
		this.GetPendingUserDocument(userid, loggin).subscribe((data: number) => {
			this.pendingDocSource.next(data);
		});
	}
	setTotalPendingDocCount(userid: number, loggin: string) {
		this.GetPendingDocuments(userid, loggin).subscribe((data) => {
			this.pendingDocSource.next(data.length);
			this.setNotificationCount(userid, loggin)
		});
	}
	getPendingDocCount() {
		return this.pendingDocs;
	}

	setPendingWithdrawal(userid: number) {
		this.GetPendingUserWithdrawals(userid).subscribe((data: number) => {
			this.pendingWithdrawalSource.next(data);
		});
	}
	getPendingWithdrawal() {
		return this.pendingWithdrawls;
	}

	GetPendingUserDocument(userId: number, loggin: string): Observable<number> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<number>(`${this.apiUrl}/common/GetPendingUserDocument?userId=${userId}&status=0&loggin=${loggin}`, { headers: header });
	}

	GetPendingDocuments(userId: number, loggin: string): Observable<PlayerDocs[]> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<PlayerDocs[]>(`${this.apiUrl}/common/GetPendingDocument?userId=${userId}&status=0&loggin=${loggin}`, { headers: header });
	}

	GetPendingUserWithdrawals(userId: number): Observable<number> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.get<number>(`${this.apiUrl}/common/GetPendingUserWithdraw?userId=${userId}&status=0`, { headers: header });
	}

	getDocumentCount(userId: number): Observable<number> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<number>(`${this.apiUrl}/common/getDocumentCount?userId=` + userId, { headers: header });
	}

	getWithdraw(userId: number): Observable<Withdraw[]> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<Withdraw[]>(`${this.apiUrl}/common/GetWithdraw?userId=` + userId, { headers: header });
	}

	getWithdrawFilter(filter): Observable<Withdraw[]> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.post<[]>(`${this.apiUrl}/common/getWithdrawFilter/`, filter, { headers: header });
	}

	updateDocument(userId: number, docId: number, status: number, description: string): Observable<DocumentResponseDto[]> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.put<DocumentResponseDto[]>(`${this.apiUrl}/common/updateDocument?userId=` + userId + `&docId=` + docId + `&status=` + status + `&description=` + description, {}, { headers: header });
	}

	UpdateUserBankInfo(userId: number, bank: string, agency: string, account: string): Observable<User> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.put<User>(`${this.apiUrl}/common/UpdateUserBankInfo?userId=` + userId + `&bank=` + bank + `&agency=` + agency + `&account=` + account, {}, { headers: header });
	}

	postWithdraw(userId: number, amount: number): Observable<number> {
		const header = new HttpHeaders({
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<number>(`${this.apiUrl}/common/SendWithdraw/?userId=` + userId +
			"&amount=" + amount, {}, { headers: header });
	}

	updateWithdraw(userId: number, widthdrawId: number, status: number, description: string): Observable<Withdraw[]> {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.put<Withdraw[]>(`${this.apiUrl}/common/updateWithdraw?userId=` + userId + `&widthdrawId=` + widthdrawId + `&status=` + status + `&description=` + description, {}, { headers: header });
	}

	/*
	getBanners(){
		return this.http.get<any[]>(`${this.apiUrl}/banners/`);
	}
	*/
	getMasterParent(userId: number) {
		return this.http.get<User>(`${this.apiUrl}/common/GetMasterParent/?userId=` + userId);
	}

	getOwnBanners(userId: number) {

		return this.http.get<Banner[]>(`${this.apiUrl}/common/GetOwnSliders/?userId=` + userId);
	}

	getOwnSlides(userId: number, languageId: number, type: string) {
		return this.http.get<Slides[]>(`${this.apiUrl}/common/GetOwnSlides/?userId=` + userId + "&languageId=" + languageId + "&type=" + type);
	}

	saveSlidesState(userId: number, languageId: number, type: string, slides: Slides[]) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post(`${this.apiUrl}/common/SaveSlidesState/?userId=` + userId + "&languageId=" +
			+ languageId + "&type=" + type, slides, { headers: header });
	}

	saveBannerState(userId: number, banners: Banner[]) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post(`${this.apiUrl}/common/SaveSlidersState/?userId=` + userId, banners, { headers: header });
	}

	deleteBanner(bannerId: number, userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.delete(`${this.apiUrl}/banners/` + bannerId + '/?userId=' + userId, { headers: header });
	}

	putSlide(slideId: number, userId: number, slide: Slides) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.put(`${this.apiUrl}/slides/` + slideId + '/?userId=' + userId, slide, { headers: header });
	}

	deleteSlide(slideId: number, userId: number) {

		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.delete(`${this.apiUrl}/slides/` + slideId + '/?userId=' + userId, { headers: header });
	}

	getFeedMatches() {
		return this.http.get(`${this.apiUrl}/common/GetFeedMatches/`);
	}

	GetBetradarMatches(idCompetition: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get(`${this.apiUrl}/common/GetBetradarMatches/?idCompetition=` + idCompetition, { headers: header });
	}

	getBetRadarCompetitions() {
		return this.http.get('../../assets/data/betradar-list.json');
	}

	getBetRadarResults(games: Game[]) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post<Game[]>(`${this.apiUrl}/common/GetBetradarResults/`, games, { headers: header });
	}

	getTransactionsByUserId(userId, activeLang) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<Transaction[]>(`${this.apiUrl}/common/GetTransactions/?userId=` + userId + "&activeLang=" + activeLang, { headers: header });
	}

	registerAPIUser(login, email, credit, parentId, key) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${key}`
			//'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFkbWluIiwiSnVyaXNkaWN0aW9uIjoiYWRtaW4iLCJuYmYiOjE1OTI5MjMwMDYsImV4cCI6MTU5MzUyNzgwNiwiaWF0IjoxNTkyOTIzMDA2fQ.gRdOAXV9BSv9owmD7Uz8O4E8damIo_RtdDR1-LQkRRs`
		});

		return this.http.post(`${this.apiUrl}/common/RegisterAPIUser/?login=` + login +
			'&email=' + email + '&credit=' + credit + '&parentId=' + parentId, {}, { headers: header });
	}

	requestCredit(userId: number, requestedCredit: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get(`${this.apiUrl}/common/RequestCredit/?userId=` + userId +
			"&requestedCredit=" + requestedCredit, { headers: header });
	}

	getCoupon(userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<JurisdictionCoupon>(`${this.apiUrl}/common/GetCouponByJurisdictionId/?userId=` + userId, { headers: header });
	}

	generateJurisdictionCoupon() {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.get<JurisdictionCoupon>(`${this.apiUrl}/common/GenerateJurisdictionCoupon/`, { headers: header });
	}

	saveJurisdictionCoupon(userId: number, coupon: string, availableValue: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		let jsonToPass: JurisdictionCoupon = {
			"id": 0,
			"code": coupon,
			"availableValue": availableValue,
			"jurisdictionId": userId
		};

		return this.http.post<JurisdictionCoupon>(`${this.apiUrl}/common/SaveJurisdictionCoupon/`, jsonToPass, { headers: header });
	}

	getCurrencyQuote() {
		return this.http.get('https://economia.awesomeapi.com.br/json/all');
	}

	createGerencianetTransaction(creditValue: number, userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post(`${this.apiUrl}/common/CreateGerencianetTransaction/?creditValue=` + creditValue + '&userId=' + userId, { headers: header });
	}

	generateLinkGerencianet(charge_id: number, defaultMessage: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post(`${this.apiUrl}/common/GenerateLinkGerencianet/?charge_id=` + charge_id + "&defaultMessage=" + defaultMessage, { headers: header });
	}

	checkoutPagseguro(creditValue: number, userId: number) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post(`${this.apiUrl}/common/CheckoutPagseguro/?creditValue=` + creditValue + '&userId=' + userId, { headers: header });
	}

	linkPagseguro(checkoutCode: string) {
		/*const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});

		return this.http.post(`${this.apiUrl}/common/CheckoutPagseguro/?creditValue=` + creditValue + '&userId=' + userId, { headers: header} );
	*/
	}

	updatePermissions(userid, jurisdiction) {
		return this.http.put<any>(`${this.apiUrl}/permissions/` + userid, jurisdiction);
	}

	updatePassword(id, oldpassword, password, login) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		let jsonToPass = {
			"Id": id,
			"Name": '',
			"LastName": '',
			"Email": '',
			"Login": login,
			"Password": password,
			"oldPassword": oldpassword,
			"Deleted": false,
			"Commission": 0,
			"ParentId": 0,
			"JurisdictionId": 0,
			"Country": '',
			"City": '',
			"Credit": 0,
			"Permits": null,
			"isPassword": true
		}
		return this.http.put<any>(`${this.apiUrl}/users/` + id, jsonToPass, { headers: header });
	}

	updateUserAssistantPassword(changePasswordObj: UserAssistantPasswordChange) {
		const header = new HttpHeaders({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
		});
		return this.http.put<any>(`${this.apiUrl}/UserAssistants/changePassword/` + changePasswordObj.Id, changePasswordObj, { headers: header });
	}

	getAllLoginHistory() {
		return this.http.get<LoginHistory[]>(this.apiUrl + '/LoginHistory');
	}

	getLoginHistories(userId: number, userUserName: string) {
		return this.http.get<LoginHistory[]>(this.apiUrl + '/LoginHistory/GetLoginHistories?userId=' + userId + '&userUserName=' + userUserName);
	}
	/*
		getLastUserBet(userId: number){
			return this.http.get<Bet>(`${this.apiUrl}/common/GetLastUserBet/?userId=` + userId);
		} */

	postLoginHistory(loginHistory: any, username: string) {
		const header = new HttpHeaders({
			'Content-type': 'application/json'
		});

		let jsonToPass = {
			"Status": loginHistory.status,
			"UserId": loginHistory.userId,
			"IpAddress": loginHistory.ipAddress,
		}

		return this.http.post<LoginHistory>(this.apiUrl + '/LoginHistory/' + username, jsonToPass, { headers: header });
	}

	getIPAddress() {
		//local test
		return axios.get('https://api.ipify.org/?format=json');
	}

	getCountry(ipaddress: any) {
		//local test
		return axios.get('https://api.ipfind.com?ip=' + ipaddress + '&auth=7e2a21d0-0e27-44b6-aa06-8a0d015e266c');
	}

	setGlobalCurrency() {
		var currency = localStorage.getItem('currency');

		currency = currency == undefined ? "Brazil" : currency;

		if (currency.toLowerCase() == "brazil" || currency == undefined) {
			this.Currency.coin = 'BRL_BRL';
			this.Currency.quote = 1;
			this.Currency.currencySymbol = 'R$';
			this.Currency.country = 'Brazil';
			this.Currency.currency = 'BRL';
			this.interactionService.setGlobalCountry(this.Currency);
			return
		}

		this.GetCountryCurrency(currency)
			.subscribe(data => {
				var DataURL: string = 'https://economia.awesomeapi.com.br/json/BRL-' + data.currencyId;
				//this.http.get('https://free.currconv.com/api/v7/convert?q=BRL_' + data.currencyId + '&apiKey=085a5c4bbbd6e6750bfc')

				this.http.get(DataURL)
					.subscribe((obj: any) => {
						var Eco = JSON.stringify(obj)
						Eco = Eco.replace("[", "").replace("]", "")
						let response: any = JSON.parse(Eco)
						if (response /*&& response.data*/) {
							//var price = (response.data.results['BRL_' + data.currencyId].val);
							var price = parseFloat(response.ask);
							//var price = (response.data.results['BRL_' + data.currencyId].val);
							this.Currency.quote = price;
							this.Currency.coin = 'BRL_' + data.currencyId;
							this.Currency.currencySymbol = data.currencySymbol;
							this.Currency.currency = data.currencyId;
							this.interactionService.setGlobalCountry(this.Currency);
							return;
						}
					}, error => {
						this.Currency.coin = 'BRL_BRL';
						this.Currency.quote = 1;
						this.Currency.currencySymbol = 'R$';
						this.Currency.country = 'Brazil';
						this.Currency.currency = 'BRL';
						this.interactionService.setGlobalCountry(this.Currency);
						return;
					});
			});
		this.Currency.coin = 'BRL_BRL';
		this.Currency.quote = 1;
		this.Currency.currencySymbol = 'R$';
		this.Currency.country = 'Brazil';
		this.Currency.currency = 'BRL';
		this.interactionService.setGlobalCountry(this.Currency);
	}

	RecoveryCasinoAndNationalLoginandId(){
		return this.http.get<User[]>(`${this.apiUrl}/users/RecoveryCasinoAndNationalLoginandId`);
	}
}
