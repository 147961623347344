import { Transaction } from './../interface/transaction.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InteractionService } from './interaction.service';
import { IpConfigService } from './Ip-Config.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

apiUrl = ''; // local test
  constructor(private http: HttpClient,	private interactionService: InteractionService, private ipConfigService: IpConfigService) {
      this.apiUrl = ipConfigService.getApiUrl(); }

    // tslint:disable-next-line:typedef
    getTransactions() {
      return this.http.get<Transaction[]>(this.apiUrl + '/transactions');
    }

    getTransaction(transactionId: number) {
      return this.http.get<Transaction[]>(this.apiUrl + '/transactions/' + transactionId);
    }

    addTransaction(transaction: Transaction){
      return this.http.post<any>(`${this.apiUrl}/transactions/`, transaction);
    }

    updateTransaction(transactionId: number, transaction: Transaction){
      return this.http.put<any>(`${this.apiUrl}/transactions/` + transactionId, transaction);
    }

    deleteTransaction(transactionId: number, transaction: Transaction){
      return this.http.delete<any>(`${this.apiUrl}/transactions/` + transactionId);
    }
}
