import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective } from 'angular-bootstrap-md';
import { CouponServiceService } from 'src/app/services/coupon-service.service';
import { Round } from 'src/app/interface/round.interface';
import { Bet } from 'src/app/interface/bet.interface';
import { User } from 'src/app/interface/user.interface';
import { LoginService } from 'src/app/services/login.service';
import {DatePipe} from '@angular/common';
import { Router } from '@angular/router';
import { Currency } from 'src/app/interface/currency.interface';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.css']
})
export class CouponDetailComponent implements OnInit {
  @ViewChild('frame3', { static: true }) modalCancel: ModalDirective;
  @ViewChild('frame3', {static: true}) modalRegister: ModalDirective;
  @ViewChild('frame2', { static: true }) modalTicket: ModalDirective;
  @ViewChild('frame4', {static: true}) modalWarning: ModalDirective;
  @ViewChild('frameMessage', {static: true}) modalMessages: ModalDirective;
  @ViewChild('frameMessageDanger', {static: true}) modalMessagesDanger: ModalDirective;
  @ViewChild('frameMessageCredit', {static: true}) modalMessagesCredit: ModalDirective;
  @ViewChild('frameMessageBetting', {static: true}) modalMessagesBetting: ModalDirective;

  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];
  showCloseBtn: boolean = false;
  showCloseRoundBtn: boolean = false;
  msgToAdd: string = '';
  titleToAdd: string = '';
  viewTicket: string;
  userNameToShow = new Array<string>();
  Currency: Currency = <Currency>{};
  userAdmin: any;
  round: Round;
  bets: Bet[] = [];
  users: User[] = [];
  indexActivated: number;
  constructor(private couponService:CouponServiceService,
    private translate: TranslateService,private cookieService: CookieService,
    private datePipe: DatePipe, private router: Router,
    private loginService:LoginService,
    private interactionService:InteractionService) {
      this.userAdmin = loginService.getUserInfoFromCookie();
    }

  ngOnInit(): void {
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  couponDetail(ticketCode) {

    this.couponService.getBet(ticketCode)
      .subscribe(bet => {
        if (bet == null) {
          this.msgStandard(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), 4);
        }
        else {
          //this.configService.getUser(bet.userAdminId)
          let rankResult = [];
          this.couponService.getRoundById(bet.roundId)
            .subscribe(round => {
              if (round && round.rankRound && round.rankResult)
                rankResult = this.round.rankResult.split('|');
            });
          this.couponService.getBasicUser(bet.userAdminId)
            .subscribe(user => {
              /*let stringTable = "<table border='1'><tr><td>" + this.translate.instant("pg-result.ticket") + "</td><td>" + this.translate.instant("pg-tickets.operator") + "</td><td>" + this.translate.instant("pg-tickets.player") + "</td><td>" + this.translate.instant("pg-tickets.results") + "</td></tr>" +
                "<tr><td>" + ticketCode + "</td><td>" + user.login + "</td><td>" + bet.playerName + "</td>" +
                "<td>" + bet.results + "</td></tr>" +  "<td>" + user.country + "</td></tr>" + 
                "</table>";*/
                this.couponService.getGames(bet.roundId)
                .subscribe(matchs => {
                  let results = bet.results.split("|");
                  let total_hit = 0;
                  let total_cancel = 0;
                  if (bet.rankRound && results.length) {
                    rankResult.forEach((element, i) => {
                      if (results[i] == element) {
                        total_hit++;
                      }
                    });
                  }
                  matchs.forEach((element, i) => {
                    if (element.status == 0) {
                      total_cancel++;
                    } else {
                      if (results[i].includes(element.matchResult) && !bet.rankRound) {
                        total_hit++;
                      }
                    }
                  });
                  let legend: string = "<div class='col-12'>";
                  legend += "<div class='row legend'><div class='col-3'><div class='running'></div>&nbsp;" + this.translate.instant("pg-tickets.running") +"</div>";
                  legend += "<div class='col-3'><div class='hit'></div>&nbsp;" + this.translate.instant("pg-tickets.hit") +"</div>";
                  legend += "<div class='col-3'><div class='wrong'></div>&nbsp;" + this.translate.instant("pg-tickets.wrong") +"</div>";
                  legend += "<div class='col-3'><div class='cancel'></div>&nbsp;" + this.translate.instant("pg-tickets.cancelled") +"</div>";
                  legend += "</div></div>";

                  
                  let resultMats: string = "<div class='col-12 total-show'>" + /*legend + */
                    "<div class='col-12'><strong style='font-weight: bold;'>" + this.translate.instant("pg-round.matches") + "</strong>: " + matchs.length + 
                    "</div><div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translate.instant("pg-result.number-hits") + "</strong>: " + total_hit + 
                    "</div><div class='col-12 total-cancel'><strong style='font-weight: bold;'>" + this.translate.instant("pg-manage-ticket.canceled") + "</strong>: " + total_cancel + "</div></div>";
                  let count: number = 0;
                  if (bet.rankRound)
                    results.forEach((element, i) => {
                      if (i < rankResult.length && rankResult[i] == element) {
                        resultMats += "<div class='col-6 search-game-list correct'>" + element + "</div><div class='col-6 search-game-list correct'> " + rankResult[i] + "</div>";
                      } else if (i < rankResult.length && rankResult[i] != element) {
                        resultMats += "<div class='col-6 search-game-list no-hit'>" + element + "</div><div class='col-6 search-game-list no-hit'> " + rankResult[i] + "</div>";
                      }
                      else {
                        resultMats += "<div class='col-9 search-game-list'>" + element + "</div><div class='col-3 search-game-list'> " + (i + 1) + "°</div>";
                      }
                    });
                  else
                    matchs.forEach(element => {
                      if (element.status == 0) {
                        resultMats += "<div class='col-9 search-game-list cancelled'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list cancelled'> " + results[count] + "</div>";
                      } else {
                        if (element.matchResult == "0") {
                          resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list'> " + results[count] + "</div>";
                        } else {
                          if (results[count].includes(element.matchResult)) {
                            resultMats += "<div class='col-9 search-game-list correct'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list correct'> " + results[count] + "</div>";
                          } else {
                            resultMats += "<div class='col-9 search-game-list no-hit'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list no-hit'> " + results[count] + "</div>";
                          }
                        }
                      }
                      count++;
                      });
                  
                  let betDate = (new Date(bet.betDate)).getTime() - (new Date()).getTimezoneOffset() * 60000;
                  let stringTable = "<div class='row'>" +
                  "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-tickets.operator") + "</div>" +
                  "<div class='col-6 search-under-border'>" + user.login + "</div>" +
                  "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-tickets.player") + "</div>" +
                  "<div class='col-6 search-under-border'>" + bet.playerName + "</div>" +
                  "<div class='col-12 text-center search-head'>" + this.translate.instant("pg-round.matches") + "</div>" +
                  resultMats +
                  "<div class='col-6 mt-3 search-head search-under-border'>" + this.translate.instant("pg-result.ticket") + "</div>" +
                  "<div class='col-6 mt-3 search-under-border'>" + ticketCode + "</div>" +
                  "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-credit-transfer.value") + "</div>" +
                  "<div class='col-6 search-under-border'>" + this.couponService.formatCurrencyValue(bet.value) + "</div>" +
                  "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-post-result.date") + "</div>" +
                  "<div class='col-6 search-under-border'>" + this.datePipe.transform(betDate, 'MM/dd/y HH:mm') + "</div>" +
                  "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-jurisdiction.country") + "</div>" +
                  "<div class='col-6 search-under-border'>" + user.country + "</div>" +
                  "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-home.quantity-tickets") + "</div>" +
                  "<div class='col-6 search-under-border'>" + bet.quantity + "</div>" +
                  "</div>";
                  
                  this.msgStandard(this.translate.instant("pg-tickets.resume-ticket") + ticketCode, stringTable, 1);
                  //<HTMLInputElement>document.getElementById("code-search")).value = "";
                }, error => {
                  this.msgStandard(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), 4);
                });              
            }, error => {
              this.msgStandard(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), 4);
            });
        }
      }, error => {
        this.msgStandard(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), 4);
      });
  }



  msgStandard(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandard').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandard').classList.add(this.msgType[type]);
    }

    this.modalMessages.show();
  }

  msgStandardDanger(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardDanger').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardDanger').classList.add(this.msgType[type]);
    }

    this.modalMessagesDanger.show();
  }

  msgStandardCredit(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardCredit').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardCredit').classList.add(this.msgType[type]);
    }

    this.modalMessagesCredit.show();
  }

  msgStandardBetting(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardBetting').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardBetting').classList.add(this.msgType[type]);
    }

    this.modalMessagesCredit.show();
  }

  CreditRedirect() {
    this.modalMessagesCredit.hide();
    this.router.navigate(['/payment']);
    //location.replace("/payment");
  }

  BettingRedirect() {
    this.modalMessagesCredit.hide();
    this.router.navigate(['/home']);
    //location.replace("/home");
  }

  makeTicket(index: number, whichModal: number){
    this.indexActivated = index;
    
    let results: string = this.bets[index].results;

    let day = this.bets[index].betDate.toString().substring(8, 10);
    let month = this.bets[index].betDate.toString().substring(5, 7);
    let year = this.bets[index].betDate.toString().substring(0, 4);
    let hour = this.bets[index].betDate.toString().substring(11, 16);
    let completeDate: string = day + '/' + month + '/' + year;

    this.viewTicket = 
    this.translate.instant("pg-manage-ticket.code") + ': '+ this.bets[index].id + ' - ' + this.translate.instant("pg-manage-ticket.round") + ': ' + this.bets[index].roundId + '<br/>' +
    ' ' + this.translate.instant("pg-manage-ticket.date") + ': ' + completeDate + ' - ' + this.translate.instant("pg-manage-ticket.hour")+ ': ' + hour + '<br/>'+
    this.translate.instant("pg-manage-ticket.operator") + ': ' + this.userNameToShow[index] + ' - ' + this.translate.instant("pg-manage-ticket.player") + ': ' + this.bets[index].playerName + '<br/><br/>'+
    this.translate.instant("pg-manage-ticket.results") + ':<br/><br/>' + results;
    

    
   
    if (whichModal == 1){
      this.modalTicket.show();
    }
    else {
      this.modalCancel.show();
    }
  }


  cancelTicket(){
    // ATTENTION: using this field for a while just to make it work for now
    this.bets[this.indexActivated].resultBetId = this.userAdmin.id;

    this.couponService.updateBet(this.bets[this.indexActivated])
      .subscribe(data => {
        this.msgStandard(this.translate.instant("pg-manage-ticket.ticket-canceled"), this.translate.instant("pg-manage-ticket.ticket-canceled-info"), 3);
        this.bets[this.indexActivated].status = 0;
      }, error => {
        let msgComplement: string;
        if (error.error == "Time exceeded!"){
          msgComplement = this.translate.instant("pg-manage-ticket.time-exceeded");
        }

        if (error.error == "You're not the owner."){
          msgComplement = this.translate.instant("pg-manage-ticket.only-owner");
        }

        this.msgStandard(this.translate.instant("pg-manage-ticket.not-canceled"), this.translate.instant("pg-manage-ticket.not-canceled-info") + " " + msgComplement, 4);
      });

    this.modalCancel.hide();
  }

}
