import { Injectable, OnInit } from '@angular/core';
import { JwtToken } from '../interface/jwt-token'
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() {
  }

  private getToken(): any {
    return localStorage.getItem('currentUserToken');
  }

  decodePayloadJWT(): JwtToken {
    try {
       return jwt_decode.jwtDecode(this.getToken());
    } catch (Error) {
      return null;
    }
  }

  getUserFromToken(): any {
    let token = this.decodePayloadJWT();
    if (token)
      return JSON.parse(token.User);
  }

}
