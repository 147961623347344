<header class="navbar navbar-fixed-top">
  <span id="toggle_sidemenu_l" class="material-icons d-md-none" (click)="navbarToggle()" role="button">menu</span>
  <div class="navbar-branding  ml-md-5">
    <a class="d-none d-md-block" href="/home">
      <img src="../../../assets/images/brasilbolao-admin.png" class="adminLogoStyle" alt="logo" />
    </a>
  </div>
  <div class="navbar-left d-none d-xl-block">
    <div class="navbar-divider"></div>
    <div id="settings_menu">
      <i class="material-icons">schedule</i>
      <span class="current-time">{{currentTime}}</span>
    </div>
    <div id="my-language-menu">
      <app-language-switcher switchType="dropdown" class="ml-2"></app-language-switcher>
      <div class="currencyArea" (mouseover)="showCurrencyDropDown()">
        {{"Currency" | translate}}:
        <div class="currencyChoiceArea">
          <div class="activeCurrency">
            {{Currency.currency}}
          </div>
          <div class="currencyDropdown">
            <p *ngIf="Currency.currency != 'EUR'" (click)="setCurrency('Italy')" class="currencyOption mb-1">
              EUR
            </p>

            <p *ngIf="Currency.currency != 'USD'" (click)="setCurrency('United States of America')" class="currencyOption mb-1">
              USD
            </p>

            <p *ngIf="Currency.currency != 'BRL'" (click)="setCurrency('Brazil')" class="currencyOption mb-1">
              BRL
            </p>

            <p *ngIf="Currency.currency != 'INR'" (click)="setCurrency('India')" class="currencyOption">
              INR
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar-left d-block d-xl-none">
    <div id="my-language-menu">
      <app-language-switcher switchType="dropdown"></app-language-switcher>
      
      <div class="currencyArea" (mouseover)="showCurrencyDropDown()">
        {{"Currency" | translate}}:
        <div class="currencyChoiceArea">
          <div class="activeCurrency">
            {{Currency.currency}}
          </div>
          <div class="currencyDropdown">
            <p *ngIf="Currency.currency != 'EUR'" (click)="setCurrency('Italy')" class="currencyOption mb-1">
              EUR
            </p>
  
            <p *ngIf="Currency.currency != 'USD'" (click)="setCurrency('United States of America')" class="currencyOption mb-1">
              USD
            </p>
  
            <p *ngIf="Currency.currency != 'BRL'" (click)="setCurrency('Brazil')" class="currencyOption mb-1">
              BRL
            </p>
  
            <p *ngIf="Currency.currency != 'INR'" (click)="setCurrency('India')" class="currencyOption">
              INR
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar-right d-none d-xl-block">
    <div class="navbar-menus navbar-logout">
      <div class="navbar-search d-flex align-items-center" *ngIf="notifications > 0 &&  displayBadge">
        <span class="font-weight-bold" (click)="notificationChangeModal.show()">
          <i class="material-icons" style="color: #e6e600;">warning</i>
          <span class="badge badge-light">{{notifications}}</span>
        </span>
      </div>
      <div class="navbar-search d-flex align-items-center jurisdiction-coupon">
        {{ jurisdictionCoupon }}
      </div>
      <div class="navbar-search d-flex align-items-center">
        <span class="font-weight-bold" *ngIf="userAdmin">{{ userAdmin.login }}</span>
      </div>
      <div class="btn-group d-flex align-items-center" id="alert-menu" mdbDropdown>
        <button (click)="updateLocalStorageUser()" mdbDropdownToggle type="button" mdbBtn class="waves-light d-flex align-items-center" mdbWavesEffect>
          <i class="material-icons">person</i>
        </button>

        <ul class="dropdown-menu dropdown-primary">
          <li class="dropdown-header">
            Credentials
            <span class="float-right material-icons" style="font-size: 18px">info</span>
          </li>
          <li class="credential-info">
            <ul class="list-unstyled">
              <li class="name">
                <span class="material-icons" style="font-size: 18px; margin-right: 15px">person</span>
                <b>Name: </b>
                <span>{{ userAdmin.login }}</span>
              </li>
              <li class="credit">{{Currency.currencySymbol}} {{userAdmin.credit.toFixed(2) * Currency.quote | currency:'':''}}</li>
              <li *ngIf = "userAdmin.Id == 1">
                <button class="btnResetCredit" (click)="resetCreditModal.show()">
                  {{ 'reset credit' | translate }}
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="navbar-logout">
        <span>
          <span class="fas fa-power-off" style="width: 18px" (click)="userLogout()"></span>
          <span class="ml-2 font-weight-bold" (click)="userLogout()">{{ 'logout.title' | translate }}</span>
        </span>
      </div>
      <div class="navbar-search">
        <span class="bg-purple2 change-btn" (click)="passChangeModal.show()">{{ 'changepassword.title' | translate
          }}</span>
      </div>
    </div>
  </div>
  <div class="navbar-right d-block d-xl-none">
    <div class="navbar-menus">
      <div class="navbar-search d-flex align-items-center">
        <div class="btn-group d-flex align-items-center" mdbDropdown>
          <a mdbDropdownToggle mdbWavesEffect type="button" class="waves-light d-flex align-items-center" mdbWavesEffect
            style="height: auto; width: 30px">
            <i class="material-icons">person</i>
          </a>
          <div class="dropdown-menu dropdown-primary">
            <a class="dropdown-item"><span class="font-weight-bold mr-2" *ngIf="userAdmin">{{ userAdmin.login
                }}</span>{{ jurisdictionCoupon }}</a>
            <a class="dropdown-item"><span class="font-weight-bold" *ngIf="userAdmin">{{ userAdmin.credit }}</span></a>
            <a class="dropdown-item">
              <span class="fas fa-power-off" style="width: 18px" (click)="userLogout()"></span>
              <span class="ml-2 font-weight-bold" (click)="userLogout()">{{ 'logout.title' | translate }}</span>
            </a>
            <a class="dropdown-item">
              <span class="bg-purple2 change-btn" (click)="openChangePasswordDlg()">{{ 'changepassword.title' |
                translate }}</span>
            </a>
            <div class="divider dropdown-divider"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div id="myModal" mdbModal #passChangeModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <form [formGroup]="changePassForm" (ngSubmit)="onSubmit(changePassForm.value)">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="passChangeModal.hide()">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel">{{ 'changepassword.title' | translate }}</h4>
        </div>
        <div class="modal-body">
          <div *ngIf="changePassSuccess" class="text-success mb-3">{{ 'changepassword.passsuccess' | translate }}</div>
          <div *ngIf="checkPassFailed" class="text-danger mb-3">{{ 'changepassword.passinc' | translate }}</div>
          <div *ngIf="changePassFailed" class="text-danger mb-3">{{ 'changepassword.passfailed' | translate }}</div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <mdb-icon fas icon="key"></mdb-icon>
              </span>
            </div>
            <input type="password" class="form-control" placeholder="{{ 'changepassword.old' | translate }}"
              formControlName="oldpass">
          </div>
          <div *ngIf="changePassForm.get('oldpass').invalid && changePassForm.get('oldpass').touched"
            class="text-danger error-msg">
            <span *ngIf="changePassForm.get('oldpass').errors.required">{{ 'changepassword.oldpassreq' | translate
              }}</span>
            <span *ngIf="changePassForm.get('oldpass').errors.minlength">{{ 'changepassword.passlength' | translate }}
              {{ passMinLength}}</span>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <mdb-icon fas icon="key"></mdb-icon>
              </span>
            </div>
            <input type="password" class="form-control" placeholder="{{ 'changepassword.new' | translate }}"
              id="newpass" formControlName="newpass">
          </div>
          <div *ngIf="changePassForm.get('newpass').invalid && changePassForm.get('newpass').touched"
            class="text-danger error-msg">
            <span *ngIf="changePassForm.get('newpass').errors.required">{{ 'changepassword.newpassreq' | translate
              }}</span>
            <span *ngIf="changePassForm.get('newpass').errors.minlength">{{ 'changepassword.passlength' | translate }}
              {{ passMinLength}}</span>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <mdb-icon fas icon="key"></mdb-icon>
              </span>
            </div>
            <input type="password" class="form-control" placeholder="{{ 'changepassword.retype' | translate }}"
              id="repass" formControlName="repass">
          </div>
          <div *ngIf="changePassForm.get('repass').invalid && changePassForm.get('repass').touched"
            class="text-danger error-msg">
            <span *ngIf="changePassForm.get('repass').errors.required">{{ 'changepassword.retypereq' | translate
              }}</span>
            <span *ngIf="changePassForm.get('repass').errors.minlength">{{ 'changepassword.passlength' | translate }} {{
              passMinLength}}</span>
            <span *ngIf="changePassForm.get('repass').errors.confirmedValidator">{{ 'changepassword.matchreq' |
              translate }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
            (click)="passChangeModal.hide()" mdbWavesEffect>{{ 'closebtn' | translate }}</button>
          <button type="submit" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect>{{ 'savebtn' |
            translate }}
            <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="loading"></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div id="notificationModal" #notificationModal mdbModal #notificationChangeModal="mdbModal" class="modal fade"
  tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="notificationChangeModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'Attention' | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="row">

          <ng-container *ngIf="DocumentListPermit">
            <div class="col-md-12 mb-3">
              {{'There are' | translate}} {{pendingDocs}} {{'Documents to be Checked' | translate}}. <a
                [routerLink]="['/documents']" (click)="redirect()">{{'Click Here' | translate}}</a> {{'to Verify it' |
              translate}}.
            </div>
          </ng-container>
          <!-- <div class="col-md-12 mb-3">
            {{'There are' | translate}} {{pendingDocs}} {{'Documents to be Checked' | translate}}. <a
              [routerLink]="['/documents']" (click)="redirect()">{{'Click Here' | translate}}</a> {{'to Verify it' |
            translate}}.
          </div> -->

          <ng-container *ngIf="WithdrawalPermit">
            <div class="col-md-12">
              {{'There are' | translate}} {{pendingWithdrawls}} {{'Withdrawals to be Checked' | translate}}. <a
                [routerLink]="['/withdrawals']" (click)="redirectToWithdrawal()">{{'Click Here' | translate}}</a>
                {{'to Verify it' | translate}}.
            </div>
          </ng-container>
          <!-- <div class="col-md-12">
            {{'There are' | translate}} {{pendingWithdrawls}} {{'Withdrawals to be Checked' | translate}}. <a
              [routerLink]="['/withdrawals']" (click)="redirectToWithdrawal()">{{'Click Here' | translate}}</a>
              {{'to Verify it' | translate}}.
          </div> -->

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="notificationChangeModal.hide()" mdbWavesEffect>{{ 'OK' | translate }}</button>
        <!-- <button type="submit" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect>{{ 'savebtn' |
            translate }}
            <span class="spinner-border spinner-border-sm float-right text-white ml-2" *ngIf="loading"></span>
          </button> -->
      </div>
    </div>
  </div>
</div>

<div id="resetCreditModal" #resetCreditModal mdbModal #resetCreditModal="mdbModal" class="modal fade"
  tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="resetCreditModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{ 'Attention' | translate }}</h4>
      </div>
      <div class="modal-body">
        Deseja realmente resetar o credito?
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button type="button" mdbBtn class="waves-light bgGreen" aria-label="Close"
          (click)="resetFunds()" mdbWavesEffect *ngIf = "userAdmin.Id == 1">
          {{ 'reset credit' | translate }}
        </button>
        <button type="button" mdbBtn class="waves-light bgRed" aria-label="Close"
          (click)="resetCreditModal.hide()" mdbWavesEffect>
          {{ 'pg-jurisdiction.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
