import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { AuthLayoutComponent } from './auth-layout.component';
import { LoginComponent } from '../../auth/login/login.component';

const authLayoutRoutes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: '', component: LoginComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(authLayoutRoutes),
  ],
  exports: [
    RouterModule
  ]
})

export class AuthLayoutRoutingModule { }
