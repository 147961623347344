import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { RoundService } from 'src/app/services/round.service';
import { MatTabsModule } from '@angular/material/tabs';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFilterFn } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSort } from '@angular/material/sort';
import { MatSelect } from '@angular/material/select';
import { LoginService } from 'src/app/services/login.service';
import { IMatTab, IUserAssistantLog } from 'src/app/interface/Logs/logsList.interface';
import { IRoundLog } from 'src/app/interface/Logs/logsList.interface';
import { DataSource } from '@angular/cdk/collections';

export enum RoundLogAction {
  Canceled,
  Modified,
  Created
}


@Component({
  selector: 'app-logs-list',
  templateUrl: './logs-list.component.html',
  styleUrls: ['./logs-list.component.css']
})
export class LogsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('sortUser') sortUser: MatSort;
  @ViewChild('sortRound') sortRound: MatSort;
  @ViewChild('roundSelect') roundLogTableSelect: MatSelect
  dataSource = new MatTableDataSource<IUserAssistantLog>([]);
  roundDataSource = new MatTableDataSource<IRoundLog>([]);
  displayedColumnsRound: String[] = ['date', 'roundId', 'roundName', 'userName', 'actionType', 'ipAddress'];
  displayedColumnsUserAssistant: String[] = ['LoginDate', 'UserName', 'UserId', 'IpAddress', 'Status']
  tableType = 0; // 0 = nothing choosen, 1 = Canceled Log Table, 2 = Created Log Table, 3 = Modified Log Table
  actionTypes = {
    0: 'Canceled',
    1: 'Modified',
    2: 'Created'
  };
  constructor(public translate: TranslateService, private roundService: RoundService, private loginService: LoginService) {
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sortUser;
    this.roundDataSource.sort = this.sortRound;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.userAssistantLogsLoad();
  }

  applyFilter(event: Event, sourceData) {
    const filterValue = (event.target as HTMLInputElement).value;

    switch(sourceData) {
      case "roundDataSource":
        this.roundDataSource.filter = filterValue.trim().toLowerCase();
        break;
      case "dataSource":
        this.dataSource.filter = filterValue.trim().toLowerCase();
        break;
    }
  }

  tabChange(event: IMatTab): void {
    this.tableType = 0;
    this.roundLogTableSelect.value = null;
    switch (event.index) {
      case 0:
        this.userAssistantLogsLoad();
        break;
      case 1:
        this.roundLogsChangeTable('Canceled');
        break;
    }
  }

  // Rounds Logs
  roundLogsChangeTable(event) {

    this.roundService.getLoggerList(event).subscribe((data: IRoundLog[]) => {
      this.roundDataSource.data = data.sort((a, b) => {
        let dateA = new Date(a.date);
        let dateB = new Date(b.date);

        return dateB.getTime() - dateA.getTime();
      });
      this.roundDataSource.paginator = this.paginator;
      this.roundDataSource.sort = this.sortRound;
    })
  }

  setDynamicRoundTable(event) {
    switch (event) {
      case 'Canceled':
        this.tableType = 1;
        break;
      case 'Created':
        this.tableType = 2;
        break;
      case 'Modified':
        this.tableType = 3;
        break;
    }
    this.roundLogsChangeTable(event);
  }

  // UserAssistants Logs
  userAssistantLogsLoad() {
    const parentId = localStorage.getItem('currentUserJurisdictionId')
    this.loginService.getUserAssistantLogsHistory(parseInt(parentId)).subscribe((data: IUserAssistantLog[]) => {
      this.dataSource.data = data.sort((a, b) => {
        let dateA = new Date(a.loginDate);
        let dateB = new Date(b.loginDate);

        return dateB.getTime() - dateA.getTime();
      });
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sortUser;
    })
  }
}