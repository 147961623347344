import { DatePipe, Time } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'angular-bootstrap-md';
//import moment from 'moment';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { error } from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BetradarCategory } from '../../interface/BetradarCategory';
import { BetradarData } from '../../interface/BetradarData';
import { BetradarMatch, BetradarTeam } from '../../interface/BetradarMatch.Interface';
import { BetradarSport } from '../../interface/BetradarSport';
import { BetradarTournament } from '../../interface/BetradarTournament';
import { Country } from '../../interface/country.interface';
import { Currency } from '../../interface/currency.interface';
import { Game } from '../../interface/game.interface';
import { Match } from '../../interface/Match.Model';
import { GetMatch } from '../../interface/Match/GetMatch';
import { RoundGroup } from '../../interface/round-group.interface';
import { Round } from '../../interface/round.interface';
import { CreateRound } from '../../interface/Round/CreateRound';
import { GetRound } from '../../interface/Round/GetRound';
import { StartRoundRequest } from '../../interface/Round/StartRoundRequest';
import { UpdateRoundRequest } from '../../interface/Round/UpdateRoundRequest';
import { GetRoundEvent } from '../../interface/RoundEvent/GetRoundEvent';
import { PostRoundEvent } from '../../interface/RoundEvent/PostRoundEvent';
import { RoundStyle } from '../../interface/RoundStyle/RoundStyle';
import { User } from '../../interface/user.interface';
import { UserIdentification } from '../../interface/UserIdentification.interface';
import { coin } from '../../services/coin.interface';
import { CountryService } from '../../services/country.service';
import { InteractionService } from '../../services/interaction.service';
import { LoginService } from '../../services/login.service';
import { RoundStyleService } from '../../services/round-style.service';
import { RoundService } from '../../services/round.service';
import { ToastService } from '../../services/toast.service';

//import { NgbModal} from '@ng-bootstrap/ng-bootstrap';

interface Tournament {
  id: number;
  name: string;
}

@Component({
  selector: 'app-managerounds',
  templateUrl: './managerounds.component.html',
  styleUrls: ['./managerounds.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class ManageroundsComponent implements OnInit {
  @ViewChild('frame', { static: true }) modalCreate: ModalDirective;
  @ViewChild('successCreation', { static: true }) modalCreationSuccess: ModalDirective;
  @ViewChild('successEdition', { static: true }) modalEditionSuccess: ModalDirective;
  @ViewChild('avoidCreation', { static: true }) modalAvoidCreatioon: ModalDirective;
  @ViewChild('frameMessage', { static: true }) modalMessages: ModalDirective;
  @ViewChild('shieldModal', { static: true }) modalShield: ModalDirective;
  @ViewChild('frameMessageDanger', { static: true }) modalMessagesDanger: ModalDirective;
  @ViewChild('frameMessageConfirmation', { static: true }) modalMessagesConfirmation: ModalDirective;
  @ViewChild('SingleMatchShieldSelect', { static: true }) SingleMatchShield: ModalDirective;

  countrySelect: string;
  match = new Match();
  chosenMatches = [];
  editChosenMatches = [];
  betradarMatchData: BetradarData[];
  chooseDate = new FormControl(new Date());
  chooseDateSingleMatch = new FormControl(new Date());
  isShown: boolean = false;
  sports: Observable<BetradarSport[]>;
  filteredOptions: Observable<BetradarTournament[]>;
  sportId: number;
  categories: Observable<BetradarCategory[]>;
  formBetradar: FormGroup;
  Currency: Currency = <Currency>{};
  lastRound: Round;
  listRoundGroups: RoundGroup[];
  roundsFinished: GetRound[];
  listCareUnits: Round[];
  listOptionCareUnits: Observable<Round[]>;
  myControlListBaseRound = new FormControl('');
  activatedRounds: GetRound[] = [];
  feedOfMatches: any;
  allTournaments: any;
  availableMatches: any = [];
  tournamentList: Tournament[] = [];
  roundGroupRound = {} as any;
  betradarMatches: BetradarMatch[];
  rankRound = 0;
  roundType = 0; // 0 = nothing choosen, 1 = existing competition, 2 = new competition
  currentJackpot = 0;
  toRecover = 0;
  roundValue = 0;
  recoverdJackpot = 0;
  groupParent = 0;
  msgToAdd: string = '';
  titleToAdd: string = '';
  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];
  roundBaseChoosen: number;
  competitionType = '';
  is2Result: boolean;
  is2ResultControl = new FormControl();
  singleMatch = new FormControl();
  isRankRound = new FormControl();
  isJiuJitsuRound = new FormControl();
  singleMatchRound = 0;
  rankRoundMatch = 0;
  jiuJitsuRoundMatch = 0;
  singleMatchId: number;
  singleMatchTime: Date = new Date();
  singleMatchDate: Date = new Date();
  singleMatchType: string = '3 results';
  tournamentSelect: string;
  tournamentIcon: File;
  httpIconTournomant: string = '../../../assets/shields/default-logo.png';
  singleMatchCustonDate: Date;
  customTimer: Time;
  customHours: Time;
  singleMatchCustonDateUpdate: Date = new Date();
  disabled: boolean = false;
  disabledrank: boolean = false;
  activatedRoundHelper: any = [];

  moreBallPossession: string = '3 results';
  singleMatchTotalGoals: string;
  singleMatchTotalCorners: string;
  singleMatchYellowCards: string;
  singleMatchCornersHomeTeam: string;
  singleMatchCornersAwayTeam: string;
  singleMatchTotalFaults: string;
  singleMatchShootOnTarget: string;
  singleMatchGoalsHomeTeam: string;
  singleMatchGoalsAwayTeam: string;
  singleMatchShootsOnTargetHomeTeam: string;
  singleMatchShootsOnTargetAwayTeam: string;
  finalPointsDifference: string;
  roundNamePort: string = '';
  roundNameEng: string = '';
  roundNameSp: string = '';
  roundNameIt: string = '';
  HomeName: string = '';
  AwayName: string = '';
  HomeNameEdit: string = '';
  AwayNameEdit: string = '';
  HomeShieldIcon: string = '../assets/shields/default-logo.png';
  AwayShieldIcon: string = '../assets/shields/default-logo.png';
  HomeShieldIconEdit: string = '../assets/shields/default-logo.png';
  AwayShieldIconEdit: string = '../assets/shields/default-logo.png';
  time: Time;
  edittime: Time;
  //EditRound Params
  editRName: string = '';
  editRNameEng: string = '';
  editRNameSpan: string = '';
  editRNameIt: string = '';
  editRoundBaseChoosen: number;
  editRoundValue = 0;
  toRecoverJackpot = 0;
  recoverdJackpotEdit = 0;
  currentJackpotEdit = 0;
  choosenRound: number = 0;
  choosenRoundEdit: number = 0;
  changeMatchEdit: number = 0;
  sportChoosen: number = 0;
  editSportChoosen: number = 0;
  singleMatchEvents: number = 18;

  singleMatchTotalGoalsEdit: string = '';
  singleMatchTotalCornersEdit: string = '';
  singleMatchYellowCardsEdit: string = '';
  singleMatchCornersHomeTeamEdit: string = '';
  singleMatchCornersAwayTeamEdit: string = '';
  editChosenMatchesDate: any;
  editChosenSingleMatchesTime: any;
  editChosenSingleMatchesDate: any;
  singleMatchTotalFaultsEdit: string = '';
  singleMatchShootOnTargetEdit: string = '';
  singleMatchGoalsHomeTeamEdit: string;
  singleMatchGoalsAwayTeamEdit: string;
  singleMatchShootsOnTargetHomeTeamEdit: string;
  singleMatchShootsOnTargetAwayTeamEdit: string;
  fileName = '';
  url = "";
  setMatchSingleRound: boolean = false;
  setMatchRankRound: boolean = false;
  setMatchJiuJitsu: boolean = false;
  continuation: boolean = false;
  isContinuousRound: boolean = false;

  processingClick: boolean = false;

  countries: Country[];
  countryId: number = 0;

  linkShield = '';
  currentTeam = '';
  teamName = '';
  isShield: boolean;
  winnerPoints: any;
  loserPoints: any;
  advantagesDifference: any;
  finishingAtMinute: any;
  editChosenJiuJitsuMatchesTime: any;
  jiuJitsuAdvantagesDifferenceEdit: any;
  jiuJitsuWinnerPointsEdit: any;
  jiuJitsuLoserPointsEdit: any;
  jiuJitsuFinalPointsDifferenceEdit: any;
  jiuJitsuFinishingAtMinuteEdit: any;

  nameHomeTeamToCreate: string = '';
  nameAwayTeamToCreate: string = '';
  parentId: number = 0;
  dateStart: Date = new Date();
  dateEnd: Date = new Date();

  RankRoundMemberName: string;
  RankRoundMemberName2: string = "";
  RankRoudMembers: Game[] = [];

  RoundName: string;
  RoundNameEng: string;
  RoundNameItaly: string;
  RoundNameSpan: string;

  noDynamicStyle: number = -1;

  RoundToUpdate: GetRound = <GetRound>{};
  RoundsToUpdate: GetRound[] = [];
  RoundsToUpdateHelper: GetRound[] = [];

  is2ResultControlForUpdate = new FormControl();

  homaNameToUpdate: string = "";
  awayNameToUpdate: string = '';

  startDateToUpdate: Date;
  startHourToUpdate;
  endDateToUpdate: Date;
  endHourToUpdate;

  matchesCount: number = 0;

  homeNameForNewMatchUpdate: string = "";
  awayNameForNewMatchUpdate: string = "";

  eventsToUpdate: GetRoundEvent[] = [];

  matchsPreUpdate: any[] = [];
  matchsToUpdate: GetMatch[] = [];

  roundStyles: RoundStyle[] = [];
  roundStyle: RoundStyle = <RoundStyle>{};
  eventStyles = [];
  eventsToPreview = [];
  PostEvents: PostRoundEvent[] = [];
  createRound: CreateRound = {
    roundName: '',
    roundNameEng: '',
    roundNameItaly: '',
    roundNameSpan: '',
    countryId: 0,
    sportId: 0,
    groupParent: 0,
    roundStyleId: '',
    firstJackpot: 0,
    recoveredJackPot: 0,
    roundValue: 0,
    startDateTime: '',
    endDateTime: '',
    roundEvents: [],
    newMatches: [],
    parentId: 0,
    newJackPot: 0,
    id: 0,
    rankRound: 0,
    singleMatchRound: 0,
    jiuJitsuRound: 0,
    userId: 0,
    userName: '',
    userIpAddress: '',
    minutesDifference: 0,
    skinId: 0,
    isAssistant: 0
  };
  imageLocal: string;
  imgURL: string;
  user: User;

  baseRecover: number = 0;
  constructor(private http: HttpClient, public loginService: LoginService, private interactionService: InteractionService,
    private cookieService: CookieService,
    private roundService: RoundService,
    private translate: TranslateService,
    private toastService: ToastService,
    public datepipe: DatePipe,
    private router: Router,
    private httpClient: HttpClient,
    private matAutocomplete: MatAutocompleteModule,
    private _adapter: DateAdapter<any>,
    private countryService: CountryService,
    private roundStyleService: RoundStyleService
  ) {
    this.user = this.loginService.getUserInfoFromCookie();
    this.createRound.userId = this.user.id;
    this.createRound.userName = this.user.login;
    this.createRound.userIpAddress = localStorage.getItem('currentUserIp')!;
    this._adapter.setLocale('pt-BR');

    this.formBetradar = new FormGroup({
      myControl: new FormControl('', Validators.required),
      sportsFormControl: new FormControl('', Validators.required),
      categoriesFormControl: new FormControl('', Validators.required),
      singleMatch: new FormControl({ value: '', disabled: false }),
      isRankRound: new FormControl({ value: '', disabled: false }),
      isJiuJitsuRound: new FormControl({ value: '', disabled: false }),
      myControlListBaseRound: new FormControl()
    });
  }

  translateGoogle() {

    this.http.post("https://translation.googleapis.com/language/translate/v2?key=AIzaSyAJVBmQGu9dZItqVDIY9KHX_LNLKRP-vyI",
      {
        "q": [this.RoundName],
        "target": "en"
      }).subscribe((response: any) => {
        this.RoundNameEng = response.data.translations[0].translatedText
      });

    this.http.post("https://translation.googleapis.com/language/translate/v2?key=AIzaSyAJVBmQGu9dZItqVDIY9KHX_LNLKRP-vyI",
      {
        "q": [this.RoundName],
        "target": "it"
      }).subscribe((response: any) => {
        this.RoundNameItaly = response.data.translations[0].translatedText
      });

    this.http.post("https://translation.googleapis.com/language/translate/v2?key=AIzaSyAJVBmQGu9dZItqVDIY9KHX_LNLKRP-vyI",
      {
        "q": [this.RoundName],
        "target": "es"
      }).subscribe((response: any) => {
        this.RoundNameSpan = response.data.translations[0].translatedText
      });
  }

  translateGoogleToUpdate() {

    this.http.post("https://translation.googleapis.com/language/translate/v2?key=AIzaSyAJVBmQGu9dZItqVDIY9KHX_LNLKRP-vyI",
      {
        "q": [this.RoundToUpdate.name],
        "target": "en"
      }).subscribe((response: any) => {
        this.RoundToUpdate.nameEng = response.data.translations[0].translatedText
      });

    this.http.post("https://translation.googleapis.com/language/translate/v2?key=AIzaSyAJVBmQGu9dZItqVDIY9KHX_LNLKRP-vyI",
      {
        "q": [this.RoundToUpdate.name],
        "target": "it"
      }).subscribe((response: any) => {
        this.RoundToUpdate.nameItaly = response.data.translations[0].translatedText
      });

    this.http.post("https://translation.googleapis.com/language/translate/v2?key=AIzaSyAJVBmQGu9dZItqVDIY9KHX_LNLKRP-vyI",
      {
        "q": [this.RoundToUpdate.name],
        "target": "es"
      }).subscribe((response: any) => {
        this.RoundToUpdate.nameSpan = response.data.translations[0].translatedText
      });

  }

  setGlobalCurrency() {
    this.loginService.GetCountryCurrency(localStorage.getItem('currentUserCountry')!)
      .subscribe(data => {

        var DataURL: string;
        if (data.currencyId == "BRL") {
          DataURL = DataURL = 'https://economia.awesomeapi.com.br/json/USD-BRL';
        } else DataURL = 'https://economia.awesomeapi.com.br/json/BRL-' + data.currencyId;

        //this.http.get('https://free.currconv.com/api/v7/convert?q=BRL_' + data.currencyId + '&apiKey=085a5c4bbbd6e6750bfc')
        this.http.get(DataURL)
          .subscribe((obj: any) => {
            var Eco = JSON.stringify(obj)
            Eco = Eco.replace("[", "").replace("]", "")
            let response: coin = JSON.parse(Eco)
            if (response && data.currencyId != "BRL"/*&& response.data*/) {
              //var price = (response.data.results['BRL_' + data.currencyId].val);
              var price = parseFloat(response.ask);
              this.Currency.quote = price;
              this.Currency.coin = 'BRL_' + data.currencyId;
              this.Currency.currencySymbol = data.currencySymbol;
              this.Currency.currency = data.currencyId;
              this.interactionService.setGlobalCountry(this.Currency);
              return;
            } else {
              this.Currency.coin = 'BRL_BRL';
              this.Currency.quote = 1;
              this.Currency.currencySymbol = 'R$';
              this.Currency.country = 'Brazil';
              this.Currency.currency = 'BRL';
              this.interactionService.setGlobalCountry(this.Currency);
            }
          }, error => {
            this.Currency.coin = 'BRL_BRL';
            this.Currency.quote = 1;
            this.Currency.currencySymbol = 'R$';
            this.Currency.country = 'Brazil';
            this.Currency.currency = 'BRL';
          });
      });
    this.Currency.coin = 'BRL_BRL';
    this.Currency.quote = 1;
    this.Currency.currencySymbol = 'R$';
    this.Currency.country = 'Brazil';
    this.Currency.currency = 'BRL';
    this.interactionService.setGlobalCountry(this.Currency);
  }

  ngOnInit() {
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
        else {
          this.setGlobalCurrency();
        }
      });

    this.getRoundsPosted();

    this.getRoundsToCancel();

    this.getLastRound();
    this.listBetradarSports();
    this.listFeedTournaments();

    this.getRoundGroupRounds();
    this.competitionType = "NewRound";
    this.roundType = 2;
    this.singleMatchRound = 0;
    this.listOptionCareUnits = this.myControlListBaseRound.valueChanges.pipe(
      startWith(''),
      map(value => this._filterListBaseRoundControl(value || '')),
    );
    this.activatedRoundHelper = this.activatedRounds;

    this.countryService.getCountries().subscribe(data => {
      this.countries = data;
    });

    this.roundStyleService.GetRoundStyles()
      .subscribe(styles => {
        styles.forEach(style => {
          this.roundStyles.push(style);
        });
      });

    this.roundService.GetRoundsToContinuation()
      .subscribe((rounds) => {
        this.roundsFinished = rounds;
      });

  }

  getRoundsToCancel() {
    this.roundService.getRoundToCancel()
      .subscribe(rounds => {
        rounds.forEach(round => {
          this.activatedRounds.push(round);
        })
      })
  }

  private _filterListBaseRoundControl(value: string): Round[] {
    const filterValue = value.toLowerCase();
    return this.listCareUnits.filter(option => option.name != null && option.name.toLowerCase().includes(filterValue) && option.singleMatchRound != 1 && option.jiuJitsuRound != 1);
  }

  searchShield() {
    this.roundService.searchShield(this.teamName)
      .subscribe(data => {
        if (data.link.length > 0) {
          this.linkShield = data.link;
          this.isShield = true;
        }
        else {
          this.isShield = false;
        }
      }, error => {
        console.log(error);
      })
  }

  saveShield() {
    // console.log('B')
    // console.log(this.currentTeam, this.linkShield)
    this.roundService.uploadShield(this.currentTeam, this.linkShield)
      .subscribe(data => {
        this.toastService.showNotification({ message: this.translate.instant('shield-saved-success'), type: 'success' });
        this.closeShieldModal()
      }, error => {
        this.toastService.showNotification({ message: this.translate.instant('shield-saved-fail'), type: 'danger' });
        console.log(error);
      });
  }

  closeAvoidCreation() {
    this.modalAvoidCreatioon.hide();
    //location.reload();
  }

  singleMatchToggle() {
    if (this.singleMatch.value) {
      this.singleMatchRound = 1;
    }
    else {
      this.singleMatchRound = 0;
    }
  }

  rankRoundToggle() {
    if (this.isRankRound.value) {
      this.rankRoundMatch = 1;
    }
    else {
      this.rankRoundMatch = 0;
    }
  }

  jiuJitsuToggle() {
    if (this.isJiuJitsuRound.value) {
      this.jiuJitsuRoundMatch = 1;
    }
    else {
      this.jiuJitsuRoundMatch = 0;
    }
  }

  changeJackpotData() {
    this.toRecover = (this.baseRecover - this.createRound.firstJackpot) * -1;
  }

  onChangeCurrentCompetition(roundId: number) {
    const rd = this.roundsFinished.find(x => x.id == roundId)!;
    if (rd != null) this.roundBaseChoosen = rd.id;
    this.setMatchRankRound = rd.rankRound == 1 ? true : false;
    this.setMatchSingleRound = rd.singleMatchRound == 1 ? true : false;
    this.setMatchJiuJitsu = rd.jiuJitsuRound == 1 ? true : false;
    this.isContinuousRound = rd.parentId == 0 ? true : false;
    this.createRound.parentId = rd.id;
    this.createRound.countryId = rd.countryId;
    this.sportChoosen = rd.betradarSportId;
    this.sportId = rd.betradarSportId;
    this.baseRecover = (rd.recoveredJackpot - rd.firstJackpot) * -1;
    this.formBetradar.setValue({
      myControl: 0,
      sportsFormControl: rd.betradarSportId,
      categoriesFormControl: 0,
      singleMatch: rd.singleMatchRound == 1 ? true : false,
      isRankRound: rd.rankRound == 1 ? true : false,
      isJiuJitsuRound: rd.jiuJitsuRound == 1 ? true : false,
      myControlListBaseRound: 0
      //lastName: 'def'
    });
    if (rd.posted === 0) {
      this.modalAvoidCreatioon.show();
      //this.msgStandard(this.translate.instant("pg-round.fail"), "Rodada ativa no momento.", 4);
      //location.reload();
    }
    this.roundService.getRoundJackpot(roundId)
      .subscribe(data => {
        if (data != null) {
          this.createRound.firstJackpot = data.firstJackpot;
          this.recoverdJackpot = data.firstJackpot + data.recoveredJackpot;
          this.toRecover = data.recoveredJackpot > 0 ? 0 : data.recoveredJackpot * -1;
          this.createRound.roundValue = data.value;
          if (data.singleMatchRound == 1) {
            const nameSingleRound: string[] = data.name.replace(" x ", " X ").split(" X ")
            this.HomeName = nameSingleRound[0];
            this.AwayName = nameSingleRound[1];
            this.takeHomeShieldIcon()
            this.takeAwayShieldIcon()
            this.singleMatch.setValue(true)
          } else {
            this.RoundName = data.name;
            this.RoundNameEng = data.nameEng;
            this.RoundNameItaly = data.nameItaly;
            this.RoundNameSpan = data.nameSpan;
            this.checkTournamentIcon()
            this.singleMatch.setValue(false)
          }
          this.singleMatchRound = data.singleMatchRound;
          this.rankRound = data.rankRound;
          this.jiuJitsuRoundMatch = data.jiuJitsuRound;
        } else {
          this.currentJackpot = 0;
          this.toRecover = 0;
          this.recoverdJackpot = 0;
          this.roundValue = 0;
          this.roundNamePort = '';
          this.roundNameEng = '';
          this.roundNameIt = '';
          this.roundNameSp = '';
          this.singleMatchRound = 0;
          this.rankRound = 0;
          this.jiuJitsuRoundMatch = 0;
        }
      }, error => {
        this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.fail-info'), 4);
        console.log(error);
      });
    try {
      this.groupParent = this.roundGroupRound.find(x => x.roundId == roundId).roundGroupId;
    } catch {
      this.groupParent = 0;
    }

    if (rd.singleMatchRound && rd.jiuJitsuRound) {
      var rdStyle = this.roundStyles.find(x => x.id == rd.roundStyleId)
      this.SetRoundStyle(2, rdStyle);
    }

    if (rd.rankRound && !rd.singleMatchRound) {
      this.SetRoundStyle(0, null);
    }

    if (!rd.rankRound && !rd.singleMatchRound) {
      this.SetRoundStyle(1, null);
    }
  }

  async onEditCurrentCompetition(roundId: number) {
    try {
      await this.roundService.getRoundById(roundId).subscribe((rd: any) => {
        if (rd != null) {
          if (rd.posted === 1) {
            this.modalAvoidCreatioon.show();
          }
          this.choosenRoundEdit = roundId;
          this.currentJackpotEdit = rd.firstJackpot;
          this.toRecoverJackpot = rd.recoveredJackpot * -1;
          this.recoverdJackpotEdit = rd.firstJackpot + rd.recoveredJackpot;
          this.editRoundValue = rd.value;
          this.editRName = rd.name;
          this.editRNameEng = rd.nameEng;
          this.editRNameIt = rd.nameItaly;
          this.editRNameSpan = rd.nameSpan;
          this.sportId = rd.betradarSportId;
          this.editSportChoosen = rd.betradarSportId;
          this.countryId = rd.countryId;
          this.setMatchRankRound = rd.rankRound == 1 ? true : false;
          this.setMatchSingleRound = rd.singleMatchRound == 1 ? true : false;
          this.setMatchJiuJitsu = rd.jiuJitsuRound == 1 ? true : false;
          this.isContinuousRound = rd.parentId == 0 ? true : false;
          const dataFormater = new Date(rd.endDateTime);
          this.chooseDate = new FormControl(dataFormater);
          this.formBetradar.setValue({
            myControl: 0,
            sportsFormControl: rd.betradarSportId,
            categoriesFormControl: 0,
            singleMatch: rd.singleMatchRound == 1 ? true : false,
            isRankRound: rd.rankRound == 1 ? true : false,
            isJiuJitsuRound: rd.jiuJitsuRound == 1 ? true : false,
            myControlListBaseRound: 0
            //lastName: 'def'
          });
          //this.sportsFormControl.setValue(data.betradarSportId); //.sportsFormControl = new FormControl(data.betradarSportId);
          if (!this.setMatchJiuJitsu && !this.setMatchSingleRound) {
            this.roundService.getRoundMatches(roundId).subscribe(data => {
              //for (let i = 0; i < data.length; i++) {
              data.forEach(x => {
                const timeAndDate = this.datepipe.transform(x.dateTime, 'MM/dd/yyyy, hh:mm:ss a');
                const SplitertDate = timeAndDate!.split(",", 2)
                const dateLocate = new Date();
                const formTime = this.datepipe.transform((new Date(timeAndDate!).getTime()), 'yyyy/MM/dd');
                const getTime = this.datepipe.transform((new Date(timeAndDate!).getTime()), 'hh:mm a');
                x.matchDate = new FormControl(new Date(this.datepipe.transform(new Date(formTime!), 'yyyy/MM/dd')!));;
                x.matchTime = '' + getTime + '';
              })

              this.editChosenMatches = data;
              this.editChosenMatchesDate = data[0].matchDate;
              this.editChosenSingleMatchesTime = data[0].matchTime;
              this.singleMatchCornersAwayTeamEdit = data[0].cornerAwayTeam;
              this.singleMatchCornersHomeTeamEdit = data[0].cornerHomeTeam;
              this.singleMatchTotalCornersEdit = data[0].totalCorners;
              this.singleMatchTotalFaultsEdit = data[0].totalFaults;
              this.singleMatchTotalGoalsEdit = data[0].totalGoals;
              this.singleMatchYellowCardsEdit = data[0].yellowCards;
              this.singleMatchShootOnTargetEdit = data[0].shootsOnTarget;
              this.singleMatchGoalsHomeTeamEdit = data[0].goalsHomeTeam;
              this.singleMatchGoalsAwayTeamEdit = data[0].goalsAwayTeam;
              this.singleMatchShootsOnTargetHomeTeamEdit = data[0].shootsOnTargetHomeTeam;
              this.singleMatchShootsOnTargetAwayTeamEdit = data[0].shootsOnTargetAwayTeam;
            });
          }

          if (this.setMatchSingleRound) {
            this.roundService.getRoundMatches(roundId).subscribe(data => {
              data.forEach(x => {
                const timeAndDate = this.datepipe.transform(x.dateTime, 'MM/dd/yyyy, hh:mm:ss a');
                const dateLocate = new Date();
                const formTime = this.datepipe.transform((new Date(timeAndDate!).getTime()), 'yyyy/MM/dd');
                const getTime = this.datepipe.transform((new Date(timeAndDate!).getTime()), 'hh:mm a');
                this.chooseDate = new FormControl(new Date(this.datepipe.transform(new Date(formTime!), 'yyyy/MM/dd')!));;
                x.matchDate = new FormControl(new Date(this.datepipe.transform(new Date(formTime!), 'yyyy/MM/dd')!));;
                x.matchTime = '' + getTime + '';
              })

              if (this.setMatchSingleRound) {
                this.changeMatchEdit = data[0].betradarMatchId;
              }
              this.editChosenMatches = data;
              this.editChosenMatchesDate = data[0].matchDate;
              this.editChosenSingleMatchesTime = data[0].matchTime;
              this.singleMatchCornersAwayTeamEdit = data[0].cornerAwayTeam;
              this.singleMatchCornersHomeTeamEdit = data[0].cornerHomeTeam;
              this.singleMatchTotalCornersEdit = data[0].totalCorners;
              this.singleMatchTotalFaultsEdit = data[0].totalFaults;
              this.singleMatchTotalGoalsEdit = data[0].totalGoals;
              this.singleMatchYellowCardsEdit = data[0].yellowCards;
              this.singleMatchShootOnTargetEdit = data[0].shootsOnTarget;
              this.singleMatchGoalsHomeTeamEdit = data[0].goalsHomeTeam;
              this.singleMatchGoalsAwayTeamEdit = data[0].goalsAwayTeam;
              this.singleMatchShootsOnTargetHomeTeamEdit = data[0].shootsOnTargetHomeTeam;
              this.singleMatchShootsOnTargetAwayTeamEdit = data[0].shootsOnTargetAwayTeam;
            });
          }

          if (this.setMatchJiuJitsu) {
            this.roundService.getJiuJitsuMatchesByRound(roundId).subscribe((data: any[]) => {
              //for (let i = 0; i < data.length; i++) {
              data.forEach(x => {
                const timeAndDate = this.datepipe.transform(x.dateTime, 'MM/dd/yyyy, hh:mm:ss a');
                const SplitertDate = timeAndDate!.split(",", 2)
                const dateLocate = new Date();
                const formTime = this.datepipe.transform((new Date(timeAndDate!).getTime()), 'yyyy/MM/dd');
                const getTime = this.datepipe.transform((new Date(timeAndDate!).getTime()), 'hh:mm a');
                this.chooseDate = new FormControl(new Date(this.datepipe.transform(new Date(formTime!), 'yyyy/MM/dd')!));;
                x.matchDate = new FormControl(new Date(this.datepipe.transform(new Date(formTime!), 'yyyy/MM/dd')!));;
                x.matchTime = '' + getTime + '';
              });
              this.editChosenMatches = data;
              this.editChosenMatchesDate = data[0].matchDate;
              this.editChosenJiuJitsuMatchesTime = data[0].matchTime;
              this.jiuJitsuAdvantagesDifferenceEdit = data[0].advantagesDifference;
              this.jiuJitsuWinnerPointsEdit = data[0].winnerPoints;
              this.jiuJitsuLoserPointsEdit = data[0].loserPoints;
              this.jiuJitsuFinalPointsDifferenceEdit = data[0].pointsDifference;
              this.jiuJitsuFinishingAtMinuteEdit = data[0].finishingAtMinute;
            });
          }

        } else {
          this.currentJackpotEdit = 0;
          this.toRecoverJackpot = 0;
          this.recoverdJackpotEdit = 0;
          this.editRoundValue = 0;
          this.editRName = '';
          this.editRNameEng = '';
          this.editRNameIt = '';
          this.editRNameSpan = '';
        }
      });
    } catch {
      this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.fail-info'), 4);
      console.log(error);
    };

    try {
      this.groupParent = this.roundGroupRound.find(x => x.roundId == roundId).roundGroupId;
    } catch {
      this.groupParent = 0;
    }
  }

  msgStandard(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandard')!.classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandard')!.classList.add(this.msgType[type!]);
    }

    this.modalMessages.show();
  }

  msgStandardDanger(title: string, msg: string, type?: number) {
    this.titleToAdd = title;
    this.msgToAdd = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandardDanger')!.classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandardDanger')!.classList.add(this.msgType[type!]);
    }

    this.modalMessagesDanger.show();
  }

  // handlerTypeRound(event) {
  //   const toggleId = event.source.id;
  //   switch (toggleId) {
  //     case "mat-slide-toggle-1": {
  //       if (event.checked) {
  //         this.formBetradar.get("isRankRound").disable();
  //         this.formBetradar.get("isJiuJitsuRound").disable();
  //         this.setMatchSingleRound = true;
  //         this.setMatchRankRound = false;
  //         this.setMatchJiuJitsu = false;
  //       }
  //       else {
  //         this.formBetradar.get("isRankRound").enable();
  //         this.formBetradar.get("isJiuJitsuRound").enable();
  //         this.setMatchSingleRound = false;
  //         this.setMatchRankRound = false;
  //         this.setMatchJiuJitsu = false;
  //       }
  //       break;
  //     }
  //     case "mat-slide-toggle-2": {
  //       if (event.checked) {
  //         this.formBetradar.get("singleMatch").disable();
  //         this.formBetradar.get("isJiuJitsuRound").disable();
  //         this.setMatchSingleRound = false;
  //         this.setMatchJiuJitsu = false;
  //         this.setMatchRankRound = true;
  //         this.rankRound = 1
  //         this.rankRoundMatch = 1
  //         this.jiuJitsuRoundMatch = 0;
  //       }
  //       else {
  //         this.formBetradar.get("singleMatch").enable();
  //         this.formBetradar.get("isJiuJitsuRound").enable();
  //         this.setMatchSingleRound = false;
  //         this.setMatchRankRound = false;
  //         this.setMatchJiuJitsu = false;
  //         this.rankRound = 0;
  //         this.rankRoundMatch = 0;
  //         this.jiuJitsuRoundMatch = 0;
  //       }
  //       break;
  //     }
  //     case "mat-slide-toggle-3": {
  //       if (event.checked) {
  //         this.formBetradar.get("singleMatch").disable();
  //         this.formBetradar.get("isRankRound").disable();
  //         this.setMatchJiuJitsu = true;
  //         this.setMatchSingleRound = false;
  //         this.setMatchRankRound = false;
  //       }
  //       else {
  //         this.formBetradar.get("singleMatch").enable();
  //         this.formBetradar.get("isRankRound").enable();
  //         this.setMatchSingleRound = false;
  //         this.setMatchRankRound = false;
  //         this.setMatchJiuJitsu = false;
  //       }
  //       break;
  //     }
  //     default: {
  //       this.formBetradar.get("isRankRound").enable();
  //       this.formBetradar.get("singleMatch").enable();
  //       this.setMatchSingleRound = false;
  //       this.setMatchRankRound = true;
  //       break;
  //     }
  //   }
  // }

  handlerTypeRound(type: string) {
    switch (type) {
      case "singleMatch": {
        this.setMatchSingleRound = true;
        this.setMatchRankRound = false;
        this.setMatchJiuJitsu = false;
        this.rankRound = 0;
        this.rankRoundMatch = 0;
        this.jiuJitsuRoundMatch = 0;
        break;
      }
      case "rankRound": {
        this.setMatchSingleRound = false;
        this.setMatchJiuJitsu = false;
        this.setMatchRankRound = true;
        this.rankRound = 1
        this.rankRoundMatch = 1
        this.jiuJitsuRoundMatch = 0;
        break;
      }
      case "jiuJitsu": {
        this.setMatchJiuJitsu = true;
        this.setMatchSingleRound = false;
        this.setMatchRankRound = false;
        this.rankRound = 0;
        this.rankRoundMatch = 0;
        this.jiuJitsuRoundMatch = 0;
        break;
      }
      case "roundMatch": {
        this.setMatchJiuJitsu = false;
        this.setMatchSingleRound = false;
        this.setMatchRankRound = false;
        this.rankRound = 0;
        this.rankRoundMatch = 0;
        this.jiuJitsuRoundMatch = 0;
        break;
      }
    }
  }

  handleChange(event) {
    var target = event.value;
    if (target == 'Continuation') {
      this.formBetradar.get("singleMatch")!.disable();
      this.formBetradar.get("isRankRound")!.disable();
      this.formBetradar.get("singleMatch")!.setValue(false);
      this.formBetradar.get("isRankRound")!.setValue(false);
      this.formBetradar.get("isJiuJitsuRound")!.setValue(false);
      this.formBetradar.get("isJiuJitsuRound")!.disable();
      this.setMatchSingleRound = false;
      this.setMatchRankRound = false;
      this.setMatchJiuJitsu = false;
      this.isShown = true;
      this.roundType = 1;
      this.continuation = true;
      this.roundNamePort = '';
      this.roundNameEng = '';
      this.roundNameIt = '';
      this.roundNameSp = '';
      this.sportChoosen = 0;
      this.formBetradar.setValue({
        myControl: 0,
        sportsFormControl: 0,
        categoriesFormControl: 0,
        singleMatch: false,
        isRankRound: false,
        isJiuJitsuRound: false,
        myControlListBaseRound: 0
      });
    } else {
      this.roundType = 2;
      this.isShown = false;
      this.currentJackpot = 0;
      this.toRecover = 0;
      this.recoverdJackpot = 0;
      this.roundValue = 0;
      this.roundBaseChoosen = 0;
      this.continuation = false;
      this.roundNamePort = '';
      this.roundNameEng = '';
      this.roundNameIt = '';
      this.roundNameSp = '';
      this.sportChoosen = 0;
      this.singleMatchRound = 0;
      this.rankRound = 0;
      this.jiuJitsuRoundMatch = 0;
      this.baseRecover = 0;
      this.formBetradar.setValue({
        myControl: 0,
        sportsFormControl: 0,
        categoriesFormControl: 0,
        singleMatch: false,
        isRankRound: false,
        isJiuJitsuRound: false,
        myControlListBaseRound: 0
      });
    }
  }

  selectedRound(event, index) {
    var target = event.target;
    if (target.checked) {
      this.choosenRound = this.betradarMatches[index].id; //target.defaultValue; //target.attributes["ng-reflect-value"].value;
      //const time = (new Date(this.betradarMatches[index].date)).getTime() - (new Date()).getTimezoneOffset() * 60000;
      this.singleMatchDate = new Date(this.betradarMatches[index].date);
    } else {
      this.choosenRound = 0;
    }
  }

  selectedRoundChange(event, index) {
    var target = event.target;
    if (target.checked) {
      this.changeMatchEdit = this.betradarMatches[index].id;
      this.singleMatchTotalGoalsEdit = '';
      this.singleMatchTotalCornersEdit = '';
      this.singleMatchYellowCardsEdit = '';
      this.singleMatchCornersHomeTeamEdit = '';
      this.singleMatchCornersAwayTeamEdit = '';
      this.singleMatchTotalFaultsEdit = '';
      this.singleMatchShootOnTargetEdit = '';
      this.editChosenMatchesDate = '';
      this.editChosenSingleMatchesTime = '';
      this.singleMatchGoalsHomeTeamEdit = "";
      this.singleMatchGoalsAwayTeamEdit = '';
      this.singleMatchShootsOnTargetHomeTeamEdit = '';
      this.singleMatchShootsOnTargetAwayTeamEdit = '';

    } else {
      this.changeMatchEdit = 0;
    }
  }

  get myControl() {
    return this.formBetradar.get('myControl');
  }

  get sportsFormControl() {
    return this.formBetradar.get('sportsFormControl');
  }

  get categoriesFormControl() {
    return this.formBetradar.get('categoriesFormControl');
  }

  listBetradarSports() {
    this.roundService.getBetradarSports()
      .subscribe((betradarSports: BetradarSport[]) => {
        this.sports = this.sportsFormControl!.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(betradarSports, name) : betradarSports.slice())
          );

        // document.getElementById('spinner-loading').classList.add('hidden');
        // this.isLoaded = true;

      }, error => {
        console.log(error);
      });
  }

  private _filter(competition: any[], name: string): any[] {
    const filterValue = name.toLowerCase();

    return competition.filter(comp => comp.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getLastRound() {
    this.roundService.getLastRound()
      .subscribe(data => {
        this.lastRound = data;
        //this.getListRoundGroups();
      }, error => {
        console.log(error);
      });
  }

  getListRoundGroups() {
    this.roundService.getListedRoundGroups()
      .subscribe((data: RoundGroup[]) => {
        this.listRoundGroups = data;
        this.getRoundsPosted();
      }, error => {
        this.msgStandard(this.translate.instant('pg-manage-group.operation-fail'), this.translate.instant('pg-manage-group.load-fail-info'), 4);
        console.log(error);
      });
  }

  getRoundsPosted() {
    this.roundService.GetRoundsToUpdate()
      .subscribe(data => {
        data.forEach(element => {
          this.RoundsToUpdate.push(element);
        })
        this.RoundsToUpdateHelper = data;
      }, error => {
        this.msgStandard(this.translate.instant('pg-manage-group.operation-fail'), this.translate.instant('pg-manage-group.load-fail-info'), 4);
        console.log(error);
      });
  }

  listFeedTournaments() {
    // this.roundService.getFeedMatches()
    //   .subscribe(data => {
    //     this.allTournaments = data;
    //     this.compList();
    //   }, error => {
    //     console.log(error);
    //   });
  }

  compList() {
    this.allTournaments.forEach(element => {
      this.tournamentList.push({
        id: element.Tournament.BetradarTournamentID,
        name: element.Tournament.SuperTournament.Name
      });
    });
  }

  tournamentMatches(tournId: number) {
    this.allTournaments.forEach(element => {
      if (element.Tournament.BetradarTournamentID == tournId) {
        // console.log("entrou");
      }
    });
  }

  getRoundGroupRounds() {
    this.roundService.getRoundGroupRound()
      .subscribe(data => {
        this.roundGroupRound = data;
      });
  }

  // displayFn(competition?: any): string | undefined {
  //   return competition ? competition.name : undefined;
  // }

  getSportCategories(sportId: number) {
    this.sportId = sportId;
    this.roundService.getBetradarCategoriesBySportId(sportId)
      .subscribe((betradarCategories: BetradarCategory[]) => {
        this.categories = this.categoriesFormControl!.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(betradarCategories, name) : betradarCategories.slice())
          );
      }, error => {
        console.log(error);
      });
  }

  getCategoryTournaments(categoryId: number) {
    this.roundService.getBetradarTournamentsByCategoryId(categoryId)
      .subscribe((betradarTournaments: BetradarTournament[]) => {
        this.filteredOptions = this.myControl!.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(betradarTournaments, name) : betradarTournaments.slice())
          );

      }, error => {
        console.log(error);
      });
  }

  setValue(i, e) {
    if (e.checked) {
      this.betradarMatches[i].twoOptions = true;
    } else {
      this.betradarMatches[i].twoOptions = false;
    }
  }

  showTournamentMatches(tournamentId: number) {
    this.roundService.getBetradarDataByTournamentId(tournamentId)
      .subscribe((betradarData: BetradarData[]) => {
        this.roundService.getBetradarTeams(betradarData)
          .subscribe((betradarTeams: BetradarTeam[]) => {
            this.betradarMatches = betradarData.map(match => {
              const time = (new Date(match.matchDate)).getTime() - (new Date()).getTimezoneOffset() * 60000;
              return {
                id: match.matchId,
                homeTeam: betradarTeams.find(team => team.id == match.teamHomeId),
                awayTeam: betradarTeams.find(team => team.id == match.teamAwayId),
                date: this.datepipe.transform(new Date(time), 'MM/dd/yyyy HH:mm'), //match.matchDate, //new Date(match.matchDate).toLocaleTimeString(),
                twoOptions: false,
                //twoOptions:match.
                // sportName: betradarTeams.find(team => team.id == match.sportId),
              };
            });
          }, error => {
            console.log(error);
          });
      }, error => {
        console.log(error);
      });
  }

  checkExistingId(matchId: number) {
    return this.chosenMatches.some(match => (match.id == matchId));
  }

  removeMatch(indexData: number) {
    if (indexData > -1) {
      //this.editChosenMatches.splice(indexData, 1);
      if (this.editChosenMatches[indexData].status == 1) this.editChosenMatches[indexData].status = 0;
      else this.editChosenMatches[indexData].status = 1;
    }
  }

  // createJiuJistuRound() {
  //   if (this.setMatchSingleRound) {
  //     const homeName = (document.getElementById('round-name-single-home') as HTMLInputElement).value;
  //     const awayName = (document.getElementById('round-name-single-away') as HTMLInputElement).value;
  //     const singleMatchDate = (document.getElementById('date-picker-NewSingleMatch') as HTMLInputElement).value;
  //     const singleMatchTime = (document.getElementById('hour-input-NewSingleMatch') as HTMLInputElement).value;
  //     if (homeName == '' || awayName == '' || singleMatchDate == '' || singleMatchTime == '') {
  //       this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('error.missingInformation'), 4);
  //       this.processingClick = false;
  //       return;
  //     }
  //     if (this.singleMatchTotalCorners == undefined || this.singleMatchTotalFaults == undefined || this.singleMatchTotalGoals == undefined
  //       || this.singleMatchYellowCards == undefined || this.singleMatchShootOnTarget == undefined || this.singleMatchCornersAwayTeam == undefined
  //       || this.singleMatchCornersHomeTeam == undefined) {
  //       this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('SingleMatchErrorMessage'), 4);
  //       this.processingClick = false;
  //       return;
  //     }
  //     const newGames: Game[] = [];
  //     const obj: any = { homeName: homeName, awayName: awayName }
  //     newGames.push(obj);
  //     newGames[0].homeName = homeName;
  //     newGames[0].awayName = awayName;
  //     if (this.choosenRound == 0) newGames[0].betradarMatchId = 123456;
  //     else newGames[0].betradarMatchId = this.choosenRound;
  //     newGames[0].status = 1;
  //     const [daySpliter, monthSpliter, yearSpliter] = singleMatchDate.split('/');
  //     const time = singleMatchTime;
  //     const dateTime = new Date(`${monthSpliter}-${daySpliter}-${yearSpliter}-${time}`);
  //     newGames[0].dateTime = dateTime;
  //     newGames[0].dateTimeStr = this.datepipe.transform(dateTime, 'yyyy/MM/dd HH:mm:ss') + 'Z';
  //     newGames[0].homeTeamScore = null;
  //     newGames[0].awayTeamScore = null;
  //     newGames[0].cornerAwayTeam = this.singleMatchCornersAwayTeam;
  //     newGames[0].cornerHomeTeam = this.singleMatchCornersHomeTeam;
  //     newGames[0].totalCorners = this.singleMatchTotalCorners;
  //     newGames[0].totalFaults = this.singleMatchTotalFaults;
  //     newGames[0].yellowCards = this.singleMatchYellowCards;
  //     newGames[0].shootsOnTarget = this.singleMatchShootOnTarget;
  //     newGames[0].totalGoals = this.singleMatchTotalGoals;
  //     newGames[0].two_options = 0;
  //     newGames[0].moreBallPossession = this.moreBallPossession;
  //     let rjackpot: number;
  //     let rn: string;
  //     let rnItaly: string;
  //     let rnEng: string;
  //     let rnSpan: string;
  //     let two_options: number;
  //     let pid: number;
  //     if (this.is2ResultControl.value == null || this.is2ResultControl.value === false) {
  //       two_options = 0;
  //     } else {
  //       two_options = 1;
  //     }
  //     if (parseInt(String(this.roundType)) === 2) { // when it's a new competition
  //       rjackpot = + this.currentJackpot;
  //       var customGameName = homeName + " X " + awayName;
  //       rn = customGameName;
  //       rnItaly = customGameName;
  //       rnEng = customGameName;
  //       rnSpan = customGameName;
  //       pid = 0;
  //     } else { // when it's a competition continuation
  //       rjackpot = this.currentJackpot;
  //       rn = this.roundsFinished.find(x => x.id === this.roundBaseChoosen).name;
  //       rnItaly = this.roundsFinished.find(x => x.id === this.roundBaseChoosen).nameItaly;
  //       rnEng = this.roundsFinished.find(x => x.id === this.roundBaseChoosen).nameEng;
  //       rnSpan = this.roundsFinished.find(x => x.id === this.roundBaseChoosen).nameSpan;
  //       pid = this.roundsFinished.find(x => x.id === this.roundBaseChoosen).id;
  //       if (this.roundValue === 0) {
  //         this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.rjackpot-field'), 4);
  //       }
  //     }
  //     this.rankRoundMatch = this.setMatchRankRound ? 1 : 0;
  //     this.singleMatchRound = this.setMatchSingleRound ? 1 : 0;
  //     this.roundService.createRound(this.groupParent, rn, rnEng, rnItaly, rnSpan, rjackpot, this.roundValue, pid, this.sportId, this.rankRoundMatch, this.singleMatchRound, newGames/*,two_options*/)
  //       .subscribe((data: string) => {
  //         this.modalCreationSuccess.show();
  //       }, error => {
  //         this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.fail-info'), 4);
  //         console.log(error);
  //       });
  //   }
  // }

  canceloRound() {
    this.modalMessagesConfirmation.show()
  }

  SetChoosenRoundEdit(id: number) {
    this.choosenRoundEdit = id;
  }

  cancelConfirm() {
    const userObj: UserIdentification = {
      UserName: this.user.login,
      UserId: this.user.id,
      UserIpAddress: localStorage.getItem('currentUserIp')!,
      SkinId: Number(localStorage.getItem("creatorId")),
      IsAssistant: Number(localStorage.getItem("isAssistant"))
    };
    this.loginService.cancelRound(this.choosenRoundEdit, userObj)
      .subscribe(data => {
        this.modalMessagesConfirmation.hide();
        this.msgStandard(this.translate.instant('success'), this.translate.instant('cancelled-round'), 3);
      }, error => {
        this.modalMessagesConfirmation.hide();
        this.msgStandard(this.translate.instant('failed'), this.translate.instant('operation-failed'), 4);
        console.log(error);
      })
  }

  closeModalConfirmation() {
    this.modalCreationSuccess.hide();
    // window.location.href = "/home";
    //this.router.navigate(['/home']);
    location.reload();
  }

  closeModalEditConfirmation() {
    this.modalEditionSuccess.hide();
    // window.location.href = "/home";
    //this.router.navigate(['/home']);
    location.reload();
  }

  openModalShield(currentTeam: string) {
    this.currentTeam = currentTeam;
    // this.imageLocal = ;
    this.modalShield.show();
  }

  openModalShieldTournament() {
    //this.tournamentSelect = (document.getElementById('tournament') as HTMLInputElement);
    this.fileName = this.roundNamePort;
    this.SingleMatchShield.show();
  }

  closeShieldModal() {
    this.teamName = '';
    this.currentTeam = '';
    this.linkShield = '';
    this.isShield = false;
    this.modalShield.hide();
  }

  getSingleMatchShield(elementName: Match, team: number) {
    let nameTeam: string[]
    nameTeam = elementName.game.split((" - ").toUpperCase());
    let getTemLogo;
    getTemLogo = nameTeam[team];
    getTemLogo = this.remove_accents(getTemLogo)
    getTemLogo = getTemLogo.replace(" ", "%20")
    return 'https://poolbet365.com/assets/shields/' + getTemLogo + '.png'
  }

  checkTournamentIcon() {
    let fileName = this.roundNamePort;
    fileName = this.remove_accents(fileName);
    fileName = fileName.replace(" ", "%20");
    fileName = fileName.toLowerCase()
    const folderPath = "https://poolbet365.com/assets/shields/" + fileName + ".png";
    if (fileName == '') {
      // this.httpIconTournomant = '../assets/shields/default-logo.png'
    } else this.httpIconTournomant = folderPath
  }

  closedTournamentModal() {
    this.SingleMatchShield.hide();
    this.tournamentIcon = null;
    this.fileName = '';
    this.url = 'https://poolbet365.com/assets/shields/default-logo.png';
  }

  takeHomeShieldIcon() {
    let fileName = this.HomeName;
    fileName = this.remove_accents(fileName);
    fileName = fileName.replace(" ", "%20")
    fileName = fileName.toLowerCase()
    const folderPath = 'https://poolbet365.com/assets/shields/' + fileName + '.png';
    if (fileName == '') {
      this.HomeShieldIcon = '../assets/shields/default-logo.png'
    } else this.HomeShieldIcon = folderPath
  }

  takeHomeShieldIconEdit() {
    let fileName = (document.getElementById("round-name-single-home-edit") as HTMLInputElement).value;
    fileName = this.remove_accents(fileName);
    fileName = fileName.replace(" ", "%20")
    fileName = fileName.toLowerCase()
    const folderPath = 'https://poolbet365.com/assets/shields/' + fileName + '.png';
    if (fileName == '') {
      this.HomeShieldIconEdit = '../assets/shields/default-logo.png'
    } else this.HomeShieldIconEdit = folderPath
  }

  takeAwayShieldIcon() {
    let fileName = this.AwayName;
    fileName = this.remove_accents(fileName);
    fileName = fileName.replace(" ", "%20");
    fileName = fileName.toLowerCase()
    const folderPath = 'https://poolbet365.com/assets/shields/' + fileName + '.png';
    if (fileName == '') {
      this.AwayShieldIcon = '../assets/shields/default-logo.png'
    } else this.AwayShieldIcon = folderPath
  }

  takeAwayShieldIconEdit() {
    let fileName = (document.getElementById("round-name-single-away-edit") as HTMLInputElement).value;
    fileName = this.remove_accents(fileName);
    fileName = fileName.replace(" ", "%20");
    fileName = fileName.toLowerCase()
    const folderPath = 'https://poolbet365.com/assets/shields/' + fileName + '.png';
    if (fileName == '') {
      this.AwayShieldIconEdit = '../assets/shields/default-logo.png'
    } else this.AwayShieldIconEdit = folderPath
  }

  takeShieldIcon(str) {
    let fileName = this.remove_accents(str);
    fileName = fileName.replace(" ", "%20");
    fileName = fileName.toLowerCase()
    return 'https://poolbet365.com/assets/shields/' + fileName + '.png';
  }

  openModalSingleMatchShieldSelect(str, whichLocal) {
    this.fileName = str;
    this.imageLocal = whichLocal;
    this.SingleMatchShield.show();
  }

  closeModalSingleMatchShield() {
    this.fileName = '';
    this.tournamentIcon = null;
    this.SingleMatchShield.hide();
    this.checkFileSelected();
    this.url = 'https://poolbet365.com/assets/shields/default-logo.png';
  }

  saveTournamentIcon() {
    const formData = new FormData();
    let fileToUpload = <File>this.tournamentIcon;
    let tournament = this.remove_accents(this.fileName)
    tournament = tournament.replace(/,/g, "").replace(/\./g, "")
    tournament = tournament.replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")
    tournament = tournament.toLowerCase();
    if (this.tournamentIcon) {
      formData.append("file", fileToUpload, tournament);
      formData.append('Type', fileToUpload.type);
      formData.append('Title', fileToUpload.name);
      this.roundService.uploadTournamentIcon(tournament, formData).subscribe(data => {
        this.toastService.showNotification({ message: this.translate.instant('tournament-saved-success'), type: 'success' });
        this.closeModalSingleMatchShield();
      }, error => {
        this.toastService.showNotification({ message: this.translate.instant('tournament-saved-fail'), type: 'danger' });
        console.log(error);
      });
    }

    this.closedTournamentModal();
    // setTimeout(() => {
    //   this.checkTournamentIcon();
    // }, 2500);
  }

  remove_accents(str) {
    let accented = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ|º";
    let withoutAccent = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let namewithoutAccent = "";
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < accented.length; a++) {
        if (str.substr(i, 1) == accented.substr(a, 1)) {
          namewithoutAccent += withoutAccent.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        namewithoutAccent += str.substr(i, 1);
      }
    }
    namewithoutAccent = this.remove_symbols(namewithoutAccent);
    return namewithoutAccent
  }

  remove_symbols(str) {
    let symbols = "()|º%$#@!*";
    let withoutAccent = "";
    let namewithoutAccent = "";
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < symbols.length; a++) {
        if (str.substr(i, 1) == symbols.substr(a, 1)) {
          namewithoutAccent += withoutAccent.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        namewithoutAccent += str.substr(i, 1);
      }
    }
    return namewithoutAccent;
  }

  checkFileSelected() {
    if (this.tournamentIcon == null) {
      return "waiting";
    } else {
      return "readyToUpLoad";
    }
  }

  readImage(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result);
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsDataURL(file);
    });
  }

  onFileSelected(event,) {
    if (event.target.files) {
      this.readImage(event.target.files[0]).then((url: string) => {
        this.url = url;
        this.httpIconTournomant = url;
        this.imgURL = url;

        if (this.imageLocal != "../assets/shields/default-logo.png" && this.imageLocal) {
          this.generateIcon(this.imageLocal, this.url, true);
        }
      }).catch(error => {
        console.error("Erro ao ler o arquivo:", error);
        return;
      });
    }

    if (event.target.files[0] != this.tournamentIcon) {
      this.tournamentIcon = null;
      this.checkFileSelected()
    }
    if (this.fileName.length == 0 && this.RoundName.length > 0) this.fileName = this.RoundName;
    this.tournamentIcon = event.target.files[0];
    if (this.fileName.length == 0) {
      this.toastService.showNotification({ message: this.translate.instant('tournament-Name-missing'), type: 'danger' });
      this.url = 'https://poolbet365.com/assets/shields/default-logo.png';
      this.tournamentIcon = null;
      this.closedTournamentModal();
    }
    else if (this.tournamentIcon.type == "image/png") this.toastService.showNotification({ message: this.translate.instant('tournament-Icon-accept'), type: 'success' });
    else if (this.tournamentIcon.type == "image/jpg") this.toastService.showNotification({ message: this.translate.instant('tournament-Icon-accept'), type: 'success' });
    else if (this.tournamentIcon.type == "image/jpeg") this.toastService.showNotification({ message: this.translate.instant('tournament-Icon-accept'), type: 'success' });
    else if (this.tournamentIcon.type == "image/svg") this.toastService.showNotification({ message: this.translate.instant('tournament-Icon-accept'), type: 'success' });
    else {
      this.toastService.showNotification({ message: this.translate.instant('tournament-Icon-declined'), type: 'danger' });
      this.tournamentIcon = null;
      this.fileName = '';
      this.url = '';
    }


  }

  formatDate(date) {
    const datePipe = new DatePipe('pt-BR');

    let dateStartHelper = new Date(date).getTime() - (new Date()).getTimezoneOffset() * 60000;
    let dateStart = new Date(dateStartHelper);
    return datePipe.transform(dateStart, 'yyyy-MM-ddThh:mm:ss', undefined, 'pt-BR');
  }

  takeShield(name: string) {
    if (name == '') {
      return '../assets/shields/default-logo.png';
    }

    let fileName = this.remove_accents(name);
    fileName = fileName.replace(" ", "%20");
    fileName = fileName.toLowerCase();
    fileName = 'https://poolbet365.com/assets/shields/' + fileName + '.png';

    return fileName;
  }

  generateIcon(docElement: string, team: string, newIcon: boolean) {
    let element = document.getElementById(docElement)!;
    let icon: string;
    if (!newIcon) {
      icon = this.takeShield(team);
    } else {
      icon = team;
    }
    let img: string = `
    <img src="${icon}" style="width: 100%;"
      class="d-flex"
      onerror="this.src='https://poolbet365.com/assets/shields/default-logo.png';"
      alt="+">
    `;
    element.innerHTML = img;
  }

  generateChampshipMatchPreview(homeTeam: string, awayTeam: string) {
    let home = this.takeShield(homeTeam);
    let away = this.takeShield(awayTeam);

    return `
    <img src="${home}"
      class="d-flex"
      onerror="this.src='https://poolbet365.com/assets/shields/default-logo.png';"
      alt="+">

      ${homeTeam} X ${awayTeam}

      <img src="${away}"
      class="d-flex width1vw"
      onerror="this.src='https://poolbet365.com/assets/shields/default-logo.png';"
      alt="+">`;
  }

  filterActivatedRounds(event: Event) {
    let baseFilter = this.activatedRounds;
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    baseFilter = baseFilter.filter(x => x.name.toLowerCase().includes(filterValue));
    this.activatedRoundHelper = baseFilter;
  }

  filterRoundsToUpdate(event: Event) {
    let filter = this.RoundsToUpdateHelper;
    let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    filter = filter.filter(x => x.name.toLowerCase().includes(filterValue));
    this.RoundsToUpdate = filter;
  }

  SetRoundStyle(type: number, style) {
    this.imageLocal = "../assets/shields/default-logo.png";
    this.imgURL = "../assets/shields/default-logo.png";
    this.httpIconTournomant = "../assets/shields/default-logo.png"
    this.url = "../assets/shields/default-logo.png";

    switch (type) {
      case 0:
        this.createRound.rankRound = 1;
        this.createRound.jiuJitsuRound = 0;
        this.createRound.singleMatchRound = 0;
        this.createRound.roundStyleId = 'gui';
        break;

      case 1:
        this.createRound.rankRound = 0;
        this.createRound.jiuJitsuRound = 0;
        this.createRound.singleMatchRound = 0;
        this.createRound.roundStyleId = 'gui';
        break;

      case 2:
        this.eventsToPreview = [];
        this.roundStyle = style;
        this.createRound.rankRound = 1;
        this.createRound.jiuJitsuRound = 1;
        this.createRound.singleMatchRound = 1;
        this.createRound.roundStyleId = style.id;

        this.generateEventsOddsFields();
        break;

      default:
        this.createRound.rankRound = 0;
        this.createRound.jiuJitsuRound = 0;
        this.createRound.singleMatchRound = 0;
        this.createRound.roundStyleId = '';
    }
  }

  generateEventsOddsFields() {
    this.PostEvents = [];
    this.eventStyles = [];

    this.roundStyle.roundEventsStyles.forEach((event, index) => {
      let roundEvent: PostRoundEvent = {
        roundEventStyleId: event.id,
        odd: ''
      }

      this.PostEvents.push(roundEvent);

      let eventToStyle = {
        id: event.id,
        name: event.name,
        type: event.type,
        index: index,
        odd: ''
      }

      if (event.type == 2) {
        this.eventStyles.push(eventToStyle);
      }

      this.eventsToPreview.push(eventToStyle);
    })
  }

  CreateRound() {
    if (this.roundStyle != null) {
      this.CreateRoundDynamicStyle();
    }

    if (this.noDynamicStyle >= 0) {
      this.CreateRankRound();
    }
  }

  AddRankRoundGame() {
    let dateRankRoundFromHTML = (document.getElementById("dateStartRankRound") as HTMLInputElement).value;

    if (dateRankRoundFromHTML == "") {
      this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.fail-insert-match'), 4);
      return;
    }

    let game: any = { homeName: this.RankRoundMemberName, awayName: this.RankRoundMemberName2 };
    this.RankRoudMembers.push(game);

    var twoOptions = 0;
    if (this.is2ResultControl.value) {
      twoOptions = 1;
    }
    else {
      twoOptions = 0;
    }


    let [daySpliter, monthSpliter, yearSpliter] = dateRankRoundFromHTML.split('/');
    let time = (document.getElementById("hourInputStartRankRound") as HTMLInputElement).value;
    time = this.formatHours(time).trim();
    time = time.trim();
    if (time.length < 5)
      time = "0" + time;
    let dateRankRound = `${yearSpliter}-${monthSpliter}-${daySpliter} ${time}`;
    let dateRankRoundstr = `${yearSpliter}-${monthSpliter}-${daySpliter} ${time}`;

    this.RankRoudMembers[this.RankRoudMembers.length - 1].status = 1;
    this.RankRoudMembers[this.RankRoudMembers.length - 1].dateTime = new Date(dateRankRound);
    this.RankRoudMembers[this.RankRoudMembers.length - 1].dateTimeStr = dateRankRoundstr;
    this.RankRoudMembers[this.RankRoudMembers.length - 1].two_options = twoOptions;
    this.RankRoudMembers[this.RankRoudMembers.length - 1].betradarMatchId = 0;
    this.RankRoudMembers[this.RankRoudMembers.length - 1].homeTeamScore = 0;
    this.RankRoudMembers[this.RankRoudMembers.length - 1].awayTeamScore = 0;

    console.log(this.RankRoudMembers)

    this.RankRoundMemberName = "";
    this.RankRoundMemberName2 = "";
  }

  CreateRankRound() {
    let pid = 0
    if (parseInt(String(this.roundType)) != 2) {
      pid = this.roundsFinished.find(x => x.id === this.roundBaseChoosen)!.id;
    }

    let isRankRound = 0;
    let isSingleMatch = 0;
    let isJiuJutsu = 0;

    switch (this.noDynamicStyle) {
      case 0:
        isRankRound = 1;
        isSingleMatch = 1;
        isJiuJutsu = 1;
        break;

      case 1:
        isRankRound = 1;
        break;
    }

    this.roundService.createRound(this.groupParent, this.RoundName, this.RoundNameEng, this.RoundNameItaly, this.RoundNameSpan, this.createRound.firstJackpot, this.createRound.roundValue, pid, this.sportChoosen, isRankRound, isSingleMatch, isJiuJutsu, this.createRound.countryId, this.RankRoudMembers, []/*,two_options*/)
      .subscribe((data: string) => {
        this.modalCreationSuccess.show();
      }, error => {
        this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.fail-info'), 4);
        console.log(error);
      });
  }

  FormatDate(elementId: string) {
    let element = (document.getElementById(elementId) as HTMLInputElement).value;
    let [daySpliter, monthSpliter, yearSpliter] = element.split('/');
    let time = (document.getElementById("hourInputStart") as HTMLInputElement).value;
    time = this.formatHours(time).trim();
    time = time.trim();
    if (time.length < 5)
      time = "0" + time;

    let date = `${yearSpliter}-${monthSpliter}-${daySpliter} ${time}`;

    return date;
  }

  CreateRoundDynamicStyle() {
    if (this.sportChoosen == 0) {
      this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('sportFail'), 4);
      return;
    }

    if (this.countryId == 0) {
      this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('countryFail'), 4);
      return;
    }

    if (this.createRound.firstJackpot == 0) {
      this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('jackpotFail'), 4);
      return;
    }

    if (this.createRound.roundValue == 0) {
      this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('roundValueFail'), 4);
      return;
    }

    if (this.createRound.roundStyleId == '') {
      this.createRound.newMatches = this.RankRoudMembers
    } else {
      this.eventStyles.forEach((event) => {
        this.PostEvents[event.index].odd = event.odd;
      });
    }

    this.createRound.sportId = this.sportChoosen;
    this.createRound.recoveredJackPot = this.toRecover;

    if (this.createRound.roundStyleId != null && this.createRound.roundStyleId != 'gui') {
      if (this.nameHomeTeamToCreate == "" || this.nameAwayTeamToCreate == "") {
        this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('nameFail'), 4);
        return;
      }
      this.createRound.roundName = `${this.nameHomeTeamToCreate} X ${this.nameAwayTeamToCreate}`;
      let dateStartRound = this.FormatDate("dateStartRound");
      let dateEndRound = this.FormatDate("dateEndRound");
      this.createRound.startDateTime = dateStartRound;
      this.createRound.endDateTime = dateEndRound;
      this.createRound.roundEvents = this.PostEvents;

    } else {
      let nameValid = this.RoundName == "" || this.RoundName == undefined;
      let nameEngValid = this.RoundNameEng == "" || this.RoundNameEng == undefined;
      let nameItalyValid = this.RoundNameItaly == "" || this.RoundNameItaly == undefined;
      let nameSpanValid = this.RoundNameSpan == "" || this.RoundNameSpan == undefined;

      if (nameValid || nameEngValid || nameItalyValid || nameSpanValid) {
        this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('nameFail'), 4);
        return;
      }

      let dateStartRoundInput = this.datepipe.transform(new Date().getTime(), 'dd/MM/yyyy');
      let dateEndRoundInput = this.datepipe.transform(new Date().getTime(), 'dd/MM/yyyy');

      let [daySpliter, monthSpliter, yearSpliter] = dateStartRoundInput!.split('/');
      let time = this.datepipe.transform(new Date().getTime(), 'HH:mm');

      let dateStartRound = `${yearSpliter}-${monthSpliter}-${daySpliter} ${time}`;

      [daySpliter, monthSpliter, yearSpliter] = dateEndRoundInput!.split('/');
      let dateEndRound = `${yearSpliter}-${monthSpliter}-${daySpliter} ${time}`;

      this.createRound.startDateTime = dateStartRound;
      this.createRound.endDateTime = dateEndRound;
      this.createRound.roundName = this.RoundName;
      this.createRound.roundNameEng = this.RoundNameEng;
      this.createRound.roundNameItaly = this.RoundNameItaly;
      this.createRound.roundNameSpan = this.RoundNameSpan;
      this.createRound.newMatches = this.RankRoudMembers;
      this.createRound.roundStyleId = '';
    }

    this.createRound.minutesDifference = parseInt(localStorage.getItem('minute')!, 10);
    this.createRound.skinId = Number(localStorage.getItem("creatorId"));
    this.createRound.isAssistant = Number(localStorage.getItem("isAssistant"))
    this.roundService.newCreateRound(this.createRound)
      .subscribe(data => {
        this.createRound = {
          roundName: '',
          roundNameEng: '',
          roundNameItaly: '',
          roundNameSpan: '',
          countryId: 0,
          sportId: 0,
          groupParent: 0,
          roundStyleId: '',
          firstJackpot: 0,
          recoveredJackPot: 0,
          roundValue: 0,
          startDateTime: '',
          endDateTime: '',
          roundEvents: [],
          newMatches: [],
          parentId: 0,
          newJackPot: 0,
          id: 0,
          rankRound: 0,
          singleMatchRound: 0,
          jiuJitsuRound: 0,
          userId: this.user.id,
          userName: this.user.login,
          userIpAddress: localStorage.getItem('currentUserIp')!,
          minutesDifference: 0,
          skinId: 0,
          isAssistant: 0
        };
        this.modalCreationSuccess.show();
      }, error => {
        this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.fail-info'), 4);
        console.log(error);
      });
  }

  formatHours(time: string) {
    let modifier = time.slice(-2);
    let [hoursString, minutes] = time.slice(0, -2).split(':');

    let hours = parseInt(hoursString);

    if (modifier == "PM" && hours < 12)
      hours += 12;

    if (modifier == "AM" && hours == 12)
      hours = 0;

    return `${hours}:${minutes}`
  }

  removeMember(index) {
    if (index > -1) {
      this.RankRoudMembers.splice(index, 1);
    }
  }

  updatePreview(index) {
    this.eventsToPreview.find(e => e.id == this.eventStyles[index].id).odd = this.eventStyles[index].odd;
  }

  checkTypeRound() {
    if (this.RoundToUpdate.matches != null && this.RoundToUpdate.matches.length > 0)
      return 2

    return 1
  }

  setRoundToUpdate(round: GetRound) {
    this.RoundToUpdate = round;
    this.matchsPreUpdate = [];
    this.eventsToPreview = [];

    let names = round.name.split(" X ");

    this.homaNameToUpdate = names[0];
    this.awayNameToUpdate = names[1];

    let dateTime = new Date(round.startDateTime);
    const dateLocate = new Date();

    let startDateToUpdate = this.datepipe.transform((dateTime.getTime()), 'yyyy/MM/dd');
    let startHourToUpdate = this.datepipe.transform((dateTime.getTime()), 'hh:mm a');

    this.startDateToUpdate = new Date(startDateToUpdate!);
    this.startHourToUpdate = startHourToUpdate;

    dateTime = new Date(round.endDateTime);
    let endDateToUpdate = this.datepipe.transform((dateTime.getTime()), 'yyyy/MM/dd');
    let endHourToUpdate = this.datepipe.transform((dateTime.getTime()), 'hh:mm a');

    this.endDateToUpdate = new Date(endDateToUpdate!);
    this.endHourToUpdate = endHourToUpdate;
    
    if (this.RoundToUpdate.events != null && this.RoundToUpdate.events.length > 0) {
      this.MakePreviewUpdateEvents(round.events);
    }

    if (this.RoundToUpdate.matches != null && this.RoundToUpdate.matches.length > 0) {
      this.MakePreviewUpdateMatchs(round.matches);
    }
  }

  MakePreviewUpdateEvents(events) {
    this.matchsPreUpdate = [];
    this.matchesCount = 0;
    this.eventsToUpdate = events;
  }

  MakePreviewUpdateMatchs(matches) {
    this.eventsToUpdate = [];
    matches.forEach(match => {
      let dateTime = new Date(match.dateTime);
      let dateLocate = new Date();

      let dateToUpdate = this.datepipe.transform((dateTime.getTime()), 'yyyy/MM/dd');
      let hourToUpdate = this.datepipe.transform((dateTime.getTime()), 'hh:mm a');

      this.matchsPreUpdate.push({
        id: match.id,
        roundId: match.roundId,
        homeName: match.homeName,
        awayName: match.awayName,
        two_options: match.two_options,
        status: match.status,
        dateToUpdate: new Date(dateToUpdate!),
        hourToUpdate: hourToUpdate
      });
    });

    this.matchesCount = this.matchsPreUpdate.length;

    let checkType = this.matchsPreUpdate.find(m => m.awayName == '');

    if (checkType != null) {
      this.noDynamicStyle = 1;
    }

    if (checkType == null) {
      this.noDynamicStyle = 0;
    }
  }

  removeMatchPreUpdate(index) {
    if (index > -1) {
      this.matchsPreUpdate.splice(index, 1);
    }
  }

  UpdateRound() {
    let updateRequest: UpdateRoundRequest = {
      id: this.RoundToUpdate.id,
      countryId: this.RoundToUpdate.countryId,
      roundName: this.RoundToUpdate.name,
      roundNameEng: this.RoundToUpdate.nameEng,
      roundNameItaly: this.RoundToUpdate.nameItaly,
      roundNameSpan: this.RoundToUpdate.nameSpan,
      newJackpot: this.RoundToUpdate.firstJackpot,
      roundValue: this.RoundToUpdate.value,
      parentId: this.RoundToUpdate.parentId,
      sportId: this.RoundToUpdate.betradarSportId,
      groupParent: 0,
      startDateTime: '',
      endDateTime: '',
      events: [],
      matches: [],
      userId: this.user.id,
      userName: this.user.login,
      userIpAddress: localStorage.getItem('currentUserIp')!,
      minutesDifference: parseInt(localStorage.getItem('minute')!, 10),
      skinId: Number(localStorage.getItem("creatorId")),
      isAssistant: Number(localStorage.getItem("isAssistant"))
    };

    if (this.RoundToUpdate.events != null && this.RoundToUpdate.events.length > 0) {
      updateRequest.roundName = `${this.homaNameToUpdate} X ${this.awayNameToUpdate}`;
      updateRequest.startDateTime = this.MakeDateStr(this.startDateToUpdate, this.startHourToUpdate);
      updateRequest.endDateTime = this.MakeDateStr(this.endDateToUpdate, this.endHourToUpdate);
      updateRequest.events = this.eventsToUpdate;
    }

    if (this.RoundToUpdate.matches != null && this.RoundToUpdate.matches.length > 0) {
      let matchsToUpdate: GetMatch[] = [];
      this.matchsPreUpdate.forEach(matchPreUpdate => {
        const date = this.MakeDateStr(matchPreUpdate.dateToUpdate, matchPreUpdate.hourToUpdate);
        const match: GetMatch = {
          id: matchPreUpdate.id,
          roundId: matchPreUpdate.roundId,
          homeName: matchPreUpdate.homeName,
          awayName: matchPreUpdate.awayName,
          two_options: matchPreUpdate.two_options,
          status: matchPreUpdate.status,
          dateTime: new Date(),
          dateTimeStr: date
        }
        matchsToUpdate.push(match);
      });
      updateRequest.startDateTime = new Date().toISOString();
      updateRequest.endDateTime = new Date().toISOString();
      updateRequest.matches = matchsToUpdate;
    }

    this.roundService.NewUpdateRound(updateRequest)
      .subscribe(data => {
        this.RoundToUpdate = <GetRound>{};
        this.modalCreationSuccess.show();
      }, error => {
        this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant(error.error), 4);
        console.log(error);
      });
  }

  MakeDate(date, time): Date {
    let splitDate = this.datepipe.transform(date.getTime(), 'dd/MM/yyyy');
    let [daySpliter, monthSpliter, yearSpliter] = splitDate!.split('/');
    let finalDate: Date = new Date(`${monthSpliter}-${daySpliter}-${yearSpliter}-${time}`);

    return finalDate;
  }

  MakeDateStr(date, time): string {
    let toSplitDate = this.datepipe.transform(date.getTime(), 'dd/MM/yyyy');
    let [daySpliter, monthSpliter, yearSpliter] = toSplitDate!.split('/');
    time = this.formatHours(time).trim();
    time = time.trim();
    if (time.length < 5)
      time = "0" + time;

    return `${yearSpliter}-${monthSpliter}-${daySpliter}-${time}`;
  }

  MakeDateStrForDynamicStyle(date, time): string {
    let toSplitDate = this.datepipe.transform(date.getTime(), 'dd/MM/yyyy');
    let [daySpliter, monthSpliter, yearSpliter] = toSplitDate!.split('/');
    time = this.formatHours(time).trim();
    time = time.trim();
    if (time.length < 5)
      time = "0" + time;

    return `${monthSpliter}-${daySpliter}-${yearSpliter}-${time}`;
  }

  AddMatchInMatchsPreUpdate() {
    let dateMatch = (document.getElementById("dateForNewMatchUpdate") as HTMLInputElement).value;
    let timeMatch = (document.getElementById("hourForNewMatchUpdate") as HTMLInputElement).value;

    let twoOptions = 0;
    if (this.is2ResultControlForUpdate.value) {
      twoOptions = 1;
    }
    else {
      twoOptions = 0;
    }

    this.matchsPreUpdate.push({
      id: 0,
      roundId: this.RoundToUpdate.id,
      homeName: this.homeNameForNewMatchUpdate,
      awayName: this.awayNameForNewMatchUpdate,
      two_options: twoOptions,
      status: 1,
      dateToUpdate: new Date(dateMatch),
      hourToUpdate: timeMatch
    });

    this.matchesCount = this.matchsPreUpdate.length;

    this.homeNameForNewMatchUpdate = "";
    this.awayNameForNewMatchUpdate = "";
  }

  isStarted() {
    const classReturn = new Date(this.RoundToUpdate.startDateTime).getTime() < new Date().getTime() ? 'startedColor' : '';
    return classReturn;
  }

  startRound() {
    const isStarted: string = this.isStarted();
    if(isStarted == 'startedColor'){
      return;
    }

    const request: StartRoundRequest = {
      roundId: this.RoundToUpdate.id,
      userId: this.user.id,
      userName: this.user.login,
      userIpAddress: localStorage.getItem('currentUserIp'),
      skinId: Number(localStorage.getItem("creatorId")),
      isAssistant: Boolean(localStorage.getItem("isAssistant"))
    }

    this.roundService.StartROund(request)
    .subscribe(data => {
      this.RoundToUpdate = <GetRound>{};
      this.modalCreationSuccess.show();
    }, error => {
      this.msgStandard(this.translate.instant('pg-round.fail'), this.translate.instant(error.error), 4);
      console.log(error);
    });
  }
}