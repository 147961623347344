<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-square-spin" [fullScreen]="true">
    <p style="color: white"></p>
</ngx-spinner>
<div id="topbar">
    <ol class="breadcrumb">
        <li class="crumb-active">
            <a href="#">{{ 'Manage Jackpot' | translate }}</a>
        </li>
    </ol>
</div>
<div id="content">

     
     
    <div class="panel">
        <div class="panel-heading">
            <div class="panel-title">
                <span class="material-icons default-icon">search</span>
                {{ 'Manage Jackpot' | translate }}
            </div>
        </div>
        <div class="panel-body">
            <div style="text-align: right;">
                <mat-form-field>
                    <mat-label>{{ 'Search' | translate }}...</mat-label>
                    <input matInput (keyup)="applyFilter($event)" #input>
                </mat-form-field>
            </div>
    

           <div class="mat-elevation-z8" style="overflow: auto;">
                <table mat-table [dataSource]="dataSource">
                 <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> {{'ID' | translate }}  </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{'CHAMPIONSHIP' | translate }}  </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="startDateTime">
                    <th mat-header-cell *matHeaderCellDef> {{'LAST UPDATE' | translate }}  </th>
                    <td mat-cell *matCellDef="let element"> {{element.startDateTime | date: 'dd/MM/yyyy hh:mm'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="firstJackpot">
                    <th mat-header-cell *matHeaderCellDef> {{'CURRENT JACKPOT' | translate }}  </th>
                    <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.firstJackpot * Currency.quote | currency:'':'' }} </td>
                  </ng-container>
                  <ng-container matColumnDef="recoveredJackpot">
                    <th mat-header-cell *matHeaderCellDef>{{'RECOVERED BALANCE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" [class.red-color]="setRedColor(element.firstJackpot,element.recoveredJackpot)" >{{Currency.currencySymbol}} {{element.recoveredJackpot * Currency.quote | currency:'':'' }} </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'ACTION' | translate }}  </th>
                    <td mat-cell *matCellDef="let element">
                        <button data-toggle="modal" data-target="#exampleModal9" class="btn btn-danger btn-gradient btn-sm text-white button-action" role="button" (click)="selectedRoundId(element.id, element.name)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div style="text-align: right;padding-top: 25px;padding-right: 50px;font-size: 15px;font-weight: bold;">
                  {{'Activated Jackpot Balance' | translate }} : <span [class.red-color]="setRedColor(0,this.activatedtotal)">{{Currency.currencySymbol}}{{this.activatedtotal * Currency.quote | currency:'':''}}</span> <br/>
                   {{'Past Jackpot Balance' | translate}}  :<span [class.red-color]="setRedColor(0,this.pasttotal)">{{Currency.currencySymbol}} {{this.pasttotal * Currency.quote | currency:'':''}}</span> <br/>
                   {{ 'General Balance' | translate}}  : <span [class.red-color]="setRedColor(0,this.generaltotal)">{{Currency.currencySymbol}}{{this.generaltotal * Currency.quote | currency:'':''}}</span>
                </div><br/>
                <mat-paginator [length]="jackpointCount" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons ></mat-paginator>
              </div> 

        </div>

        
<div class="modal fade" id="exampleModal9" tabindex="-1" aria-labelledby="exampleModalLabel9" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="exampleModalLabel9">{{'Confirm Finishing Championship' | translate}}</h4>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="
      height: 80px;">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-9">
            <p>{{ this.deletePopUpTitle | translate}}</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      
        <button type="button" class="btn btn-danger" (click)="confirmDelete()"  data-dismiss="modal">{{'Confirm' |
          translate}}</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">{{'Cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div mdbModal #frameMessage="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
        <div class="modal-content modal-lg  bg-theme" style="height: auto; max-height: 700px;">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="frameMessage.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3"style="
            height: 80px;">
                <p style="color: black" id="msg" [innerHtml]="msgToAdd"></p>
            </div>
            <div class="modal-footer d-flex justify-content-center">

                <a type="button" color="waves-light button-font"
                    style="padding:.54rem 2.14rem; background-color:#33a0df;" class="btn" mdbWavesEffect
                    (click)="frameMessage.hide()" data-dismiss="modal">OK</a>
            </div>

        </div>
    </div>
</div>

    </div>
</div>


