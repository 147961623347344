import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { LoginHistory } from '../../interface/loginhistory.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loginHistories: LoginHistory[];
  userAdmin: any;
  constructor(private loginService: LoginService) {
    this.userAdmin = loginService.getUserInfoFromCookie();
    this.loginService.getLoginHistories(this.userAdmin.id,this.userAdmin.login)
    .subscribe((data: LoginHistory[]) => {
      this.loginHistories = data;
      this.loginHistories.forEach(element => {
        element.strLoginDate = moment(element.loginDate).format('DD/MM/YYYY HH:mm:ss');
      });
    }, error => {
      console.log(error);
    });
  }

  ngOnInit(): void {
  }

}
