import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Jurisdiction } from '../interface/jurisdiction.interface';
import { InteractionService } from './interaction.service';
import { IpConfigService } from './Ip-Config.service';
import { JurisdictionCoupon } from '../interface/jurisdiction-coupon.interface';

@Injectable({
  providedIn: 'root'
})
export class JurisdictionsService {

  apiUrl = ''; // local test
  constructor(private http: HttpClient, private interactionService: InteractionService, private ipConfigService: IpConfigService) {
    this.apiUrl = ipConfigService.getApiUrl();
  }

  getJurisdictions() {
    return this.http.get<Jurisdiction[]>(this.apiUrl + '/jurisdictions');
  }

  getJurisdiction(jurisdictionId: number) {
    return this.http.get<Jurisdiction>(this.apiUrl + '/jurisdictions/' + jurisdictionId);
  }

  addJurisdiction(jurisdiction: Jurisdiction) {
    return this.http.post<any>(`${this.apiUrl}/jurisdictions/`, jurisdiction);
  }

  updateJurisdiction(jurisdictionId: number, jurisdiction: Jurisdiction) {
    return this.http.put<any>(`${this.apiUrl}/jurisdictions/` + jurisdictionId, jurisdiction);
  }

  addJurisdictionEntity(jurisdictionEntity: Jurisdiction) {
    return this.http.post<any>(`${this.apiUrl}/JurisdictionEntity/`, jurisdictionEntity);
  }

  generateJurisdictionCoupon() {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<JurisdictionCoupon>(`${this.apiUrl}/common/GenerateJurisdictionCoupon/`, { headers: header });
  }

  saveJurisdictionCoupon(userId: number, coupon: string, availableValue: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    let jsonToPass: JurisdictionCoupon = {
      "id": 0,
      "code": coupon,
      "availableValue": availableValue,
      "jurisdictionId": userId
    };

    return this.http.post<JurisdictionCoupon>(`${this.apiUrl}/common/SaveJurisdictionCoupon/`, jsonToPass, { headers: header });
  }

}
