<div id="topbar">
  <ol class="breadcrumb">
    <li class="crumb-active">
      <a href="#">{{ "RoundStyle" | translate }}</a> <br/>
    </li>
  </ol>
</div>

<div id="content">
  <div class="panel">
    <div class="panel-heading">
      <div class="panel-title row">
        <div>
          <span class="material-icons default-icon">list</span>
          {{ "RoundStyle" | translate }}
        </div>
      </div>
    </div>
    <div class="w-100">
      <mat-tab-group dynamicHeight>
        <mat-tab *ngIf="userId == 1" label="{{ 'CreateStyle' | translate }}">
          <div class="DefaultBody">
            <div class="mb-3">
              <div>
                <span class="material-icons default-icon">list</span>
                {{ "NewStyle" | translate }}
              </div>
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{ "StyleName" | translate }}</mat-label>
                <input matInput id="StyleNameCreate" [(ngModel)]="SendRoundStyle.Name" placeholder="" type="text"
                  name="styleRoundName" />
              </mat-form-field>
            </div>

            <div cdkDropList (cdkDropListDropped)="drop($event)" id="EventsStyles">
              <div id="EventStyle0" *ngFor="let eventStyle of SendEventsStyles; let i = index" cdkDrag class="dragCursor">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "EventStyleName" | translate }}</mat-label>
                  <input matInput id="StyleNameCreate" [(ngModel)]="eventStyle.Name" placeholder="" type="text"
                    name="styleRoundName" />
                </mat-form-field>

                <mat-form-field appearance="fill" class="ml-5">
                  <mat-label class="font-weight-bold">
                    {{ "Type" | translate }}
                  </mat-label>
                  <mat-select [(ngModel)]="eventStyle.Type">
                    <mat-option [value]="2">
                      {{ "UnderOver" | translate }}
                    </mat-option>
                    <mat-option [value]="3">
                      {{ "YesNO" | translate }}
                    </mat-option>
                    <mat-option [value]="1">
                      {{ "TwoOptions" | translate }}
                    </mat-option>
                    <mat-option [value]="0">
                      {{ "ThreeOptions" | translate }}
                    </mat-option>
                    <mat-option [value]="4">
                      {{ "Even/Odd" | translate }}
                    </mat-option>
                    <mat-option [value]="5">
                      {{"Home/Away" | translate}}
                    </mat-option>
                    <mat-option [value]="6">
                      {{"Home/Draw/Away" | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <button class="btnAddEventStyle ml-4" (click)="removeEventStyleToSend(i)">
                  <i class="trashIcon">
                    <img src="../../../assets/images/Icons/trash.svg" alt="">
                  </i>
                  <span>
                    {{'DeleteEvent' | translate}}
                  </span>
                </button>
              </div>
            </div>

            <div class="mb-4">
              <button (click)="GenerateEventStyleFields()" class="btnAddEventStyle">
                <i class="fa fa-plus plusIconGreen" aria-hidden="true"></i>
                {{"AddEventField" | translate}}
              </button>
            </div>

            <button class="btn btn-success px-2 mt-4" mat-flat-button (click)="CreateRoundStyle()">
              {{ "savebtn" | translate }}!!
            </button>
          </div>
        </mat-tab>

        <mat-tab label="{{ 'AlterStyle' | translate }}">
          <div class="DefaultBody">
            <div class="mb-3">
              <div>
                <span class="material-icons default-icon">list</span>
                {{ "AlterStyle" | translate }}
              </div>

              <div>
                <mat-form-field appearance="fill">
                  <mat-label class="font-weight-bold">
                    {{ "SelectStyle" | translate }}
                  </mat-label>

                  <mat-select [(ngModel)]="roundStyle">
                    <mat-option *ngFor="let roundStyleFromSelect of roundStyles" [value]="roundStyleFromSelect" (click)="setEventsStyleList(roundStyleFromSelect.roundEventsStyles)">
                      {{ roundStyleFromSelect.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="mt-4" *ngIf="roundStyle != null">
                <div>
                  <span class="material-icons default-icon">list</span>
                  {{ "StyleInfo" | translate }}
                </div>

                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "StyleName" | translate }}</mat-label>
                  <input matInput id="StyleNameCreate" [(ngModel)]="roundStyle.name" placeholder="" type="text"
                    name="styleRoundName" />
                </mat-form-field>

                <div cdkDropList (cdkDropListDropped)="dropUpdate($event)">
                  <div *ngFor="let eventStyle of roundEventsStyle; let i = index" cdkDrag class="dragCursor">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>{{ "EventStyleName" | translate }}</mat-label>
                      <input matInput id="StyleNameCreate" [(ngModel)]="eventStyle.name" placeholder="" type="text"
                        name="styleRoundName" />
                    </mat-form-field>
  
                    <mat-form-field appearance="fill" class="ml-5">
                      <mat-label class="font-weight-bold">
                        {{ "Type" | translate }}
                      </mat-label>
  
                      <mat-select [(ngModel)]="eventStyle.type">
                        <mat-option [value]="2">
                          {{ "UnderOver" | translate }}
                        </mat-option>
                        <mat-option [value]="3">
                          {{ "YesNO" | translate }}
                        </mat-option>
                        <mat-option [value]="1">
                          {{ "TwoOptions" | translate}}
                        </mat-option>
                        <mat-option [value]="0">
                          {{ "ThreeOptions" | translate }}
                        </mat-option>
                        <mat-option [value]="4">
                          {{ "Even/Odd" | translate }}
                        </mat-option>
                        <mat-option [value]="5">
                          {{"Home/Away" | translate}}
                        </mat-option>
                        <mat-option [value]="6">
                          {{"Home/Draw/Away" | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
  
                    <button class="btnAddEventStyle ml-4" (click)="removeEventStyleToUpdate(i)">
                      <i class="trashIcon">
                        <img src="../../../assets/images/Icons/trash.svg" alt="">
                      </i>
                      <span>
                        {{'DeleteEvent' | translate}}
                      </span>
                    </button>
                  </div>
                </div>

                <div class="mb-4">
                  <button (click)="addEventStyleToUpdate()" class="btnAddEventStyle">
                    <i class="fa fa-plus plusIconGreen" aria-hidden="true"></i>
                    {{"AddEventField" | translate}}
                  </button>
                </div>

                <button class="btn btn-success px-2 mt-4" mat-flat-button (click)="UpdateRoundStyle()">
                  {{ "savebtn" | translate }}!!
                </button>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab *ngIf="userId == 1" label="{{ 'RemoveStyle' | translate}}">
          <div class="ml-3 mt-3">
            <span class="material-icons default-icon">list</span>
            {{ "RemoveStyle" | translate }}
          </div>

          <div class="ml-3 mt-3">
            <mat-form-field appearance="fill" class="mr-3 my-auto">
              <mat-label class="font-weight-bold">
                {{ "SelectStyle" | translate }}
              </mat-label>

              <mat-select [(ngModel)]="roundStyleToRemove">
                <mat-option *ngFor="let roundStyleFromSelect of roundsStylesToDelete" [value]="roundStyleFromSelect" (click)="setEventsStyleList(roundStyleFromSelect.eventsStyles)">
                  {{ roundStyleFromSelect.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <a type="button" color="waves-light button-font" style="background-color: #ff4b4b" class="btn ml-3 mb-3" *ngIf="roundStyleToRemove != null"
              mdbWavesEffect (click)="frameDeleteStyle.show()">
              {{ "RemoveStyle" | translate }}
          </a>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<div mdbModal #frameDeleteStyle="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ backdrop: false, ignoreBackdropClick: true }">
  <div class="modal-dialog modal-notify" role="document" id="msgModalStandard">
    <div class="modal-content bg-theme">
      <div class="modal-header text-center" style="background-color: #ff4b4b;">
        <h4 class="modal-title w-100 font-weight-bold" style="color: white;">
          {{ 'RemoveStyle' | translate}}
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frameDeleteStyle.hide()" *ngIf="!loading">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body mx-3 text-center">
        <p style="color: black">
          {{'Do you really want to delete the round style?' | translate}}
        </p>
      </div>
      
      <div class="modal-footer d-flex justify-content-center">
        <a type="button" color="waves-light button-font" class="btn" mdbWavesEffect style="background-color: #33a0df" *ngIf="!loading"
          (click)="frameDeleteStyle.hide()" data-dismiss="modal">
          {{ "Back" | translate }}
        </a>

        <a type="button" color="waves-light button-font" style="background-color: #ff4b4b" class="btn" mdbWavesEffect (click)="RemoveRoundStyle()" *ngIf="!loading">
          {{ "Confirm Delete" | translate }}
        </a>

        <div class="spinner-border ml-4" role="status" *ngIf="loading">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>
