import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from './admin-layout.component';

import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { PlayersListComponent } from '../../pages/players/players-list/players-list.component';
import { SettingsComponent } from '../../pages/settings/settings/settings.component';
import { CreatePlayerComponent } from '../../pages/players/create-player/create-player.component';
import { SlidesComponent } from '../../pages/settings/slides/slides.component';
import { AdministratorsComponent } from '../../pages/settings/administrators/administrators.component';
import { DomainsComponent } from '../../pages/settings/domains/domains.component';
import { AuthGuard } from '../../auth/auth.guard';
import { TransactionsComponent } from 'src/app/pages/reports/transactions/transactions.component';
import { EntityCreditComponent } from 'src/app/pages/reports/entity-credit/entity-credit.component';
import { FinancialActivityComponent } from 'src/app/pages/reports/financial-activity/financial-activity.component';
import { ListJurisdictionComponent } from 'src/app/pages/jurisdiction/list-jurisdiction/list-jurisdiction.component';
import { AddJurisdictionComponent } from '../../pages/jurisdiction/add-jurisdiction/add-jurisdiction.component';
import { WithdrawComponent } from 'src/app/pages/reports/withdraw/withdraw.component';
import { EditPlayer2Component } from 'src/app/pages/players/edit-player2/edit-player2.component';
import { DocumentListComponent } from 'src/app/pages/document-list/document-list.component';
import { WhatsappListComponent } from 'src/app/pages/whatsapp-list/whatsapp-list.component';
import { CreditTransferComponent } from 'src/app/pages/credit-transfer/credit-transfer.component';
import { WithdrawalsComponent } from 'src/app/pages/withdrawals/withdrawals.component';
import { CouponListComponent } from 'src/app/pages/coupon-list/coupon-list.component';
import { CouponDetailComponent } from 'src/app/pages/coupon-detail/coupon-detail.component';
import { ManageroundsComponent } from 'src/app/pages/managerounds/managerounds.component';
import { PostresultComponent } from 'src/app/pages/postresult/postresult.component';
import { EditJackpotComponent } from 'src/app/pages/edit-jackpot/edit-jackpot.component';
import { ManageBetsComponent } from 'src/app/pages/manage-bets/manage-bets.component';
import { RoundStyleComponent } from '../../pages/round-style/round-style.component';
import { LogsListComponent } from 'src/app/pages/logs-list/logs-list.component';
import { APIPartnersComponent } from 'src/app/pages/apipartners/apipartners.component';

const adminLayoutRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'home',
            component: DashboardComponent
          },
          {
            path: 'settings',
            children: [
              { path: 'index', component: SettingsComponent },
              { path: 'banners', component: SlidesComponent, data: { slideType: 'left' } },
              { path: 'slides', component: SlidesComponent, data: { slideType: 'top' } },
              { path: 'admins', component: AdministratorsComponent },
              { path: 'domains', component: DomainsComponent },
              { path: 'StyleRound', component: RoundStyleComponent},
              { path: 'partners', component: APIPartnersComponent}
            ]
          },
          {
            path: 'jurisdiction',
            children: [
              { path: 'add', component: AddJurisdictionComponent },
              { path: 'list', component: ListJurisdictionComponent }

            ]
          },
          {
            path: 'reports',
            children: [
              { path: 'credits', component: EntityCreditComponent },
              { path: 'financial', component: FinancialActivityComponent },
              { path: 'transactions', component: TransactionsComponent },
              { path: 'refund', component: WithdrawComponent },
            ]
          },
          {
            path: 'documents',
            component: DocumentListComponent
          },
          {
            path: 'withdrawals',
            component: WithdrawalsComponent
          },
          {
            path: 'whatsapp-list',
            component: WhatsappListComponent
          },
          {
            path: 'credit-transfer',
            component: CreditTransferComponent
          },
          {
            path: 'coupon-list',
            component: CouponListComponent
          },
          {
            path: 'edit-jackpot',
            component: EditJackpotComponent
          },
          {
            path: 'coupon-detail',
            component: CouponDetailComponent
          },
          {
            path: 'managerounds',
            component: ManageroundsComponent
          },
          {
            path: 'postresult',
            component: PostresultComponent
          },
          {
            path: 'manage-bets/:roundId',
            component: ManageBetsComponent
          },
          {
            path: 'logs-list',
            component: LogsListComponent
          },
          {
            path: 'players',
            children: [
              { path: 'list', component: PlayersListComponent },
              { path: 'create', component: CreatePlayerComponent },
              { path: 'edit/:id', component: EditPlayer2Component },
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(adminLayoutRoutes),
  ],
  exports: [
    RouterModule
  ]
})

export class AdminLayoutRoutingModule { }
