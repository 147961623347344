import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IpConfigService } from './Ip-Config.service';
import { Domain } from '../interface/domain.interface';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  private apiUrl: string = '';
  constructor(private httpClient: HttpClient, private ipConfigService: IpConfigService) {
    this.apiUrl = ipConfigService.getApiUrl();
  }

  RecoverDomainsByUser(userId:number, creatorId:number) {
    const Header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.httpClient.get<Domain[]>(`${this.apiUrl}/domains/DomainsByUserId?userId=${userId}&creatorId=${creatorId}`);
  }
}
