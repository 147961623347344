import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CheckboxComponent } from 'angular-bootstrap-md';
import { Domain } from '../../../interface/domain.interface';
import { Language } from '../../../interface/language.interface';
import { LoginService } from '../../../services/login.service';
import { User } from '../../../interface/user.interface';


@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.css']
})
export class DomainsComponent implements OnInit {
  @ViewChildren('domainChk') domainChk: QueryList<CheckboxComponent>;
  @ViewChildren('newDomainChk') newDomainChk: QueryList<CheckboxComponent>;
  @ViewChildren("domainView") domainView: QueryList<ElementRef>;
  @ViewChildren("newDomainView") newDomainView: QueryList<ElementRef>;
  newDomainList = new Array<Domain>();
  domainList = new Array<Domain>();
  userAdmin: any;
  languageList: Language[];
  didFetchLanguage = false;
  didFetchDomain = false;
  casinosList: User[] = [];

  constructor(private loginService: LoginService) {
    this.userAdmin = loginService.getUserInfoFromCookie();
    this.fetchCountry();
    this.fetchDomain();
  }

  fetchCountry() {
    this.loginService.getLanguages().subscribe(
      data => {
        this.didFetchLanguage = true;
        this.languageList = data;
      }, error => {
        this.didFetchLanguage = true;
        console.log(error);
      }
    );
  }

  fetchDomain() {
    this.loginService.getAllDomains()
      .subscribe(
        data => {
          console.log(data);
          this.didFetchDomain = true;
          this.domainList = data;
        },
        error => {
          this.didFetchDomain = true;
          console.log(error);
        }
      )
  }

  ngOnInit(): void {
    this.loginService.RecoveryCasinoAndNationalLoginandId()
    .subscribe(
      casinos =>{
        this.casinosList = casinos;
      },
      error =>{
        console.log(error);
      }
    )
  }

  onClickAdd() {
    this.newDomainList.push(
      {
        id: 0,
        url: '',
        nationalId: 0,
        activated: 0,
        userId: this.userAdmin.id,
        casino: true,
        liveCasino: true,
        crashGames: true,
        name: "",
        qrCodeLink: ""
      }
    );
  }

  saveAllDomains() {
    this.newDomainView.forEach((newDomain: ElementRef, index) => {
      const url = newDomain.nativeElement.getElementsByTagName('input')[0].value;
      this.newDomainList[index].url = url;
      const nationalId = newDomain.nativeElement.getElementsByTagName('select')[0].value;
      this.newDomainList[index].nationalId = Number(nationalId);
    });

    this.newDomainChk.toArray().forEach((element, index) => {
      if (element.checked) {
        this.newDomainList[index].activated = 1;
      }
    });

    this.domainView.forEach((domain: ElementRef, index) => {
      const url = domain.nativeElement.getElementsByTagName('input')[0].value;
      this.domainList[index].url = url;
      const nationalId = domain.nativeElement.getElementsByTagName('select')[0].value;
      this.domainList[index].nationalId = nationalId;
    });

    this.domainChk.toArray().forEach((element, index) => {
      if (element.checked) {
        this.domainList[index].activated = 1;
      }
    });

    this.loginService.saveDomains(this.domainList.concat(this.newDomainList))
      .subscribe(
        data => {
          this.domainList = data;
          this.newDomainList = new Array<Domain>();
        },
        error => {
          console.log(error);
        }
      )
  }

  deleteDomain(id) {
    this.loginService.deleteDomain(id)
      .subscribe(
        data => {
          this.fetchDomain();
          this.newDomainList = new Array<Domain>();
        },
        error => {
          console.log(error);
        }
      )
  }

}
