import { Injectable } from '@angular/core';
import { IpConfigService } from './Ip-Config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Partner } from '../interface/Partner/Partner';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {
  apiUrl = '';
  header = new HttpHeaders({
    'Content-type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
  });
  constructor(private http: HttpClient, private ipConfigService: IpConfigService) { 
    this.apiUrl = ipConfigService.getApiUrl();
  }

  GetAllPartners(){
    return this.http.get<Partner[]>(`${this.apiUrl}/APIPartner`, {headers: this.header}).toPromise();
  }

  CreatePartner(partner){
    return this.http.post<any>(`${this.apiUrl}/APIPartner`, partner, {headers: this.header} );
  }

  UpdatePartner(partner){
    return this.http.put<any>(`${this.apiUrl}/APIPartner`, partner, {headers: this.header} );
  }
}
