<div class="switcher-wrapper">
  <ul class="switch-flat list-unstyled" *ngIf="switchType === 'flat'">
    <li class="language-item" (click)="changeLanguage('it')">
      <img class="switch-lang" src="/assets/images/flags/it.png" alt="it"/>
    </li>
    <li class="language-item" (click)="changeLanguage('ptbr')">
      <img class="switch-lang" src="/assets/images/flags/ptbr.png" alt="br"/>
    </li>
    <li class="language-item" (click)="changeLanguage('en')">
      <img class="switch-lang" src="/assets/images/flags/gb.png" alt="en"/>
    </li>
    <li class="language-item" (click)="changeLanguage('es')">
      <img class="switch-lang" src="/assets/images/flags/es.png" alt="es"/>
    </li>
    <!-- <li class="language-item" (click)="changeLanguage('Fr')">
      <img class="switch-lang" src="/assets/images/flags/Fr.png" alt="es"/>
    </li> -->
  </ul>

  <div class="switch-dropdown" mdbDropdown *ngIf="switchType === 'dropdown'">
    <a mdbDropdownToggle mdbWavesEffect type="button" class="waves-light" mdbWavesEffect>
      <img class="flag" src="/assets/images/flags/it.png" alt="en" *ngIf="currentLang === 'it'"/>
      <img class="flag" src="/assets/images/flags/ptbr.png" alt="en" *ngIf="currentLang === 'ptbr'"/>
      <img class="flag" src="/assets/images/flags/gb.png" alt="en" *ngIf="currentLang === 'en'"/>
      <img class="flag" src="/assets/images/flags/es.png" alt="en" *ngIf="currentLang === 'es'"/>
      <!-- <img class="flag" src="/assets/images/flags/Fr.png" alt="en" *ngIf="currentLang === 'Fr'"/> -->
      <span>{{getLanguageName()}}</span>
    </a>
    <div class="dropdown-menu dropdown-primary language-list">
      <a class="dropdown-item" *ngIf="currentLang !== 'it'"  (click)="changeLanguage('it')">
        <img class="flag" src="/assets/images/flags/it.png" alt="it"/>
        <span>Italiano</span>
      </a>
      <a class="dropdown-item" *ngIf="currentLang !== 'ptbr'" (click)="changeLanguage('ptbr')">
        <img class="flag" src="/assets/images/flags/ptbr.png" alt="tz"/>
        <span>Português</span>
      </a>
      <a class="dropdown-item" *ngIf="currentLang !== 'en'"  (click)="changeLanguage('en')">
        <img class="flag" src="/assets/images/flags/gb.png" alt="en"/>
        <span>English</span>
      </a>
      <a class="dropdown-item" *ngIf="currentLang !== 'es'" (click)="changeLanguage('es')">
        <img class="flag" src="/assets/images/flags/es.png" alt="tz"/>
        <span>Español</span>
      </a>
      <!-- <a class="dropdown-item" *ngIf="currentLang !== 'Fr'" (click)="changeLanguage('Fr')">
        <img class="flag" src="/assets/images/flags/Fr.png" alt="fr"/>
        <span>Frances</span>
      </a> -->
    </div>
  </div>

</div>
