<div class="wrapper">
  <div class="fs14">
  <app-navbar></app-navbar>
  </div>
    <div id="main" class="height-body">
      <app-sidebar id="app-sidebar" class="p-0" style="height: 100%;"></app-sidebar>
      <div id="admin-body">
          <router-outlet></router-outlet>
      </div>
    </div>
</div>
