import { DropDownList, Player, PlayerDocs } from './../../../interface/player.interface';
import { CountryService } from './../../../services/country.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Country } from 'src/app/interface/country.interface';
import { PlayerService } from 'src/app/services/player.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IpConfigService } from 'src/app/services/Ip-Config.service';
import { ConfirmedValidator } from '../../../helper/confirmed.validator';
import { LoginService } from '../../../services/login.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/services/toast.service';
import { Route, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-create-player',
  templateUrl: './create-player.component.html',
  styleUrls: ['./create-player.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'}
  ]
})
export class CreatePlayerComponent implements OnInit {
  apiUrl = ''; // local test
  imageUrl = '';
  public progress: number;
  public message: string;

  savingPlayer: boolean = false;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onUploadFinished = new EventEmitter();
  @ViewChild('validationModal') validationModal: any;
  router : RouterLink;
  countries: Country[];
  clubParents: DropDownList[];
  picker1: any;
  userAdmin:any;
  createPlayerRequest: Player;
  playerDocs: PlayerDocs[];
  ClubFatherCP: string;
  EmailCP: string;
  Email2CP: string;
  Email3CP: string;
  LoginCP: string;
  UsernameCP: string;
  LastnameCP: string;
  PasswordCP: string;
  ConfirmPasswordCP: string;
  GenderCP: string;
  BirthdayCP: Date;
  AddressCP: string;
  CityCP: string;
  CountryCP: string;
  ZipCodeCP: string;
  PhoneCP: string;
  CellPhoneCP: string;
  WhatsappCP: string;
  NotesCP: string;
  passMinLength = 8;
  IDCardFrontMsg: string;
  IDCardBackMsg: string;
  ProofOfAddressMsg: string;
  SelfieWithIdentityMsg: string;
  SelfieWithPaperMsg: string;
  IDCardFront: string;
  IDCardFrontNotes: string;
  IDCardFrontStatus: number;
  IDCardBack: string;
  IDCardBackNotes: string;
  IDCardBackStatus: number;
  ProofOfAddress: string;
  ProofOfAddressNotes: string;
  ProofOfAddressStatus: number;
  SelfieWithIdentity: string;
  SelfieWithIdentityNotes: string;
  SelfieWithIdentityStatus: number;
  SelfieWithPaper: string;
  SelfieWithPaperNotes: string;
  SelfieWithPaperStatus: number;
  createPlayerForm: FormGroup;
  errors: string[] = [];
  UserListPermit : boolean = false;
  loading: boolean = false;

  constructor(private playerService: PlayerService, private countryService: CountryService,
    private http: HttpClient,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private ipConfigService: IpConfigService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private loginService: LoginService,
    private _adapter: DateAdapter<any>,
    private routerLinkService: Router
  ) {
    this._adapter.setLocale('br');
    this.IDCardBack = '';
    this.IDCardBackNotes = '';
    this.IDCardBackStatus = -1;
    this.IDCardFront = '';
    this.IDCardFrontNotes = '';
    this.IDCardFrontStatus = -1;
    this.SelfieWithIdentity = '';
    this.SelfieWithIdentityNotes = '';
    this.SelfieWithIdentityStatus = -1;
    this.SelfieWithPaper = '';
    this.SelfieWithPaperNotes = '';
    this.SelfieWithPaperStatus = -1;
    this.ProofOfAddress = '';
    this.ProofOfAddressNotes = '';
    this.ProofOfAddressStatus = -1;

    this.playerDocs = new Array();
    this.createPlayerRequest = {} as Player;
    this.apiUrl = ipConfigService.getApiUrl();
    this.imageUrl = ipConfigService.getImageUrl();
    this.userAdmin = loginService.getUserInfoFromCookie();
    // loading countries
    this.countryService.getCountries()
      .subscribe((data: Country[]) => {
        this.countries = data;
      }, error => {
        console.log(error);
      });
    this.playerService.getClubParent(this.userAdmin.id,this.userAdmin.login)
      .subscribe((data: DropDownList[]) => {
        this.clubParents = data;
      }, error => {
        console.log(error);
      });

    this.createPlayerForm = this.formBuilder.group({
      clubParents: ['', Validators.required],
      login: [''],
      userName: [this.createPlayerRequest.userName, Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.required],
      whatsApp: [''],
      password: ['', Validators.compose([Validators.required, Validators.minLength(this.passMinLength)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(this.passMinLength)])],
      lastname: [''],
      email2: [''],
      email3: [''],
      gender: [''],
      birthDate: [''],
      address: [''],
      city: [''],
      country: [''],
      zipCode: [''],
      phone: [''],
      phone2: [''],
      cellPhone: [''],
      notes: [''],
      access: [false]

    }, {
      validator: ConfirmedValidator('password', 'confirmPassword')
    })

    //this.createPlayerForm.clubFather = -1;
  }

  // tslint:disable-next-line:typedef
  createPlayer(formData): void {
    if (this.createPlayerForm.valid) {
      this.loading = true;
      this.createPlayerRequest.parentId = formData.clubParents;
      this.createPlayerRequest.city = formData.city;
      this.createPlayerRequest.lastName = formData.lastname;
      this.createPlayerRequest.password = formData.password;
      this.createPlayerRequest.confirmPassword = formData.confirmPassword;
      this.createPlayerRequest.gender = formData.gender;
      this.createPlayerRequest.contact = formData.phone;
      this.createPlayerRequest.birthDate = formData.birthDate;
      this.createPlayerRequest.name = formData.name;
      this.createPlayerRequest.email2 = formData.email2;
      this.createPlayerRequest.email3 = formData.email3;
      this.createPlayerRequest.country = formData.country;
      this.createPlayerRequest.email = formData.email;
      this.createPlayerRequest.notes = formData.notes;
      this.createPlayerRequest.address = formData.address;
      this.createPlayerRequest.zipCode = formData.zipCode;
      this.createPlayerRequest.whatsapp = formData.whatsApp;
      this.createPlayerRequest.login = formData.userName;
      this.createPlayerRequest.userName = formData.userName;
      this.createPlayerRequest.cellPhone = formData.cellPhone;
      this.createPlayerRequest.phone = formData.phone;
      this.createPlayerRequest.phone2 = formData.phone2
      this.createPlayerRequest.access = formData.access;

      if (this.IDCardFront != '') {
        const doc:PlayerDocs= {
          id:0,
          fileName: this.createPlayerRequest.cNICFront,
          type: 1,
          status: this.IDCardFrontStatus,
          description: this.IDCardFrontNotes,
          userId:0,
          statusDesc:'',
          typeDesc:'',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr:'',
          responseDateStr:''
        }
        this.playerDocs.push(doc);
      }

      if (this.IDCardBack != '') {
        const doc:PlayerDocs= {
          id:0,
          fileName: this.createPlayerRequest.cNICBack,
          type: 5,
          status: this.IDCardBackStatus,
          description: this.IDCardBackNotes,
          userId:0,
          statusDesc:'',
          typeDesc:'',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr:'',
          responseDateStr:''
        }
        this.playerDocs.push(doc);
      }
      if (this.ProofOfAddress != '') {
        const doc:PlayerDocs= {
          id:0,
          fileName: this.createPlayerRequest.proofOfAddress,
          type: 2,
          status: this.ProofOfAddressStatus,
          description: this.ProofOfAddressNotes,
          userId:0,
          statusDesc:'',
          typeDesc:'',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr:'',
          responseDateStr:''
        }
        this.playerDocs.push(doc);
      }
      if (this.SelfieWithIdentity != '') {
        const doc:PlayerDocs= {
          id:0,
          fileName: this.createPlayerRequest.selfieWithIdentity,
          type: 3,
          status: this.SelfieWithIdentityStatus,
          description: this.SelfieWithIdentityNotes,
          userId:0,
          statusDesc:'',
          typeDesc:'',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr:'',
          responseDateStr:''
        }
        this.playerDocs.push(doc);
      }
      if (this.SelfieWithPaper != '') {
        const doc:PlayerDocs= {
          id:0,
          fileName: this.createPlayerRequest.selfieWithPaper,
          type: 4,
          status: this.SelfieWithPaperStatus,
          description: this.SelfieWithPaperNotes,
          userId:0,
          statusDesc:'',
          typeDesc:'',
          responseDate: new Date(),
          createdDate: new Date(),
          createdDateStr:'',
          responseDateStr:''
        }
        this.playerDocs.push(doc);
      }

      this.createPlayerRequest.docs = this.playerDocs;
      this.createPlayerRequest.skinPartnerId = parseInt(localStorage.getItem("creatorId"));
      this.playerService.addPlayer(this.createPlayerRequest)
        .subscribe((message: any) => {
          this.toastService.showNotification({ message: this.translateService.instant(message.msg), type: 'success' });
          this.reloadComponent();
        }, error => {
          this.toastService.showNotification({ message: this.translateService.instant(error.error.msg), type: 'danger' });
          this.loading = false;
        });

    }
    else {
      this.validateForm(this.createPlayerForm);
      this.validationModal.show();
      this.loading = false;
    }
  }

  reloadComponent() {
    const currentRoute = this.routerLinkService.url;
    this.routerLinkService.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.routerLinkService.navigateByUrl(currentRoute);
    })
  }

  validateForm(formGroup: FormGroup): void {
    this.errors = [];
    Object.keys(formGroup.controls).forEach(field => {
      let control = formGroup.controls[field];
      let errors = control.errors;
      if (errors === null || errors.count === 0) {
        return;
      }
      // Handle the 'required' case
      if (errors.required) {
        this.errors.push(`${field} is required`);
      }
      // Handle 'minlength' case
      if (errors.minlength) {
        this.errors.push(`${field} minimum length is ${errors.minlength.requiredLength}.`);
      }
      // Handle custom messages.
      if (errors.message){
        this.errors.push(`${field} ${errors.message}`);
      }


      // const control = formGroup.get(field);
      // control.markAsTouched({ onlySelf: true });
      // control.markAsDirty({ onlySelf: true });
    });
  }

  public uploadFile = (files, field) => {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files[0] as File;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.http.post(this.apiUrl + '/PlayerAction/UploadFiles', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        // if (event.type === HttpEventType.UploadProgress) {
        //   this.progress = Math.round(100 * event.loaded / event.total);
        // }
        if (event.type === HttpEventType.Response) {
          if (field === 'cnicF') {
            this.createPlayerRequest.cNICFront = event.body["dbPath"].toString();
            this.IDCardFront = this.imageUrl + "/" + this.createPlayerRequest.cNICFront;
            //this.IDCardFrontMsg = 'ID card (front) uploaded successfully.';
            this.IDCardFrontStatus = -1;
          }
          if (field === 'cnicB') {
            this.createPlayerRequest.cNICBack = event.body["dbPath"].toString();
            this.IDCardBack = this.imageUrl + "/" + this.createPlayerRequest.cNICBack;
            this.IDCardBackStatus = -1;
          }
          if (field === 'Taxcode') {
            this.createPlayerRequest.proofOfAddress = event.body["dbPath"].toString();
            this.ProofOfAddress = this.imageUrl + "/" + this.createPlayerRequest.proofOfAddress;
            this.ProofOfAddressStatus = -1;
            //this.ProofOfAddressMsg = 'Proof of address uploaded successfully.';
          }
          if (field === 'Photo') {
            this.createPlayerRequest.selfieWithIdentity = event.body["dbPath"].toString();
            this.SelfieWithIdentity = this.imageUrl + "/" + this.createPlayerRequest.selfieWithIdentity;
            this.SelfieWithIdentityStatus = -1;
            //this.SelfieWithIdentityMsg = 'Selfie With Identity uploaded successfully.';
          }
          if (field === 'SelfieWithPaper') {
            this.createPlayerRequest.selfieWithPaper = event.body["dbPath"].toString();
            this.SelfieWithPaper = this.imageUrl + "/" + this.createPlayerRequest.selfieWithPaper;
            this.SelfieWithPaperStatus = -1;
            //this.SelfieWithPaperMsg = 'Selfie With Paper uploaded successfully.';
          }
        }
      });
  }
  ngOnInit() {
    this.UserListPermit = this.userAdmin.permits.length > 0 ? this.userAdmin.permits.find(x => x.name === 'playerlist') == null ? false : true : true;
  }

}
