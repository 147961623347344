<div class="partners-body">
    <div class="panel-heading">
        <div class="panel-title">
            <span class="material-icons default-icon">list</span>
            {{ 'Partners' | translate }}
        </div>
    </div>

    <div class="partners-field_base_actions">
        <div class="partners-field_base_actions-newPartner" (click)="openModal(true, null)">
            {{ 'newPartner' | translate}}
        </div>

        <mat-form-field>
            <input matInput (keyup)="applyFilter($event)" placeholder="{{'Search' | translate}}..." #input>
        </mat-form-field>
    </div>

    <div class="partners-view">
        <table mat-table [dataSource]="dataSource" class="partner-table">
            <!-- Name Column -->
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef class="partner-table_name"> {{"Name" | translate}} </th>
                <td mat-cell *matCellDef="let element" class="partner-table_name">{{element.name}} </td>
            </ng-container>

            <!-- Juridisction Column -->
            <ng-container matColumnDef="Juridisction">
                <th mat-header-cell *matHeaderCellDef> {{"Jurisdiction" | translate}} </th>
                <td mat-cell *matCellDef="let element">{{element.userName}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">{{statusDictionary[element.status] | translate}} </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef class="partner-table_actions"> {{"Actions" | translate}} </th>
                <td mat-cell *matCellDef="let element" class="partner-table_actions">
                    <span class="Edit-Partner" (click)="openModal(false, element)">
                        <i class="fas fa-pen"></i>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[20, 40, 60, 100]"></mat-paginator>
    </div>
</div>

<div mdbModal #modalPartner="mdbModal" class="modal" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ backdrop: false, ignoreBackdropClick: true }">
    <div class="modal-dialog modal-notify modal-success modalPartner" role="document" id="msgModalStandard">
        <div class="modal-content">
            <div class="modalPartner-Header">
                <span *ngIf="newPartner">
                    {{"newPartner" | translate}}
                </span>

                <span *ngIf="!newPartner">
                    {{"editPartner" | translate}}
                </span>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="modalPartner.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modalPartner-Body">
                <div class="modalPartner-Body_Fields" *ngIf="newPartner">
                    <label class="modalPartner-Body_Fields-Title" for="Juridisction">{{"Jurisdiction" | translate}}</label>
                    <input type="text" [matAutocomplete]="auto" class="modalPartner-Body_Fields-Data" [(ngModel)]="partner.userName"
                        (keyup)="applyFilterUsers($event)">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let user of userSource.filteredData" [value]="user.login"
                            (click)="setPartnerUserId(user.id)">
                            {{ user.login }} - {{user.id}}
                        </mat-option>
                    </mat-autocomplete>

                </div>

                <div class="modalPartner-Body_Fields">
                    <label class="modalPartner-Body_Fields-Title" for="Name">{{"Name" | translate}}</label>
                    <input class="modalPartner-Body_Fields-Data" type="text" name="Name" id="Name"
                        [(ngModel)]="partner.name">
                </div>

                <div class="modalPartner-Body_Fields">
                    <label class="modalPartner-Body_Fields-Title">{{"Validity" | translate}}</label>
                    <div class="datePickerPartner" style="margin-right: 2%;" *ngIf="newPartner">
                        <input matInput [matDatepicker]="pickerStart" class="inputDatePicker" placeholder="{{'startDate' | translate}}" id="pickerStart">
                        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                    </div>
                    <div class="datePickerPartner">
                        <input matInput [matDatepicker]="pickerEnd" class="inputDatePicker" placeholder="{{'endDate' | translate}}" id="pickerEnd">
                        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                    </div>
                </div>

                <div class="modalPartner-Body_Fields">
                    <label class="modalPartner-Body_Fields-Title" for="IPAddress">{{"IP Address" | translate}}</label>
                    <input class="modalPartner-Body_Fields-Data" type="text" name="IPAddress" id="IPAddress"
                        [(ngModel)]="partner.ipAddress">
                </div>

                <div class="modalPartner-Body_Fields">
                    <label class="modalPartner-Body_Fields-Title" for="CallbackUrl">{{"CallBackUrl" | translate}}</label>
                    <input class="modalPartner-Body_Fields-Data" type="text" id="CallbackUrl"
                        [(ngModel)]="partner.callbackURL">
                </div>

                <div class="modalPartner-Body_Fields" *ngIf="!newPartner">
                    <label class="modalPartner-Body_Fields-Title" for="Status">Status</label>
                    <select class="modalPartner-Body_Fields-Data" id="Status" [(ngModel)]="partner.status">
                        <option value="0">{{"Active" | translate}}</option>
                        <option value="1">{{"Closed" | translate}}</option>
                        <option value="3">{{"Suspended" | translate}}</option>
                    </select>
                </div>
            </div>

            <div class="modalPartner-Footer">
                <div class="partnerModalBtn" *ngIf="newPartner" (click)="createPartner()">
                    {{"common.upload" | translate}}
                </div>

                <div class="partnerModalBtn" *ngIf="!newPartner" (click)="updatePartner()">
                    {{"common.upload" | translate}}
                </div>
            </div>
        </div>
    </div>
</div>