<div id="topbar">
    <ol class="breadcrumb">
        <li class="crumb-active">
            <a href="#">{{ 'whatsapp.title' | translate }}</a>
        </li>
    </ol>
</div>
<div class="panel">
    <div class="panel-heading">
        <div class="panel-title">
            <span class="material-icons default-icon">search</span>
            {{ 'whatsapp.title' | translate }}
        </div>
    </div>
    <div class="panel-body">
        <!--<div style="text-align: right;">
            <mat-form-field>
                <mat-label>{{ 'Search' | translate }}...</mat-label>
                <input matInput (keyup)="applyFilter($event)" #input>
            </mat-form-field>
        </div>-->
        <div class="mat-elevation-z8" style="overflow: auto;">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                <!-- Id Column -->
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef> No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- PlayerName Column -->
                <ng-container matColumnDef="PlayerName">
                    <th mat-header-cell *matHeaderCellDef> {{ 'whatsapp.PlayerName' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.playerName}} </td>
                </ng-container>

                <!-- PhoneNumber Column -->
                <ng-container matColumnDef="PhoneNumber">
                    <th mat-header-cell *matHeaderCellDef> {{ 'whatsapp.PhoneNumber' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
                </ng-container>

                <!-- BetUuid8 Column -->
                <ng-container matColumnDef="BetUuid8">
                    <th mat-header-cell *matHeaderCellDef> {{ 'whatsapp.BetUuid8' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.betUuid8}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>