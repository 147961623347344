export class Match {
    id: number;
    game: string;
    date: Date;
    hour: Date;
    matchType:string;
    homeName: string;
    awayName: string;
    matchDate:any;
    matchTime:any;
    two_options: number;
    totalGoals:string;
    totalCorners:string;
    yellowCards:string;
    cornerHomeTeam:string;
    cornerAwayTeam:string;
    totalFaults:string;
    shootsOnTarget:string;
}

export class BetradarTeamDetail {
    matchId: number;
    homeTeam: string;
    awayTeam: string;
    matchDate: string;
    
}