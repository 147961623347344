import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AppTranslateModule } from '../app.translate';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { CheckboxModule, ModalModule, DropdownModule, CollapseModule, ButtonsModule, CardsModule, InputsModule, WavesModule, IconsModule} from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    LanguageSwitcherComponent,
  ],
  exports: [
    LanguageSwitcherComponent,
    NavbarComponent,
    SidebarComponent,
    CommonModule,
    DropdownModule,
    ButtonsModule,
    WavesModule,
    CollapseModule,
    ButtonsModule,
    CardsModule,
    InputsModule,
    IconsModule,
    RouterModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    DragDropModule
  ],
  imports: [
    CommonModule,
    DropdownModule.forRoot(),
    ButtonsModule,
    WavesModule,
    CollapseModule,
    ButtonsModule,
    CardsModule,
    InputsModule,
    IconsModule,
    RouterModule,
    AppTranslateModule,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    DragDropModule
  ]
})
export class ComponentsModule {
}
